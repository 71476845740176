import { Create, Delete, SmartToy } from "@mui/icons-material"
import { Divider, IconButton, Skeleton, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import React, { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import TableCell from "shared/MkxTable/MkxTableCell"
import { customAutoReplyList, deleteCustomAutoReplyfn } from "Services/Settings/ChatSettings/CustomAutoReply"
import AddNewCustomReply from "./AddNewCustomReply"

const ChatSettings = () => {
  const client = useQueryClient()
  const [response, setResponse] = useState({})

  const { data: templates, isLoading } = useQuery(["CustomAutoReplys"], () => customAutoReplyList(), {
    refetchOnWindowFocus: false,
  })

  const { mutate: deleteCustomAutoReply } = useMutation(deleteCustomAutoReplyfn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        toast.success(response?.data?.message)
        client.refetchQueries("CustomAutoReplys")
      }
    },
  })

  return (
    <>
      <div className="flex flex-col">
        <span className="flex items-center justify-between p-[14px]">
          <span className="flex items-start gap-2 text-xl">
            <SmartToy />
            <span>
              <p className="font-semibold">Custom Auto Reply</p>
              <p className="text-sm">Set up and manage Custom Auto Replies for your account</p>
            </span>
          </span>
          <AddNewCustomReply setResponse={setResponse} response={response} />
        </span>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow className="bg-white bg-opacity-40">
                <TableCell isHead>Chat Input</TableCell>
                <TableCell isHead>Auto Response Preview</TableCell>
                <TableCell isHead>Response Type</TableCell>
                <TableCell isHead>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? [1, 2, 3, 4, 5, 6, 7, 8].map((i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    )
                  })
                : templates?.data?.data?.map((item) => {
                    return (
                      <TableRow key={item.id} className="cursor-pointer hover:bg-white hover:bg-opacity-40">
                        <TableCell>{item.customer_input}</TableCell>
                        <TableCell className=" !w-1/3">
                          <span
                            className="flex p-2 overflow-x-hidden border border-white border-opacity-25 rounded whitespace-nowrap text-ellipsis"
                            dangerouslySetInnerHTML={{
                              __html: item?.whatsapp_template?.body || item?.whatsApp_workflow?.workflow_title,
                            }}
                          ></span>
                        </TableCell>
                        <TableCell>{item.message_response_type}</TableCell>
                        <TableCell>
                          <span className="flex justify-center gap-2">
                            <IconButton size="small" className="group" onClick={() => setResponse(item)}>
                              <Create className="group-hover:!text-blue-500" />
                            </IconButton>
                            <IconButton
                              size="small"
                              className="group"
                              onClick={() =>
                                deleteCustomAutoReply({
                                  custom_auto_reply_id: item?.id,
                                })
                              }
                            >
                              <Delete className="group-hover:!text-red-500" />
                            </IconButton>
                          </span>
                        </TableCell>
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
}

export default ChatSettings
