import { FilterAlt } from "@mui/icons-material"
import BorderColorIcon from "@mui/icons-material/BorderColor"
import LockIcon from "@mui/icons-material/Lock"
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import axios from "config/axios"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { CustomIconButton } from "../../shared/CustomIconButton"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

const Porter = () => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [open1, setOpen1] = React.useState(false)
  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => setOpen1(false)
  const [data, setData] = useState([])
  const [tokens, setTokens] = useState("")
  const [newToken, setNewToken] = useState("")

  const porterDataList = () => {
    axios
      .get(`delivery_manager/porter/porter-super-admin/?all_store=all_store`)
      .then((response) => {
        setData(response?.data?.data)
      })
      .catch((error) => {
        console.log("", error)
      })
  }
  useEffect(() => {
    porterDataList()
  }, [])

  const editData = (event, rowToken) => {
    event.preventDefault()
    handleOpen()
    setTokens(rowToken)
  }

  const changedeliveryStatus = () => {
    axios
      .post(`delivery_manager/porter/porter-super-admin/`, { token: tokens })
      .then((response) => {
        toast.success(response?.data?.message)
        setTokens("")
        porterDataList()
        handleClose()
      })
      .catch((error) => {
        console.log("", error)
      })
  }

  const addTokenNew = () => {
    axios
      .post(`delivery_manager/porter/porter-super-admin/`, { token: newToken })
      .then((response) => {
        toast.success(response?.data?.message)
        setTokens("")
        porterDataList()
        handleClose1()
      })
      .catch((error) => {
        console.log("", error)
      })
  }

  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font-bold text-black">Porter</p>
            {/* <p className="text-black">View/Search Delivery Management</p> */}
          </div>
          <div className="p-2 pt-3">
            <Button variant="outlined" onClick={handleOpen1}>
              Add Token
            </Button>
            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="!border-0 !rounded-xl">
                <Typography id="modal-modal-title" variant="h6" component="h6">
                  <div
                    className="!text-center underline underline-offset-4"
                    style={{ fontFamily: "'Poppins', sans-serif" }}
                  >
                    <div>Super Admin Token</div>
                  </div>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div className="gap-y-3"></div>
                  <div>
                    <textarea
                      className="w-full border border-gray-400 rounded"
                      rows={"3"}
                      onChange={(event) => setNewToken(event.target.value)}
                    ></textarea>
                  </div>

                  <div className="items-center justify-between ">
                    <Button className="float-left top-3" variant="contained" color="warning" onClick={handleClose1}>
                      CANCEL
                    </Button>

                    <Button
                      variant="contained"
                      color="success"
                      className="float-right mr-3 top-3"
                      onClick={addTokenNew}
                    >
                      SAVE
                    </Button>
                  </div>
                </Typography>
              </Box>
            </Modal>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="!border-0 !rounded-xl">
                <Typography id="modal-modal-title" variant="h6" component="h6">
                  <div
                    className="!text-center underline underline-offset-4"
                    style={{ fontFamily: "'Poppins', sans-serif" }}
                  >
                    <div>Super Admin Token</div>
                  </div>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div className="gap-y-3"></div>
                  <div>
                    <textarea
                      className="w-full border border-gray-400 rounded"
                      rows={"3"}
                      value={tokens || ""}
                      onChange={(event) => setTokens(event.target.value)}
                    ></textarea>
                  </div>

                  <div className="items-center justify-between ">
                    <Button className="float-left top-3" variant="contained" color="warning" onClick={handleClose}>
                      CANCEL
                    </Button>

                    <Button
                      variant="contained"
                      color="success"
                      className="float-right mr-3 top-3"
                      onClick={changedeliveryStatus}
                    >
                      SAVE
                    </Button>
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
        <div className="flex flex-col !rounded bg-secondary border-secondary shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center py-2 bg-gray-100 rounded">
            <CustomIconButton>
              <FilterAlt className="text-black" />
            </CustomIconButton>
            <div>
              <input type="text" className="border border-black" />
            </div>
          </div>
          <TableContainer
            component={Paper}
            sx={{ width: "100%", maxHeight: 440 }}
            className="!text-black !mx-auto !bg-white overflow-auto px-2"
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    STORE NAME
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    TOKEN
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    CREATE DATE
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    UPDATE DATE
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    STATUS
                  </TableCell>

                  <TableCell
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    ACTION
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map((row) => (
                    <TableRow className="!cursor-pointer">
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.store_name ? row?.store_name : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.token ? row?.token : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.token ? row?.created_at.slice(0, 10) : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.token ? row?.updated_at.slice(0, 10) : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        <input
                          type="checkbox"
                          defaultChecked={row?.is_active}
                          //   onChange={(event) => handleChange(event, row?.user?.email)}
                          className="toggle checked:!border-green-500 !border-red-500 toggle-success"
                        />
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.store_name === "SuperAdmin" ? (
                          <BorderColorIcon
                            className="text-green-500"
                            onClick={(event) => editData(event, row?.token)}
                          />
                        ) : (
                          <LockIcon className="text-yellow-500" />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {/* {data?.length !== 0 ? (
              <TablePagination
                rowsPerPageOptions={[6]}
                count={data?.length}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null} */}
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default Porter
