import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { FormControl, IconButton, MenuItem, Select } from "@mui/material"
import React, { useEffect, useState } from "react"

const WorkingHourFeild = ({
  variable,
  Time,
  deleteStoreWorkingHour,
  AvailableDays,
  setAvailableDays,
  setAllData,
  AllData,
  index,
}) => {
  const [day, setDay] = useState(variable?.day)
  const [fromTime, setFromTime] = useState(variable?.from_time)
  const [toTime, setToTime] = useState(variable?.to_time)
  const ToTimeList = Time.slice(Time.indexOf(fromTime) + 1)
  const handleFeildDataChange = (e, type) => {
    if (type === "day") {
      setDay(e.target.value)
      setAvailableDays(() => {
        return [...AvailableDays.filter((d) => d != day), day]
      })
    } else if (type === "fromTime") {
      setFromTime(e.target.value)
    } else {
      setToTime(e.target.value)
    }
  }
  useEffect(() => {
    setAllData([
      ...AllData.map((item, idx) => {
        if (index === idx) {
          return {
            day: day,
            from_time: fromTime,
            to_time: toTime,
            id: item.id,
          }
        }
        return item
      }),
    ])
  }, [day, fromTime, toTime])

  return (
    <span className="flex gap-2 my-1">
      <span className="grid grid-cols-3 gap-2 w-[80%]">
        <FormControl sx={{ minWidth: 120 }} size="small" color="secondary">
          <Select
            size="small"
            color="secondary"
            value={day}
            onChange={(e) => handleFeildDataChange(e, "day")}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={variable?.day}>{variable?.day}</MenuItem>
            {AvailableDays?.map((dd, idx) => {
              return (
                <MenuItem key={idx} value={dd}>
                  {dd}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 120 }} size="small" color="secondary">
          <Select
            id="from_time"
            size="small"
            color="secondary"
            value={fromTime}
            onChange={(e) => handleFeildDataChange(e, "fromTime")}
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
          >
            {Time?.map((t) => {
              return (
                <MenuItem key={t} value={t}>
                  {t}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 120 }} size="small" color="secondary">
          <Select
            size="small"
            color="secondary"
            // value={variable?.from_time}

            value={toTime}
            onChange={(e) => handleFeildDataChange(e, "toTime")}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
          >
            {ToTimeList?.map((t) => {
              return (
                <MenuItem key={t} value={t}>
                  {t}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </span>
      <span className="flex gap-1">
        <IconButton
          color="error"
          className=""
          onClick={() => {
            deleteStoreWorkingHour(
              {
                store_working_hour_id: variable?.id,
              },
              variable?.day
            )
          }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </span>
    </span>
  )
}

export default WorkingHourFeild
