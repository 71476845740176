import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"

export const enableTemplateFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.enableTemplate, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
