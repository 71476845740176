import { FilterAlt } from "@mui/icons-material"
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import TextField from "@mui/material/TextField"
import axios from "config/axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import statusChange from "../../assets/update.png"
import { CustomIconButton } from "../../shared/CustomIconButton"
import { useFormik } from "formik"
import { Schema } from "./Schema"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  rounded: 2,
}

const HelpAndSupport = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [data, setData] = useState([])
  const [deleted, setDeleted] = useState([])
  const [open1, setOpen1] = useState(false)
  const [id, setId] = useState(null)
  const [searchQuery, setSearchQuery] = useState("")

  const handleOpen1 = () => {
    setOpen1(true)
  }
  const handleClose1 = () => setOpen1(false)

  const navigate = useNavigate()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const ResellersListData = () => {
    axios
      .get(`service-requests/`)
      .then((response) => {
        setData(response.data)
        console.log("all data help and support====>", response?.data)
      })
      .catch((error) => {
        console.log("", error)
      })
  }
  useEffect(() => {
    ResellersListData()
  }, [searchQuery])

  const formik = useFormik({
    initialValues: {
      status: "",
      tat: "",
      response: "",
    },
    validationSchema: Schema,
    onSubmit: (values, action) => {
      const reqbody = {
        status: values.status,
        tat: values.tat,
        response: values.response,
        service_request_id: id,
      }
      console.log(values)
      axios
        .post(`service-requests/`, reqbody)
        .then((response) => {
          // console.log("your status====>", response.data?.message);
          toast(response.data?.message)
          ResellersListData()
          handleClose1()
        })
        .catch((error) => {
          console.log("", error)
        })
    },
  })

  console.log(id)
  const openModal = (event, rowId) => {
    event.preventDefault()
    setId(rowId)
    handleOpen1()
  }
  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font-bold text-black">Help And Support</p>
            {/* <p className="text-black">View/Search Groups List</p> */}
          </div>
          <div className="p-2 pt-5">
            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <Box sx={{ ...style, width: 500 }} className="rounded-2xl !border-0 !shadow-sm !shadow-blue-900">
                <div
                  id="child-modal-title "
                  className="!text-black !underline !underline-offset-2  !font-bold pb-3 !text-center !text-xl"
                >
                  Change Status
                </div>

                <div>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="">Status : </div>
                    <select
                      className="w-full p-2 border border-gray-500 rounded"
                      id="status"
                      name="status"
                      onChange={formik.handleChange}
                      value={formik.values["status"]}
                    >
                      <option value="">--select status--</option>
                      <option value="open">open</option>
                      <option value="close">close</option>
                      <option value="inprogress">inprogress</option>
                      <option value="pending">pending</option>
                    </select>
                    <p className="text-xs text-red-500">{formik.errors.status}</p>
                    <div className="pt-3">Date : </div>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="date"
                      className="w-full "
                      id="tat"
                      name="tat"
                      onChange={formik.handleChange}
                      value={formik.values["tat"]}
                    />
                    <p className="text-xs text-red-500">{formik.errors.tat}</p>
                    <div className="pt-3">Decription : </div>
                    {/*  */}
                    <textarea
                      className="w-full p-2 border border-gray-500 rounded"
                      placeholder="Enter Your Decription..."
                      rows={"4"}
                      id="response"
                      name="response"
                      onChange={formik.handleChange}
                      value={formik.values["response"]}
                    ></textarea>
                    <p className="text-xs text-red-500">{formik.errors.response}</p>

                    {/* <button
                      type="submit"
                      className="px-3 py-1 mt-1 text-white rounded-lg bg-success"
                    >
                      submit
                    </button> */}
                    <div className="flex justify-between mt-2">
                      <Button type="submit" variant="contained" color="warning" onClick={handleClose1}>
                        cancel
                      </Button>
                      <Button type="submit" variant="contained" color="success">
                        submit
                      </Button>
                    </div>
                  </form>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
        <div className="flex flex-col !rounded bg-secondary border-secondary shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center py-2 bg-gray-100 rounded">
            <CustomIconButton>
              <FilterAlt className="text-black" />
            </CustomIconButton>
            <div>
              <input
                class="placeholder:italic placeholder:text-slate-400 block bg-white w-80 border border-slate-300 rounded-md py-2 px-2 pr-3 shadow-sm focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
                placeholder="Search for anything..."
                type="text"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />{" "}
            </div>
          </div>
          <TableContainer
            component={Paper}
            sx={{ width: "100%", maxHeight: 440 }}
            className="!text-black !mx-auto !bg-white overflow-auto px-2"
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    ID
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    STORE NAME
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    TITLE
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    IMAGE
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    DECRIPTION
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    DATE
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    STATUS
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    CHANGE STATUS
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .filter((row) => row?.title && row?.title.toLowerCase().includes(searchQuery.toLowerCase()))
                  .map((row, index) => (
                    <TableRow key={index + 1} className="!cursor-pointer">
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row.id}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row.store_id}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row.title}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row.file ? <img src={row.file} className="w-8 h-8 mx-auto" /> : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row.description ? row.description : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row.created_at ? row.created_at.slice(0, 10) : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row.status ? row.status : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        <img
                          src={statusChange}
                          onClick={(event) => openModal(event, row.id)}
                          className="w-8 h-8 mx-auto"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {data?.length !== 0 ? (
              <TablePagination
                rowsPerPageOptions={[6]}
                count={data?.length}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default HelpAndSupport
