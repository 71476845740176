import React, { useEffect, useState } from "react"
import axios from "config/axios"
import { Active, Inactive } from "../../shared/ActivaInactive"
import { toast } from "react-toastify"
import TextField from "@mui/material/TextField"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"

import EditIcon from "@mui/icons-material/Edit"

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { Checkbox, Dialog, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { useNavigate } from "react-router-dom"
import { DeleteTwoTone, ReportTwoTone } from "@mui/icons-material"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

const VariantManagement = () => {
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState("")

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [category, setCategory] = useState([])

  const [varientId, setVarientId] = useState(null)
  const [value1, setValue1] = useState("")
  const [currentPage, setCurrentPage] = useState(null)
  const [totalPage, setTotalPage] = useState(null)
  const navigate = useNavigate()

  // start
  const [newCategory, setNewCategory] = useState([])

  const [businessCategoryId, setBussinessCategoryId] = useState("")
  const [businessSubCategoryId, setBusinessSubCategoryId] = useState("")
  const [categoryId, setCategoryId] = useState("")
  const [businessData, setBusinessData] = useState([])
  const [subBusinessData, setSubBusinessData] = useState([])
  // end
  const [selectedIds, setSelectedIds] = useState([])
  const [isDelete, setIsDelete] = useState(false)

  // edit start
  const [rowData, setRowData] = useState([])
  const [ids, setIds] = useState(null)
  const [selectedItems, setSelectedItems] = useState([])

  // edit close

  const previousData = () => {
    setPage(page - 1)
  }
  const nextData = () => {
    setPage(page + 1)
  }

  const VariantList = () => {
    searchQuery === ""
      ? axios
          .get(
            `storage-varient-value-data-list/?page=${page}&category_id&product_storage_variant_name=${searchQuery}`,
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            setData(response.data.data)
            setCurrentPage(response?.data?.current_page)
            setTotalPage(response?.data?.total_pages)
          })
          .catch((error) => {
            alert(error.message)
          })
      : axios
          .get(`type-two-value-search-api-for-super-admin/?search_value=${searchQuery}`, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          })
          .then((response) => {
            setData(response.data.data)
            setCurrentPage(response?.data?.current_page)
            setTotalPage(response?.data?.total_pages)
          })
          .catch((error) => {
            alert(error.message)
          })
  }

  useEffect(
    () => {
      VariantList()
    },

    // eslint-disable-next-line
    [page, searchQuery]
  )

  const createData = () => {
    const subData = {
      id: ids,
      storage_variant_id: varientId || rowData?.variant_storage?.id,
      value: value1,
    }
    !ids
      ? axios
          .post(`add-storage-variant-value-data/`, subData, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          })
          .then((response) => {
            setValue1("")
            setVarientId("")
            toast(response?.data?.message)
            VariantList()
            handleClose()
          })
          .catch((error) => {
            console.log(error)
          })
      : axios
          .post(`storage-varient-value-data-list/`, subData, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          })
          .then((response) => {
            setValue1("")
            setVarientId("")
            toast(response?.data?.message)
            VariantList()
            handleClose()
          })
          .catch((error) => {
            console.log("error page", error.response)
            console.log(error)
          })
  }

  const redirect = () => {
    navigate("/variant-mangement-storage/variant-storage-list")
    // window.location.href="/variant-mangement-color/variant-list"
  }

  const EditData = (row) => {
    setRowData(row)
    setIds(row.id)
    setValue1(row?.value)
    console.log("row data===>", row)
  }

  const handleCloseModal = () => {
    setRowData([])
    setIds(null)
    setValue1("")
    setVarientId("")
    handleClose()
  }

  const CategoryList = () => {
    axios
      .post(
        `business-category-list-data/`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setBusinessData(response.data.business_category_list_data)
      })
      .catch((error) => {
        console.log("", error.response)
        console.log(error)
        // alert(error.message);
      })
  }

  useEffect(
    () => {
      CategoryList()
    },
    // eslint-disable-next-line
    []
  )

  const empty = () => {}

  const StoreList = () => {
    const reqbody = new FormData()
    reqbody.append(
      "business_category_ids",
      businessCategoryId !== "" ? businessCategoryId : rowData?.variant_storage?.category?.business_type?.category?.id
    )
    axios
      .post(`business-sub-category-list-data/`, reqbody, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setSubBusinessData(response?.data.business_sub_category_list)
      })
      .catch((error) => {
        console.log("error page", error.response)
        console.log(error)
      })
  }
  useEffect(
    () => {
      rowData.length !== 0 || businessCategoryId !== "" ? StoreList() : empty()
    },
    // eslint-disable-next-line
    [businessCategoryId, rowData]
  )

  const categoryListData = () => {
    const reqbody = new FormData()
    reqbody.append(
      "business_type_id",
      businessSubCategoryId !== "" ? businessSubCategoryId : rowData?.variant_storage?.category?.business_type?.id
    )

    axios
      .post(`category-list-of-business-type-api-for-admin/`, reqbody, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setNewCategory(response?.data?.data)
      })
      .catch((error) => {
        console.log("error page", error.response)
        console.log(error)
      })
  }

  useEffect(
    () => {
      rowData.length !== 0 || businessSubCategoryId !== "" ? categoryListData() : empty()
    },
    // eslint-disable-next-line
    [businessSubCategoryId, rowData]
  )

  const variantDataList = () => {
    const fd = new FormData()
    fd.append("category_id", categoryId !== "" ? categoryId : rowData?.variant_storage?.category?.id)
    axios
      .post(`type-two-list-of-category-api-for-admin/`, fd, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setCategory(response.data.data)
        console.log("varient data----- list===>", response.data.data)
      })
      .catch((error) => {
        console.log("page not found", error)
        // alert(error.message);
      })
  }

  useEffect(
    () => {
      rowData.length !== 0 || categoryId !== "" ? variantDataList() : empty()
    },
    // eslint-disable-next-line
    [categoryId, rowData]
  )

  const deleteColorValue = () => {
    const delData = window.confirm("Are You Sure Delete this storage variant...!")
    if (delData === true) {
      axios
        .post(
          `delete-type-two-value-api-for-superadmin/
        `,
          { variant_value_id: selectedItems },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message)
          setSelectedItems([])
          VariantList()
        })
        .catch((error) => {
          console.log("page not found", error)
        })
    } else {
      VariantList()
    }
  }
  // const handleSelectById = (event) => {
  //   const selectedItemId = event;
  //   setSelectedItems((prevSelectedItems) => {
  //     if (prevSelectedItems.includes(selectedItemId)) {
  //       return prevSelectedItems.filter((id) => id !== selectedItemId); // Deselect if already selected
  //     } else {
  //       return [...prevSelectedItems, selectedItemId]; // Select if not already selected
  //     }
  //   });
  // };

  const handleAllRowSelect = (event) => {
    setSelectedIds(event.target.checked ? data.map((i) => i.id) : [])
  }

  const handleSelectRow = (id) => {
    setSelectedIds((prevSelectedRow) =>
      prevSelectedRow.includes(id) ? prevSelectedRow.filter((i) => i !== id) : [...prevSelectedRow, id]
    )
  }

  const handleDelete = () => {
    axios
      .post(
        `delete-type-two-value-api-for-superadmin/`,
        { variant_value_ids: selectedIds },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        VariantList()
        setIsDelete(false)
        setSelectedIds([])
        toast.success(response.success.message)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const hadleClearAll = () => {
    setIsDelete(false)
    setSelectedIds([])
  }
  return (
    <>
      <div className="bg-slate-100">
        <div className="flex justify-between bg-slate-100">
          <div className="p-2">
            <p className="text-xl font font-bold text-black">Manage Storage Variants (Type Two)</p>
          </div>

          <div className="mt-6 flex gap-x-2 mr-3">
            <div>
              {selectedIds?.length !== 0 && (
                <>
                  <Button
                    startIcon={<DeleteTwoTone />}
                    className="!text-white bg-gradient-to-r from-red-500 to-rose-500"
                    onClick={() => setIsDelete(true)}
                  >
                    Delete
                  </Button>
                  <Dialog
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    PaperProps={{ className: "!max-w-[500px] w-96" }}
                  >
                    <div className="flex flex-col items-center gap-3 p-5">
                      <ReportTwoTone color="error" className="!text-5xl" />
                      <p className="text-xl">Are you sure!</p>
                      <p>You want to delete selected products?</p>
                    </div>
                    <span className="flex justify-end gap-3 pb-3 pr-3">
                      <Button
                        size="small"
                        variant="outlined"
                        color="inherit"
                        onClick={hadleClearAll}
                        className="!capitalize"
                      >
                        Cancel
                      </Button>
                      <Button
                        size="small"
                        disableElevation
                        color="error"
                        className="!capitalize"
                        variant="contained"
                        onClick={() => handleDelete()}
                      >
                        Delete
                      </Button>
                    </span>
                  </Dialog>
                </>
              )}
            </div>
            <Button
              onClick={handleOpen}
              className="!text-sm !font-bold !bg-white !px-2 !py-1 mt-2"
              style={{ border: "1px solid black" }}
            >
              + Add Type Two Value
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="!border-0 !rounded-xl">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  <div className="!text-center underline">
                    {ids === null ? "Add Type Two Value" : "Update Type Two Value"}
                  </div>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div className="gap-y-3">
                    <div className="gap-y-3">
                      <FormControl fullWidth>
                        <div className="py-2">Business Type</div>
                        <Select
                          value={
                            businessCategoryId || rowData?.variant_storage?.category?.business_type?.category?.id || ""
                          }
                          size="small"
                          onChange={(event) => setBussinessCategoryId(event.target.value)}
                        >
                          {businessData?.map((res) => {
                            return <MenuItem value={res.id}>{res.name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>

                      <FormControl fullWidth>
                        <div className="pt-2">Business Sub Type</div>
                        <Select
                          size="small"
                          value={businessSubCategoryId || rowData?.variant_storage?.category?.business_type?.id || ""}
                          className="!mt-3 !w-full"
                          onChange={(event) => setBusinessSubCategoryId(event.target.value)}
                        >
                          {subBusinessData?.map((res) => {
                            return <MenuItem value={res.id}>{res.name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <div className="pt-2">Category</div>
                        <Select
                          size="small"
                          className="!mt-5"
                          value={categoryId || rowData?.variant_storage?.category?.id || ""}
                          onChange={(event) => setCategoryId(event.target.value)}
                        >
                          {newCategory?.map((res) => {
                            return <MenuItem value={res.id}>{res.category}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <InputLabel id="demo-simple-select-label">Type Two</InputLabel>
                    <Select
                      className="!w-full !mt-2 !text-black"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      value={varientId || rowData?.variant_storage?.id}
                      onChange={(event) => setVarientId(event.target.value)}
                    >
                      {category
                        ? category.map((item) => (
                            <MenuItem value={item.id}>
                              {item.variant} ({item?.category})
                            </MenuItem>
                          ))
                        : null}
                    </Select>

                    <div className="!w-[100%]">
                      <InputLabel className="!mt-3 !text-gray-700" style={{ fontFamily: "'Poppins', sans-serif" }}>
                        Value :
                      </InputLabel>
                      <TextField
                        value={value1}
                        onChange={(event) => setValue1(event.target.value)}
                        id="outlined-basic"
                        // label="color"
                        variant="outlined"
                        className="!w-[100%] !mt-1"
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="justify-between items-center ">
                    <Button variant="outlined" className="float-left top-3" onClick={handleCloseModal}>
                      CANCEL
                    </Button>
                    {ids === null ? (
                      <Button
                        variant="outlined"
                        className="float-right top-3 mr-3"
                        style={{ marginRight: "15px" }}
                        onClick={createData}
                      >
                        CREATE
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        className="float-right top-3"
                        style={{ marginRight: "15px" }}
                        onClick={createData}
                      >
                        UPDATE
                      </Button>
                    )}
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>

        <div className="flex flex-col !my-3 !rounded  bg-dark bg-slate-100 shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center justify-between p-2 h-14 border-zinc-600">
            <div className="flex">
              {/* <CustomIconButton>
                <FilterAltIcon className="text-black" />
              </CustomIconButton> */}
              <input
                type="text"
                value={searchQuery}
                className="!border-3 !border-black !w-72 mx-3 px-2 rounded-md py-1"
                placeholder="search"
                style={{ border: "1px solid black" }}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
            <div className="flex gap-x-2">
              <div>
                {selectedItems.length > 0 && (
                  <Button variant="contained" className="!bg-red-500 !mt-1" size="small" onClick={deleteColorValue}>
                    All delete
                  </Button>
                )}
              </div>
              <div className="float-left">
                <button
                  onClick={redirect}
                  className="py-1 px-3 bg-white text-lg rounded-lg text-black"
                  style={{ border: "1px solid black" }}
                >
                  <VisibilityIcon /> Type Two List
                </button>
              </div>
            </div>
          </div>

          <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    <Checkbox onChange={handleAllRowSelect} />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    <div className="w-40">BUSINESS TYPE</div>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    <div className="w-48">BUSINESS SUB TYPE</div>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    <div className="w-32">CATEGORY</div>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    <div className="w-32">TYPE TWO</div>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    <div className="w-32">VALUE</div>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    STATUS
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    ACTION
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data?.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        <Checkbox
                          value={row?.id}
                          checked={selectedIds.filter((id) => id === row?.id)?.length === 1 ? true : false}
                          onChange={() => handleSelectRow(row.id)}
                        />
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.variant_storage?.category?.business_type?.category?.name}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.variant_storage?.category?.business_type?.name}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.variant_storage?.category?.title}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.variant_storage?.title}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.value}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.variant_storage?.category?.business_type?.category?.active_status === true ? (
                          <Active />
                        ) : (
                          <Inactive />
                        )}
                      </TableCell>
                      <TableCell className="!text-center !text-slate-700">
                        {/* <DeleteIcon className="!text-xl !text-red-600 !cursor-pointer" /> */}
                        <button onClick={handleOpen}>
                          <EditIcon
                            className="!text-xl !cursor-pointer !text-green-600"
                            onClick={() => EditData(row)}
                          />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="!float-right py-3 relative !right-0" style={{ marginRight: "0px" }}>
            <button onClick={nextData} className="float-right">
              {currentPage}...{totalPage} <ArrowForwardIosIcon />
            </button>
            <button onClick={previousData} className="float-right">
              <ArrowBackIosIcon />
            </button>
          </div>

          {/* edit funtionality start */}
          {/* <Modal
            open={open1}
            onClose={handleClose1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="!border-0 !rounded-xl">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div className="!text-center underline">Add Variant</div>
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="gap-y-3">
                  <InputLabel id="demo-simple-select-label">Name</InputLabel>
                  <Select
                    className="!w-80 !mt-2 !text-black"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                  >
                    {
                      newCategory?.map((elem) =>
                        <MenuItem>{elem?.title}</MenuItem>
                      )
                    }


                  </Select>
                  <TextField
                    id="outlined-basic"
                    label="value"
                    variant="outlined"
                    className="!w-80 !mt-2" />
                </div>
                <div className="justify-between items-center ">
                  <Button variant="outlined" className="float-left top-3" onClick={handleClose1}>CANCEL</Button>
                  <Button variant="outlined" className="float-right top-3" onClick={createData}>CREATE VARIANT</Button>
                </div>
              </Typography>
            </Box>
          </Modal> */}
          {/* edit funtionality end */}
        </div>
      </div>
    </>
  )
}

export default VariantManagement
