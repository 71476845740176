import { Pagination } from "@mui/material"
import React from "react"

const MkxPagination = ({ count, page, onChange, variant = "outlined", shape = "rounded", ...rest }) => {
  return (
    <span className="flex items-center justify-end p-2">
      {count && count !== 1 && (
        <Pagination variant={variant} shape={shape} count={count} page={page} onChange={onChange} {...rest} />
      )}
    </span>
  )
}

export default MkxPagination
