import * as React from "react"
import { Checkbox } from "@mui/material"
import axios from "config/axios"
import { useEffect } from "react"
import { useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { flatten } from "lodash"
import { toast } from "react-toastify"
import CircularProgress from "@mui/material/CircularProgress"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { green } from "@mui/material/colors"
import { FaMaximize, FaMinimize } from "react-icons/fa6"

const Permissions = () => {
  const { name } = useParams()
  const [permData, setPermData] = useState([])
  const [selectedUpdateModule, setSelectedUpdateModule] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [searchQuery1, setSearchQuery1] = useState("")
  const [open, setOpen] = React.useState(false)
  const [open1, setOpen1] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const moduleName = useLocation()

  const [toggle, setToggle] = useState(true)
  const [rowData, setRowData] = useState([])

  const permissionList = () => {
    axios
      .get(`group-api-for-superadmin/?group_id=${name}`)
      .then((response) => {
        setPermData(response?.data?.data)
        setOpen(true)
      })
      .catch((error) => {
        console.log("", error)
      })
  }
  useEffect(() => {
    name && permissionList()
    searchQuery && permissionList()
  }, [name, searchQuery])

  useEffect(() => {
    const filteredIds = []
    for (let i in permData) {
      const filteredPermissions = permData[i]?.permission?.filter((item) => item.is_permission === true)
      const filteredIdsForCurrentItem = filteredPermissions?.map((item) => item?.id)
      filteredIds.push(filteredIdsForCurrentItem)
    }
    setSelectedUpdateModule(flatten(filteredIds))
  }, [permData])

  const updateData = () => {
    setOpen1(true)

    const uniqueArray = []

    for (const value of selectedUpdateModule) {
      if (!uniqueArray.includes(value)) {
        uniqueArray.push(value)
      }
    }

    const permissionData = {
      group_id: Number(name),
      permission_ids: uniqueArray,
    }

    axios
      .post(`group-api-for-superadmin/`, permissionData)
      .then((response) => {
        toast.success(response?.data?.message)
        setOpen1(false)
        permissionList()
      })
      .catch((error) => {
        console.log("", error)
      })
  }

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  }

  const searchData = (even) => {
    setSearchQuery(even.split(" ").join("_"))
  }

  const details = (row) => {
    setRowData(row)
    setToggle(false)
  }

  const searchData1 = (even) => {
    setSearchQuery1(even.split(" ").join("_"))
  }

  const handleClose = () => {
    setToggle(true)
  }

  const handleSelectAll = (event, moduleId) => {
    event.target.checked === true
      ? setSelectedUpdateModule([
          ...selectedUpdateModule,
          ...permData.find((permission) => permission.id === moduleId).permission.map((i) => i.id),
        ])
      : setSelectedUpdateModule(
          selectedUpdateModule?.filter(
            (item) =>
              !permData
                ?.find((permission) => permission?.id === moduleId)
                ?.permission?.map((i) => i.id)
                ?.includes(item)
          )
        )
  }

  return (
    <>
      <div className="">
        <div className="flex justify-between p-2">
          <div className="text-xl font-bold text-black">
            {/* Admin Groups{" "} */}
            {moduleName?.state?.name}
            {/* <span className="text-sm text-green-400">{moduleName?.state?.name}</span> */}
          </div>
          <div className="float-right">
            <Box sx={{ position: "relative" }}>
              <Button
                variant="contained"
                color="success"
                className="float-right mr-3"
                sx={buttonSx}
                disabled={open1}
                onClick={updateData}
              >
                update
              </Button>
              {open1 && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </div>
        </div>

        {toggle === true ? (
          <div className="shadow grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 mx-auto !gap-4 shadow-gray-400 m-2 !h-[80vh] overflow-y-auto rounded p-2">
            {open ? (
              permData.length > 0 ? (
                permData &&
                permData?.map((row) => {
                  return (
                    <div className="rounded bg-sky-400 h-72">
                      <div className="flex items-center justify-between p-2 font-bold border-b text-slate-800">
                        <div className="flex items-center gap-2 uppercase">
                          <Checkbox
                            size="small"
                            className="!p-1 !m-0"
                            onChange={(event) => handleSelectAll(event, row.id)}
                          />
                          {row?.module_name}
                        </div>
                        <div className="flex items-center gap-x-2">
                          <input
                            type="text"
                            placeholder="search"
                            className="w-40 px-2 py-1 rounded outline-none placeholder:font-normal placeholder:text-sm hover:border-red-400"
                            onChange={(event) => searchData(event.target.value)}
                          />

                          <FaMaximize className="cursor-pointer" onClick={() => details(row)} />
                        </div>
                      </div>
                      <div className="h-[247px] overflow-auto">
                        {row?.permission.length !== 0 ? (
                          row?.permission
                            ?.filter((row) => row?.name.toLowerCase().includes(searchQuery))
                            ?.map((elem) => (
                              <div className="flex !border-[0.5px] hover:bg-sky-300 cursor-pointer">
                                <div>
                                  <Checkbox
                                    size="small"
                                    checked={selectedUpdateModule?.map((i) => i).includes(elem?.id)}
                                    onChange={(event) => {
                                      {
                                        !selectedUpdateModule?.map((i) => i).includes(elem?.id)
                                          ? setSelectedUpdateModule([...selectedUpdateModule, elem?.id])
                                          : setSelectedUpdateModule(() =>
                                              selectedUpdateModule?.filter((i) => i !== elem?.id)
                                            )
                                      }
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                </div>
                                <div className="pt-2 text-start">
                                  <span className="text-sm text-black">{elem?.name}</span>
                                </div>
                              </div>
                            ))
                        ) : (
                          <div className="mx-auto font-bold text-center text-white">No Data Found</div>
                        )}
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className="text-xl font-bold text-center text-red-400">No Data Found !</div>
              )
            ) : (
              <div className="items-center mx-auto text-center">
                <CircularProgress color="inherit" />
              </div>
            )}
          </div>
        ) : (
          <div className="shadow mx-auto !gap-4  shadow-gray-400 m-2 !h-[80vh] overflow-y-auto rounded p-2">
            <div className="rounded bg-sky-400">
              <div className="flex items-center justify-between p-2 font-bold border-b text-slate-800">
                <div>{rowData?.module}</div>
                <div className="flex items-center gap-x-2">
                  <input
                    type="text"
                    placeholder="search"
                    className="px-1 rounded outline-none placeholder:font-normal placeholder:text-sm w-72 hover:border-red-400"
                    onChange={(event) => searchData1(event.target.value)}
                  />
                  <FaMinimize className="cursor-pointer" onClick={handleClose} />
                </div>
              </div>
              <div className="">
                {rowData?.permission
                  ?.filter((row) => row?.name.toLowerCase().includes(searchQuery1))
                  ?.map((elem) => (
                    <div className="flex !border-[0.5px] hover:bg-sky-300 cursor-pointer">
                      <div>
                        <Checkbox
                          size="small"
                          checked={selectedUpdateModule?.map((i) => i).includes(elem?.id)}
                          onChange={(event) => {
                            {
                              !selectedUpdateModule?.map((i) => i).includes(elem?.id)
                                ? setSelectedUpdateModule([...selectedUpdateModule, elem?.id])
                                : setSelectedUpdateModule(() => selectedUpdateModule?.filter((i) => i !== elem?.id))
                            }
                          }}
                          inputProps={{
                            "aria-label": "controlled",
                          }}
                        />
                      </div>
                      <div className="pt-2 text-start">
                        <span className="text-sm text-black">
                          {elem?.name.split("_").join(" ").toLocaleUpperCase()}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Permissions
