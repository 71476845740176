import { Delete, Edit } from "@mui/icons-material"
import { Switch, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import {
  changeLoyalityPointStatusFn,
  deletePointSettingFn,
  loyalityPointSettingFn,
} from "Services/Settings/LoyalityPoint"
import { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { CustomIconButton } from "shared/CustomIconButton"
import CustomPagination from "shared/CustomPagination"
import CustomInput from "shared/MkxInput"
import TableCell from "shared/MkxTable/MkxTableCell"
import { MkxTableLoader } from "shared/MkxTable/MkxTableLoader"
import AddLoyalityPoint from "./AddLoyalityPoint"
import NoDataFound from "shared/NoDataFound"

const LoyalityPoint = () => {
  const { store_id } = useParams()
  const [selected, setSelected] = useState(false)
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)

  const {
    data: loyalityPoint,
    isLoading,
    refetch,
  } = useQuery(["loyalityPoint", search, page, store_id], () => loyalityPointSettingFn({ store_id, search, page }))

  const { mutate: deleteLoyality } = useMutation(deletePointSettingFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        refetch()
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const { mutate: changeLoyalityStatus } = useMutation(changeLoyalityPointStatusFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        refetch()
      } else {
        toast.error(response.data.message)
      }
    },
  })

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <CustomInput
          size="small"
          placeholder="Search Loyality Point..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <AddLoyalityPoint selected={selected} setSelected={setSelected} />
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell isHead>Created Date</TableCell>
              <TableCell isHead>Loyality Value</TableCell>
              <TableCell isHead>Max Loyality Point</TableCell>
              <TableCell isHead>Status</TableCell>
              <TableCell isHead>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <MkxTableLoader loading={isLoading} row={4} />
            {loyalityPoint?.data?.data?.map((item) => {
              return (
                <TableRow>
                  <TableCell>{item.created_at}</TableCell>
                  <TableCell>{item.value}</TableCell>
                  <TableCell>{item.max_points}</TableCell>
                  <TableCell>
                    <Switch
                      checked={item.active_status}
                      onChange={() => changeLoyalityStatus({ store_id, loyalty_id: item.id })}
                    />
                  </TableCell>
                  <TableCell>
                    <CustomIconButton color="success" onClick={() => setSelected(item)}>
                      <Edit />
                    </CustomIconButton>
                    <CustomIconButton
                      color="error"
                      onClick={() => deleteLoyality({ store_id, loyalty_ids: [item.id] })}
                    >
                      <Delete />
                    </CustomIconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <CustomPagination data={loyalityPoint} setPage={setPage} />
        <NoDataFound data={loyalityPoint} />
      </TableContainer>
    </div>
  )
}

export default LoyalityPoint
