import { API_URLS } from "../../../config/apiUrls";
import axiosInstance from "../../../config/axios";


export const resellerStoreListFn = (redBody) => {
  try {
    const response = axiosInstance.get(API_URLS.resellerStoreList, redBody);
    return response;
  } catch ({ error }) {
    throw new Error(error.message);
  }
};
export const allResellersFn = (redBody) => {
  try {
    const response = axiosInstance.get(API_URLS.resellersList, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const resetPasswordFn = (redBody) => {
  try {
    const response = axiosInstance.post(API_URLS.resellerResetPassword, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateCommissionFn = (redBody) => {
  try {
    const response = axiosInstance.post(API_URLS.updateResellerCommission, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}