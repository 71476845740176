import axiosInstance from "config/axios"

export const storePlanFn = (redBody) => {
  try {
    const response = axiosInstance.get(`store-plan-api-of-superadmin/`, { params: redBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
