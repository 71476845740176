import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"

export const addOperationsFn = (redBody) => {
  try {
    const response = axiosInstance.post(API_URLS.store.operations, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
