import axiosInstance from "config/axios"

export const paymentlistFn = (reqBody) => {
  try {
    const response = axiosInstance.get(`payment-mode-api-of-superadmin/`, { params: reqBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
