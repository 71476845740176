import BorderColorIcon from "@mui/icons-material/BorderColor"
import { Skeleton, TextField } from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { default as axios, default as axiosInstance } from "../../../config/axios"
import { Active, Inactive, Unmaped } from "../../../shared/ActivaInactive"
import { CustomIconButton } from "../../../shared/CustomIconButton"
import AddSubCategory from "./addSubCategory"

const BusinessSubCategory = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [data, setData] = useState()
  const [businessSubCategory] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const BuisenesSubCategoryList = async () => {
    setIsLoading(true)

    try {
      const response = await axiosInstance.get(
        `business-sub-category-search-api-for-super-admin/?search_value=${searchQuery}`
      )
      setData(response?.data.data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    BuisenesSubCategoryList()
  }, [searchQuery])

  const initialValues = {
    store: "",
    business_sub_category: businessSubCategory,
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,

    onSubmit: (values, action) => {
      const reqBody = {
        store: values.store_id,
        business_sub_category: businessSubCategory,
      }

      axios
        .post(`map-business-sub-category-store/`, { ...reqBody })
        .then((response) => {
          console.log(response.data)
          BuisenesSubCategoryList()
          reqBody.business_sub_category.length !== 0
            ? toast.success(
                "Business Sub Category with IDs " + reqBody.business_sub_category + " are maped to " + reqBody.store
              )
            : toast.warning("Please Select A Business Sub Category First")
        })
        .catch((error) => {
          console.log(error)
        })
      action.resetForm()
    },
  })

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="p-3">
          <p className="text-xl font-bold text-gray-800 font">Business Sub Type</p>
          <p>Manage Business Sub Type List</p>
        </div>
        <AddSubCategory />
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col !rounded bg-dark shadow-card shadow-zinc-600 m-2">
          <TableContainer component={Paper} className="max-h-[80vh]">
            <div className="flex items-center justify-between p-2 border-zinc-600">
              <TextField
                placeholder="Search Business Sub Type..."
                size="small"
                value={searchQuery}
                className="!bg-white"
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
            <Table aria-label="sticky table" size="small">
              <TableHead className="!bg-gray-200">
                <TableRow>
                  <TableCell className="!text-center border-r border-white !font-bold">S/NO</TableCell>
                  <TableCell className="!text-start border-r border-white !font-bold">BUSINESS TYPE</TableCell>
                  <TableCell className="!text-start border-r border-white !font-bold">BUSINESS SUB TYPE</TableCell>
                  <TableCell className="!text-center border-r border-white !font-bold">STATUS</TableCell>
                  <TableCell className="!text-center border-r border-white !font-bold">ACTION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map(() => {
                      return (
                        <TableRow>
                          {[1, 2, 3, 4, 5]?.map(() => {
                            return (
                              <TableCell>
                                <Skeleton />
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })
                  : data &&
                    data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((cat, index) => {
                      return (
                        <TableRow>
                          <TableCell className="!text-center border-r" component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell className="!text-start border-r">{cat?.business_type}</TableCell>
                          <TableCell className="!text-start border-r">{cat.name}</TableCell>
                          <TableCell className="!text-center border-r">
                            <div className="flex items-center justify-center ">
                              {cat.map_status === null ? <Unmaped /> : cat.map_status === 1 ? <Active /> : <Inactive />}
                            </div>
                          </TableCell>
                          <TableCell className="!text-center border-r">
                            <CustomIconButton>
                              <BorderColorIcon className="!text-center text-green-500" />
                            </CustomIconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
              </TableBody>
            </Table>

            {data && data?.length !== 0 ? (
              <TablePagination
                rowsPerPageOptions={[]}
                count={data?.length || 0}
                component="div"
                rowsPerPage={rowsPerPage || 0}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </TableContainer>
        </div>
      </form>
    </>
  )
}

export default BusinessSubCategory
