import { Button, Dialog, IconButton, TextField } from "@mui/material"
import React, { useState } from "react"
import { AddCircle, Close } from "@mui/icons-material"
import { toast } from "react-toastify"
import { useFormik } from "formik"
import { useMutation, useQueryClient } from "react-query"
import { addVariablesFn } from "Services/Templates/Variables"

const AddVariables = () => {
  const [open, setOpen] = useState(false)

  const client = useQueryClient()
  const initialValues = {
    key: "",
  }
  const { mutate: addVariables } = useMutation(addVariablesFn, {
    onSuccess: (response) => {
      if (response.data.response_code) {
        toast.success(response.data.message)
        client.refetchQueries("variables")
        setOpen(false)
        formik.resetForm()
      } else {
        toast.error(response.data.message)
      }
    },
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      addVariables({
        key: formik.values.key.toLocaleLowerCase().replace(" ", "_"),
      })
    },
  })

  return (
    <>
      <Button variant="outlined" className="!rounded-full" startIcon={<AddCircle />} onClick={() => setOpen(true)}>
        Add Variable
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        component="form"
        onSubmit={formik.handleSubmit}
        PaperProps={{
          className: "!flex !flex-col !min-w-[30%]",
        }}
      >
        <span className="relative flex items-center justify-between w-full border-b ">
          <p className="p-2 pl-5 font-semibold">Add New Variable</p>
          <IconButton onClick={() => setOpen(true)} size="small" className="absolute top-1 right-1">
            <Close />
          </IconButton>
        </span>
        <span className="flex flex-col p-4">
          <TextField
            id="key"
            name="key"
            size="small"
            label="Variable Name*"
            placeholder="customer_name"
            value={formik.values.key.toLocaleLowerCase().replace(" ", "_")}
            onChange={formik.handleChange}
          />
          <span className="flex justify-center py-2">
            <Button type="submit" variant="contained" className="!bg-gradient-to-tr from-blue-500 to-blue-800">
              Add Variable
            </Button>
          </span>
        </span>
      </Dialog>
    </>
  )
}

export default AddVariables
