import { Add } from "@mui/icons-material"
import { addSmsFn, updateSmsFn } from "Services/Settings/SmsSetting/AddSetting"
import { useFormik } from "formik"
import { useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import CustomModal from "shared/MkxModal"

const AddSetting = ({ selected, setSelected }) => {
  const { store_id } = useParams()
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
    setSelected(null)
  }
  const client = useQueryClient()
  const initialValues = {
    url: selected?.url || "",
    uname: selected?.uname || "",
    password: selected?.password || "",
    sender: selected?.sender || "",
    tempid: selected?.tempid || "",
    route: selected?.route || "",
    msgtype: selected?.msgtype || "",
    sms: selected?.sms || "",
    store_id,
  }
  const { mutate: addSms, isLoading: isLoading } = useMutation(addSmsFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("sms")
        handleClose()
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const { mutate: updateSms, isLoading: isUpdating } = useMutation(updateSmsFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("sms")
        handleClose()
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      selected ? updateSms({ ...formik.values, sms_service_id: selected?.id }) : addSms(formik.values)
    },
  })

  return (
    <>
      <CustomButton size="medium" startIcon={<Add />} className="!px-5" onClick={() => setOpen(true)}>
        Sms
      </CustomButton>
      <CustomModal title={selected ? "Update SMS" : "Add SMS"} open={open || Boolean(selected)} onClose={handleClose}>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <span className="grid grid-cols-2 gap-5 p-4 px-6">
            <CustomInput id="url" formik={formik} className="!w-full" label="URL" placeholder="Enter URL" />
            <CustomInput id="uname" formik={formik} label="Username*" placeholder="Enter Username" />
            <CustomInput type="password" id="password" formik={formik} label="Password*" placeholder="Enter Password" />
            <CustomInput type="sender" id="sender" formik={formik} label="Sender*" placeholder="Enter Sender" />
            <CustomInput type="tempid" id="tempid" formik={formik} label="Temp Id*" />
            <CustomInput type="route" id="route" formik={formik} label="Route*" />
            <CustomInput type="msgtype" id="msgtype" formik={formik} label="Msg Type*" />
            <CustomInput type="sms" id="sms" formik={formik} label="SMS*" />
          </span>
          <span className="flex justify-end gap-3 px-6 pb-4">
            <CustomButton color="inherit" onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton isLoading={isLoading || isUpdating} type="submit">
              {selected ? "Update" : "Add"}
            </CustomButton>
          </span>
        </form>
      </CustomModal>
    </>
  )
}

export default AddSetting
