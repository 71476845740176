import { API_URLS } from "../../../config/apiUrls";
import axiosInstance from "../../../config/axios";

export const variableListFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.variableList, reqBody);
    return response;
  } catch ({ error }) {
    throw new Error(error.message);
  }
};
