import axiosInstance from "config/axios"

export const emailsFn = (reqBody) => {
  try {
    const response = axiosInstance.get(`email-setup-settings-api-of-superadmin/`, { params: reqBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
