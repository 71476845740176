import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"

export const enableInventoryItemFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.enableInventoryItem, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const inventoryItemsFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.inventoryItems, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const categoryItemsFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.categoryItems, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const addCategoryItemsFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.categoryItems, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateCategoryItemsFn = (reqBody) => {
  try {
    const response = axiosInstance.put(API_URLS.categoryItems, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const deleteCategoryItemsFn = (reqBody) => {
  try {
    const response = axiosInstance.delete(API_URLS.categoryItems, {
      data: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const subCategoryItemsFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.subCategoryItems, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const addSubCategoryItemsFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.subCategoryItems, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateSubCategoryItemsFn = (reqBody) => {
  try {
    const response = axiosInstance.put(API_URLS.subCategoryItems, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const deleteSubCategoryItemsFn = (reqBody) => {
  try {
    const response = axiosInstance.delete(API_URLS.subCategoryItems, {
      data: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const brandItemsFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.brandItems, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const addBrandItemsFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.brandItems, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateBrandItemsFn = (reqBody) => {
  try {
    const response = axiosInstance.put(API_URLS.brandItems, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const deleteBrandItemsFn = (reqBody) => {
  try {
    const response = axiosInstance.delete(API_URLS.brandItems, {
      data: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
