import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"

//get
export const storeWorkingHourListFn = () => {
  try {
    const response = axiosInstance.get(API_URLS.settings.chat.storeWorkingHourList)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

//add
export const addStoreWorkingHourFn = (reqBody) => {
  console.log(reqBody)
  try {
    const response = axiosInstance.post(API_URLS.settings.chat.addStoreWorkingHour, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

//update
export const updateStoreWorkingHourfn = (reqBody) => {
  try {
    const response = axiosInstance.put(API_URLS.settings.chat.updateStoreWorkingHour, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

//delete
export const deleteStoreWorkingHourFn = (reqBody) => {
  try {
    const response = axiosInstance.delete(API_URLS.settings.chat.deleteStoreWorkingHour, { params: reqBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
