import AddBoxIcon from "@mui/icons-material/AddBox"
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import Modal from "@mui/material/Modal"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { styled } from "@mui/material/styles"
import { flatten } from "lodash"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import axiosInstance from "../../../config/axios"
import MkxPagination from "../../../shared/MkxTable/MkxPagination"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
}
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 220,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}))

const RoleDetails = () => {
  const [page, setPage] = useState(1)
  const [data, setData] = useState([])
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [storeName, setStoreName] = useState(null)
  const [open1, setOpen1] = React.useState(false)
  const handleClose1 = () => setOpen1(false)
  const [name1, setName1] = useState("")
  const [roleId, setRoleId] = useState(null)
  const [searchQuary, setSearchQuery] = useState("")
  const [permData, setPermData] = useState([])
  const [ids, setIds] = useState("")
  const [selectedUpdateModule, setSelectedUpdateModule] = useState([])
  const [selectedModule1, setSelectedModule1] = useState([])

  const modaleClose = () => {
    setPermData([])
    handleClose()
  }
  const handlePageChange = (_event, value) => {
    setPage(value)
  }

  const ResellersListData = async () => {
    try {
      const response = await axiosInstance.get(
        `module-list-of-role-api-for-superadmin/?search_value=${searchQuary}&page=${page}`
      )
      setData(response)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    ResellersListData()
  }, [searchQuary, page])

  const submitData = async (event, rowName, id) => {
    try {
      setIds(id)
      event.preventDefault()
      setStoreName(rowName)
      handleOpen()
      const response = await axiosInstance.get(`add-role-to-module-api-for-superadmin/?module_id=${id}`)
      setPermData(response?.data?.data)
      setSelectedModule1(response.data.data)
    } catch (error) {
      console.log("", error)
    }
  }
  const saveData = async () => {
    try {
      const response = await (roleId === null
        ? axiosInstance.post(`add-group-api-for-superadmin/`, {
            group_name: name1,
          })
        : axiosInstance.post(`edit-group-api-for-superadmin/`, {
            group_id: roleId,
            group_name: name1,
          }))

      toast.success(response?.data?.message)
      setName1("")
      ResellersListData()
      handleClose1()
    } catch (error) {
      console.log("", error)
    }
  }
  const closeAddData = () => {
    handleClose1()
    setName1("")
    setRoleId(null)
  }

  useEffect(() => {
    setSelectedUpdateModule(flatten(selectedModule1?.filter((i) => i.is_available === true)?.map((i) => [i.id])))
  }, [selectedModule1])

  const updateData = () => {
    const permissionData = {
      module_id: ids,
      role_id: selectedUpdateModule,
    }
    axiosInstance
      .post(`add-role-to-module-api-for-superadmin/`, permissionData)
      .then((response) => {
        toast.success(response?.data?.message)
        ResellersListData()
        handleClose()
      })
      .catch((error) => {
        console.log("", error)
      })
  }

  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font-bold text-black">Map Role Management List</p>
          </div>
          <div className="flex p-1 pt-3">
            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style1} className="!border-0 !rounded-xl">
                <Typography id="modal-modal-title" variant="h6" component="h6">
                  <div className="px-2 text-center">
                    <span className="font-bold text-center">{roleId === null ? "Add Role" : "Update Role"}</span>
                  </div>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div className="gap-2 border-gray-600 rounded">
                    <Box sx={{ flexGrow: 1 }} className="">
                      <div>
                        <div className="py-1">Name : </div>
                        <div>
                          <input
                            type="text"
                            value={name1 || ""}
                            onChange={(event) => setName1(event.target.value)}
                            className="w-full p-1 border border-gray-500 rounded"
                          />
                        </div>
                      </div>
                    </Box>
                  </div>
                  <div className="items-center justify-between ">
                    <Button className="float-left top-4" variant="contained" color="warning" onClick={closeAddData}>
                      CANCEL
                    </Button>

                    <Button variant="contained" color="success" className="float-right mr-3 top-4" onClick={saveData}>
                      {roleId === null ? "SUBMIT" : "UPDATE"}
                    </Button>
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="!border-0 !rounded-xl">
              <Typography id="modal-modal-title" variant="h6" component="h6">
                <div className="px-2">
                  <span className="font-bold">{storeName}</span>
                </div>
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="h-64 gap-2 overflow-hidden overflow-y-auto border-gray-600 rounded shadow-sm shadow-gray-500">
                  <Box sx={{ flexGrow: 1 }} className="">
                    <Grid container spacing={2}>
                      {permData &&
                        permData?.map((elem) => (
                          <Grid xs={6}>
                            <Item className="!justify-between">
                              <div className="flex">
                                <div>
                                  <Checkbox
                                    checked={selectedUpdateModule?.map((i) => i).includes(elem?.id)}
                                    onChange={(event) => {
                                      const updatedModule = selectedUpdateModule.includes(elem?.id)
                                        ? selectedUpdateModule.filter((i) => i !== elem?.id)
                                        : [...selectedUpdateModule, elem?.id]
                                      setSelectedUpdateModule(updatedModule)
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                </div>
                                <div className="pt-3 text-start">
                                  <span>{elem?.role}</span>
                                </div>
                              </div>
                            </Item>
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                </div>
                <div className="items-center justify-between ">
                  <Button className="float-left top-3" variant="contained" color="warning" onClick={modaleClose}>
                    CANCEL
                  </Button>
                  <Button variant="contained" color="success" className="float-right mr-3 top-3" onClick={updateData}>
                    UPDATE
                  </Button>
                </div>
              </Typography>
            </Box>
          </Modal>
        </div>
        <div className="flex flex-col !rounded bg-secondary border-secondary shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center py-2 bg-gray-100 rounded">
            <div className="flex justify-between">
              <div className="flex">
                <input
                  class="placeholder:italic placeholder:text-slate-400 mx-3 block bg-white w-80 border border-slate-300 rounded-md py-2 px-2 pr-3 shadow-sm focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
                  placeholder="Search for anything..."
                  type="text"
                  value={searchQuary}
                  onChange={(event) => setSearchQuery(event.target.value)}
                />
              </div>
            </div>
          </div>
          <TableContainer component={Paper} className="!text-black !mx-auto !bg-white overflow-auto px-2">
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell className="!text-center !text-gray-800 !font-bold">MODULE</TableCell>
                  <TableCell className="!text-center !text-gray-800 !font-bold">PERMISSION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.data?.map((row, index) => (
                  <TableRow key={index + 1} className="!cursor-pointer">
                    <TableCell className="!text-center !text-[12px]">{row?.module_name}</TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      <div className="flex w-24 mx-auto text-center">
                        <div>
                          <AddBoxIcon
                            className="!text-green-500"
                            onClick={(event) => submitData(event, row?.module_name, row?.id)}
                          />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <MkxPagination
              count={data?.data?.total_pages}
              page={data?.data?.current_page}
              onChange={handlePageChange}
            />
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default RoleDetails
