import {
  Box,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import React, { useState } from "react"
import { Edit } from "@mui/icons-material"
import { useQuery } from "react-query"
import GlassDiv from "shared/CustomDiv"
import AddOperations from "./AddOperations"
import { OperationsFn } from "Services/Operations"
import CustomInput from "shared/MkxInput"

const Operations = () => {
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const [selectDept, setSelectDept] = useState({})
  const handleChange = (value) => {
    setPage(value)
  }

  const { data: operationsData, isLoading: isLoading } = useQuery(
    ["operations", search, page],
    () => OperationsFn({ page, search: search }),
    { refetchOnWindowFocus: false }
  )
  const operations = operationsData?.data?.data

  return (
    <GlassDiv className="flex flex-col !bg-opacity-10 !rounded !p-0">
      <div className="flex items-center justify-between p-3">
        <CustomInput
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Search Operations"
        />
        <div className="flex items-center gap-4">
          <AddOperations selectDept={selectDept} setSelectDept={setSelectDept} />
        </div>
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow className="!bg-white !bg-opacity-20 cursor-pointer">
              <TableCell className="!border-r !py-3 !w-20 !font-bold !text-center !border-white !border-opacity-30">
                S No.
              </TableCell>
              <TableCell className="!border-r !py-3 !font-bold !text-center !border-white !border-opacity-30">
                Operations name
              </TableCell>
              <TableCell className="!py-3 !font-bold !text-center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? [1, 2, 3, 4, 5]?.map(() => {
                  return (
                    <TableRow>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  )
                })
              : operations?.map((item, index) => (
                  <TableRow className="hover:!bg-white hover:!bg-opacity-40 cursor-pointer">
                    <TableCell className="!border-r  !text-center !border-white !border-opacity-30">
                      {index + 1}
                    </TableCell>
                    <TableCell className="!border-r !py-0 !text-center !border-white !border-opacity-30">
                      {item.operations}
                    </TableCell>
                    <TableCell className="!border-r  !py-0 !text-center !border-white !border-opacity-30">
                      <Edit
                        className="!text-green-500"
                        onClick={() => {
                          setSelectDept(item)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {operationsData?.data?.total_pages && operationsData?.data?.total_pages !== 1 && (
          <span className="flex justify-end p-3">
            <Pagination
              page={operationsData?.data?.current_page || 0}
              count={operationsData?.data?.total_pages || 0}
              variant="outlined"
              color="primary"
              onChange={handleChange}
            />
          </span>
        )}
        {operations?.length === 0 && (
          <Box className="flex flex-col items-center justify-center h-full gap-3 py-20">
            <img src="https://pub-16f3de4d2c4841169d66d16992f9f0d3.r2.dev/assets/Sales/pana.svg" alt="" />
            <p className="text-3xl font-semibold">No Data Found</p>
          </Box>
        )}
      </TableContainer>
    </GlassDiv>
  )
}

export default Operations
