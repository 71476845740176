import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../config/axios";
import { CustomButton } from "../../../../shared/CustomButton";

const AddSubCategory = () => {
  const [businessCategory, setBusinessCategory] = useState();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const BusinessCategoryList = () => {
    axiosInstance
      .post(`business-category-list-data/`, {})
      .then((response) => {
        setBusinessCategory(response?.data.business_category_list_data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    BusinessCategoryList();
  }, []);

  const initialValues = {
    business_category_id: "",
    sub_category_name: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,

    onSubmit: (values, action) => {
      const reqBody = {
        business_category_id: values.business_category_id,
        sub_category_name: values.sub_category_name,
      };
      axiosInstance
        .post(`add-sub-category-data/`, reqBody)
        .then((response) => {
          handleClose();
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
        });
      action.resetForm();
    },
  });
  return (
    <>
      <CustomButton onClick={handleOpen} className="border border-black">
        + Add Business Sub Type
      </CustomButton>
      <Modal open={open} onClose={handleClose}>
        <Box className="absolute w-1/3 px-8 py-6 -translate-x-1/2 -translate-y-1/2 bg-white rounded shadow-sm outline-none top-1/2 left-1/2 shadow-blue-900">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col gap-3 py-3 text-black"
          >
            <p className="py-4 text-2xl text-center underline underline-offset-2">
              Add Business Sub Category
            </p>

            <FormControl size="small">
              <p>Business Type*</p>
              <Select
                displayEmpty
                name="business_category_id"
                value={formik.values["business_category_id"]}
                onChange={formik.handleChange}
              >
                <MenuItem value={""}>Select Business Type</MenuItem>
                {businessCategory &&
                  businessCategory.map((category) => {
                    return (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <FormControl size="small">
              <p>Business Sub Type Name*</p>
              <TextField
                name="sub_category_name"
                value={formik.values["sub_category_name"]}
                onChange={formik.handleChange}
                size="small"
                placeholder="Enter Business Sub Category Name"
              />
            </FormControl>

            <div className="flex items-center justify-end gap-3 py-3">
              <Button
                disableElevation
                color="inherit"
                variant="contained"
                onClick={handleClose}
              >
                CANCEL
              </Button>

              <Button disableElevation variant="contained" type="submit">
                Add Sub Category
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default AddSubCategory;
