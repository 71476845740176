import { Skeleton, Switch, TableContainer, TableHead, TableRow } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import { useRef, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import MkxInput from "../../shared/MkxInput"
import MkxPagination from "../../shared/MkxTable/MkxPagination"
import MkxTableCell from "../../shared/MkxTable/MkxTableCell"

import { changeTwoFactorStatusFn, storeAdminListFn } from "Services/Business/StoreAdmin"

const StoreAdmins = () => {
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const box1 = useRef()
  const client = useQueryClient()
  const handlePageChange = (_event, value) => {
    setPage(value)
  }
  const naviagte = useNavigate()
  const closeModal = () => {
    box1.current.style.display = "none"
  }

  const {
    data: stores,
    refetch,
    isLoading,
  } = useQuery(["storeAdminList", search, page], () => storeAdminListFn({ page, search: search }))

  const { mutate: storeTwoFactorChange } = useMutation(changeTwoFactorStatusFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
        client.refetchQueries("storeAdminList")
      } else {
        toast.error(res.data.message)
      }
    },
  })
  // const { mutate: deleteStore } = useMutation(deleteStoreFn, {
  //   onSuccess: (res) => {
  //     if (res.data.response_code === 200) {
  //       toast.success(res.data.message)
  //       client.refetchQueries("storeList")
  //     } else {
  //       toast.error(res.data.message)
  //     }
  //   },
  // })

  return (
    <>
      <div className="!bg-white">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font font-bold !text-black">Store Admin List</p>
            <p className="!text-black">View/Manage Admin list</p>
          </div>
        </div>
        <div className="flex flex-col !mt-3 !rounded bg-dark border-secondary shadow-card shadow-zinc-600 m-2 ">
          <div className="flex items-center p-2 h-14 border-zinc-600">
            <MkxInput placeholder="Search Store Admin..." onChange={(event) => setSearch(event.target.value)} />
          </div>

          <TableContainer>
            <Table size="small">
              <TableHead className="!bg-gray-200">
                <TableRow>
                  <MkxTableCell isHead>S.No.</MkxTableCell>
                  <MkxTableCell isHead>STORE NAME</MkxTableCell>
                  <MkxTableCell isHead>Name</MkxTableCell>
                  <MkxTableCell isHead>EMAIL</MkxTableCell>
                  <MkxTableCell isHead>MOBILE NO.</MkxTableCell>
                  <MkxTableCell isHead>STORE ID</MkxTableCell>
                  {/* <MkxTableCell isHead>WEBSITE</MkxTableCell> */}
                  {/* <MkxTableCell isHead>REAL-TIME-SETTING</MkxTableCell> */}
                  {/* <MkxTableCell isHead>RESET PASSWORD</MkxTableCell>

                  // <MkxTableCell isHead>STATUS</MkxTableCell> */}

                  <MkxTableCell isHead>Two Factor</MkxTableCell>
                </TableRow>
              </TableHead>
              <TableBody className="!overflow-y-auto">
                {isLoading
                  ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => {
                      return (
                        <TableRow>
                          {[1, 2, 3, 4, 5, 6, 7].map(() => {
                            return (
                              <MkxTableCell>
                                <Skeleton />
                              </MkxTableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })
                  : stores?.data?.data?.map((store, index) => (
                      <TableRow key={store?.id}>
                        <MkxTableCell>{index}</MkxTableCell>
                        <MkxTableCell>{store?.store_name ? store?.store_name : "--"}</MkxTableCell>
                        <MkxTableCell>{store?.username ? store?.username : "--"}</MkxTableCell>
                        <MkxTableCell>{store?.email ? store?.email : "--"}</MkxTableCell>
                        <MkxTableCell>{store?.store_mobile ? store?.store_mobile : "--"}</MkxTableCell>

                        <MkxTableCell>{store?.store ? store?.store : "--"}</MkxTableCell>

                        <MkxTableCell>
                          <input
                            type="checkbox"
                            checked={store.two_step_verification_enable === "Enable"}
                            onChange={() =>
                              storeTwoFactorChange({
                                user: store.user,
                                auth_key: store.two_step_verification_enable === "Enable" ? "Disable" : "Enable",
                              })
                            }
                            className="toggle checked:!border-green-500 !border-red-500 toggle-success"
                          />
                        </MkxTableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <MkxPagination
            count={stores?.data?.total_pages}
            page={stores?.data?.current_page}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  )
}

export default StoreAdmins
