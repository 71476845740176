import axiosInstance from "config/axios"

export const RealtimeSyncFn = (redBody) => {
  try {
    const response = axiosInstance.get(`store-real-time-sync-setup-api-of-superadmin/`, {
      params: redBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const AddRealSyncFn = (redBody) => {
  try {
    const response = axiosInstance.post(`store-real-time-sync-setup-api-of-superadmin/`, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const updateRealFn = (redBody) => {
  try {
    const response = axiosInstance.put(`store-real-time-sync-setup-api-of-superadmin/`, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
