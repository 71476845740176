import { Avatar, Divider, Menu, MenuItem } from "@mui/material"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import message from "../../assets/Header/Message.svg"
import logo from "../../assets/logobharat.png"
import { Notifications } from "../Notifications"

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleLogout = () => {
    navigate("/")
    localStorage.clear()
  }

  return (
    <>
      <div className="flex flex-row text-black h-[10vh] border-white sticky top-0 z-50">
        <div className="z-50 flex items-center shadow-md" style={{ width: "300px" }}>
          <img src={logo} alt="brandlogo" className="p-1 mr-1 cursor-pointer h-14" />
          <h1 className="text-xl font-bold text-white cursor-pointer w-52 whitespace-nowrap">Super Admin</h1>
        </div>

        <div className="flex items-center justify-end w-full bg-white shadow-md text-zinc-700">
          <div className="flex items-center justify-center px-3 rounded-md cursor-pointer">
            <div className="flex gap-5 mr-10">
              <Notifications />
              <img src={message} alt="" />
            </div>

            <Avatar alt="S" src="broken.jpg" className=" !bg-gray-600 " />
            <div className="px-2 py-1 text-zinc-700" onClick={handleClick}>
              <h1 className="font-semibold !text-black">Dashboard</h1>
              <h1 className="mb-1 text-xs">Super Admin</h1>
            </div>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              className="!absolute !top-3 !right-40 !shadow-sm"
              disableScrollLock
              elevation={10}
            >
              <MenuItem className="hover:!rounded !mx-1 hover:!bg-secondary hover:!text-black">Profile</MenuItem>
              <MenuItem className="hover:!rounded !mx-1 hover:!bg-secondary hover:!text-black">
                Account Setting
              </MenuItem>
              <Divider />
              <MenuItem className="hover:!rounded !mx-1 hover:!bg-secondary hover:!text-black" onClick={handleLogout}>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
