import { Add } from "@mui/icons-material"
import { MenuItem } from "@mui/material"
import { addRolesFn, getRolesFn, updateRolesFn } from "Services/Store/StoreRole"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import CustomModal from "shared/MkxModal"
import CustomSelect from "shared/MkxSelect"

const AddRole = ({ setSelectRole, selectRole }) => {
  const { store_id } = useParams()
  const [open, setOpen] = useState(false)
  const client = useQueryClient()
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectRole({})
  }

  const { mutate: addRole, isLoading: isLoading } = useMutation(addRolesFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("roles")
        handleClose()
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const { mutate: updateRole, isLoading: isLoading1 } = useMutation(updateRolesFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("roles")
        handleClose()
      } else {
        toast.error(response.data.message)
      }
    },
  })

  const initialValues = {
    dept_id: selectRole?.department || "",
    role_name: selectRole?.level || "",
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      const reqBody = { department_id: formik.values.dept_id, level: formik.values.role_name, store_id }
      selectRole?.id ? updateRole({ ...reqBody, role_id: selectRole?.id }) : addRole(reqBody)
    },
  })
  const { data: roles, isLoading: isLoadingRoles } = useQuery(["rolesListAdd"], () => getRolesFn({ store_id }))

  useEffect(() => {
    selectRole && Object.keys(selectRole)?.length !== 0 && handleOpen()
  }, [selectRole])

  return (
    <>
      <CustomButton startIcon={<Add />} size="medium" className="!px-5" onClick={handleOpen}>
        Role
      </CustomButton>

      <CustomModal
        open={open}
        onClose={handleClose}
        title={selectRole?.id ? "Update Role" : "Add Role"}
        className="!w-1/4"
      >
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 p-4">
          <CustomSelect
            id="dept_id"
            label="Department"
            isLoading={isLoadingRoles}
            placeholder="Select Department"
            formik={formik}
          >
            <MenuItem value={""}>Select</MenuItem>
            {roles?.data?.departments?.map((item) => (
              <MenuItem value={item?.dept_id}>{item?.dept_name}</MenuItem>
            ))}
          </CustomSelect>

          <CustomSelect
            id="role_name"
            label="Role"
            isLoading={isLoadingRoles}
            placeholder="Select Role"
            formik={formik}
          >
            <MenuItem value={""}>Select</MenuItem>
            {roles?.data?.roles?.map((item) => {
              return <MenuItem value={item}>{item}</MenuItem>
            })}
          </CustomSelect>

          <div className="flex justify-end gap-3 my-2">
            <CustomButton type="button" onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton isLoading={selectRole?.id ? isLoading1 : isLoading} type="submit">
              {selectRole?.id ? "Update Role" : "Add Role"}
            </CustomButton>
          </div>
        </form>
      </CustomModal>
    </>
  )
}

export default AddRole
