import { API_URLS } from "../../../config/apiUrls";
import axiosInstance from "../../../config/axios";

export const variablesFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.variables, { params: reqBody });
    return response;
  } catch ({ error }) {
    throw new Error(error.message);
  }
};
export const addVariablesFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.variables, reqBody);
    return response;
  } catch ({ error }) {
    throw new Error(error.message);
  }
};
