import { ArrowBackIos, ArrowForwardIos, Delete, DeleteTwoTone, Edit, ReportTwoTone } from "@mui/icons-material"
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import axios from "config/axios"
import React, { useEffect, useState } from "react"

import { toast } from "react-toastify"
import { Active, Inactive } from "../../../shared/ActivaInactive"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

const ProductVariantList = () => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState([])
  // add product variant list start
  const [name1, setName1] = useState("")
  // add product variant list end
  const [businessData, setBusinessData] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [newCategory, setNewCategory] = useState([])
  const [businessCategoryId, setBusinessCatgeoryId] = useState("")
  const [businessSubCategoryId, setBusinessSubCategoryId] = useState("")
  const [categoryId, setCategoryId] = useState(null)
  // edit product variant list start
  const [rowData, setRowData] = useState([])
  const [ids, setIds] = useState(null)
  // edit product variant list end
  const [searchQuery, setSearchQuery] = useState("")
  const [currentPage, setCurrentPage] = useState(null)
  const [totalPage, setTotalPage] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])
  const [isDelete, setIsDelete] = useState(false)

  const previousData = () => {
    setPage(page - 1)
  }
  const nextData = () => {
    setPage(page + 1)
  }

  const otherDataList = () => {
    searchQuery === ""
      ? axios
          .get(`other-varient-data-list/?page=${page}&category_id&product_other_variant_name=`)
          .then((response) => {
            setData(response?.data?.data)
            setCurrentPage(response?.data?.current_page)
            setTotalPage(response?.data?.total_pages)
          })
          .catch((error) => {
            alert(error?.message)
          })
      : axios
          .get(`type-three-search-api-for-super-admin/?search_value=${searchQuery}`)
          .then((response) => {
            setData(response?.data?.data)
          })
          .catch((error) => {
            alert(error?.message)
          })
  }
  useEffect(() => {
    otherDataList()
  }, [searchQuery])

  const createProduct = () => {
    const subData = {
      id: ids,
      category_id: categoryId || rowData?.category?.id,
      product_variant_name: name1,
    }

    !ids
      ? axios
          .post(`add-product-other-variant-data/`, subData)
          .then((response) => {
            console.log("success")
            console.log(response?.data?.message)
            setCategoryId("")
            setName1("")
            toast(response?.data?.message)
            otherDataList()
            handleClose()
          })
          .catch((error) => {
            console.log("error page", error?.response)
            console.log(error)
          })
      : axios
          .post(`other-varient-data-list/`, subData)
          .then((response) => {
            console.log("success")
            console.log(response?.data?.message)
            setCategoryId("")
            setName1("")
            toast(response?.data?.message)
            otherDataList()
            handleClose()
          })
          .catch((error) => {
            console.log(error)
          })
  }

  const EditData = (row) => {
    setRowData(row)
    setIds(row.id)
    setName1(row?.title?.choice)
  }

  const empty = () => {}

  const CategoryList = () => {
    axios
      .post(`business-category-list-data/`, {})
      .then((response) => {
        setBusinessData(response.data.business_category_list_data)
        // console.localStorage(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response)
        console.log(error)
        // alert(error.message);
      })
  }

  useEffect(() => {
    CategoryList()
  }, [])

  const StoreList = () => {
    const reqbody = new FormData()
    reqbody.append(
      "business_category_ids",
      businessCategoryId !== "" ? businessCategoryId : rowData?.category?.business_type?.category?.id
    )
    axios
      .post(`business-sub-category-list-data/`, reqbody, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setSubCategory(response?.data.business_sub_category_list)
      })
      .catch((error) => {
        console.log("error page", error.response)
        console.log(error)
      })
  }
  useEffect(() => {
    rowData.length !== 0 || businessCategoryId !== "" ? StoreList() : empty()
  }, [businessCategoryId, rowData])

  const categoryListData = () => {
    const reqbody = new FormData()
    reqbody.append(
      "business_type_id",
      businessSubCategoryId !== "" ? businessSubCategoryId : rowData?.category?.business_type?.id
    )

    axios
      .post(`category-list-of-business-type-api-for-admin/`, reqbody, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setNewCategory(response?.data?.data)
      })
      .catch((error) => {
        console.log("error page", error.response)
        console.log(error)
      })
  }
  useEffect(() => {
    rowData.length !== 0 || businessSubCategoryId !== "" ? categoryListData() : empty()
  }, [businessSubCategoryId, rowData])

  const handleCloseModale = () => {
    setCategoryId(null)
    setBusinessCatgeoryId("")
    setBusinessSubCategoryId("")
    setName1("")
    setIds(null)
    setRowData([])
    handleClose()
  }

  const handleAllRowSelect = (event) => {
    setSelectedIds(event.target.checked ? data.map((i) => i.id) : [])
  }

  const handleSelectRow = (id) => {
    setSelectedIds((prevSelectedRow) =>
      prevSelectedRow.includes(id) ? prevSelectedRow.filter((i) => i !== id) : [...prevSelectedRow, id]
    )
  }

  const handleDelete = () => {
    axios
      .post(`delete-type-three-api-for-superadmin/`, { variant_ids: selectedIds })
      .then((response) => {
        otherDataList()
        setIsDelete(false)
        setSelectedIds([])
        toast.success(response.success.message)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const hadleClearAll = () => {
    setIsDelete(false)
    setSelectedIds([])
  }
  return (
    <div className="bg-slate-100">
      <div className="flex justify-between bg-slate-100">
        <div className="p-2">
          <p className="text-xl font-bold text-black font">Manage Product Choice (Type Three)</p>
          {/* <p className="text-black">Manage/Product Variants Storage</p> */}
        </div>

        <div className="flex mt-6 mr-3 gap-x-2">
          <div>
            {selectedIds?.length !== 0 && (
              <>
                <Button
                  startIcon={<DeleteTwoTone />}
                  className="!text-white bg-gradient-to-r from-red-500 to-rose-500"
                  onClick={() => setIsDelete(true)}
                >
                  Delete
                </Button>
                <Dialog
                  open={isDelete}
                  onClose={() => setIsDelete(false)}
                  PaperProps={{ className: "!max-w-[500px] w-96" }}
                >
                  <div className="flex flex-col items-center gap-3 p-5">
                    <ReportTwoTone color="error" className="!text-5xl" />
                    <p className="text-xl">Are you sure!</p>
                    <p>You want to delete selected products?</p>
                  </div>
                  <span className="flex justify-end gap-3 pb-3 pr-3">
                    <Button
                      size="small"
                      variant="outlined"
                      color="inherit"
                      onClick={hadleClearAll}
                      className="!capitalize"
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      disableElevation
                      color="error"
                      className="!capitalize"
                      variant="contained"
                      onClick={() => handleDelete()}
                    >
                      Delete
                    </Button>
                  </span>
                </Dialog>
              </>
            )}
          </div>
          <Button
            onClick={handleOpen}
            className="!text-sm !font-bold !bg-white !px-2 !py-1 mt-2"
            style={{ border: "1px solid black" }}
          >
            + Add Type Three
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="!border-0 !rounded-xl">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {ids === null ? (
                  <div className="!text-center underline">Add Type Three</div>
                ) : (
                  <div className="!text-center underline">Update Type Three</div>
                )}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="gap-y-1">
                  <FormControl fullWidth>
                    <div className="py-1">Business Type :</div>
                    <Select
                      value={businessCategoryId || rowData?.category?.business_type?.category?.id || ""}
                      size="small"
                      onChange={(event) => setBusinessCatgeoryId(event.target.value)}
                    >
                      {businessData?.map((res) => {
                        return <MenuItem value={res.id}>{res.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <div className="pt-2">Business Sub Type :</div>
                    <Select
                      size="small"
                      value={businessSubCategoryId || rowData?.category?.business_type?.id || ""}
                      className="!mt-1"
                      onChange={(event) => setBusinessSubCategoryId(event.target.value)}
                    >
                      {subCategory?.map((res) => {
                        return <MenuItem value={res.id}>{res.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <div className="pt-2">Category : </div>
                    <Select
                      size="small"
                      className="!mt-1"
                      // setCategoryId
                      value={categoryId || rowData?.category?.id || ""}
                      onChange={(event) => setCategoryId(event.target.value)}
                    >
                      {newCategory?.map((res) => {
                        return <MenuItem value={res.id}>{res.category}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  <br />
                  <div className="!mt-3">Type :</div>
                  <TextField
                    value={name1}
                    onChange={(event) => setName1(event.target.value)}
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    className="!w-[100%] !mt-2"
                  />
                  <br />
                </div>
                <div className="justify-between items-center py-3 !pb-14">
                  <Button variant="contained" className="float-left top-3" onClick={handleCloseModale} color="warning">
                    CANCEL
                  </Button>
                  {ids === null ? (
                    <Button variant="contained" className="float-right top-3" onClick={createProduct} color="success">
                      CREATE
                    </Button>
                  ) : (
                    <Button variant="contained" className="float-right" onClick={createProduct} color="success">
                      UPDATE
                    </Button>
                  )}
                </div>
              </Typography>
            </Box>
          </Modal>
        </div>
      </div>

      <div className="flex flex-col !my-3 !rounded  bg-dark bg-slate-100 shadow-card shadow-zinc-600 m-2">
        <div className="flex items-center justify-between p-2 h-14 border-zinc-600">
          <div className="flex">
            {/* <CustomIconButton>
              <FilterAltIcon className="!text-black" />
            </CustomIconButton> */}
            <input
              type="text"
              className="!border-3 !border-black !w-72 px-2 rounded-md py-1"
              placeholder="search"
              style={{ border: "1px solid black" }}
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
          </div>
          <div className="flex">
            <div className="float-left"></div>
          </div>
        </div>

        <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  <Checkbox onChange={handleAllRowSelect} />
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  <div className="w-40">BUSINESS TYPE</div>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  <div className="w-48">BUSINESS SUB TYPE</div>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  <div className="w-32">CATEGORY</div>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  <div className="w-32">TYPE THREE</div>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  STATUS
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data?.map((row) => (
                  <TableRow key={row?.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      <Checkbox
                        value={row?.id}
                        checked={selectedIds.filter((id) => id === row?.id)?.length === 1 ? true : false}
                        onChange={() => handleSelectRow(row.id)}
                      />
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.category?.business_type?.category?.name}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.category?.business_type?.name}
                    </TableCell>

                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.category?.title}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.title?.choice}
                    </TableCell>
                    <TableCell className="justify-center text-left">
                      {row?.category?.business_type?.category?.active_status == true ? <Active /> : <Inactive />}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      <Delete className="!text-xl !text-red-600 !cursor-pointer" />
                      <button onClick={handleOpen}>
                        <Edit className="!text-xl !cursor-pointer !text-green-600" onClick={() => EditData(row)} />
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          className="!float-right bg-white w-[100%] relative mx-auto bottom-0 right-0 py-3 border-t-2 border-gray-400 !flex-col !justify-end !items-center"
          style={{ marginRight: "0px" }}
        >
          <button onClick={nextData} className="float-right">
            {currentPage}...{totalPage} <ArrowForwardIos />
          </button>
          <button onClick={previousData} className="float-right">
            <ArrowBackIos />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductVariantList
