import {
  Add,
  AddCircle,
  CheckCircle,
  Close,
  FormatListBulleted,
  KeyboardArrowRight,
  SmartToy,
} from "@mui/icons-material"
import {
  Button,
  Collapse,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material"
import { useEffect, useState } from "react"
import CustomButton from "shared/MkxButton"
import { toast } from "react-toastify"
import { useFormik } from "formik"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { chatTempsFn } from "Services/Chats/WhatsappTemplatesWithoutNumber"
import { whatsappWorkflowFn } from "Services/Chats/WhatsappWorkflow"
import {
  customAutoReplyFn,
  singleAutoReplyFn,
  updateCustomAutoReplyfn,
  variableListFn,
} from "Services/Settings/ChatSettings/CustomAutoReply"
import {
  deleteButtonFn,
  deleteSectionFn,
  deleteSectionRowFn,
  deleteVariationFn,
} from "Services/Settings/ChatSettings/DeletionAPIs"
import CustomInput from "shared/MkxInput"
import classNames from "classnames"

const AddNewCustomReply = ({ response, setResponse }) => {
  const [open, setOpen] = useState(false)
  const [body, setBody] = useState("")
  const [list, setList] = useState([])
  const [responses, setResponses] = useState({})
  const [isWorkFlow, setIsWorkflow] = useState(false)
  const { data: workFlows } = useQuery(["whatsappWorkflows"], whatsappWorkflowFn, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: isWorkFlow,
  })
  const { data: variableList } = useQuery(["variableList"], variableListFn, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })
  useQuery(["CustomAutoReply"], () => singleAutoReplyFn({ custom_auto_reply_id: response.id }), {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: response.id ? true : false,
    onSuccess: (res) => {
      setResponses(res.data.data?.[0])
    },
  })

  const client = useQueryClient()
  const { data: buttonValuesData } = useQuery(["buttonValuesData"], () => chatTempsFn({ template_type: "Custom" }), {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })
  const buttonValues = buttonValuesData?.data?.data

  const { mutate: customAutoReply } = useMutation(customAutoReplyFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
        setOpen(false)
        setResponse({})
        client.refetchQueries("CustomAutoReplys")
        client.refetchQueries("customAutoReply")
      } else {
        toast.error(res.data.message)
      }
    },
  })

  const { mutate: updateCustomAutoReply } = useMutation(updateCustomAutoReplyfn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
        setOpen(false)
        setResponse({})
        client.refetchQueries("customAutoReply")
        client.refetchQueries("CustomAutoReplys")
      } else {
        toast.error(res.data.message)
      }
    },
  })
  const { mutate: deleteButton } = useMutation(deleteButtonFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
        client.refetchQueries("CustomAutoReply")
      } else {
        toast.error(res.data.message)
      }
    },
  })

  const { mutate: deleteSection } = useMutation(deleteSectionFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
        client.refetchQueries("CustomAutoReply")
      } else {
        toast.error(res.data.message)
      }
    },
  })

  const { mutate: deleteSectionRow } = useMutation(deleteSectionRowFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
        client.refetchQueries("CustomAutoReply")
      } else {
        toast.error(res.data.message)
      }
    },
  })
  const { mutate: deleteVariation } = useMutation(deleteVariationFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
        client.refetchQueries("CustomAutoReply")
      } else {
        toast.error(res.data.message)
      }
    },
  })

  const initialValues = {
    custom_auto_reply_id: responses?.id,
    message_response_type: responses?.message_response_type || "text",
    workflow: responses?.whatsApp_workflow,
    customer_input: responses?.customer_input || "",
    customer_input_variation_list: responses?.customer_input_variation_list?.map((i) => ({
      id: i.id,
      customer_input_variation: i.customer_input_variation,
    })) || [{ customer_input_variation: "" }],
    button_list: responses?.whatsapp_template?.button_list?.map((button) => ({
      button_id: button.id,
      button_text: button.button_text,
      button_value: button.button_value,
    })) || [{ button_text: "", button_value: "" }],
    section_list: responses?.whatsapp_template?.section_list?.map((section) => ({
      section_title: section?.section_title,
      section_list_id: section?.id,
      rows_list: section?.rows_list?.map((row) => ({
        rows_id: row.rows_id,
        rows_title: row.rows_title,
        rows_list_id: row.id,
      })),
    })) || [{ section_title: "", rows_list: [{ rows_id: "", rows_title: "" }] }],
    action_button_text: responses?.whatsapp_template?.action_button_text || "",
    variable_list: responses?.whatsapp_template?.variable_list || [],
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      Object.keys(responses)?.length !== 0
        ? updateCustomAutoReply({
            ...formik.values,
            body: body,
            workflow_id: formik?.values?.workflow?.id,
          })
        : customAutoReply({
            ...formik.values,
            body: body,
            workflow_id: formik?.values?.workflow?.id,
          })
    },
  })

  useEffect(
    () => {
      if (Object.keys(responses)?.length !== 0) {
        setBody(response?.whatsapp_template?.body)
        setOpen(true)
      } else {
        setBody("")
      }
    },
    // eslint-disable-next-line
    [responses]
  )

  return (
    <>
      <CustomButton
        className="!rounded-full !px-4"
        startIcon={<AddCircle />}
        onClick={() => {
          setOpen(true)
          setResponses({})
        }}
      >
        Add New Custom Reply
      </CustomButton>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
          setResponse({})
        }}
        component="form"
        onSubmit={formik.handleSubmit}
        PaperProps={{ className: "!min-h-[80%] !flex !flex-col !min-w-[50%]" }}
      >
        <span className="flex justify-between !rounded-t items-center p-2">
          <span className="flex  !font-semibold items-center gap-2">
            <SmartToy />
            Setup Custom Auto Reply
          </span>
          <IconButton
            onClick={() => {
              setOpen(false)
              setResponse({})
            }}
          >
            <Close />
          </IconButton>
        </span>
        <span className="p-5 flex flex-col h-[70%] overflow-y-auto gap-2">
          <span className="flex flex-col gap-2">
            <p className="text-xl font-semibold">Customer Input</p>
            <p className="w-10/12 text-sm">
              Add the customer input for which you want the Custom Auto Reply to be triggered. As a user, you can enter
              the Input and up to 5 variations of the same. In case the customer types in either of them, the Auto
              response gets triggered.
            </p>
            <p className="text-lg font-semibold">Input</p>
            <p className="w-10/12 text-sm">
              While the Auto replies are triggered both for Customer input and its variations. It is the input here that
              gets shown when the Interaktive List messages are set up
            </p>
            <CustomInput id="customer_input" placeholder="Input" className="!w-1/3" formik={formik} />
            <p className="text-lg font-semibold">Input Variations</p>
            <p className="w-10/12 text-sm">
              Users can set up to 5 variations of the customer input. The Auto replies get triggered in case of an exact
              match with the input or any of the variation here
            </p>
            {formik.values.customer_input_variation_list?.map((input, index) => {
              return (
                <span className="flex items-center gap-1 group">
                  <CustomInput
                    id={`input${index + 1}`}
                    placeholder={`Input ${index + 1}`}
                    className="!w-1/3"
                    value={formik.values.customer_input_variation_list[index]?.customer_input_variation || ""}
                    onChange={(event) => {
                      const newButtonList = [...formik.values.customer_input_variation_list]
                      newButtonList[index] = {
                        ...newButtonList[index],
                        customer_input_variation: event.target.value,
                      }

                      formik.setValues({
                        ...formik.values,
                        customer_input_variation_list: newButtonList,
                      })
                    }}
                  />
                  <IconButton
                    color="error"
                    className="!hidden group-hover:!flex"
                    onClick={() =>
                      deleteVariation({
                        customer_input_variation_id: input?.id,
                      })
                    }
                  >
                    <Close />
                  </IconButton>
                </span>
              )
            })}
            <CustomButton
              variant="outlined"
              startIcon={<AddCircle />}
              className="!w-1/3"
              onClick={() =>
                formik.values.customer_input_variation_list?.length !== 5
                  ? formik.setFieldValue("customer_input_variation_list", [
                      ...formik.values.customer_input_variation_list,
                      "",
                    ])
                  : toast.info("you can enter the Input and up to 5 variations of the same")
              }
            >
              Add another variation
            </CustomButton>
          </span>
          <span className="flex flex-col gap-2">
            <p className="text-xl font-semibold"> Custom Auto Reply</p>
            <p className="text-sm">
              Configure the type of Auto reply you want to be triggered when a customer sends the user input or one of
              its variations in the chat
            </p>
            <p className="font-semibold">Select response type</p>
            <FormControl>
              <RadioGroup
                row
                id="message_response_type"
                name="message_response_type"
                value={formik.values.message_response_type}
                onChange={formik.handleChange}
              >
                <FormControlLabel value="text" control={<Radio color="secondary" />} label="Text" />
                <FormControlLabel value="button" control={<Radio color="secondary" />} label="Button" />
                <FormControlLabel value="list" control={<Radio color="secondary" />} label="List" />
                <FormControlLabel value="workflow" control={<Radio color="secondary" />} label="Workflow" />
              </RadioGroup>
            </FormControl>
            <span className="flex flex-col gap-2 pb-20">
              {formik.values.message_response_type !== "workflow" && (
                <textarea
                  id="body"
                  rows={7}
                  multiline
                  className="p-2 border border-gray-300 rounded outline-none"
                  value={body}
                  placeholder={`Hello Customer_Name,
              
    Ready to take your retail business to the next level with Store Name? 
    I am Store_Manager, here to assist you with our services , which offers POS, Billing, Accounting, Inventory 
    management, and much more to help you manage your business more efficiently.
    
Don't Type, Just Dial! 
Speak to an expert now.

Call Us: 9266667999`}
                  onChange={(event) => setBody(event.target.value)}
                />
              )}

              {formik.values.message_response_type !== "workflow" && (
                <>
                  <CustomButton
                    variant="text"
                    startIcon={<AddCircle />}
                    className="!w-1/4 !flex !justify-start !px-3"
                    onClick={() => {
                      formik.setFieldValue("variable_list", [
                        ...formik.values.variable_list,
                        {
                          index: formik.values.variable_list?.length + 1,
                          key: "",
                          value: "",
                        },
                      ])
                      setBody(`${body} *{{${formik.values.variable_list?.length + 1}}}*`)
                    }}
                  >
                    Add Variable
                  </CustomButton>
                  <Collapse in={formik.values.variable_list?.length !== 0 ? true : false}>
                    <span className="!flex !flex-col !gap-2">
                      {formik.values.variable_list?.map((variable, index) => {
                        return (
                          <span className="flex gap-2">
                            <CustomInput placeholder="Variable" value={`{{${variable?.index}}}`} disabled />
                            <FormControl className="!w-1/2">
                              <Select
                                size="small"
                                id={`button_value${index + 1}`}
                                value={formik.values.variable_list[index]?.key || ""}
                                onChange={(event) => {
                                  const newButtonList = [...formik.values.variable_list]
                                  newButtonList[index] = {
                                    ...newButtonList[index],
                                    key: event.target.value,
                                  }
                                  formik.setValues({
                                    ...formik.values,
                                    variable_list: newButtonList,
                                  })
                                }}
                              >
                                {variableList?.data?.data?.map((value) => {
                                  return <MenuItem value={value?.key}>{value?.key}</MenuItem>
                                })}
                              </Select>
                            </FormControl>
                            <CustomInput
                              placeholder="Fallback value"
                              value={variable.value}
                              onChange={(event) => {
                                const newButtonList = [...formik.values.variable_list]
                                newButtonList[index] = {
                                  ...newButtonList[index],
                                  value: event.target.value,
                                }
                                formik.setValues({
                                  ...formik.values,
                                  variable_list: newButtonList,
                                })
                              }}
                            />
                          </span>
                        )
                      })}
                    </span>
                  </Collapse>
                </>
              )}

              {formik.values.message_response_type === "button" && (
                <>
                  {formik.values.button_list?.map((button, index) => {
                    return (
                      <span className="flex flex-col gap-2 group">
                        <p>Button {index + 1} </p>
                        <span className="flex gap-2">
                          <CustomInput
                            id={`button_text${index + 1}`}
                            label="Text"
                            placeholder="Enter Button Text"
                            value={button?.button_text || ""}
                            onChange={(event) => {
                              const newButtonList = [...formik.values.button_list]
                              newButtonList[index] = {
                                ...newButtonList[index],
                                button_text: event.target.value,
                              }

                              formik.setValues({
                                ...formik.values,
                                button_list: newButtonList,
                              })
                            }}
                            className="!w-1/2"
                          />
                          <FormControl className="!w-1/2">
                            <Select
                              size="small"
                              id={`button_value${index + 1}`}
                              value={formik.values.button_list[index]?.button_value || ""}
                              onChange={(event) => {
                                const newButtonList = [...formik.values.button_list]
                                newButtonList[index] = {
                                  ...newButtonList[index],
                                  button_value: event.target.value,
                                }
                                formik.setValues({
                                  ...formik.values,
                                  button_list: newButtonList,
                                })
                              }}
                            >
                              {buttonValues?.map((value) => {
                                return <MenuItem value={value?.id}>{value?.name}</MenuItem>
                              })}
                            </Select>
                          </FormControl>
                          <span className="w-12">
                            <IconButton
                              color="error"
                              className={classNames("!hidden", button?.button_id && "group-hover:!flex")}
                              onClick={() =>
                                button?.button_id &&
                                deleteButton({
                                  whatsApp_button_id: button?.button_id,
                                })
                              }
                            >
                              <Close />
                            </IconButton>
                          </span>
                        </span>
                      </span>
                    )
                  })}
                  <CustomButton
                    variant="text"
                    startIcon={<Add />}
                    onClick={() =>
                      formik.values.button_list?.[formik.values.button_list?.length - 1]?.button_text !== "" &&
                      formik.setValues({
                        ...formik.values,
                        button_list: [...formik.values.button_list, { button_text: "", button_value: "" }],
                      })
                    }
                  >
                    Add Button
                  </CustomButton>
                </>
              )}
              {formik.values.message_response_type === "list" && (
                <>
                  <span className="flex gap-1">
                    <CustomInput
                      placeholder="Action Button Text"
                      id="action_button_text"
                      className="!w-1/2"
                      formik={formik}
                    />
                    <Button
                      startIcon={<FormatListBulleted />}
                      onClick={() => setList(formik.values.section_list)}
                      className="!w-1/2 !capitalize"
                    >
                      {formik.values.action_button_text}
                    </Button>
                  </span>
                </>
              )}
              {formik.values.message_response_type === "workflow" && (
                <span className="flex justify-center">
                  {formik.values.workflow ? (
                    <CustomButton
                      size="large"
                      variant="outlined"
                      onClick={() => setIsWorkflow(true)}
                      endIcon={<KeyboardArrowRight />}
                      className="!w-full !flex !justify-between"
                    >
                      {formik.values.workflow.workflow_title}
                    </CustomButton>
                  ) : (
                    <CustomButton
                      size="large"
                      variant="outlined"
                      startIcon={<FormatListBulleted />}
                      onClick={() => setIsWorkflow(true)}
                    >
                      View Workflow library
                    </CustomButton>
                  )}
                  <Dialog
                    open={isWorkFlow}
                    onClose={() => setIsWorkflow(false)}
                    PaperProps={{
                      className: "!min-h-[70%] !flex !flex-col !relative !min-w-[30%]",
                    }}
                  >
                    <span className="flex items-center justify-between p-3">
                      <p className="font-semibold">Workflows</p>
                      <CustomButton onClick={() => setIsWorkflow(false)}>Done</CustomButton>
                    </span>
                    <List className="!py-0">
                      <Divider />
                      <ListItem className="!p-3 !flex">
                        <CustomInput placeholder="Search Workflow..." className="!w-full" />
                      </ListItem>
                      <Divider />
                      {workFlows?.data?.data?.map((work) => {
                        return (
                          <>
                            <ListItem
                              className="!p-3 !flex !justify-between"
                              onClick={() => formik.setFieldValue("workflow", work)}
                            >
                              {work.workflow_title}
                              {work?.id === formik?.values?.workflow?.id ? (
                                <CheckCircle color="success" />
                              ) : (
                                <CheckCircle color="disabled" />
                              )}
                            </ListItem>
                            <Divider />
                          </>
                        )
                      })}
                    </List>
                  </Dialog>
                </span>
              )}
            </span>
          </span>
        </span>
        <span className="absolute bottom-0 z-50 flex items-center justify-end w-full h-16 p-2 bg-white border-t">
          <CustomButton type="submit">Save</CustomButton>
        </span>
      </Dialog>
      <Dialog
        open={list?.length === 0 ? false : true}
        onClose={() => setList([])}
        PaperProps={{
          className: "!flex min-w-[40%] !flex-col !justify-between",
        }}
      >
        <p className="py-2 font-semibold text-center">Section</p>
        <Divider />

        <List className="!p-0">
          {formik.values?.section_list?.map((section, sectionIndex) => (
            <>
              <ListItem className="!flex !items-start  !flex-col !px-0 !w-full" key={sectionIndex}>
                <span className="flex flex-col p-3 group">
                  <p>Section Title</p>
                  <span className="flex items-center gap-2">
                    <CustomInput
                      placeholder="Enter Section Text"
                      value={section.section_title || ""}
                      onChange={(event) => {
                        const newSectionList = [...formik.values.section_list]
                        newSectionList[sectionIndex] = {
                          ...newSectionList[sectionIndex],
                          section_title: event.target.value,
                        }
                        formik.setValues({
                          ...formik.values,
                          section_list: newSectionList,
                        })
                      }}
                    />
                    <IconButton
                      color="error"
                      className={classNames("!hidden", section?.section_list_id && "group-hover:!flex")}
                      onClick={() =>
                        deleteSection({
                          whatsApp_section_id: section?.section_list_id,
                        })
                      }
                    >
                      <Close />
                    </IconButton>
                  </span>
                </span>
                <Divider className="!w-full !mb-1">Row List</Divider>
                {section.rows_list?.map((row, rowIndex) => (
                  <span className="flex w-full gap-2 px-3 pb-1 border-b group" key={rowIndex}>
                    <CustomInput
                      id={`section${sectionIndex + 1}`}
                      placeholder="Enter Row Text"
                      value={row.rows_title || ""}
                      className="!w-1/2"
                      onChange={(event) => {
                        const newSectionList = [...formik.values.section_list]
                        const newRowList = [...newSectionList[sectionIndex].rows_list]

                        newRowList[rowIndex] = {
                          ...newRowList[rowIndex],
                          rows_title: event.target.value,
                        }

                        newSectionList[sectionIndex] = {
                          ...newSectionList[sectionIndex],
                          rows_list: newRowList,
                        }

                        formik.setValues({
                          ...formik.values,
                          section_list: newSectionList,
                        })
                      }}
                    />

                    <FormControl className="!w-1/2">
                      <Select
                        size="small"
                        id={`button_value${sectionIndex + 1}`}
                        value={row.rows_id || ""}
                        onChange={(event) => {
                          const newSectionList = [...formik.values.section_list]
                          newSectionList[sectionIndex].rows_list[rowIndex] = {
                            ...newSectionList[sectionIndex].rows_list[rowIndex],
                            rows_id: event.target.value,
                          }
                          formik.setValues((prevValues) => ({
                            ...prevValues,
                            section_list: newSectionList,
                          }))
                        }}
                      >
                        {buttonValues?.map((value) => (
                          <MenuItem value={value?.id} key={value?.id}>
                            {value?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <span className="w-12">
                      <IconButton
                        color="error"
                        className={classNames("!hidden", row?.rows_list_id && "group-hover:!flex")}
                        onClick={() =>
                          row?.rows_list_id &&
                          deleteSectionRow({
                            whatsApp_section_rows_id: row?.rows_list_id,
                          })
                        }
                      >
                        <Close />
                      </IconButton>
                    </span>
                  </span>
                ))}
              </ListItem>
              <ListItemButton
                className="!flex !gap-2 !text-secondary"
                onClick={() => {
                  const newSectionList = [...formik.values.section_list]
                  newSectionList[sectionIndex] = {
                    ...newSectionList[sectionIndex],
                    rows_list: [...section.rows_list, { rows_id: "", rows_title: "" }],
                  }
                  formik.setValues((prevValues) => ({
                    ...prevValues,
                    section_list: newSectionList,
                  }))
                }}
              >
                <AddCircle fontSize="small" />
                Add New Row
              </ListItemButton>
            </>
          ))}
          <Divider />
          <ListItemButton
            className="!flex !gap-2 !text-secondary"
            onClick={() =>
              formik.setFieldValue("section_list", [
                ...formik.values.section_list,
                {
                  section_title: "",
                  rows_list: [{ rows_id: "", rows_title: "" }],
                },
              ])
            }
          >
            <AddCircle fontSize="small" />
            Add New Section
          </ListItemButton>
        </List>
        <span className="flex justify-end gap-2 p-4">
          <CustomButton onClick={() => setList([])}>Save</CustomButton>
        </span>
      </Dialog>
    </>
  )
}

export default AddNewCustomReply
