import {
  Pagination,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import AddVariables from "./AddVariables";
import { variablesFn } from "../../Services/Templates/Variables";

const VariablesSettings = () => {
  const [page, setPage] = useState(1);
  const [response, setResponse] = useState({});

  const handleChange = (event, value) => {
    setPage(value);
  };

  const { data: variables, isLoading } = useQuery(
    ["variables", page],
    () => variablesFn({ page }),
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <div className="">
        <div className="flex items-center justify-between p-4">
          <div>
            <p className="text-xl font-bold text-black">Variable Management</p>
            <p className="">Manage Your Whatsapp Template Variables</p>
          </div>
          <AddVariables response={response} setResponse={setResponse} />
        </div>
        <div className="p-3">
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow className="!bg-gradient-to-tr from-blue-500 to-blue-800">
                  <TableCell className="!text-center !p-3 !text-white !font-bold">
                    ID
                  </TableCell>
                  <TableCell className="!text-center !p-3 !text-white !font-bold">
                    Variable Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? [1, 2, 3, 5, 6, 7, 8]?.map(() => {
                      return (
                        <TableRow>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : variables?.data?.data?.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell className="capitalize !text-center !p-2">
                          {row.id}
                        </TableCell>
                        <TableCell className="!text-center !p-2">
                          {row.key}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {variables?.data?.total_pages > 1 && (
              <span className="flex justify-end p-2">
                <Pagination
                  variant="outlined"
                  count={variables?.data?.total_pages || 0}
                  page={variables?.data?.current_page || 0}
                  onChange={handleChange}
                />
              </span>
            )}
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default VariablesSettings;
