import axiosInstance from "config/axios"

/**
 * Fetches restaurant tables from the API.
 * @param {Object} reqBody - The request body.
 * @returns {Promise} The response from the API.
 */
export const restaurantTablesFn = async (reqBody) => {
  try {
    const response = await axiosInstance.get(`restaurant-table-api-of-superadmin/`, { params: reqBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

/**
 * Adds new tables to the restaurant.
 * @param {Object} reqBody - The request body.
 * @returns {Promise} The response from the API.
 */
export const addTablesFn = async (reqBody) => {
  try {
    const response = await axiosInstance.post(`restaurant-table-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

/**
 * Updates existing tables in the restaurant.
 * @param {Object} reqBody - The request body.
 * @returns {Promise} The response from the API.
 */
export const updateTablesFn = async (reqBody) => {
  try {
    const response = await axiosInstance.put(`restaurant-table-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

/**
 * Deletes tables from the restaurant.
 * @param {Object} reqBody - The request body.
 * @returns {Promise} The response from the API.
 */
export const deleteTablesFn = async (reqBody) => {
  try {
    const response = await axiosInstance.delete(`restaurant-table-api-of-superadmin/`, { data: reqBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
/**
 * Fetches restaurant floors from the API.
 * @param {Object} reqBody - The request body.
 * @returns {Promise} The response from the API.
 */
export const restaurantFloorsFn = async (reqBody) => {
  try {
    const response = await axiosInstance.get(`restaurant-floor-api-of-superadmin/`, { params: reqBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

/**
 * Adds new floors to the restaurant.
 * @param {Object} reqBody - The request body.
 * @returns {Promise} The response from the API.
 */
export const addFloorsFn = async (reqBody) => {
  try {
    const response = await axiosInstance.post(`restaurant-floor-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

/**
 * Updates existing floors in the restaurant.
 * @param {Object} reqBody - The request body.
 * @returns {Promise} The response from the API.
 */
export const updateFloorsFn = async (reqBody) => {
  try {
    const response = await axiosInstance.put(`restaurant-floor-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

/**
 * Deletes floors from the restaurant.
 * @param {Object} reqBody - The request body.
 * @returns {Promise} The response from the API.
 */
export const deleteFloorsFn = async (reqBody) => {
  try {
    const response = await axiosInstance.delete(`restaurant-floor-api-of-superadmin/`, { data: reqBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
