import React, { useEffect, useState } from "react"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import { Active, Inactive } from "../../../shared/ActivaInactive"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"

import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { Checkbox, Dialog, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import axios from "config/axios"
import { toast } from "react-toastify"
import { DeleteTwoTone, ReportTwoTone } from "@mui/icons-material"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

const ProductVariantList = () => {
  const [page, setPage] = useState(1)
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [data, setData] = useState([])

  // add product variant list start
  const [businessData, setBusinessData] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [newCategory, setNewCategory] = useState([])
  const [businessCategoryId, setBusinessCatgeoryId] = useState("")
  const [businessSubCategoryId, setBusinessSubCategoryId] = useState("")
  // const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState(null)
  const [name1, setName1] = useState("")
  const [selectedIds, setSelectedIds] = useState([])
  const [isDelete, setIsDelete] = useState(false)
  // add product variant list end

  // edit product variant list start
  const [rowData, setRowData] = useState([])
  const [ids, setIds] = useState(null)
  // edit product variant list end
  const [searchQuery, setSearchQuery] = useState("")
  const [currentPage, setCurrentPage] = useState(null)
  const [totalPage, setTotalPage] = useState(null)

  const previousData = () => {
    setPage(page - 1)
  }
  const nextData = () => {
    setPage(page + 1)
  }

  const storageData = () => {
    searchQuery === ""
      ? axios
          .get(
            `storage-varient-data-list/?page=${page}&category_id&product_storage_variant_name=${searchQuery}`,

            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            setData(response?.data?.data)
            setCurrentPage(response?.data?.current_page)
            setTotalPage(response?.data?.total_pages)
          })
          .catch((error) => {
            alert(error?.message)
          })
      : axios
          .get(
            `type-two-search-api-for-super-admin/?search_value=${searchQuery} `,

            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            setData(response?.data?.data)
            setCurrentPage(response?.data?.current_page)
            setTotalPage(response?.data?.total_pages)
          })
          .catch((error) => {
            alert(error?.message)
          })
  }
  useEffect(
    () => {
      storageData()
    },
    // eslint-disable-next-line
    [page, searchQuery]
  )

  // useEffect(() => {
  //   axios
  //     .get(`category-list-api-for-admin/`, {
  //       headers: {
  //         Authorization: localStorage.getItem("token"),
  //       },
  //     })
  //     .then((response) => {
  //       setCategory(response?.data?.data);
  //     })
  //     .catch((error) => {
  //       console.log("page not found", error);
  //     });
  // }, []);

  useEffect(() => {
    rowData != [] && setName1(rowData?.title)
    setCategoryId(rowData?.category?.id)
  }, [rowData])

  const createProduct = () => {
    const subData = {
      id: ids,
      category_id: categoryId,
      product_storage_variant_name: name1,
    }
    !ids
      ? axios
          .post(`add-product-storage-variant-data/`, subData, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          })
          .then((response) => {
            setName1("")
            toast(response?.data?.message)
            setCategoryId(null)
            setBusinessCatgeoryId("")
            setBusinessSubCategoryId("")
            setIds(null)
            setRowData([])
            storageData()
            handleClose()
          })
          .catch((error) => {
            console.log(error)
          })
      : axios
          .post(`storage-varient-data-list/`, subData, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          })
          .then((response) => {
            setName1("")
            toast(response?.data?.message)
            setCategoryId(null)
            setBusinessCatgeoryId("")
            setBusinessSubCategoryId("")
            setIds(null)
            setRowData([])
            storageData()
            handleClose()
          })
          .catch((error) => {
            console.log(error)
          })
  }

  const EditData = (row) => {
    setRowData(row)
    setIds(row.id)
  }

  // useEffect(() => {
  //   axios
  //     .post(
  //       `category-list-api-for-admin/`,
  //       { category_id: categoryId },
  //       {
  //         headers: {
  //           Authorization: localStorage.getItem("token"),
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       setBussinessType(response?.data?.data);
  //     })
  //     .catch((error) => {
  //       console.log("page not found", error);
  //     });
  // }, [categoryId]);

  const handleCloseModale = () => {
    setCategoryId(null)
    setBusinessCatgeoryId("")
    setBusinessSubCategoryId("")
    setName1("")
    setIds(null)
    setRowData([])
    handleClose()
  }
  const empty = () => {}

  const CategoryList = () => {
    axios
      .post(
        `business-category-list-data/`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setBusinessData(response.data.business_category_list_data)
        // console.localStorage(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response)
        console.log(error)
        // alert(error.message);
      })
  }

  useEffect(() => {
    CategoryList()
  }, [])

  const StoreList = () => {
    const reqbody = new FormData()
    reqbody.append(
      "business_category_ids",
      businessCategoryId !== "" ? businessCategoryId : rowData?.category?.business_type?.category?.id
    )
    axios
      .post(`business-sub-category-list-data/`, reqbody, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setSubCategory(response?.data.business_sub_category_list)
      })
      .catch((error) => {
        console.log("error page", error.response)
        console.log(error)
      })
  }
  useEffect(
    () => {
      rowData.length !== 0 || businessCategoryId !== "" ? StoreList() : empty()
    },

    // eslint-disable-next-line
    [businessCategoryId, rowData]
  )

  const categoryListData = () => {
    const reqbody = new FormData()
    reqbody.append(
      "business_type_id",
      businessSubCategoryId !== "" ? businessSubCategoryId : rowData?.category?.business_type?.id
    )

    axios
      .post(`category-list-of-business-type-api-for-admin/`, reqbody, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setNewCategory(response?.data?.data)
      })
      .catch((error) => {
        console.log("error page", error.response)
        console.log(error)
      })
  }
  useEffect(
    () => {
      rowData.length !== 0 || businessSubCategoryId !== "" ? categoryListData() : empty()
    },
    // eslint-disable-next-line
    [businessSubCategoryId, rowData]
  )

  const handleAllRowSelect = (event) => {
    setSelectedIds(event.target.checked ? data.map((i) => i.id) : [])
  }

  const handleSelectRow = (id) => {
    setSelectedIds((prevSelectedRow) =>
      prevSelectedRow.includes(id) ? prevSelectedRow.filter((i) => i !== id) : [...prevSelectedRow, id]
    )
  }

  const handleDelete = () => {
    axios
      .post(
        `delete-type-two-api-for-superadmin/`,
        { variant_ids: selectedIds },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        storageData()
        setIsDelete(false)
        setSelectedIds([])
        toast.success(response.success.message)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const hadleClearAll = () => {
    setIsDelete(false)
    setSelectedIds([])
  }

  return (
    <div className="bg-slate-100">
      <div className="flex justify-between bg-slate-100">
        <div className="p-2">
          <p className="text-xl font-bold text-black font">Product Variant Storage List</p>
        </div>
        <div className="flex mt-6 mr-3 gap-x-2">
          <div>
            {selectedIds?.length !== 0 && (
              <>
                <Button
                  startIcon={<DeleteTwoTone />}
                  className="!text-white bg-gradient-to-r from-red-500 to-rose-500"
                  onClick={() => setIsDelete(true)}
                >
                  Delete
                </Button>
                <Dialog
                  open={isDelete}
                  onClose={() => setIsDelete(false)}
                  PaperProps={{ className: "!max-w-[500px] w-96" }}
                >
                  <div className="flex flex-col items-center gap-3 p-5">
                    <ReportTwoTone color="error" className="!text-5xl" />
                    <p className="text-xl">Are you sure!</p>
                    <p>You want to delete selected products?</p>
                  </div>
                  <span className="flex justify-end gap-3 pb-3 pr-3">
                    <Button
                      size="small"
                      variant="outlined"
                      color="inherit"
                      onClick={hadleClearAll}
                      className="!capitalize"
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      disableElevation
                      color="error"
                      className="!capitalize"
                      variant="contained"
                      onClick={() => handleDelete()}
                    >
                      Delete
                    </Button>
                  </span>
                </Dialog>
              </>
            )}
          </div>
          <Button
            onClick={handleOpen}
            className="!text-sm !font-bold !bg-white !px-2 !py-1 mt-2"
            style={{ border: "1px solid black" }}
          >
            + Add Type Two
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="!border-0 !rounded-xl">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {ids === null ? (
                  <div className="!text-center underline">Add Type Two</div>
                ) : (
                  <div className="!text-center underline">Update Type Two</div>
                )}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="gap-y-3">
                  <FormControl fullWidth>
                    <div className="py-2">Business Type</div>
                    <Select
                      value={businessCategoryId || rowData?.category?.business_type?.category?.id || ""}
                      size="small"
                      onChange={(event) => setBusinessCatgeoryId(event.target.value)}
                    >
                      {businessData?.map((res) => {
                        return <MenuItem value={res.id}>{res.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <div className="pt-2">Business Sub Type</div>
                    <Select
                      size="small"
                      value={businessSubCategoryId || rowData?.category?.business_type?.id || ""}
                      className="!mt-5"
                      onChange={(event) => setBusinessSubCategoryId(event.target.value)}
                    >
                      {subCategory?.map((res) => {
                        return <MenuItem value={res.id}>{res.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <div className="pt-2">Category</div>
                    <Select
                      size="small"
                      className="!mt-5"
                      // setCategoryId
                      value={categoryId || rowData?.category?.id || ""}
                      onChange={(event) => setCategoryId(event.target.value)}
                    >
                      {newCategory?.map((res) => {
                        return <MenuItem value={res.id}>{res.category}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  <InputLabel id="demo-simple-select-standard-label" className="mt-2">
                    Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={name1 || rowData?.title}
                    className="!w-full !mt-2"
                    onChange={(event) => setName1(event.target.value)}
                    size="small"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Storage">Storage</MenuItem>
                    <MenuItem value="Size">Size</MenuItem>
                    <MenuItem value="Thickness">Thickness</MenuItem>
                    <MenuItem value="Capacity">Capacity</MenuItem>
                    <MenuItem value="Display Size">Display Size</MenuItem>
                    <MenuItem value="Weight">Weight</MenuItem>
                    <MenuItem value="Thal">Thal</MenuItem>
                    <MenuItem value="Unit">Unit</MenuItem>
                    <MenuItem value="Dimension">Dimension</MenuItem>
                    <MenuItem value="Power Consumption">Power Consumption</MenuItem>
                  </Select>
                </div>
                <div className="items-center justify-between ">
                  <Button variant="contained" className="float-left top-3" onClick={handleCloseModale} color="warning">
                    CANCEL
                  </Button>
                  {ids === null ? (
                    <Button
                      variant="contained"
                      className="float-right top-3"
                      style={{ marginRight: "15px" }}
                      onClick={createProduct}
                      color="success"
                    >
                      CREATE
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="float-right top-3"
                      style={{ marginRight: "15px" }}
                      onClick={createProduct}
                      color="success"
                    >
                      UPDATE
                    </Button>
                  )}
                </div>
              </Typography>
            </Box>
          </Modal>
        </div>
      </div>

      <div className="flex flex-col !my-3 !rounded  bg-dark bg-slate-100 shadow-card shadow-zinc-600 m-2">
        <div className="flex items-center justify-between p-2 h-14 border-zinc-600">
          <div className="flex">
            <input
              type="text"
              className="!border-3 !border-black !w-72 px-2 rounded-md py-1"
              placeholder="search"
              style={{ border: "1px solid black" }}
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
          </div>
          <div className="flex"></div>
        </div>

        <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  <Checkbox onChange={handleAllRowSelect} />
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  <div className="w-40">BUSINESS TYPE</div>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  <div className="w-48">BUSINESS SUB TYPE</div>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  <div className="w-32">CATEGORY</div>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  <div className="w-32">Type Two</div>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  STATUS
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data?.map((row) => (
                  <TableRow key={row?.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      <Checkbox
                        value={row?.id}
                        checked={selectedIds.filter((id) => id === row?.id)?.length === 1 ? true : false}
                        onChange={() => handleSelectRow(row.id)}
                      />
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.category?.business_type?.category?.name}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.category?.business_type?.name}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.category?.title}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.title}
                    </TableCell>

                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.category?.business_type?.category?.active_status === true ? <Active /> : <Inactive />}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      <DeleteIcon className="!text-xl !text-red-600 !cursor-pointer" />
                      <button onClick={handleOpen}>
                        <EditIcon className="!text-xl !cursor-pointer !text-green-600" onClick={() => EditData(row)} />
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {/* <div
            className="!float-right py-3 relative w-full !right-0 border-t border-gray-500"
            style={{ marginRight: "0px" }}
          >
            <button onClick={nextData} className="float-right">
              <ArrowForwardIosIcon />
            </button>
            <button onClick={previousData} className="float-right">
              <ArrowBackIosIcon />
            </button>
          </div> */}
        </TableContainer>

        <div className="!float-right py-3 relative !right-0 bg-white" style={{ marginRight: "0px" }}>
          <button onClick={nextData} className="float-right">
            {currentPage}...{totalPage}
            <ArrowForwardIosIcon />
          </button>
          <button onClick={previousData} className="float-right">
            <ArrowBackIosIcon size="small" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductVariantList
