import { WhatsApp } from "@mui/icons-material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Divider, Tab } from "@mui/material"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import CustomButton from "shared/MkxButton"
import WhatsAppSetting from "../WhatsAppSettings"
import Automation from "./Automation"

const Whatsapp = () => {
  const navigate = useNavigate()
  const [value, setValue] = useState("WhatsApp Config")

  const buttons = ["WhatsApp Config", "WhatsApp Templates"]
  const handleChange = (_, value) => setValue(value)

  return (
    <>
      <div className="flex flex-col gap-3">
        <TabContext value={value}>
          <Box className="bg-white bg-opacity-30">
            <TabList onChange={handleChange}>
              {buttons.map((button) => {
                return <Tab className="!capitalize !font-semibold" label={button} value={button} />
              })}
            </TabList>
          </Box>
          <Divider />
          <TabPanel className="!p-0" value="WhatsApp Config">
            <WhatsAppSetting />
          </TabPanel>
          <TabPanel className="!p-0" value="WhatsApp Templates">
            <Automation />
          </TabPanel>
        </TabContext>
      </div>
    </>
  )
}

export default Whatsapp
