import { API_URLS } from "../../config/apiUrls"
import axiosInstance from "config/axios"

export const addProductCategoryFn = (redBody) => {
  try {
    const response = axiosInstance.post(API_URLS.addProductCategory, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateProductCategoryFn = (redBody) => {
  try {
    const response = axiosInstance.post(API_URLS.updateProductCategory, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
