import axiosInstance from "config/axios"

export const smsFn = (reqBody) => {
  try {
    const response = axiosInstance.get("sms-configuration-api-of-superadmin/", { params: reqBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
