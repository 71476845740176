import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"

export const chatTempsFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.whatsappTemplates2, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
