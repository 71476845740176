import { Add } from "@mui/icons-material"
import { addEmailsFn } from "Services/Settings/EmailSetting/AddEmail"
import { useFormik } from "formik"
import { useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import CustomModal from "shared/MkxModal"
const AddEmail = () => {
  const { store_id } = useParams()
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const client = useQueryClient()
  const initialValues = {
    host: "",
    username: "",
    password: "",
  }
  const { mutate: addEmails, isLoading: isLoading } = useMutation(addEmailsFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("emails")
        handleClose()
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      addEmails({ ...formik.values, store_id })
    },
  })

  return (
    <>
      <CustomButton startIcon={<Add />} size="medium" className="!px-5" onClick={() => setOpen(true)}>
        Email
      </CustomButton>
      <CustomModal title="Add Email" open={open} onClose={handleClose} className="!w-1/3">
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4 p-3">
          <span className="flex">
            <CustomInput id="host" formik={formik} className="!w-full" label="Host" />
          </span>
          <span className="grid grid-cols-2 gap-5">
            <CustomInput id="username" formik={formik} label="Email Host*" />
            <CustomInput type="password" id="password" formik={formik} label="Email Host Password*" />
          </span>
          <span className="flex justify-end gap-3">
            <CustomButton color="inherit" onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton isLoading={isLoading} type="submit">
              Add
            </CustomButton>
          </span>
        </form>
      </CustomModal>
    </>
  )
}

export default AddEmail
