import { Add } from "@mui/icons-material"
import { MenuItem } from "@mui/material"
import { addFloorsFn, updateFloorsFn } from "Services/Settings/Restaurant"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import CustomModal from "shared/MkxModal"
import CustomSelect from "shared/MkxSelect"
import { useFormik } from "formik"
import { useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { useParams } from "react-router-dom"

const AddFloors = ({ selected, setSelected }) => {
  const { store_id } = useParams()

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setSelected(null)
  }
  const isUpdate = Boolean(selected)
  const client = useQueryClient()
  const { mutate: addFloors, isLoading: isAdding } = useMutation(addFloorsFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("floors")
        handleClose()
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const { mutate: updateFloors, isLoading: isUpdating } = useMutation(updateFloorsFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("floors")
        handleClose()
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const initialValues = { store_id, floor: selected?.floor ?? "", status: selected?.status ?? "In Service" }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      isUpdate ? updateFloors({ floor_id: selected?.id, ...values }) : addFloors(values)
    },
  })

  return (
    <>
      <CustomButton startIcon={<Add />} onClick={handleOpen}>
        Floor
      </CustomButton>
      <CustomModal
        className="!w-[500px]"
        title={isUpdate ? "Update Floor" : "Add Floor"}
        open={open || isUpdate}
        onClose={handleClose}
        padding={3}
      >
        <form onSubmit={formik.handleSubmit} className="flex flex-col w-full p-5">
          <CustomInput
            id="floor"
            isRequired
            formik={formik}
            className="!w-full"
            label="Floor Name"
            placeholder="Enter Floor Name"
          />
          <CustomSelect label="Status" id="status" formik={formik} placeholder="Select Status">
            <MenuItem value="In Service">In Service</MenuItem>
            <MenuItem value="Paused Service">Paused Service</MenuItem>
          </CustomSelect>

          <span className="flex justify-end gap-3 py-3">
            <CustomButton color="inherit" onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton isLoading={isAdding || isUpdating} type="submit">
              Submit
            </CustomButton>
          </span>
        </form>
      </CustomModal>
    </>
  )
}

export default AddFloors
