import { FilterAlt } from "@mui/icons-material"
import AddIcon from "@mui/icons-material/Add"
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import axios from "config/axios"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { CustomIconButton } from "../../shared/CustomIconButton"
import Modal from "@mui/material/Modal"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

const Notification = () => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(6)
  const [data, setData] = useState([])
  const [deleted, setDeleted] = useState([])
  const [open1, setOpen1] = useState(false)
  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => setOpen1(false)
  const [searchQuery, setSearchQuery] = useState("")

  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [subject, setSubject] = useState("")
  const [image, setImage] = useState("")
  const [rowData, setRowData] = useState({})

  const navigate = useNavigate()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const ResellersListData = () => {
    axios
      .get(`notification-messages/`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setData(response?.data?.data)
        console.log("all data help and support====>", response?.data?.data)
      })
      .catch((error) => {
        console.log("", error)
      })
  }
  useEffect(() => {
    ResellersListData()
  }, [searchQuery])

  const filterDataList = () => {
    axios
      .get(`notification-messages/?title=${title}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        console.log("filter data listed===>", response?.data?.data[0])
        setRowData(response?.data?.data[0])
      })
      .catch((error) => {
        console.log("", error)
      })
  }
  useEffect(() => {
    filterDataList()
  }, [title])

  const renameData = () => {
    const fd = new FormData()
    fd.append("title", title || rowData.title)
    fd.append("subject", subject || rowData.subject)
    fd.append("description", description || rowData.image)
    fd.append("image", image || rowData?.image)

    axios
      .post(`notification-messages/`, fd, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        console.log("filter data listed===>", response?.data?.Message)
        toast.success(response?.data?.Message)
        handleClose()
      })
      .catch((error) => {
        console.log("", error)
      })
  }
  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font-bold text-black">Notification</p>
            {/* <p className="text-black">View/Search Notification</p> */}
          </div>
          <div className="p-2 pt-3">
            <Button variant="outlined" color="primary" onClick={handleOpen} size="small">
              <AddIcon /> Add Notification
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="!border-0 !rounded-xl">
                <Typography id="modal-modal-title" variant="h6" component="h6">
                  <div
                    className="!text-center underline underline-offset-4"
                    style={{ fontFamily: "'Poppins', sans-serif" }}
                  >
                    Add Type Three Value
                  </div>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div className="gap-y-3"></div>
                  <div className="pt-2 text-sm">Title:</div>
                  <Select
                    className="!w-full !mt-1 !text-black"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    onChange={(event) => setTitle(event.target.value)}
                  >
                    <MenuItem value="Add Customer">Add Customer</MenuItem>
                    <MenuItem value="Otp Sent">Otp Sent</MenuItem>
                  </Select>

                  <div className="gap-2">
                    <InputLabel className="!mt-1 !text-gray-700" style={{ fontFamily: "'Poppins', sans-serif" }}>
                      <div className="my-1 text-sm">Subject :</div>
                    </InputLabel>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      className="!w-[100%] !placeholder:text-sm"
                      size="small"
                      value={subject || rowData?.subject || ""}
                      onChange={(event) => setSubject(event.target.value)}
                    />
                    <InputLabel className="!mt-1 !text-gray-700" style={{ fontFamily: "'Poppins', sans-serif" }}>
                      <div className="my-1 text-sm">Image :</div>
                    </InputLabel>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="file"
                      className="!w-[100%] !placeholder:text-sm"
                      size="small"
                      onChange={(event) => setImage(event.target.files[0])}
                    />
                    <InputLabel className=" !text-gray-700" style={{ fontFamily: "'Poppins', sans-serif" }}>
                      <div className="my-1 text-sm">Description :</div>
                    </InputLabel>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      className="!w-[100%] !mt-1 !placeholder:text-sm"
                      size="small"
                      value={description || rowData?.description || ""}
                      onChange={(event) => setDescription(event.target.value)}
                    />
                  </div>
                  <div className="items-center justify-between ">
                    <Button className="float-left top-3" variant="contained" color="warning" onClick={handleClose}>
                      CANCEL
                    </Button>

                    <Button variant="contained" color="success" className="float-right mr-3 top-3" onClick={renameData}>
                      CREATE
                    </Button>
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
        <div className="flex flex-col !rounded bg-secondary border-secondary shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center py-2 bg-gray-100 rounded">
            <CustomIconButton>
              <FilterAlt className="text-black" />
            </CustomIconButton>
            <div>
              <input
                type="text"
                className="!border-3 !border-black !w-72 px-2 rounded-md py-1"
                placeholder="search"
                style={{ border: "1px solid black" }}
              />
            </div>
          </div>
          <TableContainer
            component={Paper}
            sx={{ width: "100%", maxHeight: 440 }}
            className="!text-black !mx-auto !bg-white overflow-auto px-2"
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    TITLE
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    SUBJECT
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    IMAGE
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    DECRIPTION
                  </TableCell>

                  <TableCell
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    ACTION
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map((row, index) => (
                    <TableRow key={index + 1} className="!cursor-pointer">
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.title}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.title}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.image ? <img src={row?.image} className="w-8 h-8 mx-auto" /> : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.description ? row?.description : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        edit
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {data?.length !== 0 ? (
              <TablePagination
                rowsPerPageOptions={[6]}
                count={data?.length}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default Notification
