import { CheckBox, CheckCircle } from "@mui/icons-material"
import { List, ListItem } from "@mui/material"
import CardContent from "@mui/material/CardContent"
import { storePlanFn } from "Services/Settings/StorePlan"
import classNames from "classnames"
import { useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import Loader from "shared/CustomLoader"
import Switchda from "./Switchda"

const StorePlan = () => {
  const [toggle, setToggle] = useState(false)
  const { store_id } = useParams()
  const { data: storePlan, isLoading } = useQuery(["storePlan", toggle], () =>
    storePlanFn({ plan_price_type: toggle ? "Yearly" : "Monthly", store_id })
  )

  const plan = storePlan?.data?.data

  return isLoading ? (
    <div className="flex items-center justify-center w-full h-full">
      <Loader />
    </div>
  ) : (
    <>
      <span className="text-xl">
        <Switchda label={"Price Comparison"} onChange={(event) => setToggle(event.target.checked)} />
      </span>

      <div className="grid grid-cols-3 gap-3 py-3">
        {plan?.map((item) => {
          return (
            <>
              <CardContent
                className={classNames(
                  "!inline-block shadow-md hover:shadow-lg transition-all duration-200 cursor-pointer border-2 text-center !rounded !py-5 px-3",
                  item?.store_active_plan
                    ? item.plan_name === "Trial"
                      ? "border-blue-500"
                      : "border-green-500"
                    : "border-gray-200"
                )}
              >
                <div className="flex justify-end w-full">
                  {item?.store_active_plan ? (
                    <CheckCircle className={item.plan_name === "Trial" ? "!text-blue-500" : "!text-green-500"} />
                  ) : (
                    <CheckCircle className="!opacity-0" />
                  )}
                </div>
                <div className="flex justify-center">
                  <img src="https://cdn-icons-png.flaticon.com/512/2815/2815428.png" alt="" className="!h-16" />
                </div>
                <p className="!text-[#00365A] font-bold text-2xl p-1">{item?.plan_name}</p>
                <p className="p-1 text-3xl font-bold">₹ {toggle ? item?.plan_price : item?.plan_price}</p>
                <p className="pt-2 text-xl font-bold text-gray-500">{toggle ? "User/Yearly" : "User/Monthly"}</p>
                <List className="!flex !flex-col !items-center w-full">
                  {item?.modules?.map((module) => {
                    return (
                      <ListItem className="!w-60 !flex !justify-between !p-2">
                        {module}
                        <CheckBox color="success" fontSize="small" className="!font-bold" />
                      </ListItem>
                    )
                  })}
                </List>
              </CardContent>
            </>
          )
        })}
      </div>
    </>
  )
}

export default StorePlan
