import { FilterAlt } from "@mui/icons-material"
import axios from "config/axios"
import excel from "../../assets/excel.svg"
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import React, { useEffect, useState, useRef } from "react"
import { CustomIconButton } from "../../shared/CustomIconButton"
import Search from "../../shared/Search"
import { DownloadTableExcel } from "react-export-table-to-excel"
import { toast } from "react-toastify"

const ProductData = () => {
  const [data, setData] = useState()

  const tableRef = useRef(null)
  const ProductDataFileList = () => {
    axios
      .get(`http://45.64.156.214:9897/product-export-data-in-excel/`)
      .then((response) => {
        setData(response?.data.data)
        // toast(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response)
        console.log(error)
        toast(error.message)
      })
  }

  useEffect(() => {
    ProductDataFileList()
  }, [])

  return (
    <div className="m-4">
      <div className="flex flex-col !my-3 !rounded  bg-secondary border-secondary shadow-card shadow-zinc-600">
        <div className="flex justify-between items-center p-2 h-14 border-zinc-600">
          <div className="flex items-center">
            <CustomIconButton>
              <FilterAlt />
            </CustomIconButton>
            <Search />
          </div>
          <DownloadTableExcel filename="users table" sheet="users" currentTableRef={tableRef.current}>
            <div className="!bg-white !rounded">
              <img src={excel} alt="" className="h-10" />
            </div>
          </DownloadTableExcel>
        </div>
      </div>
      <TableContainer component={Paper} sx={{ maxWidth: 1420 }}>
        <Table className="overflow-auto " ref={tableRef}>
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">HSN</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Sub Category</TableCell>
              <TableCell align="center">Brand</TableCell>
              <TableCell align="center">Quantity</TableCell>
              <TableCell align="center">MRP</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((item) => (
                <TableRow>
                  <TableCell align="center">{item.Name}</TableCell>
                  <TableCell align="center">{item.Description}</TableCell>
                  <TableCell align="center">{item.HSN}</TableCell>
                  <TableCell align="center">{item.Category}</TableCell>
                  <TableCell align="center">{item.Sub_Category}</TableCell>
                  <TableCell align="center">{item.Brand}</TableCell>
                  <TableCell align="center">{item.Quant}</TableCell>
                  <TableCell align="center">{item.MRP}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ProductData
