import { Divider, MenuItem, Switch } from "@mui/material"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import MuiSelect from "shared/MkxSelect"
import axiosInstance from "config/axios"
import { addgstFn } from "Services/Settings/Gst/AddGst"

const GstSettings = () => {
  const navigate = useNavigate()
  const [list, setList] = useState([])

  const { mutate: addgst, isLoading: isLoading } = useMutation(addgstFn, {
    onSuccess: (res) => {
      toast.success(res.data.message)
      gstList()
      res.data.message === "GST created successfully." && navigate(-1)
    },
  })

  const initialValues = {
    gst_number: list?.gst_number,
    pan_number: list?.pan_number,
    business_legal_name: list?.business_legal_name,
    business_trade_name: list?.business_trade_name,
    gst_registered_on: list?.gst_registered_on,
    gst_username: list?.gst_username,
    first_tax_return: list?.first_tax_return,
    reporting_period: list?.reporting_period,
  }
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      const formData = new FormData()
      formData.append("gst_number", formik.values.gst_number)
      formData.append("pan_number", formik.values.pan_number)
      formData.append("business_legal_name", formik.values.business_legal_name)
      formData.append("business_trade_name", formik.values.business_trade_name)
      formData.append("gst_registered_on", formik.values.gst_registered_on)
      formData.append("gst_username", formik.values.gst_username)
      formData.append("first_tax_return", formik.values.first_tax_return)
      formData.append("reporting_period", formik.values.reporting_period)
      addgst(formData)
    },
  })

  const gstList = () =>
    axiosInstance.get(`api_store_gst_and_online_filling_settings_bhaaraterp/`).then((res) => {
      setList(res?.data?.data)
    })

  useEffect(() => {
    gstList()
  }, [])
  const handleChange = (event) =>
    axiosInstance
      .put(`api_store_gst_and_online_filling_settings_bhaaraterp/`, {
        gst_filling_status: event.target.checked ? "true" : "false",
      })
      .then((res) => {
        toast.success(res?.data?.message)
        gstList()
      })

  return (
    <div>
      <p>Accounts you would like to enable</p>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
        <div className="py-2">
          <Switch
            checked={list?.gst_filling_status ? true : false}
            onChange={(event) => handleChange(event, list?.gst_filling_status)}
          />
          <span>GST Filling</span>
        </div>
        <div className="grid justify-center grid-cols-2 gap-4">
          <CustomInput
            id="gst_number"
            placeholder="GST Number"
            className="!w-4/5"
            label="GST Number*"
            formik={formik}
          />
          <CustomInput
            id="business_legal_name"
            placeholder="Business Legal Name"
            name="business_legal_name"
            className="!w-4/5"
            label="Business Legal Name"
            formik={formik}
          />
          <CustomInput
            id="business_trade_name"
            placeholder="Business Trade Name"
            name="business_trade_name"
            className="!w-4/5"
            label="Business Trade Name"
            formik={formik}
          />
          <CustomInput
            id="pan_number"
            placeholder="Pan Number"
            name="pan_number"
            className="!w-4/5"
            label="Pan Number"
            formik={formik}
          />
          <CustomInput
            id="gst_registered_on"
            placeholder=" GST Registered On"
            name="gst_registered_on"
            label="GST Registered On"
            className="!w-4/5"
            type="date"
            formik={formik}
          />
        </div>

        <Divider className="!my-3" />

        <p className="text-xl font-bold">Online Settings </p>
        <div className="grid justify-center grid-cols-2 gap-4 ">
          <CustomInput
            id="gst_username"
            placeholder=" GSTNumber Username"
            name="gst_username"
            className="!w-4/5"
            label="GSTNumber Username*"
            formik={formik}
          />
          <MuiSelect
            id="reporting_period"
            placeholder="Reporting Period"
            name="reporting_period"
            label="Reporting Period*"
            className="!w-45 my-2"
            value={formik.values.reporting_period}
            onChange={formik.handleChange}
          >
            <MenuItem value="Monthly">Monthly</MenuItem>
            <MenuItem value="Quaterly">Quaterly</MenuItem>
          </MuiSelect>

          <CustomInput
            id="first_tax_return"
            placeholder="Generate First Tax Return From"
            name="first_tax_return"
            label="Generate First Tax Return From"
            className="!w-4/5"
            type="date"
            formik={formik}
          />
        </div>

        <Divider className="!my-3" />
        <div className="flex justify-end">
          <CustomButton type="submit" variant="contained" size="small" className="!capitalize" isLoading={isLoading}>
            Save
          </CustomButton>
        </div>
      </form>
    </div>
  )
}

export default GstSettings
