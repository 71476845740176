import { AddCircle, Delete, Edit } from "@mui/icons-material"
import { Divider, Grow, IconButton, List, ListItem, ListItemButton, Skeleton } from "@mui/material"
import React, { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import CustomButton from "shared/MkxButton"

import { toast } from "react-toastify"
import { delateWorkFlowFn, whatsappWorkflowFn } from "Services/Chats/WhatsappWorkflow"

const Workflows = () => {
  const [hover, setHover] = useState("")
  const client = useQueryClient()
  const navigate = useNavigate()
  const { data: workFlows, isLoading } = useQuery(["whatsappWorkflow"], whatsappWorkflowFn, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })
  const { mutate: delateWorkFlow } = useMutation(delateWorkFlowFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("whatsappWorkflow")
      } else {
        toast.error(response.data.message)
      }
    },
  })

  return (
    <div className="flex flex-col">
      <span className="flex items-center justify-between p-[21px]">
        <p className="text-lg font-semibold">Workflows</p>
        <CustomButton
          startIcon={<AddCircle />}
          className="!rounded-full"
          onClick={() =>
            navigate("/settings/whatsapp/templates", {
              state: { page: "CreateWorkflows" },
            })
          }
        >
          Create New Workflow
        </CustomButton>
      </span>
      <Divider />
      <List className="rounded !p-0">
        {isLoading
          ? [1, 2, 3, 4, 5, 6]?.map(() => {
              return (
                <>
                  <ListItem className="!flex !justify-between !p-2">
                    <Skeleton className="!w-1/3 !scale-100" />
                    <span className="flex gap-2">
                      <Skeleton className="!rounded-full !scale-100 !h-6 !w-6" />{" "}
                      <Skeleton className="!rounded-full !scale-100 !h-6 !w-6" />
                    </span>
                  </ListItem>
                  <Divider />
                </>
              )
            })
          : workFlows?.data?.data?.map((item) => {
              return (
                <>
                  <ListItemButton
                    className="!flex !justify-between"
                    onMouseEnter={() => setHover(item.id)}
                    onMouseLeave={() => setHover("")}
                    onClick={() =>
                      navigate("/settings/whatsapp/templates", {
                        state: { page: "CreateWorkflows", workflowId: item.id },
                      })
                    }
                  >
                    <p>{item?.workflow_title}</p>
                    <Grow in={item.id === hover}>
                      <span className="flex items-center gap-2">
                        <IconButton size="small">
                          <Edit className="hover:text-blue-500" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={(event) => {
                            event.stopPropagation()
                            delateWorkFlow({ id: item.id })
                          }}
                        >
                          <Delete className="hover:text-red-500" />
                        </IconButton>
                      </span>
                    </Grow>
                  </ListItemButton>
                  <Divider />
                </>
              )
            })}
      </List>
    </div>
  )
}

export default Workflows
