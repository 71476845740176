import axiosInstance from "config/axios"

export const WebsiteSyncFn = (redBody) => {
  try {
    const response = axiosInstance.get(`store-website-sync-setup-api-of-superadmin/`, {
      params: redBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateWebsiteFn = (redBody) => {
  try {
    const response = axiosInstance.put(`store-website-sync-setup-api-of-superadmin/`, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const AddwebsiteSyncFn = (redBody) => {
  try {
    const response = axiosInstance.post(`store-website-sync-setup-api-of-superadmin/`, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
