import { Button, Checkbox, createTheme, FormHelperText, TextField, ThemeProvider } from "@mui/material"
import axios from "config/axios"
import { useFormik } from "formik"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import login from "../../assets/authentication/bg.jpg"
import logo from "../../assets/logobharat.png"
import { logInSchema } from "../../schema"

const SignIn = () => {
  const lightTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#1345B7",
      },
    },
  })

  const navigate = useNavigate()

  const initialValues = { email: "", password: "" }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: logInSchema,
    onSubmit: (values) => {
      const reqBody = { email: values.email, password: values.password }
      axios
        .post(`super-admin-login/`, { ...reqBody })
        .then((response) => {
          localStorage.setItem("token", response?.data.token)
          localStorage.setItem("user_name", response?.data.user_name)
          localStorage.setItem("role", "admin")
          navigate("/dashboard")
          toast(response.data.message)
        })
        .catch((error) => {
          toast.error(error.response.data.message)
        })
    },
  })

  useEffect(() => {
    localStorage.getItem("role") === "reseller"
      ? navigate("/resellers/dashboard")
      : localStorage.getItem("role") === "admin"
      ? navigate("/dashboard")
      : navigate("/")
  }, [])

  return (
    <>
      <div className="flex">
        <img src={login} alt="" className="fixed object-cover w-screen h-screen" />
        <div className="flex flex-col h-screen items-center p-16 text-black w-[30%] border-r-2 border-opacity-10 bg-white bg-opacity-10 backdrop-blur-md">
          <img src={logo} alt="" className="mt-8 h-28" />
          <p className="mt-10 text-2xl font-semibold">Sign In</p>
          <p className="mt-4 text-xs">Sign in to stay connected.</p>
          <form onSubmit={formik.handleSubmit} className="flex flex-col w-full my-2">
            <ThemeProvider theme={lightTheme}>
              <label className="py-1 mt-3">Email*</label>
              <TextField
                id="email"
                name="email"
                size="small"
                placeholder="Enter Email"
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                type="email"
                className="p-1 text-black bg-white border-2 rounded-md focus:outline-none bg-opacity-20 backdrop-blur-sm"
              />
              <FormHelperText className="!text-red-700 px-1">{formik.errors["email"]}</FormHelperText>
              <label className="py-1 mt-3">Password*</label>
              <TextField
                id="password"
                name="password"
                size="small"
                placeholder="Enter Password"
                value={formik.values["password"]}
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                type="password"
                className="rounded-md text-black p-1.5 focus:outline-none border-2 bg-white bg-opacity-20 backdrop-blur-sm"
              />
              <FormHelperText className="!text-red-700 px-1">{formik.errors["password"]}</FormHelperText>
              <div className="flex items-center justify-start w-full">
                <div className="flex items-center my-1">
                  <Checkbox className="!p-0 !pr-1" />
                  <p className="cursor-pointer">Remember Me</p>
                </div>
              </div>
              <div className="flex justify-center mt-5">
                <Button variant="contained" type={"submit"} className="!px-10 !py-1.5 !capitalize !rounded-md">
                  Sign In
                </Button>
              </div>
            </ThemeProvider>
          </form>
        </div>
      </div>
    </>
  )
}

export default SignIn
