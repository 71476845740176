import { Add } from "@mui/icons-material"
import { postloyalityPointSettingFn, updateloyalityPointSettingFn } from "Services/Settings/LoyalityPoint"
import { useFormik } from "formik"
import { useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import CustomModal from "shared/MkxModal"

const AddLoyalityPoint = ({ selected, setSelected }) => {
  const { store_id } = useParams()
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
    setSelected(false)
  }
  const isUpdate = Boolean(selected)

  const client = useQueryClient()
  const initialValues = {
    value: selected?.value ?? "",
    max_points: selected?.max_points ?? "",
    store_id,
  }
  const { mutate: addLoyality, isLoading: isCreating } = useMutation(postloyalityPointSettingFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("loyalityPoint")
        handleClose()
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const { mutate: updateLoyality, isLoading: isUpdating } = useMutation(updateloyalityPointSettingFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("loyalityPoint")
        handleClose()
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      isUpdate ? updateLoyality({ loyalty_id: selected?.id, ...formik.values }) : addLoyality(formik.values)
    },
  })

  return (
    <>
      <CustomButton size="medium" startIcon={<Add />} onClick={() => setOpen(true)}>
        Add Loyality
      </CustomButton>
      <CustomModal
        padding={0}
        title="Manage Loyality"
        onClose={handleClose}
        className="w-1/3 mkxx"
        open={open || isUpdate}
      >
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4 p-4">
          <CustomInput
            type="number"
            isRequired
            id="value"
            formik={formik}
            label="Loyality Value"
            placeholder="Enter Loyality Value"
          />
          <CustomInput
            type="number"
            isRequired
            id="max_points"
            formik={formik}
            label="Loyality Point"
            placeholder="Enter Loyality Point"
          />
          <span className="flex items-center justify-end gap-3">
            <CustomButton color="inherit" onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton isLoading={isCreating || isUpdating} type="submit">
              Save
            </CustomButton>
          </span>
        </form>
      </CustomModal>
    </>
  )
}

export default AddLoyalityPoint
