import axiosInstance from "config/axios"

export const confirmEmailFn = (reqBody) => {
  try {
    const response = axiosInstance.patch(`email-setup-settings-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
