import { useCallback, useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import MkxButton from "shared/MkxButton"
import useEmailTemplate from "shared/useEmailTemplate"
import { emailsTempFn, getTemplateType, postEmailTemplateFn, updateEmailTemplateFn } from "Services/EmailTemplates"
import SearchAndSelect from "shared/SearchAndSelect"

const AddTemplate = () => {
  const { templateId } = useParams()
  const [content, setContent] = useState("")
  const [options, setOptions] = useState([])
  const [selectedType, setSelectedType] = useState()
  const [submitClick, setSubmitClick] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [design, setDesign] = useState("")
  const { EmailTemplate, exportHtml } = useEmailTemplate({ content, setContent, design })
  const navigate = useNavigate()

  useQuery(["templateData", templateId], () => emailsTempFn({ email_template_id: templateId }), {
    onSuccess: (res) => {
      if (res.data.data) {
        setSelectedType(res.data.data.template_type)
        setDesign(res.data.data.content_design)
        setSuggestions(templateTypeData?.data?.data?.find((item) => item.id === res.data.data.template_type)?.variables)
      }
    },
    enabled: Boolean(templateId),
  })

  const { mutate: addTemplateMutation } = useMutation(postEmailTemplateFn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        toast.success(response?.data?.message)
        navigate("/email-templates")
      } else {
        toast.error(response?.data?.message)
      }
    },
  })

  const { mutate: updateTemplateMutation } = useMutation(updateEmailTemplateFn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        toast.success(response?.data?.message)
        navigate("/email-templates")
      } else {
        toast.error(response?.data?.message)
      }
    },
  })

  const handleSubmit = () => {
    exportHtml()
    setSubmitClick(true)
  }

  useEffect(() => {
    if (submitClick && content) {
      const reqBody = new FormData()
      reqBody.append("content", content.html)
      reqBody.append("template_type", selectedType)
      reqBody.append("content_design", JSON.stringify(content.design))
      if (!templateId) {
        addTemplateMutation(reqBody)
      } else {
        reqBody.append("email_template_id", templateId)
        updateTemplateMutation(reqBody)
      }
      setSubmitClick(false)
    }
  }, [submitClick, content])

  const { data: templateTypeData } = useQuery(["templateTypes", selectedType], getTemplateType, {
    onSuccess: (res) => {
      if (res?.data?.response_code === 200) {
        const arr = []
        res.data?.data?.map((item) => {
          arr.push({
            label: item.title,
            value: item.id,
          })
        })
        setOptions(arr)
      }
    },
  })

  const EmailTemplateComponent = useCallback(() => {
    return (
      <div className="grid grid-cols-1 px-6 !w-full">
        <EmailTemplate />
      </div>
    )
  }, [content, design])

  const handleTypeChange = (...args) => {
    setSuggestions(templateTypeData?.data?.data?.find((item) => item.id === args[1].value)?.variables)
  }

  const SuggestionSection = useCallback(() => {
    return (
      suggestions &&
      suggestions.length > 0 && (
        <div className="flex flex-wrap text-xl font-semibold text-red-800 text-wrap">
          Use these variables to access their values in templates:&nbsp;
          <span>
            {suggestions?.map((item, idx) => {
              return (
                <span className="text-blue-500">{`{{${item}}}${idx !== suggestions.length - 1 ? ", " : "."}`}</span>
              )
            })}
          </span>
        </div>
      )
    )
  }, [suggestions])

  return (
    <>
      <div className="flex flex-col justify-between gap-4 mt-4">
        <div className="w-1/3 px-6">
          <SearchAndSelect
            onChange={handleTypeChange}
            options={options}
            value={selectedType}
            setValue={setSelectedType}
            label="Template Type"
          />
        </div>
        <div>
          <div className="flex h-24 gap-8 p-4 px-6">
            <div className="text-lg font-bold w-fit whitespace-nowrap">Template Content</div>
            <SuggestionSection />
          </div>
          <EmailTemplateComponent />
        </div>
        <div className="flex justify-end gap-3 px-6 pb-4">
          <MkxButton onClick={handleSubmit} className="!rounded-full !py-2 !px-10 !w-48">
            {templateId ? "Update" : "Create"}
          </MkxButton>
        </div>
      </div>
    </>
  )
}

export default AddTemplate
