import { Close, Create, Dashboard, Delete, InfoOutlined } from "@mui/icons-material"
import {
  Button,
  Chip,
  Dialog,
  Divider,
  IconButton,
  MenuItem,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import AddNewERPTemplate from "./AddNewERPTemplate"

import { toast } from "react-toastify"
import { deleteErpTemplateFn, erpTemplateListFn } from "Services/Settings/ChatSettings/ERPTemplate"
import {
  addTemplateApprovalFn,
  deleteTemplateApprovalFn,
  updateTemplateApprovalFn,
} from "Services/Settings/ChatSettings/TemplateApproval"

import CustomSelect from "shared/MkxSelect"
import TableCell from "shared/MkxTable/MkxTableCell"
import APIIntgeration from "./APIIntegration"

const ERPTemplateSettings = () => {
  const [response, setResponse] = useState({})
  const [page, setPage] = useState(1)
  const [templateType, setTemplateType] = useState("Custom")
  const [isAbout, setIsAbout] = useState(false)
  const [rowId, setRowId] = useState("")

  const client = useQueryClient()
  const handleChange = (event, value) => {
    setPage(value)
    console.log(value)
  }

  const { data: templates, isLoading } = useQuery(
    ["ERPTemplate", page, templateType],
    () => erpTemplateListFn({ template_type: templateType, page }),
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  )
  const { mutate: deleteTemplates } = useMutation(deleteErpTemplateFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        client.refetchQueries("ERPTemplate")
        toast.success(response.data.message)
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const { mutate: addTemplateApproval } = useMutation(addTemplateApprovalFn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        toast.success(response?.data?.message)
        client.refetchQueries("ERPTemplate")

        if (JSON.parse(response?.data?.data)?.error) {
          toast.error(
            `Type : ${JSON.parse(response?.data?.data)?.error?.type} : ${
              JSON.parse(response?.data?.data)?.error?.error_user_msg
            }`
          )
        }
      }
    },
  })
  const { mutate: updateTemplateApproval, isLoading: isLoadingUpdateStatus } = useMutation(updateTemplateApprovalFn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        toast.success(response?.data?.message)
        client.refetchQueries("ERPTemplate")
        setIsAbout(false)
        if (JSON.parse(response?.data?.data)?.error) {
          toast.error(
            `Type : ${JSON.parse(response?.data?.data)?.error?.type} : ${
              JSON.parse(response?.data?.data)?.error?.error_user_msg
            }`
          )
        }
      }
    },
  })
  const { mutate: deleteTemplateApproval } = useMutation(deleteTemplateApprovalFn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        toast.success(response?.data?.message)
        client.refetchQueries("ERPTemplate")
        setIsAbout(false)
        if (JSON.parse(response?.data?.data)?.error) {
          toast.error(
            `Type : ${JSON.parse(response?.data?.data)?.error?.type} : ${
              JSON.parse(response?.data?.data)?.error?.error_user_msg
            }`
          )
        }
      }
    },
  })

  return (
    <>
      <div className="flex flex-col">
        <span className="flex items-center justify-between p-[14px]">
          <span className="flex items-start gap-2 text-xl">
            <Dashboard />
            <span>
              <p className="font-semibold"> ERP Templates</p>
              <p className="text-sm">Set up and manage Custom ERP Templates for your account</p>
            </span>
          </span>
          <AddNewERPTemplate setResponse={setResponse} response={response} />
        </span>
        <Divider />
        <span className="flex items-center justify-between p-1">
          <span>{"Total Template : " + (templates?.data?.count || 0)}</span>
          <CustomSelect
            id="template_type"
            value={templateType}
            placeholder="Select Template Type"
            className="!w-36"
            onChange={(event) => setTemplateType(event.target.value)}
          >
            <MenuItem value="Fixed">Fixed</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
          </CustomSelect>
        </span>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow className="bg-white bg-opacity-40">
                <TableCell className="!font-bold">Template Name</TableCell>
                <TableCell className="!font-bold">Auto Response Preview</TableCell>
                <TableCell className="!font-bold">Template Type</TableCell>
                <TableCell className="!font-bold">Template Response Type</TableCell>
                <TableCell className="!font-bold">API Integration</TableCell>
                {templateType === "Fixed" && (
                  <>
                    <TableCell className="!font-bold">Status</TableCell>
                    <TableCell className="!font-bold">Action</TableCell>
                  </>
                )}
                <TableCell className="!font-bold">Update/Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? [1, 2, 3, 4, 5, 6, 7, 8].map((i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell className="!p-3">
                          <Skeleton />
                        </TableCell>
                        <TableCell className="!p-3">
                          <Skeleton />
                        </TableCell>
                        <TableCell className="!p-3">
                          <Skeleton />
                        </TableCell>
                        <TableCell className="!p-3">
                          <Skeleton />
                        </TableCell>
                        <TableCell className="!p-3">
                          <Skeleton />
                        </TableCell>
                        {templateType === "Fixed" && (
                          <>
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                          </>
                        )}

                        <TableCell className="!p-3">
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    )
                  })
                : templates?.data?.data?.map((item) => {
                    return (
                      <TableRow key={item.id} className="cursor-pointer hover:bg-opacity-40 hover:bg-white">
                        <TableCell className="!font-bold !p-1">{item?.name}</TableCell>

                        <TableCell className="!font-bold !p-1">
                          <span
                            className="flex p-2 overflow-x-hidden border border-white border-opacity-25 rounded w-60 whitespace-nowrap text-ellipsis"
                            dangerouslySetInnerHTML={{
                              __html: item?.body,
                            }}
                          ></span>
                        </TableCell>
                        <TableCell className="!font-bold !p-1 !capitalize">{item.template_type}</TableCell>
                        <TableCell className="!font-bold !p-1 !capitalize">{item.message_object_type}</TableCell>
                        <TableCell className="!font-bold !p-1 !capitalize">
                          <APIIntgeration isDisbaled={!item?.is_default_template} template={item} />
                        </TableCell>
                        {templateType === "Fixed" && (
                          <>
                            <TableCell className="!text-center !p-2">
                              <Chip
                                variant="outlined"
                                label={item?.status}
                                color={
                                  item?.status === "APPROVED"
                                    ? "success"
                                    : item?.status === "REJECTED"
                                    ? "error"
                                    : item?.status === "NEW"
                                    ? "info"
                                    : "warning"
                                }
                              />
                            </TableCell>

                            <TableCell>
                              <Button
                                variant="contained"
                                color={
                                  item?.status === "APPROVED"
                                    ? "success"
                                    : item?.status === "REJECTED"
                                    ? "error"
                                    : item?.status === "NEW"
                                    ? "info"
                                    : "warning"
                                }
                                className="!capitalize !rounded-full !w-24"
                                size="small"
                                disabled={item?.status === "PENDING" || item?.is_default_template}
                                onClick={() => {
                                  item?.status === "APPROVED"
                                    ? setIsAbout(true)
                                    : item?.status === "REJECTED"
                                    ? updateTemplateApproval({
                                        template_id: String(item?.id),
                                      })
                                    : item?.status === "NEW" &&
                                      addTemplateApproval({
                                        template_id: String(item?.id),
                                      })
                                  setRowId(item?.id)
                                }}
                              >
                                {item?.status === "APPROVED"
                                  ? "Update"
                                  : item?.status === "REJECTED"
                                  ? "Retry"
                                  : item?.status === "NEW"
                                  ? "Request"
                                  : item?.status}
                              </Button>
                            </TableCell>
                          </>
                        )}
                        <TableCell className="!font-bold !p-1">
                          <span className="flex gap-2">
                            <IconButton
                              className="group"
                              disabled={item?.is_default_template}
                              onClick={() => setResponse(item)}
                            >
                              <Create className="group-hover:!text-blue-500" />
                            </IconButton>

                            <IconButton
                              disabled={item?.is_default_template || item?.status === "APPROVED"}
                              className="group"
                              onClick={() =>
                                item?.template_type === "Fixed"
                                  ? deleteTemplateApproval({
                                      template_id: item?.id,
                                    })
                                  : deleteTemplates({ template_id: item?.id })
                              }
                            >
                              <Delete className="group-hover:!text-red-500" />
                            </IconButton>
                          </span>
                        </TableCell>
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <span className="flex justify-end p-2">
          {templates?.data?.total_pages > 1 && (
            <Pagination
              color="primary"
              variant="outlined"
              count={templates?.data?.total_pages || 0}
              page={templates?.data?.current_page || 0}
              onChange={handleChange}
            />
          )}
        </span>
      </div>
      <Dialog open={isAbout} onClose={() => setIsAbout(false)}>
        <span className="flex items-center justify-between pl-3">
          <p className="text-xs">Comment edits</p>
          <IconButton size="small" onClick={() => setIsAbout(false)}>
            <Close />
          </IconButton>
        </span>
        <Divider />
        <div className="flex flex-col gap-2 p-3">
          <div className="flex gap-2 h-12 px-2 items-center !border-l-4 rounded !border-blue-500 !shadow !text-black !bg-white">
            <InfoOutlined className="text-blue-500" /> You can only edit a template once in 24 hours.
          </div>
          <p className="p-1">Are you sure that you want to submit the content for English (US)?</p>
          <p className="p-1">
            If these edits are rejected, you can edit the template to match a previously approved version. This will
            allow you to continue sending the template.
          </p>
        </div>
        <span className="flex justify-end gap-2 p-2">
          <Button disableElevation variant="contained" size="small" color="inherit" className="!capitalize">
            Cancel
          </Button>
          <Button
            disableElevation
            variant="contained"
            size="small"
            className="!capitalize !bg-blue-500"
            disabled={isLoadingUpdateStatus}
            onClick={() =>
              updateTemplateApproval({
                template_id: rowId,
              })
            }
          >
            Confirm
          </Button>
        </span>
      </Dialog>
    </>
  )
}

export default ERPTemplateSettings
