import { API_URLS } from "../../config/apiUrls"
import axiosInstance from "config/axios"

export const newsAnnoucementsFn = (redBody) => {
  try {
    const response = axiosInstance.get(API_URLS.newsAnnoucements, { params: redBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const addNewsAnnoucementsFn = (redBody) => {
  try {
    const response = axiosInstance.post(API_URLS.newsAnnoucements, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateNewsAnnoucementsFn = (redBody) => {
  try {
    const response = axiosInstance.put(API_URLS.newsAnnoucements, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const deleteNewsAnnoucementsFn = (redBody) => {
  try {
    const response = axiosInstance.delete(API_URLS.newsAnnoucements, { data: redBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
