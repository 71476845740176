import { DeleteTwoTone, Edit, ReportTwoTone } from "@mui/icons-material"
import {
  Button,
  Checkbox,
  Dialog,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { paymentlistFn } from "Services/Payment/Paymentlist"
import { updatePaymentFn } from "Services/Payment/UpdatePayment"
import axiosInstance from "config/axios"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { CustomIconButton } from "shared/CustomIconButton"
import CustomPagination from "shared/CustomPagination"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import CustomModal from "shared/MkxModal"
import CustomSelect from "shared/MkxSelect"
import TableCell from "shared/MkxTable/MkxTableCell"
import { MkxTableLoader } from "shared/MkxTable/MkxTableLoader"
import NoDataFound from "shared/NoDataFound"
import AddPayment from "./AddPayment"

const PaymentsMode = () => {
  const { store_id } = useParams()
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const [selectedIds, setSelectedIds] = useState([])
  const [isDelete, setIsDelete] = useState(false)
  const [mode, setMode] = useState()
  const client = useQueryClient()

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }
  const { data: paymentlist, isLoading } = useQuery(["paymentlist", page, search, open], () =>
    paymentlistFn({ store_id, page, search })
  )

  const handleSelectRow = (id) => {
    setSelectedIds((prevSelectedRow) =>
      prevSelectedRow.includes(id) ? prevSelectedRow.filter((i) => i !== id) : [...prevSelectedRow, id]
    )
  }
  const handleAllRowSelect = (event) => {
    setSelectedIds(event.target.checked ? paymentlist?.data?.data?.map((i) => i.id) : [])
  }

  const handleDelete = () => {
    axiosInstance
      .delete(`store-payment-method-api-of-bhaaraterp/`, { data: { store_id, payment_mode_ids: selectedIds } })
      .then((response) => {
        if (response.data.response_code === 200) {
          toast.success(response.data.message)
          setIsDelete(false)
          setSelectedIds([])
          client.refetchQueries("paymentlist")
        }
      })
  }
  const { mutate: updatePayment, isLoading: isLoading1 } = useMutation(updatePaymentFn, {
    onSuccess: (res) => {
      toast.success(res.data.message)
      res.data.response_code === 200 && handleClose()
      client.refetchQueries("paymentlist")
    },
  })

  const initialValues = {
    payment_type: paymentlist?.data?.data?.find((i) => i.id === open)?.payment_type || "",
    id: open,
  }
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,

    onSubmit: () => {
      const formData = new FormData()
      formData.append("payment_mode_id", formik.values.id)
      formData.append("payment_type", formik.values.payment_type)
      formData.append("store_id", store_id)
      updatePayment(formData)
    },
  })

  const payment = () =>
    axiosInstance.get(`payment-mode-api-of-superadmin/`, { params: { store_id } }).then((res) => {
      setMode(res.data.data)
    })

  useEffect(() => {
    payment()
  }, [])
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <CustomInput value={search} onChange={(event) => setSearch(event.target.value)} placeholder="Search Payment" />
        <div className="flex items-center gap-4">
          {selectedIds?.length !== 0 && (
            <>
              <CustomButton
                startIcon={<DeleteTwoTone />}
                className="!text-red-400 hover:!text-red-500"
                onClick={() => setIsDelete(true)}
              >
                Delete Selected
              </CustomButton>
              <Dialog
                open={isDelete}
                onClose={() => setIsDelete(false)}
                PaperProps={{ className: "!max-w-[500px] w-96" }}
              >
                <div className="flex flex-col items-center gap-3 p-5">
                  <ReportTwoTone color="error" className="!text-5xl" />
                  <p className="text-xl">Are you sure!</p>
                  <p>You want to delete selected products?</p>
                </div>
                <span className="flex justify-end gap-3 pb-3 pr-3">
                  <Button
                    size="small"
                    variant="outlined"
                    color="inherit"
                    onClick={() => setIsDelete(false)}
                    className="!capitalize"
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    disableElevation
                    color="error"
                    className="!capitalize"
                    variant="contained"
                    onClick={() => handleDelete()}
                  >
                    Delete
                  </Button>
                </span>
              </Dialog>
            </>
          )}

          <AddPayment />
        </div>
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell isHead>
                <Checkbox size="small" className="!p-0" onChange={handleAllRowSelect} />
              </TableCell>
              <TableCell isHead>Payment Type</TableCell>
              <TableCell isHead>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <MkxTableLoader loading={isLoading} row={2} />
            {paymentlist?.data?.data?.map((item) => (
              <TableRow>
                <TableCell>
                  <Checkbox
                    size="small"
                    checked={selectedIds.filter((id) => id === item.id)?.length === 1 ? true : false}
                    className="!p-0"
                    onChange={() => handleSelectRow(item.id)}
                  />
                </TableCell>
                <TableCell>{item.payment_type}</TableCell>
                <TableCell>
                  <CustomIconButton color="success" onClick={() => setOpen(item?.id)}>
                    <Edit />
                  </CustomIconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <CustomPagination data={paymentlist} setPage={setPage} />
        <NoDataFound data={paymentlist} />
      </TableContainer>

      <CustomModal title="Add Payment" open={open} onClose={handleClose} className="!w-1/3 !min-h-fit">
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4 p-4">
          <CustomSelect type="text" id="payment_type" formik={formik} label="Payment Mode">
            {mode?.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </CustomSelect>

          <div className="flex justify-end gap-3 my-2">
            <CustomButton color="inherit" onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton isLoading={isLoading1} type="submit">
              Update
            </CustomButton>
          </div>
        </form>
      </CustomModal>
    </div>
  )
}

export default PaymentsMode
