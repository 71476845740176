export const API_URLS = {
  resellerProfile: "reseller_app/reseller-profile-details/",
  resellerState: "state-list-data/",
  resellerCity: "city-list-data/",
  resellerStoreList: "reseller_app/reseller-store-list-api-for-superadmin/",
  resellerResetPassword: "reseller_app/reset-reseller-password-superadmin-api/",
  updateResellerCommission: "reseller_app/set-reseller-commission-superadmin-api/",
  templateList: "cronjob/whatsapp-default-template-list/",
  deleteButton: "cronjob/whatsapp-template-button-api/",
  deleteSection: "cronjob/whatsapp-message-section-api/",
  deleteSectionRow: "cronjob/whatsapp-message-section-rows-api/",
  emailTemplateList: "email-template-api-of-superadmin/",
  templateType: "email-template-type-api-of-superadmin/",
  erpTemplateList: "cronjob/whatsapp-default-template-list/",
  variableList: "cronjob/whatsapp-customer-input-variable/",
  templateApproval: "cronjob/manage-whatsApp-fixed-template-by-super-admin-api/",
  variables: "cronjob/whatsapp-template-default-variable-api/",
  businessType: "business-category-list-data/",
  addProductCategory: "add-product-category-data/",
  updateProductCategory: "edit-product-category-data/",
  storeList: "store-list-api-for-superadmin/",
  storeSettings: "store-setting-api-of-superadmin/",
  storeDelete: "store-delete-api-of-superadmin/",
  storePassword: "reset-store-password-api-of-superadmin/",
  storeStatusChange: "store-deactivate-or-activate-api-for-superadmin/",
  storeLicense: "store-licsense-api-for-superadmin/",
  storePlans: "view-license-plan-module-api-for-superadmin/",
  newsAnnoucements: "news-announcement-api-of-superadmin/",
  learningZone: "module-youtube-link-api-of-superadmin/",
  resellersList: "reseller_app/reseller-list/",
  storeAdminList: "store-admin-user-list-for-two-factor-verification-api/",
  paymentStatusChange: "super-admin-manually-licence-payment-success_api/",
}
