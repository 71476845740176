import { Circle } from "@mui/icons-material"
import React from "react"

export const Active = () => {
  return (
    <>
      <span className="flex items-center justify-center p-px m-1 text-black rounded-full bg-zinc-300 w-28">
        <Circle color="success" className="!text-xs mr-1" />
        Active
      </span>
    </>
  )
}

export const Inactive = () => {
  return (
    <>
      <span className="flex items-center justify-center p-px m-1 text-black rounded-full bg-zinc-300 w-28">
        <Circle color="error" className="!text-xs mr-1" />
        Inactive
      </span>
    </>
  )
}

export const Unmaped = () => {
  return (
    <>
      <span className="flex items-center justify-center p-px m-1 text-black rounded-full bg-zinc-300 w-28">
        <Circle color="warning" className="!text-xs mr-1" />
        Unmaped
      </span>
    </>
  )
}
