import { DeleteTwoTone, HighlightOff } from "@mui/icons-material"
import { useEffect } from "react"
import { toast } from "react-toastify"
import MkxButton from "shared/MkxButton"
import MkxModal from "shared/MkxModal"

/**
 * @typedef {Object} DeleteButtonProps
 * @property {Function} onConfirm - Callback function invoked when delete is confirmed.
 * @property {boolean} isDelete - State indicating whether the delete dialog is open or closed.
 * @property {Function} setIsDelete - Function to set the state of delete dialog.
 * @property {Array} selectedIds - Array of selected item ids.
 */

/**
 * Delete button component.
 * @param {DeleteButtonProps} props - The props of the component.
 * @returns {JSX.Element} - The JSX element representing the delete button component.
 */
const DeleteButton = ({ onConfirm, isDelete, setIsDelete, selectedIds, isPermit }) => {
  /**
   * Function to handle closing of delete dialog.
   */
  const handleClose = () => setIsDelete(false)

  /**
   * Function to handle opening of delete dialog.
   */
  const handleOpen = () => {
    if (!isPermit) {
      toast.warn("You don't have permission for delete.")
    } else {
      setIsDelete(true)
    }
  }

  useEffect(() => {
    selectedIds.length === 0 && handleClose()
  }, [selectedIds])

  return (
    <>
      {selectedIds.length !== 0 && (
        <MkxButton color="error" startIcon={<DeleteTwoTone />} onClick={handleOpen}>
          Delete Selected
        </MkxButton>
      )}
      <MkxModal title="Alert" open={isDelete} onClose={handleClose} padding={2} className="!w-[450px] !min-h-fit">
        <div className="flex flex-col items-center gap-3 pb-5">
          <HighlightOff color="error" className="!text-6xl" />
          <p className="text-xl ">Are you sure!</p>
          <p className="">You want to delete selected Items?</p>
        </div>
        <span className="flex justify-end gap-3 p-3">
          <MkxButton size="small" variant="outlined" color="inherit" onClick={handleClose} className="!capitalize">
            No
          </MkxButton>
          <MkxButton color="error" onClick={onConfirm}>
            Yes
          </MkxButton>
        </span>
      </MkxModal>
    </>
  )
}

export default DeleteButton
