import BorderColorIcon from "@mui/icons-material/BorderColor"
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import LockIcon from "@mui/icons-material/Lock"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import CustomModal from "shared/MkxModal"
import GlassDiv from "shared/CustomDiv"

const Porter = () => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [open1, setOpen1] = React.useState(false)
  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => setOpen1(false)
  const [data, setData] = useState([])
  const [tokens, setTokens] = useState("")
  const [newToken, setNewToken] = useState("")

  const porterDataList = () => {
    axios
      .get(`https://mstore.bhaaraterp.com/delivery_manager/porter/porter-super-admin/?all_store=all_store`, {})
      .then((response) => {
        setData(response?.data?.data)
      })
      .catch((error) => {
        console.log("", error)
      })
  }
  useEffect(() => {
    porterDataList()
  }, [])

  const editData = (event, rowToken) => {
    event.preventDefault()
    handleOpen()
    setTokens(rowToken)
  }

  const changedeliveryStatus = () => {
    axios
      .post(`https://mstore.bhaaraterp.com/delivery_manager/porter/porter-super-admin/`, { token: tokens })
      .then((response) => {
        toast.success(response?.data?.message)
        setTokens("")
        porterDataList()
        handleClose()
      })
      .catch((error) => {
        console.log("", error)
      })
  }

  const addTokenNew = () => {
    axios
      .post(`https://mstore.bhaaraterp.com/delivery_manager/porter/porter-super-admin/`, { token: newToken })
      .then((response) => {
        toast.success(response?.data?.message)
        setTokens("")
        porterDataList()
        handleClose1()
      })
      .catch((error) => {
        console.log("", error)
      })
  }

  return (
    <>
      <GlassDiv className="overflow-y-scroll w-full !p-0 !overflow-auto">
        <div className="flex items-center justify-between p-2 rounded-b-none">
          <p className="text-lg font-semibold">Porter</p>

          <CustomButton onClick={handleOpen1}>Add Token</CustomButton>

          <CustomModal
            open={open1}
            onClose={handleClose1}
            setOpen={setOpen1}
            title="Super Admin Token"
            className="pb-4"
          >
            <div>
              <CustomInput
                label="New Token"
                id="newToken"
                onChange={(event) => setNewToken(event.target.value)}
              ></CustomInput>
            </div>

            <div className="flex justify-between pt-3 ">
              <div></div>
              <CustomButton onClick={addTokenNew}>SAVE</CustomButton>
            </div>
          </CustomModal>
          <CustomModal open={open} onClose={handleClose} setOpen={setOpen} title="Super Admin Token" className="pb-4">
            <div>
              <textarea
                className="border border-gray-400 !bg-white !opacity-20 w-full rounded"
                rows={"4"}
                value={tokens || ""}
                onChange={(event) => setTokens(event.target.value)}
              ></textarea>
            </div>

            <div className="items-center justify-between ">
              <Button className="float-left top-3" variant="contained" color="warning" onClick={handleClose}>
                CANCEL
              </Button>
              <Button
                variant="contained"
                color="success"
                className="float-right mr-3 top-3"
                onClick={changedeliveryStatus}
              >
                SAVE
              </Button>
            </div>
          </CustomModal>
        </div>

        <TableContainer component="div" className="!p-0">
          <Table className="bg-white whitespace-nowrap bg-opacity-20 !overflow-x-scroll">
            <TableHead className="!bg-white !bg-opacity-30">
              <TableRow>
                <TableCell className="border-r !font-bold !py-2 !text-center !border-white !border-opacity-50">
                  STORE NAME
                </TableCell>
                <TableCell className="border-r !font-bold !py-2 !text-center !border-white !border-opacity-50">
                  TOKEN
                </TableCell>
                <TableCell className="border-r !font-bold !py-2 !text-center !border-white !border-opacity-50">
                  CREATE DATE
                </TableCell>
                <TableCell className="border-r !font-bold !py-2 !text-center !border-white !border-opacity-50">
                  UPDATE DATE
                </TableCell>
                <TableCell className="border-r !font-bold !py-2 !text-center !border-white !border-opacity-50">
                  STATUS
                </TableCell>

                <TableCell className="border-r !font-bold !py-2 !text-center !border-white !border-opacity-50">
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[1].map((row) => (
                <TableRow className="!cursor-pointer">
                  <TableCell className="border-r !py-2 !text-center !border-white !border-opacity-50">
                    {row?.store_name ? row?.store_name : "--"}
                  </TableCell>
                  <TableCell className="border-r !py-2 !text-center !border-white !border-opacity-50">
                    {row?.token ? row?.token : "--"}
                  </TableCell>
                  <TableCell className="border-r !py-2 !text-center !border-white !border-opacity-50">
                    {row?.token ? row?.created_at.slice(0, 10) : "--"}
                  </TableCell>
                  <TableCell className="border-r !py-2 !text-center !border-white !border-opacity-50">
                    {row?.token ? row?.updated_at.slice(0, 10) : "--"}
                  </TableCell>
                  <TableCell className="border-r !py-2 !text-center !border-white !border-opacity-50">
                    <input
                      type="checkbox"
                      defaultChecked={row?.is_active}
                      //   onChange={(event) => handleChange(event, row?.user?.email)}
                      className="toggle checked:!border-green-500 !border-red-500 toggle-success"
                    />
                  </TableCell>
                  <TableCell className="border-r !py-2 !text-center !border-white !border-opacity-50">
                    {row?.store_name === "SuperAdmin" ? (
                      <BorderColorIcon className="text-green-500" onClick={(event) => editData(event, row?.token)} />
                    ) : (
                      <LockIcon className="text-yellow-500" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {data.length === 0 && <p className="p-2 text-center">No data found</p>}
        </TableContainer>
      </GlassDiv>
    </>
  )
}

export default Porter
