import { RealtimeSyncFn } from "Services/Sync/Realtimesync"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import CustomDiv from "shared/CustomDiv"
import NoDataFound from "shared/NoDataFound"
import AddRealSync from "./AddRealSync"
import { Divider } from "@mui/material"

const RealSync = () => {
  const { store_id } = useParams()
  const { data } = useQuery(["realtime"], () => RealtimeSyncFn({ store_id }))
  const Realsync = data?.data?.data

  return (
    <CustomDiv className="flex flex-col !p-0">
      <div className="flex justify-end p-2">
        <AddRealSync isUpdate={Realsync?.length !== 0} />
      </div>
      <Divider />
      {Realsync?.map((item) => {
        return (
          <div className="flex gap-5 p-10">
            <div>
              <p className="font-bold">Store </p>
              <p className="mb-5 text-gray-800">{item?.store}</p>
              <p className="mt-5 font-bold">ApiKey </p>
              <p className="mb-5 text-gray-800">{item?.apiKey}</p>
              <p className="mt-5 font-bold">AuthDomain </p>
              <p className="mb-5 text-gray-800">{item?.authDomain}</p>
              <p className="mt-5 font-bold">ProjectId </p>
              <p className="mb-5 text-gray-800">{item?.projectId}</p>
              <p className="mt-5 font-bold">DatabaseURL </p>
              <p className="mb-5 text-gray-800">{item?.databaseURL}</p>
            </div>
            <div>
              <p className="font-bold">StorageBucket </p>
              <p className="mb-5 text-gray-800">{item?.storageBucket}</p>
              <p className="mt-5 font-bold">MessagingSenderId </p>
              <p className="mb-5 text-gray-800">{item?.messagingSenderId}</p>
              <p className="mt-5 font-bold">Is Active </p>
              <p className="mb-5 text-gray-800">{item?.is_active === true ? "True" : "False"}</p>
              <p className="mt-5 font-bold">MeasurementId </p>
              <p className="mb-5 text-gray-800">{item?.measurementId}</p>
              <p className="mt-5 font-bold">AppId </p>
              <p className="mb-5 text-gray-800">{item?.appId}</p>
            </div>
          </div>
        )
      })}
      <NoDataFound data={data} />
    </CustomDiv>
  )
}

export default RealSync
