import { Delete, RemoveRedEye, Settings } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import { IconButton, Skeleton, Switch, TableContainer, TableHead, TableRow } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import { useRef, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import Popup from "reactjs-popup"
import { deleteStoreFn, storeListFn, storeStatusChangeFn } from "../../Services/Business/Store"
import MkxInput from "../../shared/MkxInput"
import MkxPagination from "../../shared/MkxTable/MkxPagination"
import MkxTableCell from "../../shared/MkxTable/MkxTableCell"
import { CustomIconButton } from "shared/CustomIconButton"
import { useNavigate } from "react-router-dom"
import ResetPassword from "./ResetPassword"
import { paymentStatusChangeFn } from "Services/Business/StoreAdmin"

const Store = () => {
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const box1 = useRef()
  const client = useQueryClient()
  const handlePageChange = (_event, value) => {
    setPage(value)
  }
  const naviagte = useNavigate()
  const closeModal = () => {
    box1.current.style.display = "none"
  }

  const {
    data: stores,
    refetch,
    isLoading,
  } = useQuery(["storeList", search, page], () => storeListFn({ page, search: search }))

  const { mutate: storeStatusChange } = useMutation(storeStatusChangeFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
        client.refetchQueries("storeList")
      } else {
        toast.error(res.data.message)
      }
    },
  })
  const { mutate: deleteStore } = useMutation(deleteStoreFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
        client.refetchQueries("storeList")
      } else {
        toast.error(res.data.message)
      }
    },
  })
  const { mutate: paymentStatus } = useMutation(paymentStatusChangeFn, {
    onSuccess: (response) => {
      console.log("res", response)
      if (response?.data?.response_code === 200) {
        toast.success(response?.data?.status)
        refetch()
      } else {
        toast.error(response?.data?.staus)
      }
    },
  })
  return (
    <>
      <div className="!bg-white">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font font-bold !text-black">Business List</p>
            <p className="!text-black">View/Manage Business list</p>
          </div>
        </div>
        <div className="flex flex-col !mt-3 !rounded bg-dark border-secondary shadow-card shadow-zinc-600 m-2 ">
          <div className="flex items-center p-2 h-14 border-zinc-600">
            <MkxInput placeholder="Search Stores..." onChange={(event) => setSearch(event.target.value)} />
          </div>

          <TableContainer>
            <Table size="small">
              <TableHead className="!bg-gray-200">
                <TableRow>
                  <MkxTableCell isHead>STORE NAME</MkxTableCell>
                  <MkxTableCell isHead>EMAIL</MkxTableCell>
                  <MkxTableCell isHead>MOBILE NO.</MkxTableCell>
                  <MkxTableCell isHead>Store Qr Code</MkxTableCell>
                  <MkxTableCell isHead>GSTN</MkxTableCell>
                  <MkxTableCell isHead>WEBSITE</MkxTableCell>
                  <MkxTableCell isHead>REAL-TIME-SETTING</MkxTableCell>
                  <MkxTableCell isHead>RESET PASSWORD</MkxTableCell>
                  <MkxTableCell isHead>Payment Status</MkxTableCell>
                  <MkxTableCell isHead>STATUS</MkxTableCell>
                  <MkxTableCell isHead>ACTION</MkxTableCell>
                </TableRow>
              </TableHead>
              <TableBody className="!overflow-y-auto">
                {isLoading
                  ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => {
                      return (
                        <TableRow>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(() => {
                            return (
                              <MkxTableCell>
                                <Skeleton />
                              </MkxTableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })
                  : stores?.data?.data?.map((store) => (
                      <TableRow key={store?.id}>
                        <MkxTableCell>{store?.store_name ? store?.store_name : "--"}</MkxTableCell>
                        <MkxTableCell>{store?.email ? store?.email : "--"}</MkxTableCell>
                        <MkxTableCell>{store?.store_mobile ? store?.store_mobile : "--"}</MkxTableCell>
                        <MkxTableCell>
                          <span className="flex items-center justify-between">
                            {store?.store_qr_code_unique_no || "--"}
                            <Popup
                              trigger={
                                <IconButton size="small">
                                  <RemoveRedEye className="!text-blue-500" />
                                </IconButton>
                              }
                            >
                              <div
                                className="p-4 text-black rounded-xl"
                                style={{
                                  border: "1px solid gray",
                                  background:
                                    "linear-gradient(180deg, rgba(2, 0, 40, 1) 0%, rgba(1, 0, 75, 1) 49%, rgba(2, 0, 95, 1) 65%, rgba(2, 0, 120, 1) 100%)",
                                }}
                              >
                                <div className="absolute cursor-pointer right-[-9px] top-[-7px] rounded">
                                  <span className="p-1 pb-2 text-white bg-black rounded-2xl" onClick={closeModal}>
                                    <CloseIcon />
                                  </span>
                                </div>
                                <div className="py-2">
                                  <img
                                    alt=""
                                    src={store?.store_qr_code_image}
                                    className="!w-[200px] !h-[200px] rounded"
                                    style={{ backgroundSize: "cover" }}
                                  />
                                </div>
                              </div>
                            </Popup>
                          </span>
                        </MkxTableCell>
                        <MkxTableCell>{store?.gst_number ? store?.gst_number : "--"}</MkxTableCell>
                        <MkxTableCell>{store?.website ? store?.website : "--"}</MkxTableCell>
                        <MkxTableCell>
                          <Popup
                            trigger={
                              <button className="button bg-blue-400 !text-[10px] p-1 text-white rounded">SETUP</button>
                            }
                            modal
                          >
                            <div
                              className="p-4 text-black bg-white rounded-xl h-52 w-80"
                              style={{ border: "1px solid gray" }}
                              ref={box1}
                            >
                              <div className="font-bold text-gray-600">REAL TIME SYNC SETTING</div>
                              <div
                                className="absolute px-3 text-red-600 bg-gray-500 rounded cursor-pointer right-2 top-4"
                                onClick={closeModal}
                              >
                                X
                              </div>
                              <hr />
                              <div className="py-3">
                                <p>
                                  <span className="text-gray-700">Store Id</span> : {"hello"}
                                </p>
                                <p>
                                  <span className="text-gray-700">Store Name</span> : {"hello"}
                                </p>

                                <p>
                                  <span className="text-gray-700">Order Sync Active</span> : {"hello"}
                                </p>
                                <p>
                                  <span className="text-gray-700">Order Sync Active</span> : {"hello"}
                                </p>
                              </div>
                            </div>
                          </Popup>
                        </MkxTableCell>
                        <MkxTableCell>
                          <ResetPassword store_id={store.id} />
                        </MkxTableCell>

                        <MkxTableCell>
                          {" "}
                          <Switch
                            disabled={store.license?.paid}
                            checked={store.license?.paid}
                            onChange={() =>
                              !store.license?.paid &&
                              paymentStatus({ licence_id: store.license?.id, store_id: store?.id })
                            }
                            color="secondary"
                          />
                        </MkxTableCell>
                        <MkxTableCell>
                          <input
                            type="checkbox"
                            checked={store.is_store_active_status}
                            onChange={() => storeStatusChange({ store_id: store.id })}
                            className="toggle checked:!border-green-500 !border-red-500 toggle-success"
                          />
                        </MkxTableCell>
                        <MkxTableCell>
                          <div className="flex items-center justify-center gap-2">
                            <CustomIconButton color="primary" onClick={() => naviagte(`/business-store/${store.id}`)}>
                              <Settings />
                            </CustomIconButton>
                            <CustomIconButton color="error" onClick={() => deleteStore({ store_id: store.id })}>
                              <Delete />
                            </CustomIconButton>
                          </div>
                        </MkxTableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <MkxPagination
            count={stores?.data?.total_pages}
            page={stores?.data?.current_page}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  )
}

export default Store
