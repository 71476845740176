import { Box, Button, Modal } from "@mui/material"
import axios from "config/axios"
import { useFormik } from "formik"
import { useState } from "react"
import { toast } from "react-toastify"
import { CustomButton } from "../../../shared/CustomButton"

const CreateResellers = ({ ResellersListData }) => {
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      const reqBody = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        mobile_number: values.mobile_number,
      }

      axios
        .post(`reseller_app/create-reseller/`, reqBody)
        .then((response) => {
          console.log(response.data)
          ResellersListData()
          toast.success(response.data.message)
          handleClose()
        })
        .catch((error) => {
          toast.error(error.response.data.message)
        })
    },
  })
  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        + Create Resellers
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box className="absolute w-1/3 p-6 text-white -translate-x-1/2 -translate-y-1/2 rounded shadow-md outline-none top-1/2 left-1/2 bg-popup">
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-1 mx-auto text-black">
            <p className="my-5 text-2xl font-semibold text-center text-white underline">Create Resellers</p>
            <div className="grid grid-cols-2 gap-5">
              <div className="flex flex-col">
                <p className="text-left text-white">First Name</p>
                <input
                  autoComplete="off"
                  className="p-2 bg-white border-2 rounded-md focus:outline-none border-zinc-700"
                  name="first_name"
                  id="first_name"
                  value={formik.values["first_name"]}
                  onChange={formik.handleChange}
                  placeholder="Enter First Name"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left text-white">Last Name</p>
                <input
                  autoComplete="off"
                  className="p-2 bg-white border-2 rounded-md focus:outline-none border-zinc-700"
                  name="last_name"
                  id="last_name"
                  value={formik.values["last_name"]}
                  onChange={formik.handleChange}
                  placeholder="Enter Last Name"
                />
              </div>
            </div>

            <p className="text-left text-white">Email</p>
            <input
              autoComplete="off"
              className="p-2 bg-white border-2 rounded-md sm:w-auto focus:outline-none border-zinc-700"
              name="email"
              id="email"
              value={formik.values["email"]}
              onChange={formik.handleChange}
              placeholder="Enter Email"
            />

            <p className="text-left text-white">Contact Number</p>
            <input
              autoComplete="off"
              className="p-2 bg-white border-2 rounded-md sm:w-auto focus:outline-none border-zinc-700"
              name="mobile_number"
              id="mobile_number"
              value={formik.values["mobile_number"]}
              onChange={formik.handleChange}
              placeholder="Enter Contact Number"
            />

            <CustomButton className="!mx-auto px-4 !my-7 !p-2" type="submit">
              Create Resellers
            </CustomButton>
          </form>
        </Box>
      </Modal>
    </>
  )
}

export default CreateResellers
