import { Settings } from "@mui/icons-material"
import { Divider } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import { deliverySettingsFn, postDeliverySettingsFn } from "Services/Settings/DeliverySetting"
import React, { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import CustomModal from "shared/MkxModal"
const DeliveryManagement = () => {
  const { store_id } = useParams()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [deliveryId, setDeliveryId] = useState("")
  const client = useQueryClient()

  const { data: delievrySettings } = useQuery(["delievrySettings"], () => deliverySettingsFn({ store_id }))
  const { mutate: postDelievrySettings } = useMutation(postDeliverySettingsFn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        toast.success(response?.data?.message)
        setOpen(false)
        client.refetchQueries("delievrySettings")
      } else {
        toast.error(response?.data?.message)
      }
    },
  })

  const buttons = [
    {
      name: "Ship Rocket",
      path: `/business-store/${store_id}/shiprocket-config`,
    },
    {
      name: "Porter",
      path: `/business-store/${store_id}/porter-config`,
    },
    {
      name: "Bhaarat Delivery",
      path: `/business-store/${store_id}/bhaarat-delivery-config`,
    },
  ]

  useEffect(() => {
    delievrySettings && setDeliveryId(delievrySettings?.data?.data?.find((i) => i.active_status)?.name)
  }, [delievrySettings])

  return (
    <>
      <div className="flex flex-col">
        <div className="pb-3">
          <CustomButton startIcon={<Settings />} variant="text" onClick={handleOpen}>
            Delivery Prefrence
          </CustomButton>

          <CustomModal
            open={open}
            onClose={handleClose}
            setOpen={setOpen}
            title="Delivery Prefrence"
            className="w-1/3 !min-h-fit"
          >
            <div className="relative flex flex-col justify-between h-full">
              <FormControl className="!p-2">
                <RadioGroup
                  value={delievrySettings?.data?.data?.find((i) => i.active_status)?.name || ""}
                  onChange={(event) => setDeliveryId(event.target.value)}
                >
                  {delievrySettings?.data?.data?.map((delivery) => (
                    <FormControlLabel value={delivery.name} control={<Radio />} label={delivery.name} />
                  ))}
                </RadioGroup>
              </FormControl>
              <Divider />
              <div className="flex items-center justify-end p-2">
                <CustomButton onClick={() => postDelievrySettings({ store_id, delivery_partner: deliveryId })}>
                  Save Changes
                </CustomButton>
              </div>
            </div>
          </CustomModal>
        </div>

        <div className="flex gap-5">
          {buttons.map((button) => {
            return (
              <CustomButton
                startIcon={<Settings className="!h-10 !w-10" />}
                onClick={() => navigate(button.path)}
                className="!text-2xl !w-80 !h-32"
              >
                {button.name}
              </CustomButton>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default DeliveryManagement
