import { isFunction } from "lodash"
import { useRef } from "react"
import EmailEditor from "react-email-editor"

const useEmailTemplate = ({ onComplete = () => {}, setContent, design, className }) => {
  const emailEditorRef = useRef("")

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      setContent(data)
      if (isFunction(onComplete)) {
        onComplete(data)
      }
    })
  }

  const onReady = (unlayer) => {
    if (design) {
      try {
        unlayer.loadDesign(JSON.parse(design))
      } catch (error) {
        console.error("Error parsing design JSON:", error)
      }
    }
  }

  const EmailTemplate = () => {
    return <EmailEditor className={className} ref={emailEditorRef} onReady={onReady} />
  }

  return { EmailTemplate, onReady, exportHtml }
}

export default useEmailTemplate
