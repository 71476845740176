import BorderColorIcon from "@mui/icons-material/BorderColor"
import { Divider, Skeleton, TablePagination, TextField } from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { default as axiosInstance } from "config/axios"
import { useEffect, useState } from "react"
import { Active, Inactive, Unmaped } from "../../shared/ActivaInactive"
import { CustomIconButton } from "../../shared/CustomIconButton"
import AddBussinessCategory from "./AddBusinessCategory"

const BusinessCategory = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [data, setData] = useState()
  const [searchQuery, setSearchQuery] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const BuisenesCategoryList = async () => {
    setIsLoading(true)
    try {
      let response
      if (searchQuery === "") {
        response = await axiosInstance.post(`business-category-list-data/`, {})
      } else {
        response = await axiosInstance.get(`business-category-search-api-for-super-admin/?search_value=${searchQuery}`)
      }
      setData(response?.data.business_category_list_data || response?.data.data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    BuisenesCategoryList()
  }, [searchQuery])

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="p-3">
          <p className="text-xl font-bold text-gray-800">Business Type List</p>
          <p>Manage Business Type List</p>
        </div>
        <AddBussinessCategory />
      </div>

      <div className="flex flex-col !rounded bg-dark shadow-card shadow-zinc-600 m-2">
        <TableContainer component={Paper}>
          <div className="flex items-center p-2">
            <TextField
              placeholder="Search Business Type..."
              size="small"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
          </div>
          <Divider />
          <Table size="small">
            <TableHead className="!bg-gray-200">
              <TableRow>
                <TableCell className="!text-center border-r border-white !font-bold">S/NO</TableCell>
                <TableCell className="!text-start border-r border-white !font-bold">BUSINESS SUB TYPE</TableCell>
                <TableCell className="!text-center border-r border-white !font-bold">STATUS</TableCell>
                <TableCell className="!text-center border-r border-white !font-bold">ACTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map(() => {
                    return (
                      <TableRow>
                        {[1, 2, 3, 4]?.map(() => {
                          return (
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })
                : data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((cat, index) => {
                    return (
                      <TableRow>
                        <TableCell className="!text-center border-r">{index + 1}</TableCell>
                        <TableCell className="!text-start border-r">{cat.name}</TableCell>

                        <TableCell className="!text-center border-r">
                          <div className="flex justify-center">
                            {cat.map_status === null ? (
                              <Unmaped className="items-center" />
                            ) : cat.map_status === 1 ? (
                              <Active className="items-center" />
                            ) : (
                              <Inactive className="items-center" />
                            )}
                          </div>
                        </TableCell>
                        <TableCell className="!text-center border-r">
                          <div className="items-center justify-center mx-auto text-center ">
                            <CustomIconButton>
                              <BorderColorIcon className="!text-center text-green-500" />
                            </CustomIconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>
          {data && data?.length !== 0 ? (
            <TablePagination
              rowsPerPageOptions={[]}
              count={data?.length || 0}
              component="div"
              rowsPerPage={rowsPerPage || 0}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </TableContainer>
      </div>
    </>
  )
}

export default BusinessCategory
