import { Edit } from "@mui/icons-material"
import { Switch, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { changeMapConfigsFn, googleMapConfigsFn } from "Services/Settings/GoogleMapConfig"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import { CustomLoader } from "shared/CustomLoader"
import CustomPagination from "shared/MkxTable/MkxPagination"
import GlassDiv from "shared/CustomDiv"
import NoDataFound from "shared/NoDataFound"
import TableCell from "shared/MkxTable/MkxTableCell"
import moment from "moment"
import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import AddGoogleMapConfig from "./AddGoogleMapConfig"
import ViewConfig from "./ViewConfig"

const GoogleMapConfig = () => {
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const [configId, setConfigId] = useState(null)
  const [config, setConfig] = useState(null)
  const client = useQueryClient()

  const { data: googleMapConfigs, isLoading } = useQuery(
    ["googleMapConfigs", search, page],
    () => googleMapConfigsFn({ page, search }),
    { refetchOnWindowFocus: false }
  )

  const handleResponse = (res) => {
    if (res.data.response_code === 200) {
      toast.success(res.data.message)
      client.refetchQueries("notifictaions")
    } else {
      toast.error(res.data.message)
    }
  }

  const googleMapConfig = googleMapConfigs?.data?.data

  const { mutate: changeMapConfig } = useMutation(changeMapConfigsFn, {
    onSuccess: (res) => {
      handleResponse(res)
    },
  })

  return (
    <GlassDiv className="flex flex-col !p-0">
      <div className="flex items-center justify-between p-3">
        <CustomInput
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Search Configs..."
        />
        <div className="flex items-center gap-4">
          <AddGoogleMapConfig config={configId} setConfig={setConfigId} />
        </div>
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow className="!bg-white !bg-opacity-40 cursor-pointer">
              <TableCell isHead>S No.</TableCell>
              <TableCell isHead>Create At</TableCell>
              <TableCell isHead>Update At</TableCell>
              <TableCell isHead>Config Detail</TableCell>
              <TableCell isHead>Status</TableCell>
              <TableCell isHead>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <CustomLoader loading={isLoading} row={5} />
            {[]?.map((item, index) => (
              <TableRow className="hover:!bg-white hover:!bg-opacity-40 cursor-pointer">
                <TableCell>{index + 1}</TableCell>
                <TableCell>{moment(item.create_at).format("lll")}</TableCell>
                <TableCell>{moment(item.update_at).format("lll")}</TableCell>
                <TableCell>
                  <CustomButton onClick={() => setConfig(item.id)}>View</CustomButton>
                </TableCell>
                <TableCell>
                  <Switch checked={item.active_status} onChange={() => changeMapConfig({ firebase_id: item.id })} />
                </TableCell>
                <TableCell className="!border-r !py-0 !text-center !border-white !border-opacity-30">
                  <Edit className="!text-green-500" onClick={() => setConfigId(item.id)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <CustomPagination data={googleMapConfigs} setPage={setPage} />
        <NoDataFound data={googleMapConfigs} />
      </TableContainer>
      <ViewConfig config={config} setConfig={setConfig} />
    </GlassDiv>
  )
}

export default GoogleMapConfig
