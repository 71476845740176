import { API_URLS } from "config/apiUrls"
import axios from "config/axios"

export const addgstFn = (redBody) => {
  try {
    const response = axios.post(API_URLS.setting.addgst, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
