import { Close, ContentCopy } from "@mui/icons-material"
import { Button, CircularProgress, Divider, IconButton } from "@mui/material"
import {
  createAllCatgeoryFn,
  createProductFn,
  deleteAllCatgeoryFn,
  deleteAllProductFn,
  getWoocommerceSyncFn,
  syncAllOrdersFn,
  updateProductFn,
  updateWoocommerceSyncFn,
} from "Services/Settings/AllSettings/Sync/Wocommerce"
import { useFormik } from "formik"
import CopyToClipboard from "react-copy-to-clipboard"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import GlassDiv from "shared/CustomDiv"
import Loader from "shared/CustomLoader"
import CustomInput from "shared/MkxInput"

const Woocommerce = () => {
  const { store_id } = useParams()
  const client = useQueryClient()
  const { data: wocommerceSync, isLoading } = useQuery(["wocommerceSync"], () => getWoocommerceSyncFn({ store_id }))
  const navigate = useNavigate()
  const woocommerce = wocommerceSync?.data?.data?.[0]

  const { mutate: updateWoocommerceSync } = useMutation(updateWoocommerceSyncFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
        client.refetchQueries("wocommerceSync")
      } else {
        toast.error(res.data.message)
      }
    },
  })
  const { mutate: createProduct, isLoading: isLoadingCreate } = useMutation(createProductFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 201) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
    },
  })

  const { mutate: updateProduct, isLoading: isLoadingUpdate } = useMutation(updateProductFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
    },
  })
  const { mutate: syncOrders, isLoading: isLoadingSyncOrders } = useMutation(syncAllOrdersFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
    },
  })
  const { mutate: deleteProduct, isLoading: isLoadingDelete } = useMutation(deleteAllProductFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
    },
  })
  const { mutate: createAllCatgeory, isLoading: isLoadingCreateCategory } = useMutation(createAllCatgeoryFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 201) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
    },
  })
  const { mutate: deleteAllCatgeory, isLoading: isLoadingDeleteCategory } = useMutation(deleteAllCatgeoryFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
      } else {
        toast.error(res.data.message)
      }
    },
  })
  const initialValues = {
    woocommerce_setup_id: woocommerce?.id || "",
    consumer_key: woocommerce?.consumer_key || "",
    consumer_secret: woocommerce?.consumer_secret || "",
    webhooks_for_customer_created_api_url: woocommerce?.webhooks_for_customer_created_api_url || "",
    webhooks_for_order_created_api_url: woocommerce?.webhooks_for_order_created_api_url || "",
    webhooks_for_order_updated_api_url: woocommerce?.webhooks_for_order_updated_api_url || "",
    woocommerce_api_url: woocommerce?.woocommerce_api_url || "",
    is_active: woocommerce?.is_active || false,
    store_id,
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateWoocommerceSync(values)
    },
  })

  return (
    <GlassDiv className="flex flex-col h-full !p-0">
      <div className="flex items-center justify-between p-2">
        <span className="flex items-center gap-3">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" className="w-10 h-10">
            <path
              fill="#7f54b3"
              d="M116.3 89.1H11.7C5.2 89.1 0 83.9 0 77.4v-40C0 31 5.2 25.8 11.7 25.8h104.7c6.4 0 11.7 5.2 11.7 11.7v40c-.1 6.4-5.3 11.6-11.8 11.6z"
            />
            <path
              fill="#FFF"
              d="M13.8 76.7s2.8 11.8 8.5 3.9 11.2-20.3 11.2-20.3.4-3.1 2 3.7S44 80 44 80s6.3 7.9 8.9-.4c-1-11 2.8-31 6.7-40.6 1.6-8.5-7.3-6.1-8.1-4.1s-6.3 14.8-6.7 28.2c0 0-4.7-12.8-5.1-17.4-.4-4.7-5.3-5.9-8.1-1.4S20.3 66.2 20.3 66.2l-5.5-28.4s-5.5-7.3-8.7 1.6c0 0 5.7 34.9 7.7 37.3zM87 45.7c-8.5-14.2-21.1-3.4-21.1-3.4s-9.6 11.1-5.3 26.2c6.9 14.9 16.6 8.3 19.2 7.1 2.7-1.3 14.1-14.3 7.2-29.9zm-6.5 12.5c0 5.9-4.9 11.4-8.9 10.2-2.2-1.3-3.6-4.8-3.6-10.8 2-9.7 6.4-11 8.7-10.8 4.3 2.3 4.1 7.4 3.8 11.4zM118.9 45.7c-8.5-14.2-21.1-3.4-21.1-3.4s-9.6 11.1-5.3 26.2c6.9 14.9 16.6 8.3 19.2 7.1 2.6-1.3 14.1-14.3 7.2-29.9zm-6.5 12.5c0 5.9-4.9 11.4-8.9 10.2-2.2-1.3-3.6-4.8-3.6-10.8 2-9.7 6.4-11 8.7-10.8 4.2 2.3 4 7.4 3.8 11.4z"
            />
            <path fill="#7f54b3" d="M61.3 89.1l22.3 13.1-4.7-13.1-12.8-3.6z" />
          </svg>
          <p className="text-lg font-semibold">WooCommerce</p>
        </span>
        <IconButton onClick={() => navigate("/settings/sync-api", { state: "Sync API" })}>
          <Close />
        </IconButton>
      </div>
      <Divider />
      {isLoading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col overflow-y-auto">
          <div className="flex flex-col p-5">
            <div className="flex justify-between">
              <p className="text-blue-800 underline">
                Read The Instruction Carefully To Create Consumer Key & Consumer Secret
              </p>

              <label class="relative inline-flex items-center cursor-pointer">
                <input
                  name="is_active"
                  type="checkbox"
                  className="sr-only peer"
                  checked={formik.values.is_active}
                  onChange={formik.handleChange}
                />
                <div class="group peer ring-0 bg-rose-700 rounded-full outline-none duration-300 after:duration-300 w-[88px] h-10 shadow-md peer-checked:bg-emerald-500 peer-focus:outline-none after:content-['✖️'] after:rounded-full after:absolute after:bg-gray-50 after:outline-none after:h-8 after:w-8 after:top-1 after:left-1 after:flex after:justify-center after:items-center peer-checked:after:translate-x-12 peer-checked:after:content-['✔️'] peer-hover:after:scale-95"></div>
              </label>
            </div>

            <span className="flex flex-col pt-3">
              <p>
                1. Log into your WooCommerce store and visit the Dashboard. Go to <strong>Setting</strong>
                {`-->`} <strong>Permalinks</strong> & Choose {`-->`} Post Name
              </p>
              <p>
                2. <strong>WooCommerce</strong> {`-->`} Setting {`-->`} <strong>API</strong> tab or an{" "}
                <strong>Advanced</strong> tab
              </p>
              <span className="px-5">
                <p>
                  A. First Click to <strong>Legacy API</strong> then Check the Enable the legacy REST API & Save Changes
                </p>
              </span>
              <span className="px-5">
                <p>
                  B. If you see the "API" tab, click it and ensure the 'REST API' checkbox is enabled from the
                  'Settings' sub-tab. Then, click 'Keys/Apps'
                </p>
              </span>
              <span className="px-5">
                <p>
                  C. If you see the "Advanced" tab, click it and then visit the "REST API" sub-tab to create an API Key.
                  Follow along below for specific permission instructions
                </p>
              </span>
              <p>
                3. Create a new key with <strong>Read/Write</strong> Permissions
              </p>
              <p>4. And save your Consumer key and Consumer secret into safe place</p>
              <p>
                5. Then After Paste your <strong>Consumer Key</strong> and <strong>Consumer Secret</strong> here
              </p>
              <p>6. WooCommerce Base URL is your Website url with {formik.values.woocommerce_api_url}</p>
            </span>
          </div>

          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
            <div className="grid grid-cols-3 gap-3 p-3">
              <CustomInput
                id="consumer_key"
                isRequired
                label="Consumer Key"
                placeholder="Enter Consumer Key"
                formik={formik}
              />
              <CustomInput
                id="consumer_secret"
                isRequired
                label="Consumer Secret"
                placeholder="Enter Consumer Secret"
                formik={formik}
              />
              <CustomInput
                id="woocommerce_api_url"
                isRequired
                label="WooCommerce Base URL"
                placeholder="Enter WooCommerce Base URL"
                formik={formik}
              />
            </div>
            <div className="grid grid-cols-3 gap-3 p-3">
              <CustomInput
                id="webhooks_for_order_created_api_url"
                label="Webhooks for Order Created"
                placeholder="Enter Webhooks for Order Created"
                formik={formik}
                className="!pr-0"
                InputProps={{
                  endAdornment: (
                    <CopyToClipboard text={formik.values.webhooks_for_order_created_api_url}>
                      <IconButton size="small">
                        <ContentCopy color="primary" />
                      </IconButton>
                    </CopyToClipboard>
                  ),
                }}
              />
              <CustomInput
                id="webhooks_for_order_updated_api_url"
                label="Webhooks for Order Updated"
                placeholder="Enter Webhooks for Order Updated"
                formik={formik}
                InputProps={{
                  endAdornment: (
                    <CopyToClipboard text={formik.values.webhooks_for_order_updated_api_url}>
                      <IconButton size="small">
                        <ContentCopy color="primary" />{" "}
                      </IconButton>
                    </CopyToClipboard>
                  ),
                }}
              />
              <CustomInput
                id="webhooks_for_customer_created_api_url"
                label="Webhooks for Customer Created"
                placeholder="Enter Webhooks for Customer Created"
                formik={formik}
                InputProps={{
                  endAdornment: (
                    <CopyToClipboard text={formik.values.webhooks_for_customer_created_api_url}>
                      <IconButton size="small">
                        <ContentCopy color="primary" />{" "}
                      </IconButton>
                    </CopyToClipboard>
                  ),
                }}
              />
            </div>
            <div className="flex justify-end w-full px-4 pb-4">
              <Button disableElevation size="small" variant="contained" type="submit" className="!w-96">
                Save Changes
              </Button>
            </div>
          </form>
          <Divider />
          <div className="grid grid-cols-6 gap-3 p-4">
            <Button
              color="primary"
              disabled={isLoadingCreate}
              disableElevation
              size="small"
              variant="contained"
              onClick={() => createProduct({ store_id })}
            >
              {isLoadingCreate ? (
                <span className="flex items-center gap-2">
                  <p>Creating</p>
                  <CircularProgress size={16} thickness={5} color="secondary" />
                </span>
              ) : (
                "Create All Products"
              )}
            </Button>
            <Button
              color="success"
              disableElevation
              size="small"
              disabled={isLoadingUpdate}
              variant="contained"
              onClick={() => updateProduct({ store_id })}
            >
              {isLoadingUpdate ? (
                <span className="flex items-center gap-2">
                  <p>Updating</p>
                  <CircularProgress size={16} thickness={5} color="secondary" />
                </span>
              ) : (
                "Update All Products"
              )}
            </Button>
            <Button
              color="error"
              disableElevation
              disabled={isLoadingDelete}
              size="small"
              variant="contained"
              onClick={() => deleteProduct({ store_id })}
            >
              {isLoadingDelete ? (
                <span className="flex items-center gap-2">
                  <p>Deleting</p>
                  <CircularProgress size={16} thickness={5} color="secondary" />
                </span>
              ) : (
                "Delete All Products"
              )}
            </Button>
            <Button
              color="warning"
              disableElevation
              size="small"
              disabled={isLoadingSyncOrders}
              variant="contained"
              onClick={() => syncOrders({ store_id })}
            >
              {isLoadingSyncOrders ? (
                <span className="flex items-center gap-2">
                  <p>Syncing Orders</p>
                  <CircularProgress size={16} thickness={5} color="secondary" />
                </span>
              ) : (
                "Sync All Orders"
              )}
            </Button>
            <Button
              color="primary"
              disableElevation
              size="small"
              variant="contained"
              onClick={() => createAllCatgeory({ store_id })}
            >
              {isLoadingCreateCategory ? (
                <span className="flex items-center gap-2">
                  <p>Syncing Category</p>
                  <CircularProgress size={16} thickness={5} color="secondary" />
                </span>
              ) : (
                "Sync All Category"
              )}
            </Button>
            <Button
              color="error"
              disableElevation
              size="small"
              variant="contained"
              onClick={() => deleteAllCatgeory({ store_id })}
            >
              {isLoadingDeleteCategory ? (
                <span className="flex items-center gap-2">
                  <p>Deleting Category</p>
                  <CircularProgress size={16} thickness={5} color="secondary" />
                </span>
              ) : (
                "Delete All Category"
              )}
            </Button>
          </div>
        </div>
      )}
    </GlassDiv>
  )
}

export default Woocommerce
