import {
  AccountBalanceWallet,
  CallOutlined,
  CheckCircle,
  EmailRounded,
  LocalShipping,
  Map,
  Notifications,
  Percent,
  Redeem,
  RestaurantRounded,
  Settings,
  SmsOutlined,
  SyncOutlined,
  WhatsApp,
} from "@mui/icons-material"
import DeliveryManagement from "components/Store/StoreSetting/DeliveryManagement"
import DepartmentRole from "components/Store/StoreSetting/DepartmentRole"
import EmailSetting from "components/Store/StoreSetting/EmailSetting"
import GeneralSetting from "components/Store/StoreSetting/GeneralSettings"
import GoogleMapConfig from "components/Store/StoreSetting/GoogleMapConfig"
import GstSettings from "components/Store/StoreSetting/GstSettings"
import HiringSettings from "components/Store/StoreSetting/HiringSettings"
import InventorySettings from "components/Store/StoreSetting/InventorySetting"
import LoyalityPoint from "components/Store/StoreSetting/LoyalityPoint"
import OfferSettings from "components/Store/StoreSetting/OfferSettings"
import Operations from "components/Store/StoreSetting/Operations"
import PaymentGateway from "components/Store/StoreSetting/PaymentGateway"
import PaymentsMode from "components/Store/StoreSetting/PaymentsMode"
import PushNotification from "components/Store/StoreSetting/PushNotification"
import Restaurant from "components/Store/StoreSetting/Restaurant"
import SmsSettings from "components/Store/StoreSetting/SmsSettings"
import StorePlan from "components/Store/StoreSetting/StorePlan"
import Sync from "components/Store/StoreSetting/Sync"
import UnitOfMeasurement from "components/Store/StoreSetting/UnitOfMeasurementSettings"
import UserApproval from "components/Store/StoreSetting/UserApproval"
import Whatsapp from "components/Store/StoreSetting/Whatsapp"
import Diversity3Icon from "@mui/icons-material/Diversity3"
import HRMSettings from "components/Store/StoreSetting/HRMSettings"

export const permissionData = [
  { name: "sales_update", id: "sales_update", item: "Sales Update" },
  { name: "erp_update", id: "erp_update", item: "Erp Update" },
  { name: "hrm_update", id: "hrm_update", item: "Hrm Update" },
  { name: "api_update", id: "api_update", item: "API Update" },
  { name: "purchase_update", id: "purchase_update", item: "Purchase Update" },
  { name: "leads_update", id: "leads_update", item: "Leads Update" },
  { name: "ledger_update", id: "ledger_update", item: "Ledger Update" },
  { name: "report_update", id: "report_update", item: "Report Update" },
]

export const menuItems = [
  { path: "/dashboard", label: "Dashboard" },
  {
    path: "/business-data",
    label: "Business Data",
    stateKey: "open3",
    subItems: [
      { path: "/business-category", label: "Business Type" },
      { path: "/business-category/business-sub-category", label: "Business Sub Type" },
      { path: "/manage-product", label: "Category" },
      { path: "/product-sub-category", label: "Sub Category" },
      { path: "/product-brand", label: "Brand" },
      {
        path: "/variant-mangement",
        label: "Variant Management",
        stateKey: "open11",
        subItems: [
          { path: "/variant-mangement-color", label: "Manage Color Variant (Type One)" },
          { path: "/variant-mangement-storage", label: "Manage Storage Variant (Type Two)" },
          { path: "/variant-management-other", label: "Manage Other Variant (Type Three)" },
        ],
      },
    ],
  },
  { path: "/invertory/request", label: "Inventory Approval" },
  { path: "/email-templates", label: "Email Templates" },
  {
    path: "/manage-store",
    label: "Manage Store Data",
    stateKey: "open1",
    subItems: [
      { path: "/store", label: "Store" },
      { path: "/store-admins", label: "Store Admin" },
      { path: "/store-license", label: "Store License" },
      { path: "/store-wallet-name", label: "Store Wallet" },
      { path: "/offer-management", label: "Offer Management" },
    ],
  },
  {
    path: "/resellers",
    label: "Resellers Management",
    stateKey: "open7",
    subItems: [
      { path: "/resellers/resellers-management", label: "Resellers Management" },
      { path: "/resellers/resellers-sales", label: "Resellers Sales" },
      {
        path: "/resellers/plans",
        label: "Plans Management",
        stateKey: "open12",
        subItems: [
          { path: "/resellers/plans/plan-type", label: "Plan Type" },
          { path: "/resellers/plans/module", label: "Module" },
          { path: "/resellers/plans/plan", label: "Plan" },
        ],
      },
    ],
  },
  {
    path: "/role-management",
    label: "Role Management",
    stateKey: "open10",
    subItems: [
      { path: "/role-management", label: "Role" },
      { path: "/role/module", label: "Module" },
    ],
  },
  { path: "/order-management", label: "Orders Management" },
  { path: "/news-management", label: "News Management" },
  { path: "/learning-zone", label: "Learning Zone" },
  { path: "/notification", label: "Notification" },
  { path: "/delivery-management", label: "Delivery Management" },
  {
    path: "/manage-demography",
    label: "Manage Demography",
    stateKey: "open4",
    subItems: [
      { path: "/manage-demography/country", label: "Country List" },
      { path: "/manage-demography/state", label: "State List" },
      { path: "/manage-demography/city", label: "City List" },
    ],
  },
  { path: "/backup-restore", label: "Backup & Restore" },
  { path: "/contact", label: "Contact" },
  { path: "/help-suport", label: "Help And Support" },
  { path: "/whatsapp", label: "Bhaarat ERP WhatsApp Setting" },
  {
    path: "/whatsapp-settings",
    label: "Default Template Settings",
    stateKey: "open9",
    subItems: [{ path: "/settings/whatsapp/variables", label: "Variable Management" }],
  },
]

export const settings = [
  {
    category: "Inventory Settings",
    settings: [
      { name: "variant_status", label: "Variant Configuration" },
      { name: "spec_status", label: "Specification Upload" },
      { name: "sync_status", label: "Website Data Sync" },
      { name: "barcode_export_status", label: "Bar Export Status" },
      { name: "bulk_product_status", label: "Bulk Product Upload" },
      { name: "asset_management_status", label: "Asset Management" },
      { name: "rack_setting_status", label: "Rack Status" },
      { name: "measuring_unit_setting_status", label: "Measuring Unit" },
      { name: "multiple_warehouse_status", label: "Multi Warehouse Status" },
      { name: "alternate_barcode_status", label: "Alternate Barcode Status" },
      { name: "manage_product_variant", label: "Product With Variant Based" },
    ],
  },
  {
    category: "Vendor Settings",
    settings: [
      { name: "vendor_mail_status", label: "Mail" },
      { name: "vendor_sms_status", label: "SMS" },
      { name: "vendor_whatsapp_status", label: "Whatsapp" },
    ],
  },
  {
    category: "Customer Settings",
    settings: [
      { name: "customer_mail_status", label: "Mail" },
      { name: "customer_sms_status", label: "SMS" },
      { name: "customer_whatsapp_status", label: "Whatsapp" },
    ],
  },
  {
    category: "Employee Settings",
    settings: [
      { name: "employee_mail_status", label: "Mail" },
      { name: "employee_sms_status", label: "SMS" },
      { name: "employee_whatsapp_status", label: "Whatsapp" },
    ],
  },
  {
    category: "Candidate Settings",
    settings: [
      { name: "candidate_mail_status", label: "Mail" },
      { name: "candidate_sms_status", label: "SMS" },
      { name: "candidate_whatsapp_status", label: "Whatsapp" },
    ],
  },
  {
    category: "Estimates Settings",
    settings: [
      { name: "estimate_mail_status", label: "Mail" },
      { name: "estimate_sms_status", label: "SMS" },
      { name: "estimate_whatsapp_status", label: "Whatsapp" },
    ],
  },
  {
    category: "Purchase Settings",
    settings: [
      { name: "purchase_mail_status", label: "Mail" },
      { name: "purchase_sms_status", label: "SMS" },
      { name: "purchase_whatsapp_status", label: "Whatsapp" },
    ],
  },
  {
    category: "Sales Settings",
    settings: [
      { name: "sales_mail_status", label: "Mail" },
      { name: "sales_sms_status", label: "SMS" },
      { name: "sales_whatsapp_status", label: "Whatsapp" },
      { name: "negative_billing", label: "B2C Negative Billing Status" },
      { name: "custom_sales_billing", label: "Custom Billing" },
      { name: "product_show_image", label: "Show Image" },
    ],
  },
  {
    category: "HRM Settings",
    settings: [
      { name: "hiring_status", label: "Hiring Status" },
      { name: "candidate_bulk_upload_status", label: "Candidate Bulk Upload Status" },
      { name: "hiring_history_status", label: "Hiring History Status" },
      { name: "attendance_status", label: "Attendance Status" },
      { name: "attendance_bulk_upload_status", label: "Attendance Bulk Upload Status" },
      { name: "whatsapp_chat_in_hrm_status", label: "Whatsapp Chat Status" },
      { name: "team_status", label: "Team Status" },
      { name: "email_calendar_invitaion", label: "Email Calendar Invitation" },
    ],
  },
  {
    category: "CRM Settings",
    settings: [
      { name: "leads_status", label: "Lead Status" },
      { name: "sales_target_status", label: "Sales Target Status" },
      { name: "customer_bulk_upload_status", label: "Customer Bulk Upload Status" },
      { name: "whatsapp_chat_in_crm_status", label: "Whatsapp Chat Status" },
    ],
  },
  {
    category: "Finance Settings",
    settings: [{ name: "gst_filling_status", label: "GST Filling Status" }],
  },
  {
    category: "Store License",
    settings: [
      { name: "license_status", label: "License Status", disabled: true },
      { name: "is_reseller", label: "Reseller" },
      { name: "is_store_active_for_Bhaarat_ERP_Bussiness_WhatsAapp", label: "Active Bhaarat ERP Business Store" },
      { name: "is_store_active_status", label: "Store Active Status" },
    ],
  },
]
export const allSettings = [
  { name: "Approval", icons: <CheckCircle />, component: <UserApproval /> },
  { name: "Delivery", icons: <LocalShipping />, component: <DeliveryManagement /> },
  { name: "Department & Role", icons: <Settings />, component: <DepartmentRole /> },
  { name: "Email", icons: <EmailRounded />, component: <EmailSetting /> },
  { name: "General", icons: <Settings />, component: <GeneralSetting /> },
  { name: "GST", icons: <Percent />, component: <GstSettings /> },
  { name: "Google Map", icons: <Map />, component: <GoogleMapConfig /> },
  { name: "Hiring", icons: <CallOutlined />, component: <HiringSettings /> },
  { name: "HRM", icons: <Diversity3Icon className="!text-red-600" />, component: <HRMSettings /> },
  { name: "Inventory", icons: <Settings />, component: <InventorySettings /> },
  { name: "Loyality Point", icons: <Settings />, component: <LoyalityPoint /> },
  { name: "Offer", icons: <Redeem />, component: <OfferSettings /> },
  { name: "Operations", icons: <Settings />, component: <Operations /> },
  { name: "Payment Gateway", icons: <AccountBalanceWallet />, component: <PaymentGateway /> },
  { name: "Payments Mode", icons: <AccountBalanceWallet />, component: <PaymentsMode /> },
  { name: "Push Notification", icons: <Notifications />, component: <PushNotification /> },
  { name: "SMS", icons: <SmsOutlined />, component: <SmsSettings /> },
  { name: "Store Plan", icons: <Settings />, component: <StorePlan /> },
  { name: "Sync API", icons: <SyncOutlined />, component: <Sync /> },
  { name: "Restaurant", icons: <RestaurantRounded />, component: <Restaurant /> },
  { name: "Unit of Measurement", icons: <Settings />, component: <UnitOfMeasurement /> },
  { name: "WhatsApp", icons: <WhatsApp className="!text-green-600" />, component: <Whatsapp /> },
]
