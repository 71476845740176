import { Add } from "@mui/icons-material"
import { useFormik } from "formik"
import React, { useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import CustomModal from "shared/MkxModal"
import { addShipRocketConfigFn, updateShipRocketConfigFn } from "Services/Settings/DeliverySetting"
import { useParams } from "react-router-dom"

const AddConfig = ({ setSelected, selected }) => {
  const { store_id } = useParams()

  const [open, setOpen] = useState(false)
  const initialValues = {
    username: selected?.username ?? "",
    password: selected?.password ?? "",
  }
  const handleClose = () => {
    setOpen(false)
    setSelected(false)
  }
  const client = useQueryClient()

  const { mutate: addShipRocketConfig } = useMutation(addShipRocketConfigFn, {
    onSuccess: ({ data }) => {
      if (data.response_code === 200) {
        toast.success(data.message)
        handleClose()
        client.refetchQueries("getShipRocketConfig")
      } else {
        toast.error(data.message)
      }
    },
  })

  const { mutate: updateShipRocketConfig } = useMutation(updateShipRocketConfigFn, {
    onSuccess: ({ data }) => {
      if (data.response_code === 200) {
        toast.success(data.message)
        handleClose()
        setSelected(false)
      } else {
        toast.error(data.message)
      }
    },
  })
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      selected
        ? updateShipRocketConfig({ ...values, store_id, shiprocket_id: selected.id })
        : addShipRocketConfig({ ...values, store_id })
    },
  })
  return (
    <>
      <CustomButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Add Config
      </CustomButton>
      <CustomModal
        open={open || selected}
        onClose={handleClose}
        padding={0}
        title={`${selected ? "Update" : "Add New"} Config`}
        className="!w-1/3"
      >
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 p-3">
          <CustomInput
            id="username"
            label="Username"
            isRequired
            autoComplete="off"
            formik={formik}
            placeholder="Enter Username"
          />
          <CustomInput
            id="password"
            type="password"
            label="Password"
            isRequired
            formik={formik}
            autoComplete="new-username"
            placeholder="Enter Password"
          />
          <div className="flex justify-end gap-2 py-3">
            <CustomButton onClick={handleClose}>Cancel</CustomButton> <CustomButton type="submit">Submit</CustomButton>
          </div>
        </form>
      </CustomModal>
    </>
  )
}

export default AddConfig
