import { Add } from "@mui/icons-material"
import { Divider } from "@mui/material"
import { NotifictaionFn, addNotifictaionFn, updateNotifictaionFn } from "Services/PushNotification"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import CustomModal from "shared/MkxModal"

const AddNotification = ({ config, setConfig }) => {
  const { store_id } = useParams()

  const [open, setOpen] = useState(false)
  const client = useQueryClient()
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setConfig(null)
  }

  const { data: configsData } = useQuery(
    ["notifictaionsId", config],
    () => NotifictaionFn({ store_id, firebase_id: config }),
    { enabled: !!store_id }
  )

  const configs = configsData?.data?.data

  const handleResponse = (response) => {
    if (response.data.response_code === 200) {
      toast.success(response.data.message)
      client.refetchQueries("notifictaions")
      handleClose()
    } else {
      toast.error(response.data.message)
    }
  }

  const { mutate: addConfig, isLoading } = useMutation(addNotifictaionFn, {
    onSuccess: (response) => {
      handleResponse(response)
    },
  })

  const { mutate: updateConfig, isLoading: isUpdating } = useMutation(updateNotifictaionFn, {
    onSuccess: (response) => {
      handleResponse(response)
    },
  })

  const initialValues = {
    config: JSON.stringify(configs?.firebase_service_account) || "",
    store_id,
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      const requestBody = { firebase_service_account: formik.values.config, store_id }
      config ? updateConfig({ firebase_id: config, ...requestBody }) : addConfig(requestBody)
    },
  })

  useEffect(() => {
    config && handleOpen()
  }, [config])

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      const fileContent = e.target.result
      formik.setFieldValue("config", fileContent)
    }
    reader.readAsText(file)
  }

  return (
    <>
      <CustomButton startIcon={<Add />} className="!px-5" onClick={() => setOpen(true)}>
        Server Key
      </CustomButton>
      <CustomModal title={config ? "Update " : "Add "} open={open} onClose={handleClose} className="!w-1/4" padding={4}>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 p-5">
          <CustomInput
            id="config"
            placeholder="Firebase Service Account"
            label="Firebase Service Account"
            type="file"
            onChange={handleFileChange}
          />
          <Divider>OR</Divider>
          <CustomInput
            id="config"
            placeholder="Firebase Service Account"
            label="Firebase Service Account"
            formik={formik}
            multiline
            rows={10}
            paddingX={0}
          />

          <div className="flex justify-end gap-3 my-2">
            <CustomButton type="button" onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton isLoading={config ? isUpdating : isLoading} type="submit">
              {config ? "Update" : "Add"}
            </CustomButton>
          </div>
        </form>
      </CustomModal>
    </>
  )
}

export default AddNotification
