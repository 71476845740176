import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import React, { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import GlassDiv from "shared/CustomDiv"
import Loader from "shared/CustomLoader"
import { enableInventoryItemFn, inventoryItemsFn } from "Services/Settings/Inventory"
import CustomPagination from "shared/MkxTable/MkxPagination"
import RequestInventory from "../RequestInventory"
const TypeThree = ({ value }) => {
  const [page, setPage] = useState(1)

  const client = useQueryClient()
  const {
    data: inventoryItems,
    isLoading,
    refetch,
  } = useQuery(["inventoryItems", value, page], () => inventoryItemsFn({ inventory_type: value, page }), {
    refetchOnWindowFocus: false,
  })
  const data = inventoryItems?.data?.data
  const { mutate: enableDisable } = useMutation(enableInventoryItemFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("inventoryItems")
      } else {
        toast.error(response.data.message)
      }
    },
  })
  return (
    <GlassDiv className="!p-0 !rounded">
      <div className="flex items-center justify-end p-2">
        <RequestInventory value="type-three" refetch={refetch} />
      </div>
      {!isLoading && (
        <TableContainer component="div" className="!p-0">
          <Table className="bg-white whitespace-nowrap bg-opacity-20 !overflow-x-scroll">
            <TableHead className="!bg-white !bg-opacity-30">
              <TableRow>
                <TableCell className="border-r !font-bold !text-center !border-white !border-opacity-30">
                  Sr. No.
                </TableCell>
                <TableCell className="border-r !font-bold !text-center !border-white !border-opacity-30">ID</TableCell>
                <TableCell className="border-r !font-bold !text-center !border-white !border-opacity-30">
                  Name
                </TableCell>
                {value !== "type-two" && value !== "type-three" && (
                  <TableCell className="border-r !font-bold !text-center !border-white !border-opacity-30">
                    Sync Status
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.map((item, ind) => (
                <TableRow className="!bg-white !bg-opacity-10" key={item?.id}>
                  <TableCell className="!border-r !py-2 !text-center !border-white !border-opacity-30">
                    {ind + 1}
                  </TableCell>
                  <TableCell className="!border-r !py-2 !text-center !border-white !border-opacity-30">
                    {item?.id}
                  </TableCell>
                  <TableCell className="!border-r !py-2 !text-center !border-white !border-opacity-30">
                    {item?.name}
                  </TableCell>
                  {value !== "type-two" && value !== "type-three" && (
                    <TableCell className="!border-r !py-2 !text-center !border-white !border-opacity-30">
                      <Chip
                        label={item?.active_for_sync ? "Active" : "Inactive"}
                        color={item?.active_for_sync ? "success" : "error"}
                        className="!w-20"
                        size="small"
                        onClick={() =>
                          enableDisable({
                            inventory_id: item?.id,
                            inventory_type: value,
                          })
                        }
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <CustomPagination data={inventoryItems} setPage={setPage} />
        </TableContainer>
      )}
      {isLoading && (
        <div className="flex items-center justify-center h-96">
          <Loader />
        </div>
      )}
    </GlassDiv>
  )
}

export default TypeThree
