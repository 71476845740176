import React from "react"
import "./Switch.css"

const Switch = ({ label, onChange, value }) => {
  return (
    <div className="container1">
      {label}{" "}
      <div className="toggle-switch">
        <input type="checkbox" value={value} className="checkbox1" onChange={onChange} name={label} id={label} />
        <label className="label1" htmlFor={label}>
          <span className="inn" />
          <span className="switch1" />
        </label>
      </div>
    </div>
  )
}

export default Switch
