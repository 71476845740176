import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"

export const erpTemplateListFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.settings.chat.erpTemplateList, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const addErpTemplateFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.settings.chat.erpTemplateList, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const singleErpTemplateFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.settings.chat.erpTemplateList, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateErpTemplateFn = (reqBody) => {
  try {
    const response = axiosInstance.put(API_URLS.settings.chat.erpTemplateList, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const deleteErpTemplateFn = (reqBody) => {
  try {
    const response = axiosInstance.delete(API_URLS.settings.chat.erpTemplateList, { params: reqBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
