import { ListItem } from "@mui/material"
import { useState } from "react"
import MkxButton from "shared/MkxButton"
import MkxModal from "shared/MkxModal"

const ViewLinks = ({ links }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <MkxButton onClick={() => setOpen(true)}>View</MkxButton>
      <MkxModal open={open} onClose={() => setOpen(false)} title="Video Links">
        {links.map((i, index) => {
          return (
            <ListItem>
              {index + 1}. {i}
            </ListItem>
          )
        })}
      </MkxModal>
    </>
  )
}

export default ViewLinks
