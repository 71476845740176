import { Autocomplete } from "@mui/material"
import { offerDataFn } from "Services/Settings/Offers"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import CustomInput from "shared/MkxInput"

export const DataSelect = ({ formik }) => {
  const { store_id } = useParams()
  const [searchValue, setSearchValue] = useState("")
  const { data: offerData, isLoading } = useQuery(
    ["offerData", formik.values.offer_on],
    () => offerDataFn({ store_id, data_type: formik.values.offer_on, search: searchValue }),
    { enabled: formik.values.offer_on !== "coupon" }
  )
  const options = offerData?.data?.data?.map((i) => i.title)
  useEffect(() => {
    const selected = offerData?.data?.data?.find((option) => option.title === formik.values.title)
    if (selected) {
      formik.setFieldValue(`${formik.values.offer_on}_id`, selected.id)
      setSearchValue(formik.values.title)
    }
  }, [formik.values.title, offerData])

  return (
    <Autocomplete
      freeSolo
      loading={isLoading}
      options={options || []}
      value={searchValue}
      onChange={(_, newValue) => {
        const selected = offerData?.data?.data?.find((option) => option?.title === newValue)
        if (selected) {
          formik.setFieldValue(`${formik.values.offer_on}_id`, selected.id)
        } else {
          setSearchValue(newValue)
        }
      }}
      onInputChange={(_, newValue) => setSearchValue(newValue)}
      renderInput={(params) => (
        <CustomInput
          label={<span className="capitalize">{formik.values.offer_on}</span>}
          isRequired
          className="placeholder:!capitalize"
          placeholder={`Search and Select ${
            formik.values.offer_on?.charAt(0).toUpperCase() + formik.values.offer_on.slice(1)
          }`}
          {...params}
        />
      )}
    />
  )
}
