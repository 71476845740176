import * as React from "react"
import axios from "config/axios"
import { useEffect } from "react"
import { useState } from "react"
import { flatten } from "lodash"
import CircularProgress from "@mui/material/CircularProgress"
import AddIcon from "@mui/icons-material/Add"
import ViewModule from "./ViewModule"

const Module = () => {
  const [permData, setPermData] = useState([])
  const [selectedUpdateModule, setSelectedUpdateModule] = useState([])
  const [open, setOpen] = React.useState(false)

  const [toggle, setToggle] = useState(true)
  const [rowData, setRowData] = useState([])

  const permissionList = () => {
    axios
      .get(`module-permission-api-for-superadmin/`)
      .then((response) => {
        setPermData(response?.data?.data)
        setOpen(true)
      })
      .catch((error) => {
        console.log("", error)
      })
  }
  useEffect(() => {
    permissionList()
  }, [])

  useEffect(() => {
    const filteredIds = []
    for (let i in permData) {
      const filteredPermissions = permData[i]?.permission?.filter((item) => item.is_permission === true)
      const filteredIdsForCurrentItem = filteredPermissions?.map((item) => item?.id)
      filteredIds.push(filteredIdsForCurrentItem)
    }
    setSelectedUpdateModule(flatten(filteredIds))
  }, [permData])

  const details = (row) => {
    setRowData(row)
    setToggle(false)
  }

  const handleClose = () => {
    setToggle(true)
  }

  return (
    <>
      <div className="">
        <div className="flex justify-between p-2">
          <div className="text-xl font-bold text-black">Module Permission </div>
        </div>

        {toggle === true ? (
          <div className="shadow grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 mx-auto !gap-4 shadow-gray-400 m-2 !h-[80vh] overflow-y-auto rounded p-2">
            {open ? (
              permData.length > 0 ? (
                permData &&
                permData?.map((row) => {
                  return (
                    <div className="rounded bg-sky-400 h-72">
                      <div className="flex items-center justify-between p-2 font-bold border-b text-slate-800">
                        <div className="flex items-center gap-2 uppercase">{row?.module_name}</div>
                        <div className="flex items-center gap-x-2">
                          <AddIcon className="bg-white rounded-full cursor-pointer" onClick={() => details(row)} />
                        </div>
                      </div>
                      <div className="h-[247px] overflow-auto">
                        {row?.permission.length !== 0 ? (
                          row?.permission?.map((elem, ind) => (
                            <div className="flex !border-[0.5px] hover:bg-sky-300 cursor-pointer">
                              {/* <div className="pt-2.5 text-start text-sm mx-2 text-white">
                                  {ind+1}.
                                </div> */}
                              <div className="px-2 pt-2 text-start">
                                <span className="text-sm text-black">{elem?.name}</span>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="mx-auto font-bold text-center text-white">No Data Found</div>
                        )}
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className="text-xl font-bold text-center text-red-400">No Data Found !</div>
              )
            ) : (
              <div className="items-center mx-auto text-center">
                <CircularProgress color="inherit" />
              </div>
            )}
          </div>
        ) : (
          <ViewModule row={rowData} handleClose={handleClose} permissionList1={permissionList} />
        )}
      </div>
    </>
  )
}

export default Module
