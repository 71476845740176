import axiosInstance from "config/axios"

export const rackListFn = (reqbody) => {
  try {
    const response = axiosInstance.get(`store-rack-api-of-superadmin/`, { params: reqbody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const postRackListFn = (reqbody) => {
  try {
    const response = axiosInstance.post(`store-rack-api-of-superadmin/`, reqbody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const UnitOfMeasuringFn = (reqbody) => {
  try {
    const response = axiosInstance.get(`store-measuring-unit-api-of-superadmin/`, { params: reqbody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
