import BorderColorIcon from "@mui/icons-material/BorderColor"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import CustomModal from "shared/MkxModal"
import GlassDiv from "shared/CustomDiv"

const BhaaratDelivery = () => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [open1, setOpen1] = React.useState(false)
  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => setOpen1(false)
  const [data, setData] = useState({})
  const [tokens, setTokens] = useState("")
  const [newToken, setNewToken] = useState("")

  const BhaaratDeliveryList = () => {
    axios
      .get(`https://mstore.bhaaraterp.com/delivery_manager/get_delivery_details_api_superuser/`, {
        headers: {
          Token: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        setData(response?.data)
      })
      .catch((error) => {
        console.log("", error)
      })
  }
  useEffect(() => {
    BhaaratDeliveryList()
  }, [])

  const editData = (event, rowToken) => {
    event.preventDefault()
    handleOpen()
    setTokens(rowToken)
  }

  const changedeliveryStatus = () => {
    axios
      .post(
        `https://mstore.bhaaraterp.com/delivery_manager/get_delivery_details_api_superuser/`,
        { token: tokens },
        {
          headers: {
            Token: localStorage.getItem("Token"),
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message)
        setTokens("")
        BhaaratDeliveryList()
        handleClose()
      })
      .catch((error) => {
        console.log("", error)
      })
  }

  const addTokenNew = () => {
    axios
      .post(
        `https://mstore.bhaaraterp.com/delivery_manager/get_delivery_details_api_superuser/`,
        { token: newToken },
        {
          headers: {
            Token: localStorage.getItem("Token"),
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message)
        setTokens("")
        BhaaratDeliveryList()
        handleClose1()
      })
      .catch((error) => {
        console.log("", error)
      })
  }

  return (
    <>
      <GlassDiv className="overflow-y-scroll w-full !p-0 !overflow-auto">
        <GlassDiv className="flex items-center justify-between rounded-b-none !p-1">
          <div></div>
          <div className="float-right p-2 pt-3">
            <CustomButton className="!float-right items-end " onClick={handleOpen1}>
              Add Token
            </CustomButton>
            <CustomModal open={open1} onClose={handleClose1} setOpen={setOpen1} title="Token" className="w-1/3 pb-4">
              <CustomInput label="New Token" rows={"3"} onChange={(event) => setNewToken(event.target.value)} />

              <div className="flex items-center justify-between pt-2 ">
                <div></div>

                <CustomButton onClick={addTokenNew}>SAVE</CustomButton>
              </div>
            </CustomModal>
            <CustomModal
              open={open}
              onClose={handleClose}
              setOpen={setOpen}
              title="Super Admin Token"
              className="w-1/3 pb-4"
            >
              <CustomInput
                rows={"3"}
                label="Token"
                value={tokens || ""}
                placeholder="Enter Token"
                onChange={(event) => setTokens(event.target.value)}
              />

              <div className="flex items-center justify-between pt-3">
                <div></div>

                <CustomButton onClick={changedeliveryStatus}>SAVE</CustomButton>
              </div>
            </CustomModal>
          </div>
        </GlassDiv>

        <TableContainer component="div" className="!p-0">
          <Table className="bg-white whitespace-nowrap bg-opacity-20 !overflow-x-scroll">
            <TableHead className="!bg-white !bg-opacity-30">
              <TableRow>
                <TableCell className="border-r !font-bold !py-2 !text-center !border-white !border-opacity-50">
                  Token
                </TableCell>
                <TableCell className="border-r !font-bold !py-2 !text-center !border-white !border-opacity-50">
                  Status
                </TableCell>

                <TableCell className="border-r !font-bold !py-2 !text-center !border-white !border-opacity-50">
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className="!cursor-pointer">
                <TableCell className="border-r !py-2 !text-center !border-white !border-opacity-50">
                  {data?.token ? data?.token : "--"}
                </TableCell>
                <TableCell className="border-r !py-2 !text-center !border-white !border-opacity-50">
                  {data?.is_active ? "yes" : "no"}
                </TableCell>
                <TableCell className="border-r !py-2 !text-center !border-white !border-opacity-50">
                  <BorderColorIcon className="text-green-500" onClick={(event) => editData(event, data?.token)} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {/* {data?.length !== 0 ? (
              <TablePagination
                rowsPerPageOptions={[6]}
                count={data?.length}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null} */}
        </TableContainer>
      </GlassDiv>
    </>
  )
}

export default BhaaratDelivery
