import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"

export const storeListFn = (redBody) => {
  try {
    const response = axiosInstance.get(API_URLS.storeList, {
      params: redBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const storeSettingsFn = (redBody) => {
  try {
    const response = axiosInstance.get(API_URLS.storeSettings, {
      params: redBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const changeSettingsFn = (redBody) => {
  try {
    const response = axiosInstance.patch(API_URLS.storeSettings, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const deleteStoreFn = (redBody) => {
  try {
    const response = axiosInstance.post(API_URLS.storeDelete, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const resetPasswordStoreFn = (redBody) => {
  try {
    const response = axiosInstance.post(API_URLS.storePassword, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const storeStatusChangeFn = (redBody) => {
  try {
    const response = axiosInstance.post(API_URLS.storeStatusChange, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const storeLicenseFn = (redBody) => {
  try {
    const response = axiosInstance.get(API_URLS.storeLicense, {
      params: redBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const upgradePlanFn = (redBody) => {
  try {
    const response = axiosInstance.post(API_URLS.storeLicense, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const storePlansFn = (redBody) => {
  try {
    const response = axiosInstance.get(API_URLS.storePlans, { params: redBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
