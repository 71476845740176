import * as React from "react"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import axios from "config/axios"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"

const WalletManagement = () => {
  const [walletData, setWalletData] = React.useState([])
  const [historyData, setHistoryData] = React.useState([])
  const [storeList, setStoreList] = React.useState([])
  const [store_id, setStore_id] = React.useState(9)

  React.useEffect(
    () => {
      // storeData();
      axios
        .get(`all-store-wallet-list/?store_id=${store_id}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          //   setData(response?.data.store_list_data);
          // toast.success(response.data.msg);
          console.log("wallet histry", response?.data?.data?.wallet_history)
          setHistoryData(response?.data?.data?.wallet_history)
          setWalletData(response?.data?.data?.wallet)
          console.log("wallet Data", response?.data?.data?.wallet)
        })
        .catch((error) => {
          console.log("", error.response)
          console.log(error)
        })
    },
    // eslint-disable-next-line
    [store_id]
  )

  React.useEffect(() => {
    axios
      .get(`store-list-api-for-superadmin/`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setStoreList(response.data.data)
      })
      .catch((error) => {
        console.log("", error.response)
        console.log(error)
        //   toast.error(error.message);
      })
  }, [])

  return (
    <>
      <div className="!bg-white">
        <div className="flex justify-between !bg-slate-100">
          <div className="p-2 py-3">
            <p className="text-xl font-bold !text-black">Store Wallet Management</p>
            {/* <p className="!text-black">View/Search Store list</p> */}
          </div>
          {/* <div className="p-2 pt-3">
                        <div className="flex items-center p-2 h-14 !bg-slate-100 border-zinc-600">
                            <input type="text"
                                className="!border-3 !border-black px-2 !w-64 rounded-md py-1"
                                placeholder="search"
                                style={{ border: "1px solid black" }}
                            />
                        </div>
                    </div> */}
        </div>

        <div className="flex justify-between py-2">
          <div className="items-center">
            {walletData
              ? walletData.map((elem) => {
                  return (
                    <Card sx={{ minWidth: 150 }} className="!w-[80%] mx-auto mt-2  !shadow-sm !shadow-blue-800">
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          <div className="flex gap-x-8">
                            <div className="text-xl w-72 font-bold pt-5">Total Balance : {elem.balance}</div>
                            <div className="bg-slate-200 !text-green-500 px-5 rounded-lg text-md pt-2">
                              {elem.income_balance}00.00 <ArrowOutwardIcon className="" />
                            </div>
                            <div className="bg-slate-200 !text-pink-500 px-5 rounded-lg text-md pt-2">
                              {elem.spend_balance}00.00 <ArrowDownwardIcon className="!rotate-45" />{" "}
                            </div>
                          </div>
                        </Typography>
                      </CardContent>
                    </Card>
                  )
                })
              : null}
          </div>
          <div className="mt-10 ml-4  mr-2">
            <select
              className="!placeholder:text-gray-900 rounded-lg h-10"
              style={{ border: "1px solid gray" }}
              value={store_id}
              onChange={(event) => setStore_id(event.target.value)}
            >
              {storeList.map((ele) => (
                <option value={ele.id}>{ele.store_admin_email}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="">
          <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-32">TRANSACTION Id</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-36">TRANSACTION TYPE</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-44">TRANSACTION AMOUNT</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-36">PREVIOUS AMOUNT</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-40">REMAINING AMOUNT</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    DESCRIPTION
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    DATE
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    STATUS
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historyData &&
                  historyData.map((row) => (
                    <TableRow key={row?.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.transaction_id ? row?.transaction_id : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.transaction_type ? row?.transaction_type : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.transaction_amount ? row?.transaction_amount : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.previous_amount ? row?.previous_amount : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.remaining_amount ? row?.remaining_amount : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row.description ? row.description : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.date ? row?.date : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.status ? row?.status : "--"}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default WalletManagement
