import { Close } from "@mui/icons-material"
import { Backdrop, Box, Divider, Fade, IconButton, Modal } from "@mui/material"
import classNames from "classnames"
import * as React from "react"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
}

const MkxModal = ({ open = false, onClose, children, className = "", title = "Modal" }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style} className={classNames("min-w-[33%] !outline-none !rounded min-h-[33%]", className)}>
          <span className="flex items-center justify-between p-2">
            <p className="text-lg font-semibold">{title}</p>
            <IconButton size="small" onClick={() => onClose()}>
              <Close />
            </IconButton>
          </span>
          <Divider />
          {children}
        </Box>
      </Fade>
    </Modal>
  )
}
export default MkxModal
