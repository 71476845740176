import { ArrowBack } from "@mui/icons-material"
import { Box } from "@mui/material"
import { useNavigate } from "react-router-dom"
import MkxButton from "shared/MkxButton"

const NotFound = () => {
  const navigate = useNavigate()
  return (
    <Box className="flex flex-col items-center justify-center h-full gap-3 py-16">
      <img src="https://pub-16f3de4d2c4841169d66d16992f9f0d3.r2.dev/assets/Sales/pana.svg" alt="" />
      <p className="text-3xl font-semibold">404</p>
      <p className="text-3xl font-semibold">Page Not Found</p>
      <MkxButton size="large" className="!px-10" startIcon={<ArrowBack />} onClick={() => navigate(-1)}>
        Go Back
      </MkxButton>
    </Box>
  )
}

export default NotFound
