import { DeleteTwoTone, ReportTwoTone } from "@mui/icons-material"
import { Button, Checkbox, Dialog, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { rackListFn } from "Services/UnitofMeasurement"
import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"
import { useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import GlassDiv from "shared/CustomDiv"
import CustomPagination from "shared/CustomPagination"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import MkxTableCell from "shared/MkxTable/MkxTableCell"
import { MkxTableLoader } from "shared/MkxTable/MkxTableLoader"
import NoDataFound from "shared/NoDataFound"
import AddRack from "./AddRack"

const Rack = () => {
  const { store_id } = useParams()
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const [selectedIds, setSelectedIds] = useState([])
  const [isDelete, setIsDelete] = useState(false)

  const client = useQueryClient()

  const { data, isLoading } = useQuery(["rackData", page, search, store_id], () =>
    rackListFn({ store_id, page: page, search })
  )

  const handleSelectRow = (id) => {
    setSelectedIds((prevSelectedRow) =>
      prevSelectedRow.includes(id) ? prevSelectedRow.filter((i) => i !== id) : [...prevSelectedRow, id]
    )
  }
  const handleAllRowSelect = (event) => {
    setSelectedIds(event.target.checked ? data?.data?.data?.map((i) => i.id) : [])
  }

  const handleDelete = () => {
    axiosInstance.delete(API_URLS.rackList, { data: { store_id, rack_ids: selectedIds } }).then((response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        setIsDelete(false)
        setSelectedIds([])
        client.refetchQueries("rackData")
      } else {
        toast.error(response.data.message)
      }
    })
  }

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between p-2">
        <CustomInput value={search} onChange={(event) => setSearch(event.target.value)} placeholder="Search Rack" />
        <div className="flex items-center gap-4">
          {selectedIds?.length !== 0 && (
            <>
              <CustomButton color="error" startIcon={<DeleteTwoTone />} onClick={() => setIsDelete(true)}>
                Delete Selected
              </CustomButton>
              <Dialog
                open={isDelete}
                onClose={() => setIsDelete(false)}
                PaperProps={{ className: "!max-w-[500px] w-96" }}
              >
                <div className="flex flex-col items-center gap-3 p-5">
                  <ReportTwoTone color="error" className="!text-5xl" />
                  <p className="text-xl">Are you sure!</p>
                  <p>You want to delete selected Rack?</p>
                </div>
                <span className="flex justify-end gap-3 pb-3 pr-3">
                  <Button
                    size="small"
                    variant="outlined"
                    color="inherit"
                    onClick={() => setIsDelete(false)}
                    className="!capitalize"
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    disableElevation
                    color="error"
                    className="!capitalize"
                    variant="contained"
                    onClick={() => handleDelete()}
                  >
                    Delete
                  </Button>
                </span>
              </Dialog>
            </>
          )}
          <AddRack />
        </div>
      </div>

      <TableContainer>
        <Table className="border">
          <TableHead>
            <TableRow>
              <MkxTableCell isHead>
                <Checkbox className="!p-0" size="small" onChange={handleAllRowSelect} />
              </MkxTableCell>
              <MkxTableCell isHead>Row No.</MkxTableCell>
              <MkxTableCell isHead>Column No.</MkxTableCell>
              <MkxTableCell isHead>Product</MkxTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <MkxTableLoader loading={isLoading} row={3} />
            {data?.data?.data?.map((item, ind) => (
              <TableRow key={ind + 1}>
                <MkxTableCell>
                  <Checkbox
                    size="small"
                    className="!p-0"
                    checked={selectedIds.filter((id) => id === item.id)?.length === 1 ? true : false}
                    onChange={() => handleSelectRow(item.id)}
                  />
                </MkxTableCell>
                <MkxTableCell>{item?.row_no ? item?.row_no : "--"}</MkxTableCell>
                <MkxTableCell>{item?.column_no ? item?.column_no : "--"}</MkxTableCell>
                <MkxTableCell>{item?.product ? item?.product : "--"}</MkxTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination data={data} setPage={setPage} />
      <NoDataFound data={data} />
    </div>
  )
}

export default Rack
