import { AccessTime, Close, Edit, MailOutline, MoveToInbox } from "@mui/icons-material"
import { Collapse, Dialog, Divider, FormControl, FormControlLabel, IconButton, Radio, RadioGroup } from "@mui/material"
import React, { useState } from "react"
import { enableTemplateFn } from "Services/Chats/EnableDisable"
import { chatTempsFn } from "Services/Chats/WhatsappTemplatesWithoutNumber"
import CustomButton from "shared/MkxButton"
import classNames from "classnames"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import SetWorkingHours from "./SetWorkingHours"
import GlassDiv from "shared/CustomDiv"

const Inbox = () => {
  const [typeId, setTypeId] = useState("")
  const [isUpdate, setIsUpdate] = useState(false)
  const client = useQueryClient()
  const { data: templates } = useQuery(["chatTemps"], () => chatTempsFn({ template_type: "Inbox" }), {
    refetchOnWindowFocus: false,
  })
  const { mutate: defaultSet } = useMutation(enableTemplateFn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        toast.success(response?.data?.message)
        client.refetchQueries("chatTemps")
      } else {
        toast.error(response?.data?.message)
      }
    },
  })
  return (
    <>
      <span className="flex items-start gap-2 p-[14px] text-xl">
        <MoveToInbox />
        <span>
          <p className="font-semibold"> Inbox Settings</p>
          <p className="text-sm">
            Manage Auto replies to user messages and improve your customer experience. Read here to know more about this
            section in detail.
          </p>
        </span>
      </span>

      <Divider />
      <div className="flex flex-col p-3">
        <span className="py-4">
          <span className="flex items-center justify-start gap-2">
            <AccessTime />
            <p className="text-lg font-semibold">Setup your working hours</p>
            <SetWorkingHours />
          </span>
          <p className="px-10">Mon, Tue 10am to 06pm</p>
        </span>
        <span className="flex flex-col gap-2">
          {templates?.data?.data?.map((t) => {
            return (
              <GlassDiv className="flex !p-0 flex-col rounded-md shadow cursor-pointer hover:shadow-md">
                <span
                  className="flex items-center justify-between gap-2 p-3"
                  onClick={() => (t.id !== typeId ? setTypeId(t.id) : setTypeId(""))}
                >
                  <span>
                    <p className="text-lg font-semibold">{t.name}</p>
                    <p>{t.sub_name}</p>
                  </span>
                  <span className="flex justify-end w-32">
                    <p
                      className={classNames(
                        "text-lg font-semibold",
                        t.is_automation_enable ? "text-green-500" : "text-red-500"
                      )}
                    >
                      {t.is_automation_enable ? "Enabled" : "Disabled"}
                    </p>
                  </span>
                </span>
                <Collapse in={t.id == typeId}>
                  <span className="flex flex-col w-full rounded-b">
                    <span className="flex flex-col p-2">
                      <span className="flex items-center justify-start gap-2">
                        <MailOutline />
                        <p className="font-semibold">{t.name}</p>
                        <IconButton size="small" onClick={() => setIsUpdate(true)}>
                          <Edit className="hover:!text-blue-500" />
                        </IconButton>
                      </span>
                      <span className="flex flex-col gap-2 p-5">
                        <span className="p-3 bg-gray-100 rounded-lg">{t.body}</span>
                        <FormControl>
                          <RadioGroup defaultValue="None" name="radio-buttons-group">
                            <FormControlLabel
                              value="female"
                              control={<Radio />}
                              label={
                                <span className="flex flex-col">
                                  <p className="font-semibold">
                                    Add list of Product Collections (<span className="text-blue-500">set here</span>) to
                                    the Out of Office Message
                                  </p>
                                  <p className="text-xs">
                                    If customer selects a collection, corresponding Product Catalog will be sent.
                                  </p>
                                </span>
                              }
                            />
                            <FormControlLabel
                              value="male"
                              control={<Radio />}
                              label="Enable Interaktive List Message"
                            />
                            <FormControlLabel value="None" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </span>
                      <span className="p-5">
                        <CustomButton
                          size="large"
                          color={t.is_automation_enable ? "error" : "success"}
                          onClick={() =>
                            defaultSet({
                              template_id: t.id,
                            })
                          }
                        >
                          {t.is_automation_enable ? "Disable" : "Enable"}
                        </CustomButton>
                      </span>
                    </span>
                  </span>
                </Collapse>
              </GlassDiv>
            )
          })}
        </span>
      </div>

      <Dialog open={isUpdate} onClose={() => setIsUpdate(false)}>
        <span className="flex items-center justify-between p-3 text-white bg-secondary">
          <span className="flex items-center gap-2">
            <MailOutline />
            <p className="font-semibold">Set up Out of Office Message</p>
          </span>
          <IconButton onClick={() => setIsUpdate(false)}>
            <Close className="!text-white" />
          </IconButton>
        </span>
        <p className="p-5 text-xs font-semibold">
          Auto Reply messages will be triggered based on the below working hours. To understand how business working
          hours affect Auto Replies, read here. (timings will match your system timings)
        </p>
      </Dialog>
    </>
  )
}

export default Inbox
