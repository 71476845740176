import { Add, Delete } from "@mui/icons-material"
import { IconButton, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { postRackListFn } from "Services/UnitofMeasurement"
import { useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import GlassDiv from "shared/CustomDiv"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import CustomModal from "shared/MkxModal"
import MkxTableCell from "shared/MkxTable/MkxTableCell"

export default function AddRack() {
  const { store_id } = useParams()
  const [open, setOpen] = useState(false)
  const [rows, setRows] = useState([{ row_no: "", column_no: "" }])

  const client = useQueryClient()

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  const deleteRow = (id) => {
    if (rows.length !== 1) {
      const updatedRows = rows.filter((_item, index) => index !== id)
      setRows(updatedRows)
    } else {
      toast.error("At List One Rows Not Delete")
    }
  }

  const submitButton = () => {
    mutate({ rack_data: rows, store_id })
  }

  const { mutate, isLoading } = useMutation(postRackListFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        handleClose()
        client.refetchQueries("rackData")
      } else {
        toast.error(response.data.message)
      }
    },
  })

  return (
    <>
      <CustomButton startIcon={<Add />} className="!px-5" onClick={handleOpen}>
        Add Rack
      </CustomButton>
      <CustomModal open={open} onClose={handleClose} className="!w-1/3" title="Add Rack">
        <TableContainer component={GlassDiv} className="!p-0 !rounded-b-none">
          <Table>
            <TableHead>
              <TableRow className="!bg-white !bg-opacity-30 cursor-pointer">
                <MkxTableCell isHead>Row No.</MkxTableCell>
                <MkxTableCell isHead>Column No.</MkxTableCell>
                <MkxTableCell isHead>Action</MkxTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((_sales, index) => (
                <TableRow>
                  <MkxTableCell>
                    <CustomInput
                      type="number"
                      placeholder="Enter Row Number"
                      id="row_no"
                      value={rows?.[index].row_no}
                      onChange={(event) => {
                        const updatedRows = [...rows]
                        updatedRows[index]["row_no"] = event.target.value
                        setRows([...updatedRows])
                      }}
                    />
                  </MkxTableCell>
                  <MkxTableCell>
                    <CustomInput
                      type="number"
                      placeholder="Enter Column Number"
                      id="column_no"
                      value={rows?.[index].column_no}
                      onChange={(event) => {
                        const updatedRows = [...rows]
                        updatedRows[index]["column_no"] = event.target.value
                        setRows([...updatedRows])
                      }}
                    />
                  </MkxTableCell>
                  <MkxTableCell>
                    <IconButton onClick={() => deleteRow(index)} className="!bg-white !bg-opacity-10">
                      <Delete fontSize="small" color="error" />
                    </IconButton>
                  </MkxTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <GlassDiv className="flex !rounded p-2">
          <CustomButton startIcon={<Add />} onClick={() => setRows([...rows, { row_no: "", column_no: "" }])}>
            Add
          </CustomButton>
        </GlassDiv>
        <div className="flex items-center justify-center p-3">
          <CustomButton isLoading={isLoading} className="flex items-center justify-center" onClick={submitButton}>
            Submit
          </CustomButton>
        </div>
      </CustomModal>
    </>
  )
}
