import React, { useEffect, useState } from "react"
import CustomModal from "shared/MkxModal"
import { useFormik } from "formik"
import axiosInstance from "config/axios"
import { toast } from "react-toastify"
import CustomSelect from "shared/MkxSelect"
import { MenuItem } from "@mui/material"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import { API_URLS } from "config/apiUrls"

const RequestInventory = ({ value, refetch }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [categoryData, setCategoryData] = useState([])
  const [typeOneData, setTypeOneData] = useState([])
  const [typeTwoData, setTypeTwoData] = useState([])
  const [typeThreeData, setTypeThreeData] = useState([])
  const handleClose = () => {
    setOpen(false)
  }
  const initialValues = {
    type_of_request: value || "",
    request_title: "",
    category_id: "",
    type_one_variant_id: "",
    type_two_variant_id: "",
    type_three_variant_id: "",
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      const fd = new FormData()
      fd.append("type_of_request", formik.values.type_of_request)
      fd.append("request_title", formik.values.request_title)
      fd.append("type_one_variant_id", formik.values.type_one_variant_id)
      fd.append("category_id", formik.values.category_id)
      fd.append("type_two_variant_id", formik.values.type_two_variant_id)
      fd.append("type_three_variant_id", formik.values.type_three_variant_id)
      setIsLoading(true)
      axiosInstance
        .post(API_URLS.storeRequest, fd)
        .then((response) => {
          if (response.data.response_code === 200) {
            toast.success(response?.data?.message)
            refetch()
            handleClose()
          } else {
            toast.error(response?.data?.message)
          }
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    },
  })
  const getCategoryList = () => {
    axiosInstance.get(API_URLS.categoryList).then((response) => {
      setCategoryData(response.data.data)
    })
  }
  useEffect(() => {
    getCategoryList()
  }, [])
  const getTypeOneValue = () => {
    axiosInstance.get(API_URLS.variantTypeOne + `?category_id=${formik.values.category_id}`).then((response) => {
      setTypeOneData(response.data.data)
    })
  }
  useEffect(() => {
    formik.values.category_id && getTypeOneValue()
  }, [formik.values.category_id])

  const getTypeTwoValue = () => {
    axiosInstance.get(API_URLS.variantTypeTwo + `?category_id=${formik.values.category_id}`).then((response) => {
      setTypeTwoData(response.data.data)
    })
  }
  useEffect(() => {
    formik.values.category_id && getTypeTwoValue()
  }, [formik.values.category_id])

  const getTypeThreeValue = () => {
    axiosInstance.get(API_URLS.variantTypeThree + `?category_id=${formik.values.category_id}`).then((response) => {
      setTypeThreeData(response.data.data)
    })
  }
  useEffect(() => {
    formik.values.category_id && getTypeThreeValue()
  }, [formik.values.category_id])

  return (
    <>
      <CustomButton onClick={() => setOpen(true)}>Request</CustomButton>
      <CustomModal title="Inventory Request" open={open} onClose={handleClose} className="!w-2/5" padding={4}>
        <form onSubmit={formik.handleSubmit} className="grid w-full gap-y-2">
          <CustomSelect label="Select Type Of Request" id="type_of_request" formik={formik} className="!w-full">
            <MenuItem value="type-one-value">Type One Value</MenuItem>
            <MenuItem value="type-two">Type Two Variant</MenuItem>
            <MenuItem value="type-two-value">Type Two Value</MenuItem>
            <MenuItem value="type-three">Type Three Variant</MenuItem>
            <MenuItem value="type-three-value">Type Three Value</MenuItem>
          </CustomSelect>

          {formik.values["type_of_request"] === "category" || formik.values["type_of_request"] === "brand" ? (
            ""
          ) : (
            <CustomSelect
              label="Category"
              id="category_id"
              placeholder="Select Category"
              formik={formik}
              className="!w-full"
            >
              {categoryData?.map((item) => (
                <MenuItem value={item?.id}>{item?.title}</MenuItem>
              ))}
            </CustomSelect>
          )}

          {formik.values["type_of_request"] === "type-one-value" ? (
            <CustomSelect label="Select variant Type One" id="type_one_variant_id" formik={formik}>
              {typeOneData?.map((item) => (
                <MenuItem value={item?.id}>
                  {item?.variant} ({item?.variant})
                </MenuItem>
              ))}
            </CustomSelect>
          ) : (
            ""
          )}

          {formik.values["type_of_request"] === "type-two-value" ? (
            <CustomSelect label="Select variant Type Two" id="type_two_variant_id" formik={formik}>
              {typeTwoData?.map((item) => (
                <MenuItem value={item?.id}>
                  {item?.variant} ({item?.variant})
                </MenuItem>
              ))}
            </CustomSelect>
          ) : (
            ""
          )}

          {formik.values["type_of_request"] === "type-three-value" ? (
            <CustomSelect label="Select variant Type Three" id="type_three_variant_id" formik={formik}>
              {typeThreeData?.map((item) => (
                <MenuItem value={item?.id}>
                  {item?.variant} ({item?.variant})
                </MenuItem>
              ))}
            </CustomSelect>
          ) : (
            ""
          )}
          <CustomInput type="text" label="Title" id="request_title" placeholder="Enter Title" formik={formik} />
          <CustomButton
            type="submit"
            className="!my-2 !text-center !mx-auto !flex !justify-center"
            isLoading={isLoading}
          >
            Request
          </CustomButton>
        </form>
      </CustomModal>
    </>
  )
}

export default RequestInventory
