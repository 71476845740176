import {
  FormControl,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material"
import axios from "config/axios"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"

const ApprovalRequest = () => {
  const [data, setData] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [status, setStatus] = useState("")
  const [page, setPage] = useState(1)
  const [count, setCount] = useState([])
  const handleChange = (event, value) => {
    setPage(value)
  }
  const ApprovalDataList = () => {
    axios
      .get(`store-request-for-inventory-data-api-of-bhaaraterp-superadmin/`, {
        params: { page, status, search: searchQuery },
      })
      .then((response) => {
        setData(response.data.data)
        setCount(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    ApprovalDataList()
  }, [status, page, searchQuery])

  const changesStatus = (id, status) => {
    const fd = new FormData()
    fd.append("request_type_id", id)
    fd.append("status", status)
    axios
      .put(`store-request-for-inventory-data-api-of-bhaaraterp-superadmin/`, fd, {})
      .then((response) => {
        toast.success(response.data.message)
        ApprovalDataList()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div className="bg-gray-100">
      <div className="flex justify-between">
        <div className="p-3">
          <p className="text-xl !text-black font font-bold">Request Inventory</p>
          <p>Manage Inventory Requests</p>
        </div>
        <div className="p-2 pt-5"></div>
      </div>
      <div className="flex flex-col !rounded !text-black bg-slate-100 shadow-card shadow-zinc-600 m-2">
        <div className="flex items-center justify-between p-2">
          <span className="flex gap-3">
            <TextField
              placeholder="Search Inventory Request..."
              size="small"
              value={searchQuery}
              className="!bg-white"
              onChange={(event) => setSearchQuery(event.target.value)}
            />
          </span>
          <span>
            <FormControl size="small">
              <Select displayEmpty value={status} className="w-32" onChange={(event) => setStatus(event.target.value)}>
                <MenuItem value={""}>All</MenuItem>
                <MenuItem value={"Pending"}>Pending</MenuItem>
                <MenuItem value={"Accepted"}>Accepted</MenuItem>
                <MenuItem value={"Rejected"}>Rejected</MenuItem>
              </Select>
            </FormControl>
          </span>
        </div>
        <TableContainer component={Paper} className="!text-black !mx-auto !bg-white overflow-auto">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className="!text-center border-r !text-gray-800 !font-bold">Request Title</TableCell>
                <TableCell className="!text-center border-r !text-gray-800 !font-bold">Request Type Approval</TableCell>

                <TableCell className="!text-center border-r !text-gray-800 !font-bold">Category</TableCell>

                <TableCell className="!text-center border-r !text-gray-800 !font-bold">Store Name</TableCell>
                <TableCell className="!text-center border-r !text-gray-800 !font-bold">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="!bg-red">
              {data &&
                data?.map((row) => (
                  <TableRow key={row.id} className="!text-slate-700">
                    <TableCell className="!py-1 border-r !text-center">{row?.request_title}</TableCell>

                    <TableCell className="!py-1 border-r !text-center">{row?.type_of_request}</TableCell>

                    <TableCell className="!py-1 border-r !text-center">{row?.category || "--"}</TableCell>
                    <TableCell className="!py-1 border-r !text-center">{row?.store_name}</TableCell>
                    <TableCell className="!py-1 border-r !text-center">
                      <FormControl size="small">
                        <Select
                          displayEmpty
                          value={row?.status || ""}
                          className="w-32"
                          onChange={(event) => event.target.value && changesStatus(row?.id, event.target.value)}
                          renderValue={() => row?.status || ""}
                          placeholder={row?.status || ""}
                        >
                          {row?.status === "Pending" && <MenuItem value="Accepted">Accepted</MenuItem>}
                          {row?.status === "Pending" && <MenuItem value="Rejected">Rejected</MenuItem>}
                          {row?.status === "Rejected" && <MenuItem value="Accepted">Accepted</MenuItem>}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <span className="flex items-center justify-end p-2">
            <Pagination
              shape="rounded"
              variant="outlined"
              page={count?.current_page}
              count={count?.total_pages}
              onChange={handleChange}
            />
          </span>
        </TableContainer>
      </div>
    </div>
  )
}

export default ApprovalRequest
