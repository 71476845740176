import { Checkbox, Divider } from "@mui/material"
import { storePermissionFn, updateStorePermissionFn } from "Services/Permission"
import { flatten } from "lodash"
import { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import GlassDiv from "shared/CustomDiv"
import Loader from "shared/CustomLoader"
import MkxButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"

const AddPermissions = () => {
  const [selectedUpdateModule, setSelectedUpdateModule] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const { store_id, emp_id } = useParams()
  const [moduleSearch, setModuleSearch] = useState("")

  const { data, isLoading } = useQuery(["storePermission", store_id, emp_id], () =>
    storePermissionFn({ store_id, employee_id: emp_id })
  )

  const permData = data?.data?.data
  const employee = data?.data?.employeesinfo

  const { mutate: updatePermission, isLoading: isUpdating } = useMutation(updateStorePermissionFn, {
    onSuccess: ({ data }) => {
      if (data.response_code === 200) {
        toast.success(data?.message)
      } else {
        toast.error(data?.message)
      }
    },
  })

  useEffect(() => {
    const filteredIds = []
    for (let i in permData) {
      const filteredPermissions = permData?.[i]?.permissions?.filter((item) => item.active_status === true)
      const filteredIdsForCurrentItem = filteredPermissions.map((item) => item.id)
      filteredIds.push(filteredIdsForCurrentItem)
    }
    setSelectedUpdateModule(flatten(filteredIds))
  }, [permData])

  const reqBody = { store_id, employee_id: emp_id, permission_ids: selectedUpdateModule }

  const handleSelectAll = (event, moduleId) => {
    const selectedPermissions = permData?.find((i) => i?.module_name === moduleId)?.permissions?.map((i) => i.id) || []
    if (event.target.checked) {
      setSelectedUpdateModule((prev) => [...prev, ...selectedPermissions])
    } else {
      setSelectedUpdateModule((prev) => prev.filter((item) => !selectedPermissions.includes(item)))
    }
  }

  return isLoading ? (
    <div className="flex items-center justify-center h-[70vh]">
      <Loader />
    </div>
  ) : (
    <>
      <div className="flex flex-col w-full h-full gap-1 p-1 bg-gray-200">
        <GlassDiv className="flex items-center justify-between">
          <div className="flex items-center gap-2 font-bold">
            <span>Employee : </span>
            <span className="text-blue-500">{employee?.user}</span>
            <span className="text-black">({employee?.role})</span>
          </div>
          <MkxButton isLoading={isUpdating} onClick={() => updatePermission(reqBody)}>
            update
          </MkxButton>
        </GlassDiv>

        <div className="grid grid-cols-2 gap-1 h-fit">
          {data?.data?.data?.map((module) => {
            return (
              <>
                <GlassDiv className="!p-0">
                  <div className="flex items-center justify-between p-2 px-2 font-bold border-b border-white border-opacity-30">
                    <div className="flex items-center justify-between gap-x-2">
                      <Checkbox
                        size="small"
                        className="!p-0"
                        onChange={(event) => handleSelectAll(event, module.module_name)}
                      />
                      <span>{module?.module_name}</span>
                    </div>

                    <CustomInput
                      placeholder={`Search ${module?.module_name || "permission"}...`}
                      type="search"
                      onChange={(event) => {
                        setSearchQuery(event.target.value)
                        setModuleSearch(module?.module_name)
                      }}
                    />
                  </div>
                  <Divider />
                  <div className="grid grid-cols-2 py-2 overflow-y-auto max-h-60">
                    {module?.permissions
                      ?.filter((row) =>
                        module?.module_name === moduleSearch
                          ? row?.name?.split("_").join(" ").toLocaleUpperCase().includes(searchQuery?.toUpperCase())
                          : row?.name.toLowerCase().includes("")
                      )
                      ?.map((elem) => (
                        <div className="flex items-center">
                          <Checkbox
                            size="small"
                            checked={selectedUpdateModule?.map((i) => i).includes(elem?.id)}
                            onChange={() => {
                              {
                                !selectedUpdateModule?.map((i) => i).includes(elem?.id)
                                  ? setSelectedUpdateModule([...selectedUpdateModule, elem?.id])
                                  : setSelectedUpdateModule(() => selectedUpdateModule?.filter((i) => i !== elem?.id))
                              }
                            }}
                          />
                          <span className="text-sm capitalize">{elem?.name.replaceAll("_", " ")}</span>
                        </div>
                      ))}
                  </div>
                </GlassDiv>
              </>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default AddPermissions
