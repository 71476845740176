import { CheckCircle, Update } from "@mui/icons-material"
import { CircularProgress, Collapse, Divider, IconButton, List, ListItem, ListItemButton } from "@mui/material"
import classNames from "classnames"
import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { storePlansFn, upgradePlanFn } from "Services/Business/Store"
import MkxButton from "shared/MkxButton"
import MkxModal from "shared/MkxModal"

const UpgradePlan = ({ store }) => {
  const [open, setOpen] = useState(false)
  const [collapse, setCollapse] = useState(false)
  const [planType, setPlanType] = useState("")
  const { data: storePlans, isLoading } = useQuery(["storePlans", open], () => storePlansFn(), {
    refetchOnWindowFocus: false,
    enabled: open,
  })
  const client = useQueryClient()
  const { mutate: upgradePlan } = useMutation(upgradePlanFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
        client.refetchQueries("storeLicense")
        setOpen(false)
      } else {
        toast.error(res.data.message)
      }
    },
  })
  return (
    <>
      <IconButton size="small" color="primary" onClick={() => setOpen(true)}>
        <Update />
      </IconButton>
      <MkxModal
        open={open}
        onClose={() => setOpen(false)}
        title="Upgrade Plan"
        className="overflow-y-auto flex justify-between flex-col h-[80vh]"
      >
        <List className="!p-0 h-[68vh] overflow-y-auto">
          {isLoading ? (
            <span className="flex items-center justify-center w-full h-full">
              <CircularProgress size={20} />
            </span>
          ) : (
            storePlans?.data?.data?.map((plan) => {
              return (
                <>
                  <ListItemButton
                    className={classNames(plan?.id === collapse ? "!bg-blue-500 !text-white" : "")}
                    onClick={() => (plan?.id !== collapse ? setCollapse(plan?.id) : setCollapse(false))}
                  >
                    {plan.plan_name}
                  </ListItemButton>

                  <Collapse in={plan?.id === collapse} className="border-2 border-blue-500">
                    <ListItem className="!text-xs !p-1">Monthly/Yearly</ListItem>
                    <Divider />
                    <ListItemButton
                      onClick={() => setPlanType("Monthly")}
                      className={classNames(
                        "!flex !items-center !px-6 !h-12 !text-sm !justify-between",
                        planType === "Monthly" && "!bg-gray-100"
                      )}
                    >
                      <p>Monthly</p>
                      {planType === "Monthly" && <CheckCircle color="success" />}
                    </ListItemButton>

                    <ListItemButton
                      onClick={() => setPlanType("Yearly")}
                      className={classNames(
                        "!flex !items-center !px-6 !h-12 !text-sm !justify-between",
                        planType === "Yearly" && "!bg-gray-100"
                      )}
                    >
                      <p>Yearly</p>
                      {planType === "Yearly" && <CheckCircle color="success" />}
                    </ListItemButton>
                  </Collapse>
                </>
              )
            })
          )}
        </List>
        <Divider />
        <div className="flex items-center justify-end gap-5 h-[5vh] p-2">
          <MkxButton color="inherit" onClick={() => setOpen(false)}>
            Cancel
          </MkxButton>
          <MkxButton
            onClick={() =>
              upgradePlan({
                license_no: store?.license,
                plan_id: collapse,
                active_plan_type: planType,
              })
            }
          >
            Upgrade
          </MkxButton>
        </div>
      </MkxModal>
    </>
  )
}

export default UpgradePlan
