import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"

export const addTemplateApprovalFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.templateApproval, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateTemplateApprovalFn = (reqBody) => {
  try {
    const response = axiosInstance.put(API_URLS.templateApproval, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const deleteTemplateApprovalFn = (reqBody) => {
  try {
    const response = axiosInstance.delete(API_URLS.templateApproval, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
