import React, { useEffect, useState } from "react"
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { Add } from "@mui/icons-material"
import axios from "config/axios"
import BorderColorIcon from "@mui/icons-material/BorderColor"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { Inactive, Unmaped, Active } from "../../../shared/ActivaInactive"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import { toast } from "react-toastify"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  rounded: 2,
}

const CradiantialSetup = () => {
  const [data, setData] = useState([])
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [key, setKey] = useState("")
  const [secret, setSecret] = useState("")
  const [status, setStatus] = useState("")
  const [payment, setPayment] = useState("")
  const [ids, setIds] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState([])

  const editFunctionality = (row) => {
    console.log("edit data==>", row)
    setIds(row?.id)
    setKey(row?.key)
    setSecret(row?.secret)
    setStatus(row?.status ? "True" : "False")
    setPayment(row?.store_payment_Accepted_by?.id)
    handleOpen()
  }
  const handleModalClose = () => {
    setKey("")
    setSecret("")
    setStatus("")
    setPayment("")
    setIds(null)
    handleClose()
  }

  const Setuplist = () => {
    axios
      .get(
        `api/admin/multivendor-store-pay-payment-accepted-by-credentials/`,

        {
          headers: {
            Token: localStorage.getItem("Token"),
          },
        }
      )
      .then((response) => {
        setData(response?.data?.store_payment_accepted_by_credentials)
      })
      .catch((error) => {
        console.log("", error)
      })
  }

  useEffect(() => {
    Setuplist()
  }, [])

  const submitData = () => {
    const reqbody = new FormData()
    reqbody.append("id", ids)
    reqbody.append("key", key)
    reqbody.append("secret", secret)
    reqbody.append("status", status)
    reqbody.append("payment_gateway", payment)
    ids === null
      ? axios
          .post(`api/admin/multivendor-add-store-pay-payment-accepted-by-credentials/`, reqbody, {
            headers: {
              Token: localStorage.getItem("Token"),
            },
          })
          .then((response) => {
            toast.success(response?.data?.message)
            setKey("")
            setSecret("")
            setStatus("")
            setPayment("")
            setIds(null)
            Setuplist()
            handleClose()
          })
          .catch((error) => {
            console.log("", error)
          })
      : axios
          .post(`api/admin/multivendor-store-pay-payment-accepted-by-credentials/`, reqbody, {
            headers: {
              Token: localStorage.getItem("Token"),
            },
          })
          .then((response) => {
            toast.success(response?.data?.message)
            setKey("")
            setSecret("")
            setStatus("")
            setPayment("")
            setIds(null)
            Setuplist()
            handleClose()
          })
          .catch((error) => {
            console.log("", error)
          })
  }

  const paymentGetwayData = () => {
    axios
      .get(`api/admin/multivendor-store-payment-accepted-by-list`, {
        headers: {
          Token: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        setPaymentMethod(response?.data?.store_payment_accepted_by_list)
      })
      .catch((error) => {
        console.log("", error)
      })
  }

  useEffect(() => {
    paymentGetwayData()
  }, [])

  return (
    <div className=" !bg-slate-100">
      <div className="flex justify-between">
        <div className="p-2">
          <p className="text-xl !text-black font font-bold">SetUp Credentials</p>
        </div>{" "}
        <div className="p-2">
          <p className="text-xl !text-black font font-bold">
            <Button onClick={handleOpen} size="small" variant="outlined">
              <Add /> ADD
            </Button>
          </p>
        </div>{" "}
      </div>
      <TableContainer
        component={Paper}
        // sx={{  }}
        sx={{ width: "100%", maxHeight: 440 }}
        className="!text-black !mx-auto !bg-white overflow-auto px-2"
      >
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  borderRight: "1px solid #D0D0D0",
                  fontFamily: "'Poppins', sans-serif",
                }}
                className="!text-center !text-gray-800 !font-bold"
              >
                ID
              </TableCell>
              <TableCell
                style={{
                  borderRight: "1px solid #D0D0D0",
                  fontFamily: "'Poppins', sans-serif",
                }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Store
              </TableCell>
              <TableCell
                style={{
                  borderRight: "1px solid #D0D0D0",
                  fontFamily: "'Poppins', sans-serif",
                }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Key /MID
              </TableCell>
              <TableCell
                style={{
                  borderRight: "1px solid #D0D0D0",
                  fontFamily: "'Poppins', sans-serif",
                }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Secret / Salt Key
              </TableCell>
              <TableCell
                style={{
                  borderRight: "1px solid #D0D0D0",
                  fontFamily: "'Poppins', sans-serif",
                }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Payment Gateway
              </TableCell>
              <TableCell
                style={{
                  borderRight: "1px solid #D0D0D0",
                  fontFamily: "'Poppins', sans-serif",
                }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Status
              </TableCell>

              <TableCell
                style={{
                  fontFamily: "'Poppins', sans-serif",
                }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="!bg-red">
            {data &&
              data?.map((row) => (
                <TableRow className="!text-slate-700">
                  <TableCell
                    className="!text-center !text-[12px]"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    {row?.id}
                  </TableCell>
                  <TableCell
                    className="!text-center !text-[12px]"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    {row?.store?.store_name}
                  </TableCell>
                  <TableCell
                    className="!text-center !text-[12px]"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    {row?.key}
                  </TableCell>
                  <TableCell
                    className="!text-center !text-[12px]"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    {row?.secret}
                  </TableCell>
                  <TableCell
                    className="!text-center !text-[12px]"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    {row?.store_payment_Accepted_by?.payment_gateway}
                  </TableCell>
                  <TableCell
                    className="!text-center !text-[12px]"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    <div className="flex justify-center w-full p-4">
                      {row?.status === null ? <Unmaped /> : row?.status === true ? <Active /> : <Inactive />}
                    </div>
                  </TableCell>

                  <TableCell
                    className="!text-center !text-[12px]"
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    <div>
                      <BorderColorIcon className="text-green-400" onClick={(event) => editFunctionality(row)} />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        className="!float-right relative bg-white w-[100%] mx-auto bottom-0 right-0 py-3 border-t-2 border-gray-400 !flex-col !justify-end !items-center"
        style={{ marginRight: "0px" }}
      ></div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 500 }} className="rounded-2xl !border-0">
          <div id="child-modal-title " className="!text-black !underline !font-bold !py-3 !text-center !text-xl">
            {ids === null ? "Add Credentials" : "Update Credentials"}
          </div>
          <div className=" ">
            <div className="">
              <div>Payment Method</div>

              {ids === null ? (
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    className="mt-1"
                    value={payment}
                    onChange={(event) => setPayment(event.target.value)}
                  >
                    {paymentMethod === ""
                      ? null
                      : paymentMethod?.map((elem) => <MenuItem value={elem?.id}>{elem?.payment_gateway}</MenuItem>)}
                  </Select>
                </FormControl>
              ) : (
                <FormControl fullWidth disabled>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    className="mt-1"
                    value={payment}
                    onChange={(event) => setPayment(event.target.value)}
                  >
                    {paymentMethod === ""
                      ? null
                      : paymentMethod?.map((elem) => <MenuItem value={elem?.id}>{elem?.payment_gateway}</MenuItem>)}
                  </Select>
                </FormControl>
              )}
              <div className="mt-2">Key (MID)</div>
              <TextField
                size="small"
                id="outlined-basic"
                onChange={(event) => setKey(event.target.value)}
                className="!w-[100%] !mt-1"
                value={key}
              />
            </div>
            <div className="">
              <div className="mt-2">Secret (Salt Key)</div>
              <TextField
                size="small"
                id="outlined-basic"
                className="!w-[100%] !mt-1"
                value={secret}
                onChange={(event) => setSecret(event.target.value)}
              />
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label" className="!mt-2 !text-black">
                  Active Status
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={status}
                  onChange={(event) => setStatus(event.target.value)}
                >
                  <FormControlLabel value="True" control={<Radio />} label="True" />
                  <FormControlLabel value="False" control={<Radio />} label="False" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <Button onClick={handleModalClose} variant="contained" color="warning" className="!py-2 !mt-3">
            CANCEL
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="success"
            className="!py-2 !px-5 !font-bold !mt-3 !float-right"
            onClick={submitData}
          >
            Update
          </Button>
        </Box>
      </Modal>
      {/* <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 400 }} className="rounded-2xl !border-0">
          <div
            id="child-modal-title "
            className="!text-black !underline !font-bold !py-3 !text-center !text-xl"
          >
            ADD Credentials
          </div>
          <div className=" ">
            <div className="">
              <div>Payment Method</div>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  className="mt-1"
                  value={payment}
                  onChange={(event) => setPayment(event.target.value)}
                >
                  {paymentMethod === ""
                    ? null
                    : paymentMethod?.map((elem) => (
                        <MenuItem value={elem?.id}>
                          {elem?.payment_gateway}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>

              <div className="mt-2">Key</div>
              <TextField
                size="small"
                id="outlined-basic"
                onChange={(event) => setKey(event.target.value)}
                className="!w-[100%] !mt-1"
                value={key}
              />
            </div>
            <div className="">
              <TextField
                size="small"
                id="outlined-basic"
                label="Secret"
                variant="outlined"
                className="!w-[100%] !mt-3"
                value={secret}
                onChange={(event) => setSecret(event.target.value)}
              />

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Active Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                  size="small"
                  className="mt-3"
                  value={status}
                  onChange={(event) => setStatus(event.target.value)}
                >
                  <MenuItem value={"True"}>True</MenuItem>
                  <MenuItem value={"False"}>False</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <Button
            onClick={handleModalClose1}
            variant="contained"
            color="warning"
            className="!py-2 !mt-3"
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="success"
            className="!py-2 !px-5 !font-bold !mt-3 !float-right"
            onClick={Setadd}
          >
            Add
          </Button>
        </Box>
      </Modal> */}
    </div>
  )
}

export default CradiantialSetup
