import { Close, Delete, Edit, InfoOutlined } from "@mui/icons-material";
import {
  Alert,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Pagination,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import AddNewERPTemplate from "./AddNewERPTemplate";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { templateListFn } from "../../Services/Templates";
import { deleteErpTemplateFn } from "../../Services/Templates/ERPTemplate";
import { toast } from "react-toastify";
import {
  addTemplateApprovalFn,
  deleteTemplateApprovalFn,
  updateTemplateApprovalFn,
} from "../../Services/Templates/TemplateApproval";

const DefaultTemplates = () => {
  const [page, setPage] = useState(1);
  const [response, setResponse] = useState({});
  const [templateType, setTemplateType] = useState("Custom");
  const client = useQueryClient();
  const [isAbout, setIsAbout] = useState(false);
  const [rowId, setRowId] = useState("");

  const handleChange = (event, value) => {
    setPage(value);
  };

  const { data: templates, isLoading } = useQuery(
    ["templates", page, templateType],
    () => templateListFn({ page: page, template_type: templateType }),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
  const { mutate: addTemplateApproval, isLoading: isLoadingAddStatus } =
    useMutation(addTemplateApprovalFn, {
      onSuccess: (response) => {
        if (response?.data?.response_code === 200) {
          toast.success(response?.data?.message);
          client.refetchQueries("templates");
          if (JSON.parse(response?.data?.data)?.error) {
            toast.error(
              `Type : ${JSON.parse(response?.data?.data)?.error?.type} : ${
                JSON.parse(response?.data?.data)?.error?.error_user_msg
              }`
            );
          }
        }
      },
    });
  const { mutate: updateTemplateApproval, isLoading: isLoadingUpdateStatus } =
    useMutation(updateTemplateApprovalFn, {
      onSuccess: (response) => {
        if (response?.data?.response_code === 200) {
          toast.success(response?.data?.message);
          client.refetchQueries("templates");
          setIsAbout(false);
          if (JSON.parse(response?.data?.data)?.error) {
            toast.error(
              `Type : ${JSON.parse(response?.data?.data)?.error?.type} : ${
                JSON.parse(response?.data?.data)?.error?.error_user_msg
              }`
            );
          }
        }
      },
    });
  const { mutate: deleteTemplateApproval, isLoading: isLoadingDeleteStatus } =
    useMutation(deleteTemplateApprovalFn, {
      onSuccess: (response) => {
        if (response?.data?.response_code === 200) {
          toast.success(response?.data?.message);
          client.refetchQueries("templates");
          setIsAbout(false);
          if (JSON.parse(response?.data?.data)?.error) {
            toast.error(
              `Type : ${JSON.parse(response?.data?.data)?.error?.type} : ${
                JSON.parse(response?.data?.data)?.error?.error_user_msg
              }`
            );
          }
        }
      },
    });

  const { mutate: deleteErpTemplate } = useMutation(deleteErpTemplateFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message);
        client.refetchQueries("templates");
      } else {
        toast.error(response.data.message);
      }
    },
  });

  return (
    <>
      <div className="">
        <div className="flex items-center justify-between p-4">
          <div>
            <p className="text-xl font-bold text-black">
              Default Template List
            </p>
            <p className="">Manage Your Default Templates</p>
          </div>
          <AddNewERPTemplate response={response} setResponse={setResponse} />
        </div>
        <div className="p-3">
          <span className="flex justify-end gap-2 py-2">
            <Chip
              label="Custom"
              color={templateType === "Custom" ? "info" : "default"}
              onClick={() => setTemplateType("Custom")}
              className="!rounded"
            />
            <Chip
              label="Fixed"
              color={templateType === "Fixed" ? "info" : "default"}
              onClick={() => setTemplateType("Fixed")}
              className="!rounded"
            />
          </span>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow className="!bg-gradient-to-tr from-blue-500 to-blue-800">
                  <TableCell className="!text-center !p-3 !text-white !font-bold">
                    Template Name
                  </TableCell>
                  <TableCell className="!text-center !p-3 !text-white !font-bold">
                    Template Type
                  </TableCell>
                  <TableCell className="!text-center !p-3 !text-white !font-bold">
                    Template Response Type
                  </TableCell>
                  {templateType === "Fixed" && (
                    <>
                      <TableCell className="!text-center !p-3 !text-white !font-bold">
                        Status
                      </TableCell>
                      <TableCell className="!text-center !p-3 !text-white !font-bold">
                        Action
                      </TableCell>
                    </>
                  )}
                  <TableCell className="!text-center !p-3 !text-white !font-bold">
                    Update/Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? [1, 2, 3, 5, 6, 7, 8]?.map(() => {
                      return (
                        <TableRow>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          {templateType === "Fixed" && (
                            <>
                              <TableCell>
                                <Skeleton />
                              </TableCell>
                              <TableCell>
                                <Skeleton />
                              </TableCell>
                            </>
                          )}
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : templates?.data?.data?.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell className="!text-center !p-2">
                          {row.name}
                        </TableCell>
                        <TableCell className="!text-center !p-2">
                          {row?.template_type}
                        </TableCell>
                        <TableCell className="capitalize !text-center !p-2">
                          {row?.message_object_type}
                        </TableCell>
                        {templateType === "Fixed" && (
                          <>
                            <TableCell className="!text-center !p-2">
                              <Chip
                                variant="outlined"
                                label={row?.status}
                                color={
                                  row?.status === "APPROVED"
                                    ? "success"
                                    : row?.status === "REJECTED"
                                    ? "error"
                                    : row?.status === "NEW"
                                    ? "info"
                                    : "warning"
                                }
                              />
                            </TableCell>

                            <TableCell>
                              <Button
                                variant="contained"
                                color={
                                  row?.status === "APPROVED"
                                    ? "success"
                                    : row?.status === "REJECTED"
                                    ? "error"
                                    : row?.status === "NEW"
                                    ? "info"
                                    : "warning"
                                }
                                className="!capitalize !rounded-full !w-24"
                                size="small"
                                disabled={row?.status === "PENDING"}
                                onClick={() => {
                                  row?.status === "APPROVED"
                                    ? setIsAbout(true)
                                    : row?.status === "REJECTED"
                                    ? updateTemplateApproval({
                                        template_id: String(row?.id),
                                      })
                                    : row?.status === "NEW" &&
                                      addTemplateApproval({
                                        template_id: String(row?.id),
                                      });
                                  setRowId(row?.id);
                                }}
                              >
                                {row?.status === "APPROVED"
                                  ? "Update"
                                  : row?.status === "REJECTED"
                                  ? "Retry"
                                  : row?.status === "NEW"
                                  ? "Request"
                                  : row?.status}
                              </Button>
                            </TableCell>
                          </>
                        )}

                        <TableCell className="!text-center !p-2">
                          <span className="flex items-center justify-center w-full gap-2">
                            <IconButton onClick={() => setResponse(row)}>
                              <Edit />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                row?.template_type === "Fixed"
                                  ? deleteTemplateApproval({
                                      template_id: row?.id,
                                    })
                                  : deleteErpTemplate({ template_id: row?.id })
                              }
                            >
                              <Delete />
                            </IconButton>
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {templates?.data?.total_pages > 1 && (
              <span className="flex justify-end p-2">
                <Pagination
                  variant="outlined"
                  count={templates?.data?.total_pages || 0}
                  page={templates?.data?.current_page || 0}
                  onChange={handleChange}
                />
              </span>
            )}
          </TableContainer>
        </div>
      </div>
      <Dialog open={isAbout} onClose={() => setIsAbout(false)}>
        <span className="flex items-center justify-between pl-3">
          <p className="text-xs">Comment edits</p>
          <IconButton size="small" onClick={() => setIsAbout(false)}>
            <Close />
          </IconButton>
        </span>
        <Divider />
        <div className="flex flex-col gap-2 p-3">
          <div className="flex gap-2 h-12 px-2 items-center !border-l-4 rounded !border-blue-500 !shadow !text-black !bg-white">
            <InfoOutlined className="text-blue-500" /> You can only edit a
            template once in 24 hours.
          </div>
          <p className="p-1">
            Are you sure that you want to submit the content for English (US)?
          </p>
          <p className="p-1">
            If these edits are rejected, you can edit the template to match a
            previously approved version. This will allow you to continue sending
            the template.
          </p>
        </div>
        <span className="flex justify-end gap-2 p-2">
          <Button
            disableElevation
            variant="contained"
            size="small"
            color="inherit"
            className="!capitalize"
          >
            Cancel
          </Button>
          <Button
            disableElevation
            variant="contained"
            size="small"
            className="!capitalize !bg-blue-500"
            disabled={isLoadingUpdateStatus}
            onClick={() =>
              updateTemplateApproval({
                template_id: rowId,
              })
            }
          >
            Confirm
          </Button>
        </span>
      </Dialog>
    </>
  );
};

export default DefaultTemplates;
