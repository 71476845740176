import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"

export const learningZoneFn = (redBody) => {
  try {
    const response = axiosInstance.get(API_URLS.learningZone, { params: redBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const addLearningZoneFn = (redBody) => {
  try {
    const response = axiosInstance.post(API_URLS.learningZone, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateLearningZoneFn = (redBody) => {
  try {
    const response = axiosInstance.put(API_URLS.learningZone, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const deleteLearningZoneFn = (redBody) => {
  try {
    const response = axiosInstance.delete(API_URLS.learningZone, { data: redBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
