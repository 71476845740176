import { Switch, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { paymentGatewayFn, updateGatewayFn } from "Services/Settings/PaymentGateway"
import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { Active, Inactive } from "shared/ActivaInactive"
import GlassDiv from "shared/CustomDiv"
import CustomPagination from "shared/CustomPagination"
import TableCell from "shared/MkxTable/MkxTableCell"
import { MkxTableLoader } from "shared/MkxTable/MkxTableLoader"
import NoDataFound from "shared/NoDataFound"

const AvailablePayment = () => {
  const { store_id } = useParams()
  const [page, setPage] = useState(1)
  const client = useQueryClient()

  const { data: paymentGateways, isLoading } = useQuery(["paymentGateways", store_id, page], () =>
    paymentGatewayFn({ page, store_id })
  )

  const { mutate: updateStatus } = useMutation(updateGatewayFn, {
    onSuccess: (response) => {
      if (response.data.response_code == 200) {
        toast.success(response.data.message)
        client.refetchQueries("paymentGateways")
      } else {
        toast.error(response.data.message)
      }
    },
  })

  return (
    <div className="flex flex-col h-full p-1 bg-gray-200">
      <GlassDiv className="flex h-full flex-col gap-2 !p-0">
        <p className="p-2 text-xl font-semibold">Payment Gateways</p>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell isHead>SNo</TableCell>
                <TableCell isHead>Payment Gateway</TableCell>
                <TableCell isHead>Status</TableCell>
                <TableCell isHead>Enabled/Disabled</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <MkxTableLoader loading={isLoading} row={3} />
              {paymentGateways?.data?.data?.map((item, index) => (
                <TableRow className="hover:!bg-white hover:!bg-opacity-40 cursor-pointer">
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item?.payment_gateway}</TableCell>
                  <TableCell>
                    <span className="flex items-center justify-center">
                      {item?.status === true ? <Active /> : <Inactive />}
                    </span>
                  </TableCell>

                  <TableCell>
                    <Switch
                      checked={item.status}
                      onChange={() => updateStatus({ store_id, payment_gateway_id: item?.id })}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <CustomPagination data={paymentGateways} setPage={setPage} />
          <NoDataFound data={paymentGateways} />
        </TableContainer>
      </GlassDiv>
    </div>
  )
}
export default AvailablePayment
