import { DeleteTwoTone, FilterAlt, ReportTwoTone } from "@mui/icons-material"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import EditIcon from "@mui/icons-material/Edit"
import {
  Checkbox,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Modal from "@mui/material/Modal"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import axios from "config/axios"
import React, { useEffect, useState } from "react"
import { CustomIconButton } from "../../../shared/CustomIconButton"

import AddIcon from "@mui/icons-material/Add"

import { toast } from "react-toastify"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  rounded: 2,
}

const ProductBrand = () => {
  const [image, setImage] = useState("")
  const [description, setDescription] = useState("")
  const [metaTitle, setMetaTitle] = useState("")
  const [metaDecription, setMetaDescription] = useState("")
  const [metaKeyword, setMetaKeyword] = useState("")
  const [productData, setProductData] = useState([])
  const [open1, setOpen1] = React.useState(false)
  const [page, setPage] = React.useState(1)
  const [rowData, setRowData] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [category, setCategory] = useState([])
  const [categoryId, setCategoryId] = useState(null)
  const [SubCategoryName, setSubCategoryName] = useState("")
  const [subCategoryId, setSubCategoryId] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])
  const [isDelete, setIsDelete] = useState(false)

  const handleChange = (e) => {
    setImage(e.target.files[0])
  }
  const CategoryList = () => {
    axios
      .get(`category-list-api-for-admin/`)
      .then((response) => {
        setCategory(response.data?.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const submitCategory = () => {
    const fd = new FormData()
    fd.append("category_id", categoryId || rowData?.category?.id)
    fd.append("sub_category_id", subCategoryId)
    fd.append("product_sub_category_name", SubCategoryName)
    fd.append("description", description)
    fd.append("image", image)
    fd.append("meta_title", metaTitle)
    fd.append("meta_description", metaDecription)
    fd.append("meta_keyword", metaKeyword)

    rowData.length === 0
      ? axios
          .post(`add-product-sub-category-data/`, fd)
          .then((response) => {
            toast.success(response.data.message)
            setImage("")
            setDescription("")
            setMetaTitle("")
            setMetaDescription("")
            setMetaKeyword("")
            setSubCategoryId(null)
            ProductDataList()
            handleClose1()
          })
          .catch((error) => {
            console.log(error)
          })
      : axios
          .post(`edit-product-sub-category-data/`, fd)
          .then((response) => {
            toast.success(response.data.message)
            setImage("")
            setDescription("")
            setMetaTitle("")
            setMetaDescription("")
            setMetaKeyword("")
            setSubCategoryId(null)
            setRowData([])
            ProductDataList()
            handleClose1()
            ProductDataList()
          })
          .catch((error) => {
            console.log(error)
          })
  }

  const previousData = () => setPage(page - 1)

  const nextData = () => setPage(page + 1)

  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => setOpen1(false)

  useEffect(() => {
    CategoryList()
  }, [])

  useEffect(() => {
    rowData && rowData?.length !== 0 && setSubCategoryName(rowData?.title)
    setDescription(rowData?.description)
    setMetaDescription(rowData?.meta_description)
    setMetaKeyword(rowData?.meta_keyword)
    setMetaTitle(rowData?.meta_title)
  }, [rowData])

  const ProductDataList = () => {
    searchQuery === ""
      ? axios
          .get(`product-sub-category-list-data/?page=${page}&category_id&product_sub_category_name=`)
          .then((response) => {
            setCurrentPage(response?.data?.current_page)
            setTotalPage(response?.data?.total_pages)
            setProductData(response.data.data)
          })
          .catch((error) => {
            console.log("", error)
          })
      : axios
          .get(`sub-category-search-api-for-admin/?search_value=${searchQuery}`)
          .then((response) => {
            setProductData(response.data.data)
          })
          .catch((error) => {
            // console.log("data not found why=====>", error);
          })
  }

  const EditFunction = (row) => {
    setRowData(row)
    setSubCategoryId(row?.id)
  }
  useEffect(() => {
    ProductDataList()
  }, [page, searchQuery])

  const handleCloseModal = () => {
    setRowData([])
    setDescription("")
    setMetaDescription("")
    setMetaKeyword("")
    setMetaTitle("")
    setCategoryId(null)
    setSubCategoryName("")
    setSubCategoryId(null)
    handleClose1()
  }

  const handleAllRowSelect = (event) => {
    setSelectedIds(event.target.checked ? productData.map((i) => i.id) : [])
  }
  const handleSelectRow = (id) => {
    setSelectedIds((prevSelectedRow) =>
      prevSelectedRow.includes(id) ? prevSelectedRow.filter((i) => i !== id) : [...prevSelectedRow, id]
    )
  }

  const handleDelete = () => {
    axios
      .post(`delete-sub-category-api-for-superadmin/`, { sub_category_ids: selectedIds })
      .then((response) => {
        ProductDataList()
        setIsDelete(false)
        setSelectedIds([])
        toast.success(response.success.message)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const hadleClearAll = () => {
    setIsDelete(false)
    setSelectedIds([])
  }
  return (
    <div className=" !bg-slate-100">
      <div className="flex justify-between">
        <div className="p-2">
          <p className="text-xl !text-black font font-bold">Sub Category List</p>
        </div>
      </div>
      <div className="flex flex-col !rounded !text-black bg-slate-100 shadow-card shadow-zinc-600 m-2">
        <div className="flex items-center justify-between my-2">
          <span className="flex gap-3">
            <CustomIconButton>
              <FilterAlt className="!text-black" />
            </CustomIconButton>
            <input
              class="placeholder:italic placeholder:text-slate-400 block bg-white w-80 border border-slate-300 rounded-md py-2 px-2 pr-3 shadow-sm focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
              placeholder="Search for anything..."
              type="text"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
          </span>
          <span>
            <div className="flex mx-3 text-red-500 gap-x-4">
              <div>
                {selectedIds?.length !== 0 && (
                  <>
                    <Button
                      startIcon={<DeleteTwoTone />}
                      className="!text-white bg-gradient-to-r from-red-500 to-rose-500"
                      onClick={() => setIsDelete(true)}
                    >
                      Delete
                    </Button>
                    <Dialog
                      open={isDelete}
                      onClose={() => setIsDelete(false)}
                      PaperProps={{ className: "!max-w-[500px] w-96" }}
                    >
                      <div className="flex flex-col items-center gap-3 p-5">
                        <ReportTwoTone color="error" className="!text-5xl" />
                        <p className="text-xl">Are you sure!</p>
                        <p>You want to delete selected products?</p>
                      </div>
                      <span className="flex justify-end gap-3 pb-3 pr-3">
                        <Button
                          size="small"
                          variant="outlined"
                          color="inherit"
                          onClick={hadleClearAll}
                          className="!capitalize"
                        >
                          Cancel
                        </Button>
                        <Button
                          size="small"
                          disableElevation
                          color="error"
                          className="!capitalize"
                          variant="contained"
                          onClick={() => handleDelete()}
                        >
                          Delete
                        </Button>
                      </span>
                    </Dialog>
                  </>
                )}
              </div>
              <Button variant="outlined" color="primary" onClick={handleOpen1}>
                <AddIcon /> Add Product sub Category
              </Button>
              <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
              >
                <Box sx={{ ...style, width: 700 }} className="rounded-2xl !border-0">
                  <div
                    id="child-modal-title "
                    className="!text-black !underline !font-bold !py-3 !text-center !text-xl"
                  >
                    {subCategoryId === null ? "Add Product Sub Category" : "Update Product Sub Category"}
                  </div>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Category Name</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      variant="outlined"
                      className="!mt-2"
                      value={"" || rowData?.category?.id}
                      onChange={(event) => setCategoryId(event.target.value)}
                    >
                      {category?.map((res) => {
                        return <MenuItem value={res.id}>{res.category}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  <div className="flex">
                    <div className="w-[50%]">
                      <TextField
                        size="small"
                        id="outlined-basic"
                        variant="outlined"
                        label="Product Sub Category Name"
                        className="!w-[100%] !mt-3"
                        value={SubCategoryName}
                        onChange={(event) => setSubCategoryName(event.target.value)}
                      />
                      <br />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        label="description"
                        className="!w-[100%] !mt-3"
                      />
                      <br />
                      <TextField
                        id="outlined-basic"
                        size="small"
                        onChange={(event) => metaDecription(event.target.value)}
                        value={metaDecription}
                        label="Meta Description"
                        variant="outlined"
                        className="!w-[100%] !mt-3"
                      />
                      <br />
                    </div>
                    <div className="w-[50%]">
                      <TextField
                        id="outlined-basic"
                        type="file"
                        size="small"
                        onChange={handleChange}
                        className="!w-[100%] !mt-3 !m-1"
                      />
                      <br />
                      <TextField
                        id="outlined-basic"
                        size="small"
                        onChange={(event) => setMetaTitle(event.target.value)}
                        value={metaTitle}
                        variant="outlined"
                        label="meta title"
                        className="!w-[100%] !mt-2 !m-1"
                      />
                      <br />
                      <TextField
                        id="outlined-basic"
                        label="Meta Keyword"
                        onChange={(event) => setMetaKeyword(event.target.value)}
                        value={metaKeyword}
                        size="small"
                        variant="outlined"
                        className="!w-[100%] !mt-2 !m-1"
                      />
                      <br />
                    </div>
                  </div>
                  <Button onClick={handleCloseModal} variant="contained" color="warning" className="!py-2 !mt-3">
                    CANCEL
                  </Button>
                  {subCategoryId === null ? (
                    <Button
                      variant="contained"
                      color="success"
                      className="!py-2 !px-5 !font-bold !mt-3 !float-right"
                      onClick={submitCategory}
                    >
                      ADD
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="success"
                      className="!py-2 !px-5 !mt-3 !font-bold !float-right"
                      onClick={submitCategory}
                    >
                      UPDATE
                    </Button>
                  )}
                </Box>
              </Modal>
            </div>
          </span>
        </div>
        <TableContainer
          component={Paper}
          // sx={{  }}
          sx={{ width: "100%", maxHeight: 440 }}
          className="!text-black !mx-auto !bg-white overflow-auto px-2"
        >
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  <Checkbox onChange={handleAllRowSelect} />
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  SUB CATEGORY
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  CATEGORY
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  BUSINESS TYPE
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  SUB BUSINESS TYPE
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  IMAGE
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="!bg-red">
              {productData &&
                productData?.map((row, index) => (
                  <TableRow key={row.id} className="!text-slate-700">
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      <Checkbox
                        value={row?.id}
                        checked={selectedIds.filter((id) => id === row?.id)?.length === 1 ? true : false}
                        onChange={() => handleSelectRow(row.id)}
                      />
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row.title ? row.title : "--"}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.category?.title ? row?.category?.title : "--"}
                    </TableCell>

                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.category?.business_type?.category?.name
                        ? row?.category?.business_type?.category?.name
                        : "--"}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.category?.business_type?.name ? row?.category?.business_type?.name : "--"}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.image ? <img src={row?.image} className="mx-auto h-7 w-7" /> : "--"}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      <button onClick={handleOpen1}>
                        <EditIcon
                          className="!text-xl !cursor-pointer !text-green-600"
                          onClick={() => EditFunction(row)}
                        />
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className="!float-right relative bg-white w-[100%] mx-auto bottom-0 right-0 py-3 border-t-2 border-gray-400 !flex-col !justify-end !items-center"
          style={{ marginRight: "0px" }}
        >
          <button onClick={nextData} className="!float-right">
            {currentPage}...{totalPage}
            <ArrowForwardIosIcon />
          </button>
          <button onClick={previousData} className="float-right">
            <ArrowBackIosIcon />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductBrand
