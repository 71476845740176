import { FilterAlt } from "@mui/icons-material"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import Modal from "@mui/material/Modal"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import axios from "config/axios"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { CustomIconButton } from "../../shared/CustomIconButton"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

const BackupRestore = () => {
  const [page, setPage] = useState(1)
  const [moduleData, setModuleData] = useState([])
  const [modelData, ModelData] = useState([])
  const [storeData, setStoreData] = useState([])
  const [moduleName, setModuleName] = useState("")
  const [modelName, setModelName] = useState("")
  const [storeId, setStoreId] = useState(null)
  const [open, setOpen] = React.useState(false)
  const [backupData, setBackupData] = useState([])
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [getData, setGetData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(null)

  const [open1, setOpen1] = React.useState(false)
  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => setOpen1(false)

  const [model1, setModel1] = useState("")
  const [url1, setUrl1] = useState("")
  const [storeId1, setStoreId1] = useState(null)

  const [model2, setModel2] = useState("")
  const [url2, setUrl2] = useState("")
  const previousData = () => {
    setPage(page - 1)
  }
  const nextData = () => {
    setPage(page + 1)
  }

  const backupModuleList = () => {
    axios
      .get(`backup-module-list-api/`)
      .then((response) => {
        setModuleData(response?.data?.data)
      })
      .catch((error) => {
        console.log("", error)
        toast.success(error?.data?.token)
      })
  }
  useEffect(() => {
    backupModuleList()
  }, [open])

  const ModelDataList = () => {
    axios
      .post(`backup-module-list-api/`, {
        app_label: moduleName,
      })
      .then((response) => {
        ModelData(response?.data?.data)
        // console.log("model data list===>",response?.data?.data)
      })
      .catch((error) => {
        console.log("", error)
        toast.success(error?.data?.token)
      })
  }
  useEffect(() => {
    moduleName && ModelDataList()
  }, [moduleName])

  const storeDataList = () => {
    axios
      .get(`store-list-data-api/`)
      .then((response) => {
        // setModuleData(response?.data?.data);
        console.log("store data list===>", response?.data?.data)
        setStoreData(response?.data?.data)
      })
      .catch((error) => {
        console.log("", error)
        toast.success(error?.data?.token)
      })
  }

  useEffect(() => {
    storeDataList()
  }, [])

  const backupDataList = () => {
    axios
      .post(`backup-stored-data-list-api/`, {
        model_name: modelName,
        store_id: storeId,
      })
      .then((response) => {
        // ModelData(response?.data?.data);
        console.log("backup data list===>", response?.data?.data)
        setBackupData(response?.data?.data)
      })
      .catch((error) => {
        console.log("", error)
        toast.success(error?.data?.token)
      })
  }

  useEffect(() => {
    storeId && backupDataList()
  }, [storeId, modelName])

  const getBackupData = () => {
    axios
      .get(`backup-stored-data-list-api/?page=${page}`)
      .then((response) => {
        console.log("get backup data list===>", response?.data)
        setCurrentPage(response?.data?.current_page)
        setTotalPage(response?.data?.total_pages)
        setGetData(response?.data?.data)
      })
      .catch((error) => {
        console.log("", error)
        toast.success(error?.data?.token)
      })
  }
  useEffect(() => {
    getBackupData()
  }, [page])

  const handleChange = (event) => {
    const { value } = event.target
    console.log("model data==>", value.model)
    setModel1(value.model)
    setUrl1(value.url)
  }

  const saveBackup = () => {
    const reqBody = {
      store_id: storeId1,
      model_name: model1,
      app_label: moduleName,
    }
    axios
      .post(`${url1}`, reqBody)
      .then((response) => {
        toast.success(response?.data?.message)
        getBackupData()
        handleClose()
      })
      .catch((error) => {
        console.log("", error)
        toast.error("You should not backup of this module")
      })
  }

  const handleChange1 = (event) => {
    const { value } = event.target
    console.log("model data==>", value)
    setModel2(value.id)
    setUrl2(value.url)
  }

  const saveRestore = () => {
    const reqBody = {
      app_label: moduleName,
      model_name: modelName,
      store_id: storeId,
      stored_data_id: model2,
    }
    axios
      .post(`${url2}`, reqBody)
      .then((response) => {
        toast.success(response?.data?.message)
        getBackupData()
        handleClose()
      })
      .catch((error) => {
        console.log("", error)
        toast.error("You should not backup of this module")
      })
  }

  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font-bold text-black">Backup & Restore</p>
            {/* <p className="text-black">View/Search Delivery Management</p> */}
          </div>
          <div className="p-2 pt-3">
            <Button variant="outlined" className="!mr-3" onClick={handleOpen}>
              Backup
            </Button>
            <Button variant="outlined" onClick={handleOpen1}>
              restore
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="!border-0 !rounded-xl">
                <Typography id="modal-modal-title" variant="h6" component="h6">
                  <div
                    className="!text-center underline underline-offset-4"
                    style={{ fontFamily: "'Poppins', sans-serif" }}
                  >
                    Backup
                  </div>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div className="gap-y-3"></div>
                  <div className="gap-2">
                    <InputLabel className="!mt-1 !text-gray-700" style={{ fontFamily: "'Poppins', sans-serif" }}>
                      <div className="my-1 text-sm">Module :</div>
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        size="small"
                        placeholder="select your status"
                        onChange={(event) => setModuleName(event.target.value)}
                      >
                        {moduleData?.map((elem) => (
                          <MenuItem value={elem?.app_label}>{elem?.module_name}</MenuItem>
                        ))}

                        {/* <MenuItem value={"Replacement Completed"}>Replacement Completed</MenuItem> */}
                      </Select>
                    </FormControl>

                    <InputLabel className=" !text-gray-700" style={{ fontFamily: "'Poppins', sans-serif" }}>
                      <div className="my-1 text-sm">Model :</div>
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        size="small"
                        placeholder="select your status"
                        onChange={handleChange}
                      >
                        {modelData?.map((elem, index) => (
                          <MenuItem key={index} value={{ model: elem?.model, url: elem?.url }}>
                            {elem?.model_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <InputLabel className=" !text-gray-700" style={{ fontFamily: "'Poppins', sans-serif" }}>
                      <div className="my-1 text-sm">Store :</div>
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        size="small"
                        placeholder="select your status"
                        onChange={(event) => setStoreId1(event.target.value)}
                      >
                        {storeData?.map((elem) => (
                          <MenuItem value={elem?.id}>{elem?.store_name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="items-center justify-between ">
                    <Button className="float-left top-3" variant="contained" color="warning" onClick={handleClose}>
                      CANCEL
                    </Button>

                    <Button variant="contained" color="success" className="float-right mr-3 top-3" onClick={saveBackup}>
                      SAVE
                    </Button>
                  </div>
                </Typography>
              </Box>
            </Modal>
            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="!border-0 !rounded-xl">
                <Typography id="modal-modal-title" variant="h6" component="h6">
                  <div
                    className="!text-center underline underline-offset-4"
                    style={{ fontFamily: "'Poppins', sans-serif" }}
                  >
                    Restore
                  </div>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div className="gap-y-3"></div>
                  <div className="gap-2">
                    <InputLabel className=" !text-gray-700" style={{ fontFamily: "'Poppins', sans-serif" }}>
                      <div className="my-1 text-sm">Store :</div>
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        size="small"
                        placeholder="select your status"
                        onChange={(event) => setStoreId(event.target.value)}
                      >
                        {storeData?.map((elem) => (
                          <MenuItem value={elem?.id}>{elem?.store_name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <InputLabel className="!mt-1 !text-gray-700" style={{ fontFamily: "'Poppins', sans-serif" }}>
                      <div className="my-1 text-sm">Module :</div>
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        size="small"
                        placeholder="select your status"
                        onChange={(event) => setModuleName(event.target.value)}
                      >
                        {moduleData?.map((elem) => (
                          <MenuItem value={elem?.app_label}>{elem?.module_name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <InputLabel className=" !text-gray-700" style={{ fontFamily: "'Poppins', sans-serif" }}>
                      <div className="my-1 text-sm">Model :</div>
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        size="small"
                        placeholder="select your status"
                        onChange={(event) => setModelName(event.target.value)}
                      >
                        {modelData?.map((elem) => (
                          <MenuItem value={elem?.model}>{elem?.model_name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <InputLabel className=" !text-gray-700" style={{ fontFamily: "'Poppins', sans-serif" }}>
                      <div className="my-1 text-sm">Backup Data :</div>
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        size="small"
                        placeholder="select your status"
                        onChange={handleChange1}
                      >
                        {backupData?.map((elem, index) => (
                          <MenuItem key={index} value={{ id: elem?.id, url: elem?.restore_url }}>
                            {elem?.model_name}({elem?.date})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="items-center justify-between ">
                    <Button className="float-left top-3" variant="contained" color="warning" onClick={handleClose1}>
                      CANCEL
                    </Button>

                    <Button
                      variant="contained"
                      color="success"
                      className="float-right mr-3 top-3"
                      onClick={saveRestore}
                    >
                      SAVE
                    </Button>
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
        <div className="flex flex-col !rounded bg-secondary border-secondary shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center py-2 bg-gray-100 rounded">
            <CustomIconButton>
              <FilterAlt className="text-black" />
            </CustomIconButton>
            <div>
              <input type="text" className="border border-black" />
            </div>
          </div>
          <TableContainer
            component={Paper}
            sx={{ width: "100%", maxHeight: 440 }}
            className="!text-black !mx-auto !bg-white overflow-auto px-2"
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    STORE
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    MODEL NAME
                  </TableCell>

                  <TableCell
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    BACKUP DATE
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getData?.map((row) => (
                  <TableRow className="!cursor-pointer">
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.store ? row?.store : "--"}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.model_name ? row?.model_name : "--"}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.backup_date ? row?.backup_date.slice(0, 10) : "--"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            className="!float-right relative bg-white w-[100%] mx-auto bottom-0 right-0 py-3 border-t-2 border-gray-400 !flex-col !justify-end !items-center"
            style={{ marginRight: "0px" }}
          >
            <button onClick={nextData} className="!float-right">
              {currentPage}...{totalPage}
              <ArrowForwardIosIcon />
            </button>
            <button onClick={previousData} className="float-right">
              <ArrowBackIosIcon />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default BackupRestore
