import Layout from "Layout"
import SignIn from "components/SignIn"
import ResetPassword from "components/SignIn/ResetPassword"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { routes } from "routes"

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/reset-paasword" element={<ResetPassword />} />
        <Route element={<Layout />}>
          {routes.map((route) => {
            return <Route key={route.id} path={route.path} element={route.component} />
          })}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
