import { Edit } from "@mui/icons-material"
import { Switch, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { NotifictaionFn, changeConfigStatusFn } from "Services/PushNotification"
import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { CustomIconButton } from "shared/CustomIconButton"
import { CustomLoader } from "shared/CustomLoader"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"

import CustomPagination from "shared/CustomPagination"
import TableCell from "shared/MkxTable/MkxTableCell"
import NoDataFound from "shared/NoDataFound"
import AddNotification from "./AddNotification"
import ViewConfig from "./ViewConfig"

const PushNotification = () => {
  const { store_id } = useParams()
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const [configId, setConfigId] = useState(null)
  const [config, setConfig] = useState(null)
  const client = useQueryClient()

  const { data: notificationData, isLoading } = useQuery(
    ["notifictaions", search, page],
    () => NotifictaionFn({ store_id, page, search }),
    { enabled: !!store_id }
  )
  const handleResponse = (res) => {
    if (res.data.response_code === 200) {
      toast.success(res.data.message)
      client.refetchQueries("notifictaions")
    } else {
      toast.error(res.data.message)
    }
  }
  const notification = notificationData?.data?.data
  const { mutate: changeConfigStatus } = useMutation(changeConfigStatusFn, {
    onSuccess: (res) => {
      handleResponse(res)
    },
  })

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between p-3">
        <CustomInput value={search} onChange={(event) => setSearch(event.target.value)} placeholder="Search" />

        <div className="flex items-center gap-4">
          <AddNotification config={configId} setConfig={setConfigId} />
        </div>
      </div>

      <TableContainer>
        <Table className="border">
          <TableHead>
            <TableRow>
              <TableCell isHead>S No.</TableCell>
              <TableCell isHead>Create At</TableCell>
              <TableCell isHead>Update At</TableCell>
              <TableCell isHead>Config Detail</TableCell>
              <TableCell isHead>Status</TableCell>
              <TableCell isHead>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <CustomLoader loading={isLoading} row={4} />
            {notification?.map((item, index) => (
              <TableRow>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.create_at}</TableCell>
                <TableCell>{item.update_at}</TableCell>
                <TableCell>
                  <CustomButton onClick={() => setConfig(item.id)}>View</CustomButton>
                </TableCell>
                <TableCell>
                  <Switch
                    checked={item.active_status}
                    onChange={() => changeConfigStatus({ store_id, firebase_id: item.id })}
                  />
                </TableCell>
                <TableCell>
                  <CustomIconButton onClick={() => setConfigId(item.id)}>
                    <Edit />
                  </CustomIconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <CustomPagination data={notificationData} setPage={setPage} />
        <NoDataFound data={notificationData} />
      </TableContainer>
      <ViewConfig config={config} setConfig={setConfig} />
    </div>
  )
}

export default PushNotification
