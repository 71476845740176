import { Edit, WhatsApp } from "@mui/icons-material"
import {
  Divider,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import CustomButton from "shared/MkxButton"
import CustomModal from "shared/MkxModal"
import GlassDiv from "shared/CustomDiv"
import axiosInstance from "config/axios"

const WhatsAppSetting = () => {
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)
  const [open1, setOpen1] = useState(false)
  const handleClose1 = () => setOpen1(false)
  const handleOpen1 = () => setOpen1(true)
  const [open2, setOpen2] = useState(false)
  const handleClose2 = () => setOpen2(false)
  const handleOpen2 = () => setOpen2(true)
  const [token, settoken] = useState("")
  const [verify, setverify] = useState("")
  const [business, setbid] = useState("")
  const [List, setList] = useState([])
  const navigate = useNavigate()
  const store_id = localStorage.getItem("store")
  const [isLoading, setIsLoading] = useState(false)

  const WhatsappList = () => {
    setIsLoading(true)
    axiosInstance
      .get(`/whatsapp-api-configuration-for-admin/?store_id=${store_id}`)
      .then((res) => {
        setList(res.data.data)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    WhatsappList()
  }, [])

  const handledata = (token) => {
    handleOpen()
    settoken(token)
  }
  const handletoken = (verify) => {
    handleOpen1()
    setverify(verify)
  }
  const handleId = (business) => {
    handleOpen2()
    setbid(business)
  }

  return (
    <>
      <GlassDiv>
        <span className="flex items-center justify-between px-2 pb-2">
          <div>
            <WhatsApp className="!text-green-500" size="large" />
            <span className="text-xl "> WhatsApp Setting</span>
          </div>

          <CustomButton className="mx-2 !px-5">
            <span className="flex items-center " onClick={() => navigate("/settings/whatsapp/config/add-config")}>
              <img
                src="https://pub-16f3de4d2c4841169d66d16992f9f0d3.r2.dev/assets/Inventory/addbutton.svg"
                alt=""
                className="h-6 mt-1 mr-2"
              />
              New
            </span>
          </CustomButton>
        </span>
        <TableContainer>
          <Table size="small" className="bg-white bg-opacity-20  !overflow-x-auto">
            <TableHead className="!w-full">
              <TableRow className="!bg-white !bg-opacity-20 ">
                <TableCell className="!w-5 !border-r-2 !font-bold !text-center !border-white !border-opacity-50">
                  Id
                </TableCell>
                <TableCell className="!border-r-2 !font-bold !text-center !border-white !border-opacity-50">
                  Store
                </TableCell>
                <TableCell className="!border-r-2 !font-bold !text-center !border-white !border-opacity-50">
                  Date
                </TableCell>
                <TableCell className="!border-r-2 !font-bold !text-center !border-white !border-opacity-50">
                  Profile
                </TableCell>
                <TableCell className="!border-r-2 !font-bold !text-center !border-white !border-opacity-50">
                  PhoneNo
                </TableCell>
                <TableCell className="!border-r-2 !font-bold !text-center !border-white !border-opacity-50">
                  PhoneId
                </TableCell>
                <TableCell className="!border-r-2 !font-bold !text-center !border-white !border-opacity-50">
                  Busines
                </TableCell>
                <TableCell className="!border-r-2 !font-bold !text-center !border-white !border-opacity-50">
                  Bearer
                </TableCell>
                <TableCell className="!border-r-2 !font-bold !text-center !border-white !border-opacity-50">
                  Verify
                </TableCell>
                <TableCell className="!border-r-2 !font-bold !text-center !border-white !border-opacity-50">
                  Action
                </TableCell>
                <TableCell className="!border-r-2 !font-bold !text-center !border-white !border-opacity-50">
                  Status
                </TableCell>
                <TableCell className="!border-r-2 !font-bold !text-center !border-white !border-opacity-50">
                  Permission
                  <Divider />
                  <span> Read </span> | <span> Write </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody size="small" className="">
              {isLoading
                ? [1, 2, 3, 4, 5]?.map(() => {
                    return (
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    )
                  })
                : List?.map((item) => {
                    return (
                      <>
                        <TableRow className="hover:!bg-white hover:!bg-opacity-40 cursor-pointer">
                          <TableCell className="!border-r-2 !text-center !border-white !border-opacity-50">
                            {item?.id}
                          </TableCell>
                          <TableCell className="!border-r-2  !text-center !border-white !border-opacity-50">
                            {" "}
                            {item?.store}{" "}
                          </TableCell>
                          <TableCell className="!border-r-2  !text-center !border-white !border-opacity-50">
                            {item?.created_date.slice(0, 10)}{" "}
                          </TableCell>
                          <TableCell className="!border-r-2  !text-center !border-white !border-opacity-50">
                            {item?.profileName}{" "}
                          </TableCell>
                          <TableCell className="!border-r-2  !text-center !border-white !border-opacity-50">
                            {item?.phoneNumber}{" "}
                          </TableCell>
                          <TableCell className="!border-r-2  !text-center !border-white !border-opacity-50">
                            {item?.phoneNumberId}{" "}
                          </TableCell>
                          <TableCell
                            className="!border-r-2  !text-center !border-white !border-opacity-50"
                            onClick={() => handleId(item?.businessAccountId)}
                          >
                            Click
                          </TableCell>
                          <TableCell
                            className="!border-r-2  !text-center !border-white !border-opacity-50"
                            onClick={() => handledata(item?.bearer_token)}
                          >
                            Click
                          </TableCell>
                          <TableCell
                            className="!border-r-2  !text-center !border-white !border-opacity-50"
                            onClick={() => handletoken(item?.verify_token)}
                          >
                            Click{" "}
                          </TableCell>

                          {item?.is_erp_whatsAppBusiness_active ? (
                            <>
                              <TableCell className=" !border-r-2  !text-center !border-white !border-opacity-50">
                                --
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell className=" !border-r-2  !text-center !border-white !border-opacity-50">
                                <Edit onClick={() => navigate(`/settings/whatsapp/config/update-config/${item?.id}`)} />
                              </TableCell>
                            </>
                          )}
                          <TableCell className="!border-r-2 !font-bold !text-center !border-white !border-opacity-50">
                            {" "}
                            <Switch checked={item.is_erp_whatsAppBusiness_active} />
                          </TableCell>
                          <TableCell className="!border-r-2  !text-center !border-white !border-opacity-50">
                            <div className="flex ">
                              <Switch checked={item.is_permission_to_read_message} />
                              <div className="border border-white border-right" />
                              <Switch checked={item.is_permission_to_send_message} />
                            </div>{" "}
                          </TableCell>
                        </TableRow>
                      </>
                    )
                  })}
              <CustomModal open={open2} onClose={handleClose2} setOpen2={false} className="!w-96 !p-0 ">
                <p className="text-xl text-center">Business Id</p>
                <p className="!w-full !text-white py-2">{business}</p>{" "}
              </CustomModal>
              <CustomModal open={open} onClose={handleClose} setOpen={false} className="!w-96 !p-0 ">
                <p className="text-xl text-center">Bearer Token</p>
                <p className="!w-full !text-white py-2">{token}</p>{" "}
              </CustomModal>
              <CustomModal open={open1} onClose={handleClose1} setOpen1={false} className="!w-96 ">
                <p className="py-2 text-xl text-center">Verify Token</p>
                <Divider />
                <p className="!w-full !text-white py-2">{verify}</p>{" "}
              </CustomModal>
            </TableBody>
          </Table>
        </TableContainer>
      </GlassDiv>
    </>
  )
}

export default WhatsAppSetting
