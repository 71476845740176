import { Edit } from "@mui/icons-material"
import { Checkbox, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { deleteTablesFn, restaurantTablesFn } from "Services/Settings/Restaurant"
import { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import useRowSelection from "shared"
import DeleteButton from "shared/CustomConfimation"
import { CustomIconButton } from "shared/CustomIconButton"

import CustomPagination from "shared/CustomPagination"
import CustomInput from "shared/MkxInput"
import TableCell from "shared/MkxTable/MkxTableCell"
import { MkxTableLoader } from "shared/MkxTable/MkxTableLoader"
import NoDataFound from "shared/NoDataFound"
import AddTables from "./AddTables"

const RestaurantTables = () => {
  const [search, setSearch] = useState("")
  const { store_id } = useParams()
  const [page, setPage] = useState(1)
  const [isDelete, setIsDelete] = useState(false)
  const [selected, setSelected] = useState(null)

  const {
    data: tables,
    isLoading,
    refetch,
  } = useQuery(["tables", page, search], () => restaurantTablesFn({ store_id, search, page }))
  const { selectedIds, handleSelectRow, handleSelectAll, handleClearSelection } = useRowSelection(
    [],
    tables?.data?.data
  )

  const { mutate: deleteTables } = useMutation(deleteTablesFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        handleClearSelection()
        refetch()
      } else {
        toast.error(response.data.message)
      }
    },
  })

  const handleDelete = () => {
    deleteTables({ store_id, table_ids: selectedIds })
  }

  return (
    <>
      <div className="flex items-center justify-between p-2">
        <CustomInput
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Search Tables..."
        />
        <span className="flex items-center gap-2">
          <DeleteButton
            isDelete={isDelete}
            onConfirm={handleDelete}
            selectedIds={selectedIds}
            setIsDelete={setIsDelete}
            isPermit
          />
          <AddTables setSelected={setSelected} selected={selected} />
        </span>
      </div>

      <TableContainer className="!p-0 !overflow-auto">
        <Table className="border">
          <TableHead>
            <TableRow>
              <TableCell isHead padding={1} className="!w-20">
                <Checkbox
                  size="small"
                  checked={selectedIds?.length === tables?.data?.data?.length ? true : false}
                  onClick={() => handleSelectAll()}
                />
              </TableCell>
              <TableCell isHead padding={1}>
                Table
              </TableCell>
              <TableCell isHead padding={1}>
                Floor
              </TableCell>
              <TableCell isHead padding={1}>
                Status
              </TableCell>
              <TableCell isHead padding={1}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <MkxTableLoader loading={isLoading} row={4} />
            {tables?.data?.data?.map((item) => (
              <TableRow>
                <TableCell padding={1} className="!w-20">
                  <Checkbox
                    size="small"
                    checked={selectedIds.includes(item.id)}
                    onClick={() => handleSelectRow(item.id)}
                  />
                </TableCell>
                <TableCell padding={1}>{item.table_no}</TableCell>
                <TableCell padding={1}>{item.floor_name}</TableCell>
                <TableCell padding={1}>{item.status}</TableCell>
                <TableCell padding={1}>
                  <CustomIconButton color="primary" onClick={() => setSelected(item)}>
                    <Edit />
                  </CustomIconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <CustomPagination data={tables} setPage={setPage} />
        <NoDataFound data={tables} />
      </TableContainer>
    </>
  )
}

export default RestaurantTables
