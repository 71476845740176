import { TableCell as MuiTableCell } from "@mui/material"
import classNames from "classnames"
import React from "react"

const MkxTableCell = ({ className = "", children, isHead = false, isCentered = true, padding = 1, ...rest }) => {
  return (
    <MuiTableCell
      className={classNames(
        "!border-r !border-gray-300",
        isHead && "!font-bold bg-gray-200",
        isCentered && "!text-center",
        `!p-${padding}`,
        className
      )}
      {...rest}
    >
      {children}
    </MuiTableCell>
  )
}

export default MkxTableCell
