import { Add } from "@mui/icons-material"
import { addDepartmentFn } from "Services/Department/AddDepartment"
import { updateDepartmentFn } from "Services/Department/UpdateDepartment"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import CustomModal from "shared/MkxModal"

const AddDepartment = ({ setSelectDept, selectDept }) => {
  const { store_id } = useParams()
  const [open, setOpen] = useState(false)
  const client = useQueryClient()
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectDept({})
  }

  const { mutate: addDepartment, isLoading: isLoading } = useMutation(addDepartmentFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("departmentsMain")
        handleClose()
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const { mutate: updateDepartment, isLoading: isLoading1 } = useMutation(updateDepartmentFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("departmentsMain")
        handleClose()
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const initialValues = {
    dept_name: selectDept?.dept_name || "",
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      const formData = new FormData()
      formData.append("store_id", store_id)
      formData.append("dept_name", formik.values.dept_name)

      if (selectDept?.dept_id) {
        formData.append("department_id", selectDept?.dept_id)
        updateDepartment(formData)
      } else {
        addDepartment(formData)
      }
    },
  })
  useEffect(() => {
    selectDept && Object.keys(selectDept)?.length !== 0 && handleOpen()
  }, [selectDept])

  return (
    <>
      <CustomButton size="medium" startIcon={<Add />} className="!px-5" onClick={handleOpen}>
        Department
      </CustomButton>
      <CustomModal
        title={selectDept?.dept_id ? "Update Department" : "Add Department"}
        open={open}
        onClose={handleClose}
        className="!w-1/4 min-h-fit"
      >
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 p-3">
          <CustomInput id="dept_name" placeholder="Department Name" label="Department Name" formik={formik} />

          <div className="flex justify-end gap-3 my-2">
            <CustomButton type="button" onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton isLoading={selectDept?.dept_id ? isLoading1 : isLoading} type="submit">
              {selectDept?.dept_id ? "Update" : "Add"}
            </CustomButton>
          </div>
        </form>
      </CustomModal>
    </>
  )
}

export default AddDepartment
