import { FilterAlt } from "@mui/icons-material"
import {
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"

import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import axios from "config/axios"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Active, Inactive, Unmaped } from "../../shared/ActivaInactive"
import { CustomIconButton } from "../../shared/CustomIconButton"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  rounded: 2,
}

const WhatsappSetting = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [data, setData] = useState([])
  const [Add, setAdd] = useState([])
  const [open1, setOpen1] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")

  const handleClose1 = () => setOpen1(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const WhatsappList = () => {
    axios
      .get(`cronjob/whatsapp-bussiness-mapped-to-store/`)
      .then((response) => {
        setData(response.data.data)
      })
      .catch((error) => {
        console.log("", error)
      })
  }
  useEffect(() => {
    WhatsappList()
  }, [searchQuery])

  const handleChange = (event, store_id) => {
    axios
      .post(`cronjob/whatsapp-bussiness-mapped-to-store/`, {
        store_id: store_id,
        is_erp_whatsAppBusiness_active: event.target.checked ? "true" : "false",
      })
      .then((response) => {
        toast.success(response.data.message)
        WhatsappList()
      })
      .catch((error) => {
        console.log(error.response)
      })
  }
  const handleChange1 = (event, store_id) => {
    axios
      .post(`cronjob/whatsapp-bussiness-store-permission-to-read-message/`, {
        store_id: store_id,
        is_permission_to_read_message: event.target.checked ? "true" : "false",
      })
      .then((response) => {
        toast.success(response.data.message)
        WhatsappList()
      })
      .catch((error) => {
        console.log(error.response)
      })
  }
  const handleChange2 = (event, store_id) => {
    axios
      .post(`cronjob/whatsapp-bussiness-store-permission-to-send-message/`, {
        store_id: store_id,
        is_permission_to_send_message: event.target.checked ? "true" : "false",
      })
      .then((response) => {
        toast.success(response.data.message)
        WhatsappList()
      })
      .catch((error) => {
        console.log(error.response)
      })
  }

  const Addlist = () => {
    axios
      .get(`cronjob/whatsapp-bussiness-umapped-to-store-list/`)
      .then((response) => {
        setAdd(response.data.data)
      })
      .catch((error) => {
        console.log("", error)
      })
  }
  useEffect(() => {
    Addlist()
  }, [])

  const formikk = useFormik({
    initialValues: {
      store_id: "",
    },

    onSubmit: () => {
      const reqbody = {
        store_id: formikk.values.store_id,
      }
      axios
        .post(`cronjob/whatsapp-bussiness-mapped-to-store/`, reqbody)
        .then((response) => {
          WhatsappList()
          toast.success(response.data.message)
          handleClose1()
        })
        .catch((error) => {
          console.log("", error)
        })
    },
  })

  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font-bold text-black">Bhaarat ERP WhatsApp Setting </p>
          </div>
          <div>
            <p className="p-2 text-xl font-bold text-black " onClick={() => setOpen1(true)}>
              +Add
            </p>
          </div>
        </div>
        <div className="flex flex-col !rounded bg-secondary border-secondary shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center py-2 bg-gray-100 rounded">
            <CustomIconButton>
              <FilterAlt className="text-black" />
            </CustomIconButton>
            <div>
              <input
                class="placeholder:italic placeholder:text-slate-400 block bg-white w-80 border border-slate-300 rounded-md py-2 px-2 pr-3 shadow-sm focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
                placeholder="Search for anything..."
                type="text"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
          </div>

          <div className="p-2 pt-5">
            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <Box sx={{ ...style, width: 500 }} className="rounded-2xl !border-0 !shadow-sm !shadow-blue-900">
                <div
                  id="child-modal-title "
                  className="!text-black !underline !underline-offset-2  !font-bold pb-3 !text-center !text-xl"
                >
                  Add Buisness WhatsApp Bhaarat Erp
                </div>
                <form onSubmit={formikk.handleSubmit}>
                  <div className="py-2 ">Store Name </div>
                  <select
                    className="w-full p-2 border border-gray-500 rounded"
                    id="store_id"
                    name="store_id"
                    onChange={formikk.handleChange}
                  >
                    <option value="">---Select Store</option>
                    {Add?.map((i) => {
                      return (
                        <option key={i.id} value={`${i.id}`}>
                          {i?.store_name}
                        </option>
                      )
                    })}
                  </select>

                  <div className="!flex !justify-center ">
                    <Button className="!my-4 " variant="contained" type="submit">
                      Add Mapped
                    </Button>
                  </div>
                </form>
                <div></div>
              </Box>
            </Modal>
          </div>
          <TableContainer
            component={Paper}
            sx={{ width: "100%", maxHeight: 440 }}
            className="!text-black !mx-auto !bg-white overflow-auto px-2"
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    ID
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    Store ID
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    STORE
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    Profile
                  </TableCell>

                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-xs !text-gray-800 !font-bold"
                  >
                    WhatsApp No
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    Create Date
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    STATUS
                  </TableCell>

                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    CHANGE STATUS
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    Permissions
                    <Divider />
                    <span>Read</span> | <span>Write</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .filter((item) => item?.store && item?.store.toLowerCase().includes(searchQuery.toLowerCase()))
                  .map((item) => {
                    return (
                      <>
                        <TableRow>
                          <TableCell
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                            className="!text-center !text-gray-800 "
                          >
                            {item?.id}
                          </TableCell>
                          <TableCell
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                            className="!text-center !text-gray-800 "
                          >
                            {item?.store_id}
                          </TableCell>
                          <TableCell
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                            className="!text-center !text-gray-800 "
                          >
                            {item?.store}
                          </TableCell>
                          <TableCell
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                            className="!text-center !text-gray-800 "
                          >
                            {item?.profileName}
                          </TableCell>
                          <TableCell
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                            className="!text-center !text-gray-800 "
                          >
                            {item?.phoneNumber}
                          </TableCell>
                          <TableCell
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                            className="!text-center !text-gray-800 "
                          >
                            {item?.created_date}
                          </TableCell>
                          <TableCell
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                            className="!text-center !text-gray-800"
                          >
                            {item.is_erp_whatsAppBusiness_active === null ? (
                              <Unmaped className="items-center" />
                            ) : item.is_erp_whatsAppBusiness_active === true ? (
                              <Active className="items-center" />
                            ) : (
                              <Inactive className="items-center" />
                            )}
                          </TableCell>
                          <TableCell
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                            className="!text-center !text-gray-800 "
                          >
                            <input
                              type="checkbox"
                              checked={item.is_erp_whatsAppBusiness_active}
                              onChange={(event) => handleChange(event, item?.store_id)}
                              className="toggle checked:!border-green-500 !border-red-500 toggle-success"
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                            className="!text-center !text-gray-800 "
                          >
                            <div className="flex gap-2">
                              <input
                                type="checkbox"
                                checked={item.is_permission_to_read_message}
                                onChange={(event) => handleChange1(event, item?.store_id)}
                                className="h-5 toggle checked:!border-green-500 !border-red-500 toggle-success"
                              />
                              <div className="border border-right" />
                              <input
                                type="checkbox"
                                checked={item.is_permission_to_send_message}
                                onChange={(event) => handleChange2(event, item?.store_id)}
                                className="!h-5 toggle checked:!border-green-500 !border-red-500 toggle-success"
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    )
                  })}
              </TableBody>
            </Table>
            {data?.length !== 0 ? (
              <TablePagination
                rowsPerPageOptions={[6]}
                count={data?.length}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default WhatsappSetting
