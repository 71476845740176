import axiosInstance from "config/axios"

export const storePermissionFn = (redBody) => {
  try {
    const response = axiosInstance.post(`store-employee-permission-api-of-superadmin/`, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateStorePermissionFn = (redBody) => {
  try {
    const response = axiosInstance.put(`store-employee-permission-api-of-superadmin/`, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const getPermissionFn = (redBody) => {
  try {
    const response = axiosInstance.get(`store-employee-permission-api-of-superadmin/`, {
      params: redBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
