import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"

export const updateOperationsFn = (redBody) => {
  try {
    const response = axiosInstance.put(API_URLS.store.operations, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
