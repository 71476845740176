import { Add, Delete, Save } from "@mui/icons-material"
import { MenuItem } from "@mui/material"
import { addLearningZoneFn, updateLearningZoneFn } from "Services/LearningZone"
import axiosInstance from "config/axios"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { CustomIconButton } from "shared/CustomIconButton"
import MkxButton from "shared/MkxButton"
import MkxInput from "shared/MkxInput"
import MkxModal from "shared/MkxModal"
import MkxSelect from "shared/MkxSelect"

const ManageLearningZone = ({ selected, setSelected }) => {
  const [open, setOpen] = useState(false)
  const [moduleType, setModuleType] = useState([])
  const [modules, setModules] = useState([])
  const [link, setLink] = useState([""])

  const isUpdate = Boolean(selected)

  const client = useQueryClient()

  const { mutate: addLearningZone } = useMutation(addLearningZoneFn, {
    onSuccess: ({ data }) => {
      if (data.response_code === 200) {
        toast.success(data.message)
        setOpen(false)
        setSelected(null)
        client.refetchQueries("learningZone")
      } else {
        toast.error(data.message)
      }
    },
  })
  const { mutate: updateLearningZone } = useMutation(updateLearningZoneFn, {
    onSuccess: ({ data }) => {
      if (data.response_code === 200) {
        toast.success(data.message)
        setOpen(false)
        setSelected(null)
        client.refetchQueries("learningZone")
      } else {
        toast.error(data.message)
      }
    },
  })

  const initialValues = {
    module_type: selected?.module_type ?? 1,
    module: selected?.module ?? "",
    title: selected?.title ?? "",
    user_manual: "",
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const reqbody = new FormData()
      reqbody.append("module_type", values.module_type)
      isUpdate && reqbody.append("module_youtube_id", selected.id)
      reqbody.append("module_id", values.module)
      reqbody.append("title", values.title)
      reqbody.append("user_manual", values.user_manual)
      reqbody.append("link", JSON.stringify(link))

      isUpdate ? updateLearningZone(reqbody) : addLearningZone(reqbody)
    },
  })

  const moduleTypeFn = async () => {
    const response = await axiosInstance.get(`plan-type-list-without-pagination-api-for-superadmin/`)
    setModuleType(response.data.data)
  }

  useEffect(() => {
    moduleTypeFn()
  }, [])

  const modulesFn = async () => {
    const response = await axiosInstance.post(`module-and-value-api-for-superadmin/`, {
      plan_type_id: formik.values.module_type,
    })
    setModules(response.data.data)
  }

  useEffect(() => {
    formik.values.module_type && modulesFn()
  }, [formik.values.module_type])

  const handleChange = (value, index) => {
    const updatedLinks = [...link]
    updatedLinks[index] = value
    setLink(updatedLinks)
  }

  useEffect(() => {
    selected && setLink(selected.link)
  }, [selected])

  return (
    <>
      <MkxButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Learning Zone
      </MkxButton>
      <MkxModal
        title="Learning Zone"
        open={open || isUpdate}
        onClose={() => {
          setOpen(false)
          setSelected(null)
        }}
        className="w-1/2"
      >
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 p-5 max-h-[80vh] overflow-y-auto">
          <div className="grid grid-cols-2 gap-2">
            <MkxSelect id="module_type" label="Module Type" placeholder="Select Module Type" formik={formik}>
              {moduleType?.map((i) => (
                <MenuItem value={i.id}>{i.title}</MenuItem>
              ))}
            </MkxSelect>

            <MkxSelect id="module" label="Module" placeholder="Select Module" formik={formik}>
              {modules?.map((i) => (
                <MenuItem value={i.id}>{i.module_name}</MenuItem>
              ))}
            </MkxSelect>

            <MkxInput label="Title" placeholder="Enter Title" id="title" formik={formik} />

            <MkxInput
              id="user_manual"
              type="file"
              label="User Manual"
              onChange={(event) => formik.setFieldValue("user_manual", event.target.files[0])}
            />
          </div>
          <div className="flex flex-col gap-1">
            <p className="px-1 font-semibold">Links</p>
            {link.map((i, index) => {
              return (
                <div className="flex items-center gap-2 !w-full">
                  <MkxInput
                    placeholder="Enter Video Link"
                    id="link"
                    value={i}
                    onChange={(event) => handleChange(event.target.value, index)}
                    className="w-full"
                  />
                  <CustomIconButton
                    color="error"
                    size="medium"
                    onClick={() => setLink(link.filter((_, i) => i !== index))}
                  >
                    <Delete />
                  </CustomIconButton>
                </div>
              )
            })}
            <div>
              <MkxButton variant="text" startIcon={<Add />} onClick={() => setLink([...link, ""])}>
                Add Link
              </MkxButton>
            </div>
          </div>
          <MkxInput id="data" label="Content" placeholder="Enter Content" rows={10} multiline formik={formik} />
          <MkxButton startIcon={<Save />} type="submit">
            Save
          </MkxButton>
        </form>
      </MkxModal>
    </>
  )
}

export default ManageLearningZone
