import { ThemeProvider, createTheme } from "@mui/material/styles"
import ReactDOM from "react-dom/client"
import { QueryClient, QueryClientProvider } from "react-query"
import "react-quill/dist/quill.snow.css"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import App from "./App"
import "./index.css"

const root = ReactDOM.createRoot(document.getElementById("root"))
const client = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } })
console.error = () => {}
const darkTheme = createTheme({ palette: { mode: "light", primary: { main: "#020073" } } })
root.render(
  <>
    <ThemeProvider theme={darkTheme}>
      <ToastContainer
        theme="dark"
        position="top-center"
        autoClose={3000}
        closeOnClick
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        className="!w-1/4 !capitalize"
      />
      <QueryClientProvider client={client}>
        <App />
      </QueryClientProvider>
    </ThemeProvider>
  </>
)
