import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"

export const whatsappWorkflowFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.whatsappWorkflow, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const createWorkFlowFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.whatsappWorkflow, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateWorkFlowFn = (reqBody) => {
  try {
    const response = axiosInstance.put(API_URLS.whatsappWorkflow, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const delateWorkFlowFn = (reqBody) => {
  try {
    const response = axiosInstance.delete(API_URLS.whatsappWorkflow, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const workflowStepsFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.workflowSteps, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const addflowStepsFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.workflowSteps, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateflowStepsFn = (reqBody) => {
  try {
    const response = axiosInstance.put(API_URLS.workflowSteps, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
