import React, { useEffect, useState } from "react"
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import axios from "config/axios"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import TextField from "@mui/material/TextField"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import AddIcon from "@mui/icons-material/Add"
import { toast } from "react-toastify"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  rounded: 2,
}
const ModuleManagement = () => {
  const [data, setData] = useState([])
  const [open, setOpen] = React.useState(false)
  const [page, setPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(null)
  const [title, setTitle] = useState("")
  const [planId, setPlanId] = useState("")
  const [planData, setPlanData] = useState([])
  const [ids, setIds] = useState(null)
  const [rowData, setRowData] = useState({})
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const previousData = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }
  const nextData = () => {
    if (page < totalPage) {
      setPage(page + 1)
    }
  }

  const moduleDataList = () => {
    axios
      .get(`module-list-api-for-superadmin/?page=${page}&search_value=${searchQuery}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        console.log("plan type data list===>", response?.data?.data)
        setData(response?.data?.data)
        setCurrentPage(response?.data?.current_page)
        setTotalPage(response?.data?.total_pages)
      })
      .catch((error) => {
        console.log("", error.response)
        console.log(error)
      })
  }

  useEffect(
    () => {
      moduleDataList()
    },
    // eslint-disable-next-line

    [page, searchQuery]
  )
  const handleCloseModal = () => {
    setTitle("")
    setRowData({})
    setIds(null)
    handleClose()
  }

  const submitData = () => {
    const fd = new FormData()
    fd.append("plan_type_id", planId || rowData?.plan_type?.id)
    fd.append("module_name", title)
    fd.append("module_id", ids)

    ids === null
      ? axios
          .post(`add-module-api-for-superadmin/`, fd, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          })
          .then((response) => {
            toast.success(response.data.message)
            moduleDataList()
            setTitle("")
            setPlanId("")
            setRowData({})
            setIds(null)
            handleClose()
          })
          .catch((error) => {
            console.log("", error.response)
            console.log(error)
          })
      : axios
          .post(`module-list-api-for-superadmin/`, fd, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          })
          .then((response) => {
            toast.success(response.data.message)
            moduleDataList()
            setTitle("")
            setIds(null)
            setPlanId("")
            setRowData({})
            handleClose()
          })
          .catch((error) => {
            console.log("", error.response)
            console.log(error)
          })
  }

  const editFunction = (row) => {
    setIds(row?.id)
    setRowData(row)
    setTitle(row?.module_name)
    console.log(row)
    handleOpen()
  }

  const planTypeList = () => {
    axios
      .get(`plan-type-list-without-pagination-api-for-superadmin/`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setPlanData(response?.data?.data)
      })
      .catch((error) => {
        console.log("", error.response)
        console.log(error)
      })
  }
  useEffect(() => {
    planTypeList()
  }, [])
  // plan-type-list-without-pagination-api-for-superadmin

  return (
    <div className=" !bg-slate-100">
      <div className="flex justify-between">
        <div className="p-2">
          <p className="text-xl !text-black font font-bold">Module Management List</p>
        </div>
        <div className="p-2 pt-5">
          <Button variant="outlined" color="primary" onClick={handleOpen}>
            <AddIcon />
            Add Module
          </Button>
        </div>
      </div>
      <div className="flex flex-col !rounded !text-black bg-slate-100 shadow-card shadow-zinc-600 m-2">
        <div className="flex items-center justify-between my-2">
          <span className="flex  gap-3">
            <input
              class="placeholder:italic placeholder:text-slate-400 block mx-3 bg-white w-80 border border-slate-300 rounded-md py-2 px-2 pr-3 shadow-sm focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
              placeholder="Search for anything..."
              type="text"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
          </span>
          <span>
            {/* Add Category Bussiness start */}

            <div className="text-red-500 mx-3">
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
              >
                <Box sx={{ ...style, width: 450 }} className="rounded-2xl !border-0 !shadow-sm !shadow-blue-900">
                  <div
                    id="child-modal-title "
                    className="!text-black !underline !underline-offset-2  !font-bold pb-3 !text-center !text-xl"
                  >
                    {ids === null ? "Add Module" : "Update Module"}
                  </div>
                  <div className="">
                    <div>Plan Type</div>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        className="m-1"
                        value={planId || rowData?.plan_type?.id || ""}
                        onChange={(event) => setPlanId(event.target.value)}
                      >
                        {planData?.map((res) => {
                          return <MenuItem value={res?.id}>{res?.title}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                    <div className="mt-2">Module Name </div>
                    <TextField
                      value={title}
                      onChange={(event) => setTitle(event.target.value)}
                      id="outlined-basic"
                      size="small"
                      className="!w-[100%] !m-1"
                    />
                  </div>
                  <br />
                  <Button onClick={handleCloseModal} variant="contained" color="warning" className="!py-2">
                    CANCEL
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    className="!py-2 !px-5 !font-bold !float-right"
                    onClick={submitData}
                  >
                    {ids == null ? "Add" : "UPDATE"}
                  </Button>
                </Box>
              </Modal>
            </div>
          </span>
        </div>
        <TableContainer
          component={Paper}
          sx={{ width: "100%", maxHeight: 440 }}
          className="!text-black !mx-auto !bg-white overflow-auto px-2"
        >
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  SR. No.
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  PLAN TYPE
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  MODULE
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="!bg-red">
              {data &&
                data?.map((row, index) => (
                  <TableRow key={row.id} className="!text-slate-700">
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.plan_type?.title ? row?.plan_type?.title : "--"}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.module_name ? row?.module_name : "--"}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      <DeleteIcon className="!text-xl !text-red-600 !cursor-pointer" />
                      <EditIcon
                        className="!text-xl !cursor-pointer !text-green-600"
                        onClick={() => editFunction(row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className="!float-right relative bg-white w-[100%] mx-auto bottom-0 right-0 py-3 border-t-2 border-gray-400 !flex-col !justify-end !items-center"
          style={{ marginRight: "0px" }}
        >
          <button onClick={nextData} className="!float-right">
            {currentPage}...{totalPage}
            <ArrowForwardIosIcon />
          </button>
          <button onClick={previousData} className="float-right">
            <ArrowBackIosIcon />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModuleManagement
