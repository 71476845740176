import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Pagination,
} from "@mui/material"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"
import Grid from "@mui/material/Unstable_Grid2"
import axios from "config/axios"
import React, { useState } from "react"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { CustomButton } from "../../shared/CustomButton"
import AddBoxIcon from "@mui/icons-material/AddBox"
import VisibilityIcon from "@mui/icons-material/Visibility"
import BorderColorIcon from "@mui/icons-material/BorderColor"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import Typography from "@mui/material/Typography"
import Checkbox from "@mui/material/Checkbox"
import { toast } from "react-toastify"
import { flatten } from "lodash"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
}
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 220,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}))

const Groups = () => {
  const [data, setData] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [page1, setPage1] = useState(1)

  const [open, setOpen] = React.useState(false)
  const handleClose = () => setOpen(false)

  const [open1, setOpen1] = React.useState(false)
  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => setOpen1(false)
  const [name1, setName1] = useState("")
  const [roleId, setRoleId] = useState(null)
  const navigate = useNavigate()

  const [permData, setPermData] = useState([])
  const [ids, setIds] = useState(null)

  const modaleClose = () => {
    setPermData([])
    handleClose()
  }

  // start
  const [selectedUpdateModule, setSelectedUpdateModule] = useState([])
  const [selectedModule1, setSelectedModule1] = useState([])

  const handleChange = (event, value) => {
    setPage1(value)
  }
  const ResellersListData = () => {
    axios
      .get(`group-api-for-superadmin/?search_value=${searchValue}&page=${page1}`)
      .then((response) => {
        setData(response?.data)
      })
      .catch((error) => {
        console.log("", error)
      })
  }
  useEffect(() => {
    ResellersListData()
  }, [searchValue, page1])

  const submitData = (event, rowName) => {
    event.preventDefault()
    navigate(`/role-management/permission/${rowName?.id}`, { state: rowName })
  }

  const saveData = () => {
    roleId === null
      ? axios
          .post(`add-group-api-for-superadmin/`, { group_name: name1 })
          .then((response) => {
            toast.success(response?.data?.message)
            setName1("")
            ResellersListData()
            handleClose1()
          })
          .catch((error) => {
            console.log("", error)
          })
      : axios
          .post(`edit-group-api-for-superadmin/`, { group_id: roleId, group_name: name1 })
          .then((response) => {
            toast.success(response?.data?.message)
            setName1("")
            ResellersListData()
            handleClose1()
          })
          .catch((error) => {
            console.log("", error)
          })
  }

  const editData = (event, row) => {
    event.preventDefault()
    handleOpen1()
    console.log(row)
    setRoleId(row?.id)
    setName1(row?.name)
  }

  const closeAddData = () => {
    handleClose1()
    setName1("")
    setRoleId(null)
  }

  const deleteRow = (event, id) => {
    event.preventDefault()
    const trueData = window.confirm("we want to sure delete this role...?")
    trueData &&
      axios
        .post(`group-delete-api-for-superadmin/`, { group_id: id })
        .then((response) => {
          toast.success(response?.data?.message)
          ResellersListData()
        })
        .catch((error) => {
          console.log("", error)
        })
  }
  useEffect(() => {
    setSelectedUpdateModule(flatten(selectedModule1?.filter((i) => i.active_status === true)?.map((i) => [i.id])))
  }, [selectedModule1])
  //
  const updateData = () => {
    const permissionData = {
      group_id: ids,
      permission_id: selectedUpdateModule,
    }
    axios
      .post(`add-group-permission-api-for-superadmin/`, permissionData)
      .then((response) => {
        toast.success(response?.data?.message)
        ResellersListData()
        handleClose()
      })
      .catch((error) => {
        console.log("", error)
      })
  }

  const viewDetails = (rowName) => {
    navigate(`/role-management/details/${rowName}`)
  }

  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font-bold text-black">Role Management List</p>
          </div>
          <div className="flex p-1 pt-3">
            <div>
              <CustomButton
                variant="outlined"
                size="small"
                className="px-5 border border-gray-600"
                onClick={() => navigate("/role-management/details")}
              >
                <VisibilityIcon /> Map Role
              </CustomButton>
            </div>
            <div>
              <CustomButton className="px-5 border border-gray-600" onClick={handleOpen1}>
                + Add Role
              </CustomButton>
            </div>

            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style1} className="!border-0 !rounded-xl">
                <Typography id="modal-modal-title" variant="h6" component="h6">
                  <div className="px-2 text-center" style={{ fontFamily: "'Poppins', sans-serif" }}>
                    <span className="font-bold text-center"> {roleId === null ? "Add Role" : "Update Role"}</span>{" "}
                  </div>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div className="gap-2 border-gray-600 rounded">
                    <Box sx={{ flexGrow: 1 }} className="">
                      <div>
                        <div className="py-1">Name : </div>
                        <div>
                          <input
                            type="text"
                            value={name1 || ""}
                            onChange={(event) => setName1(event.target.value)}
                            className="w-full p-1 border border-gray-500 rounded"
                          />{" "}
                        </div>
                      </div>
                    </Box>
                  </div>
                  <div className="items-center justify-between ">
                    <Button className="float-left top-4" variant="contained" color="warning" onClick={closeAddData}>
                      CANCEL
                    </Button>

                    <Button variant="contained" color="success" className="float-right mr-3 top-4" onClick={saveData}>
                      {roleId === null ? "SUBMIT" : "UPDATE"}
                    </Button>
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="!border-0 !rounded-xl">
              <Typography id="modal-modal-title" variant="h6" component="h6">
                <div className="px-2" style={{ fontFamily: "'Poppins', sans-serif" }}>
                  <span className="text-sm">(Permission)</span>
                </div>
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="h-64 gap-2 overflow-hidden overflow-y-auto border-gray-600 rounded shadow-sm shadow-gray-500">
                  <Box sx={{ flexGrow: 1 }} className="">
                    <Grid container spacing={2}>
                      {permData &&
                        permData?.map((elem) => (
                          <Grid xs={6}>
                            <Item className="!justify-between">
                              <div className="flex">
                                <div>
                                  <Checkbox
                                    checked={selectedUpdateModule?.map((i) => i).includes(elem?.id)}
                                    onChange={(event) => {
                                      {
                                        !selectedUpdateModule?.map((i) => i).includes(elem?.id)
                                          ? setSelectedUpdateModule([...selectedUpdateModule, elem?.id])
                                          : setSelectedUpdateModule(() =>
                                              selectedUpdateModule?.filter((i) => i !== elem?.id)
                                            )
                                      }
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                </div>
                                <div className="pt-3 text-start">
                                  <span>{elem?.name.split("_").join(" ").toLocaleUpperCase()}</span>
                                </div>
                              </div>
                            </Item>
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                </div>
                <div className="items-center justify-between ">
                  <Button className="float-left top-3" variant="contained" color="warning" onClick={modaleClose}>
                    CANCEL
                  </Button>
                  <Button variant="contained" color="success" className="float-right mr-3 top-3" onClick={updateData}>
                    UPDATE
                  </Button>
                </div>
              </Typography>
            </Box>
          </Modal>
        </div>
        <div className="flex flex-col !rounded bg-secondary border-secondary shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center py-2 bg-gray-100 rounded">
            <div className="flex justify-between">
              <div className="flex">
                <input
                  class="placeholder:italic placeholder:text-slate-400 block mx-3 bg-white w-80 border border-slate-300 rounded-md py-2 px-2 pr-3 shadow-sm focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
                  placeholder="Search for anything..."
                  type="text"
                  value={searchValue}
                  onChange={(event) => setSearchValue(event.target.value)}
                />
              </div>
              <div className="absolute float-right right-12 "></div>
            </div>
          </div>
          <TableContainer
            component={Paper}
            sx={{ width: "100%" }}
            className="!text-black h-[72vh] !mx-auto !bg-white overflow-auto"
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    ROLE NAME
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    PERMISSION
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    ACTION
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data?.data?.map((row, index) => (
                    <TableRow
                      key={index + 1}
                      className="!cursor-pointer"
                      //
                    >
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.name}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        <div className="w-24 mx-auto text-center gap-x-10">
                          {/* <div>
                            <VisibilityIcon
                              className="!text-blue-500"
                              onClick={(event) => viewDetails(row?.id)}
                            />
                          </div> */}
                          <AddBoxIcon className="!text-green-500" onClick={(event) => submitData(event, row)} />
                          {/* </div> */}
                        </div>
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        <div className="mx-auto gap-x-5">
                          <DeleteForeverIcon
                            className="!text-red-600"
                            size="small"
                            onClick={(event) => deleteRow(event, row?.id)}
                          />
                          <BorderColorIcon className="!text-green-400 mx-3" onClick={(event) => editData(event, row)} />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {data?.data && data?.data?.length !== 0 ? (
              <Pagination
                page={page1}
                size="medium"
                count={data?.total_pages}
                variant="outlined"
                className="float-right p-3"
                color="primary"
                onChange={handleChange}
              />
            ) : null}
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default Groups
