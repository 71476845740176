import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"

export const emailsTempFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.emailTemplateList, { params: reqBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const patchEmailTemplateFn = (reqBody) => {
  try {
    const response = axiosInstance.patch(API_URLS.emailTemplateList, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const postEmailTemplateFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.emailTemplateList, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const updateEmailTemplateFn = (reqBody) => {
  try {
    const response = axiosInstance.put(API_URLS.emailTemplateList, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const getTemplateType = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.templateType, { params: reqBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
