import { Close } from "@mui/icons-material"
import AddIcon from "@mui/icons-material/Add"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import VisibilityIcon from "@mui/icons-material/Visibility"
import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import Modal from "@mui/material/Modal"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import axios from "config/axios"
import { flatten } from "lodash"
import React, { useEffect, useState } from "react"
import ReactQuill from "react-quill"
import { toast } from "react-toastify"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  rounded: 2,
}

const Plan = () => {
  const [data, setData] = useState([])
  const [open, setOpen] = React.useState(false)
  const [open1, setOpen1] = React.useState(false)
  const [open2, setOpen2] = React.useState(false)
  const [page, setPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState("")
  const [page1, setPage1] = useState(1)
  const [searchQuery1, setSearchQuery1] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(null)
  const [currentPage1, setCurrentPage1] = useState(1)
  const [totalPage1, setTotalPage1] = useState(null)
  const [planId, setPlanId] = useState("")
  const [hideValue, setHideValue] = useState({})
  const [planData, setPlanData] = useState([])
  const [viewData, setViewData] = useState({})
  const [details, setDetails] = useState([])
  const [formValues, setFormValues] = useState([])
  const [planName, setPlanName] = useState("")
  const [monthlyPrice, setMonthlyPrice] = useState("")
  const [yearlyPrice, setYearlyPrice] = useState("")
  const [userNo, setUserNo] = useState("")
  const [module, setModule] = useState([])
  const [selectedModule, setSelectedModule] = useState([])
  const [planName1, setPlanName1] = useState("")
  const [monthlyPrice1, setMonthlyPrice1] = useState("")
  const [yearlyPrice1, setYearlyPrice1] = useState("")
  const [userNo1, setUserNo1] = useState("")
  const [editModule, setEditModule] = useState([])
  const [module1, setModule1] = useState([])
  const [selectedModule1, setSelectedModule1] = useState([])
  const [inputs, setInputs] = useState([])
  const [ids, setIds] = useState("")
  const [selectedUpdateModule, setSelectedUpdateModule] = useState([])
  const [description, setDescription] = useState("")
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => {
    setOpen1(false)
    setDescription("")
  }
  const handleOpen2 = () => setOpen2(true)
  const handleClose2 = () => setOpen2(false)

  const previousData = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }
  const nextData = () => {
    if (page < totalPage) {
      setPage(page + 1)
    }
  }
  const previousData1 = () => {
    if (page1 > 1) {
      setPage1(page1 - 1)
    }
  }
  const nextData1 = () => {
    if (page1 < totalPage) {
      setPage1(page1 + 1)
    }
  }

  const moduleDataList = () => {
    axios
      .get(`plan-list-api-for-superadmin/?page=${page}&search_value=${searchQuery}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setData(response?.data?.data)
        setCurrentPage(response?.data?.current_page)
        setTotalPage(response?.data?.total_pages)
      })
  }

  useEffect(() => {
    moduleDataList()
  }, [page, searchQuery])

  const handleCloseModal = () => {
    setIds(null)
    setDescription("")
    handleClose()
  }

  const submitData = () => {
    const formData = {
      plan_name: planName,
      monthly_price: monthlyPrice,
      yearly_price: yearlyPrice,
      user_no: userNo,
      plan_type_id: planId,
      module_id: selectedModule,
      description: description,
    }

    axios
      .post(`add-plan-api-for-superadmin/`, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        toast.success(response.data.message)
        moduleDataList()
        setSelectedModule([])
        handleClose1()
        handleClose()
      })
  }

  const planTypeList = () => {
    axios
      .get(`plan-type-list-without-pagination-api-for-superadmin/`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setPlanData(response?.data?.data)
      })
  }
  useEffect(() => {
    planTypeList()
  }, [])

  const handleViewDetail = (event, row) => {
    event.preventDefault()
    setViewData(row)
    handleOpen1()
  }

  const viewModuleDetails = () => {
    axios
      .get(
        `plan-module-details-api-for-superadmin/?plan_id=${viewData?.id}&search_value=${searchQuery1}&page=${page1}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setDetails(response?.data?.data)
        setCurrentPage1(response?.data?.current_page)
        setTotalPage1(response?.data?.total_pages)
      })
  }
  useEffect(() => {
    open1 === true && viewModuleDetails()
  }, [viewData, searchQuery1, page])

  const dynamicallyField = () => {
    axios
      .post(
        `module-and-value-api-for-superadmin/`,
        { plan_type_id: planId },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setFormValues(response?.data?.data)
      })
  }

  useEffect(() => {
    planId !== "" && dynamicallyField()
  }, [planId])

  const handleSelect = (event) => {
    setPlanId(event?.target?.value?.id)
    setHideValue(event?.target?.value)
  }

  const maxStringFn = () => {
    if (!Array.isArray(module) || module.length === 0) {
      return []
    }

    const uniqueIds = [...new Set(module.map((item) => item.id))]
    const result = []
    uniqueIds.forEach((id) => {
      const objectsWithId = module.filter((item) => item.id === id)
      const maxObject = objectsWithId.reduce((maxObj, currentObj) => {
        const currentValue = currentObj.value
        const maxValue = maxObj.value || ""
        return currentValue.length > maxValue.length ? currentObj : maxObj
      })
      result.push(maxObject)
    })
    setSelectedModule(result)
  }
  useEffect(() => {
    hideValue?.title !== "ERP" && maxStringFn()
  }, [module])

  const maxStringFn1 = () => {
    if (!Array.isArray(module1) || module1.length === 0) {
      return []
    }
    const uniqueIds = [...new Set(module1.map((item) => item.module_id))]
    const result = []
    uniqueIds.forEach((module_id) => {
      const objectsWithId = module1.filter((item) => item.module_id === module_id)
      const maxObject = objectsWithId.reduce((maxObj, currentObj) => {
        const currentValue = currentObj.value
        const maxValue = maxObj.value || ""
        return currentValue?.length > maxValue?.length ? currentObj : maxObj
      })
      result.push(maxObject)
    })

    setInputs(result)
  }
  useEffect(() => {
    hideValue?.title !== "ERP" && maxStringFn1()
  }, [module1])

  useEffect(() => {
    setSelectedUpdateModule(
      flatten(selectedModule1?.filter((i) => i.is_available === true)?.map((i) => [{ module_id: i.module_id }]))
    )
  }, [selectedModule1])

  useEffect(() => {
    setModule1(flatten(selectedModule1?.map((i) => [{ module_id: i.module_id, value_id: i.value_id, value: i.value }])))
  }, [selectedModule1])

  const editDataList = (row) => {
    handleOpen2()
    axios
      .get(`edit-plan-api-for-superadmin/?plan_id=${row?.id}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setEditModule(response?.data?.data)
        setSelectedModule1(response?.data?.data?.modules)
        setModule1()
      })
  }

  useEffect(() => {
    setPlanName1(editModule?.plan_name)
    setMonthlyPrice1(editModule?.monthly_price)
    setYearlyPrice1(editModule?.yearly_price)
    setUserNo1(editModule?.user_num)
    setIds(editModule?.id)
    setDescription(editModule?.description)
  }, [editModule])

  const submitUpdate = () => {
    const formData =
      editModule?.plan_type?.title === "ERP"
        ? {
            plan_name: planName1,
            monthly_price: monthlyPrice1,
            yearly_price: yearlyPrice1,
            user_no: userNo1,
            plan_id: ids,
            plan_type_id: editModule?.plan_type?.id,
            module_data: selectedUpdateModule,
            description: description,
          }
        : {
            plan_name: planName1,
            monthly_price: monthlyPrice1,
            yearly_price: yearlyPrice1,
            user_no: userNo1,
            plan_id: ids,
            plan_type_id: editModule?.plan_type?.id,
            module_data: inputs,
            description: description,
          }
    axios
      .post(`edit-plan-api-for-superadmin/`, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        handleClose2()
        toast.success(response.data.message)
        setSelectedUpdateModule([])
        setInputs([])
        moduleDataList()
      })
  }

  return (
    <div className=" !bg-slate-100">
      <div className="flex justify-between">
        <div className="p-2">
          <p className="text-xl !text-black font font-bold">Plan Management List</p>
        </div>
        <div className="p-2 pt-5">
          <Button variant="outlined" color="primary" onClick={handleOpen}>
            <AddIcon />
            Add Plan
          </Button>
        </div>
      </div>
      <div className="flex flex-col !rounded !text-black bg-slate-100 shadow-card shadow-zinc-600 m-2">
        <div className="flex items-center justify-between my-2">
          <span className="flex gap-3">
            <input
              class="placeholder:italic placeholder:text-slate-400 block mx-3 bg-white w-80 border border-slate-300 rounded-md py-2 px-2 pr-3 shadow-sm focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
              placeholder="Search for anything..."
              type="text"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
          </span>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box
              sx={{ ...style }}
              className="rounded-2xl !outline-none !w-1/2 !h-5/6 !border-0 !shadow-sm !shadow-blue-900 overflow-auto"
            >
              <p className="!text-black !underline !underline-offset-2  !font-bold pb-3 !text-center !text-xl">
                Add Plan
              </p>
              <div className="flex flex-col gap-2">
                <div className="!w-full">
                  <p>Plan Name </p>
                  <TextField
                    value={planName}
                    onChange={(event) => setPlanName(event.target.value)}
                    id="outlined-basic"
                    size="small"
                    className="!w-[100%]"
                    placeholder="Enter Plan Name"
                  />
                </div>

                <div className="flex justify-between w-full gap-4">
                  <div className="!w-full">
                    <p>Monthly Price </p>
                    <TextField
                      value={monthlyPrice}
                      onChange={(event) => setMonthlyPrice(event.target.value)}
                      id="outlined-basic"
                      size="small"
                      className="!w-[100%]"
                      placeholder="Enter Monthly Price "
                    />
                  </div>
                  <div className="!w-full">
                    <p>Yearly Price </p>
                    <TextField
                      value={yearlyPrice}
                      onChange={(event) => setYearlyPrice(event.target.value)}
                      id="outlined-basic"
                      size="small"
                      className="!w-[100%]"
                      placeholder="Enter Yearly Price "
                    />
                  </div>
                </div>
                <div>
                  <p>Plan Type</p>
                  <FormControl fullWidth>
                    <Select
                      displayEmpty
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      value={planId}
                      onChange={(event) => handleSelect(event)}
                    >
                      {planData?.map((res) => {
                        return <MenuItem value={res}>{res?.title}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </div>

                {hideValue?.title === "ERP" ? (
                  <div>
                    <p>Number Of Users</p>
                    <TextField
                      value={userNo}
                      onChange={(event) => setUserNo(event.target.value)}
                      id="outlined-basic"
                      size="small"
                      className="!w-[100%]"
                      placeholder="Enter Number Of Users"
                    />
                  </div>
                ) : null}

                {hideValue?.title === "ERP" ? (
                  <List className="!grid !grid-cols-2 !border">
                    {formValues &&
                      formValues?.map((elem) => (
                        <ListItem className="!flex !items-center">
                          <Checkbox
                            onChange={(event) => {
                              hideValue?.title !== "ERP" ? (
                                setModule([
                                  ...module,
                                  {
                                    id: elem?.id,
                                    value: "",
                                  },
                                ])
                              ) : (
                                <>
                                  {!selectedModule.map((i) => i.id).includes(elem?.id)
                                    ? setSelectedModule([
                                        ...selectedModule,
                                        {
                                          id: elem?.id,
                                        },
                                      ])
                                    : setSelectedModule(() => selectedModule?.filter((i) => i.id !== elem?.id))}
                                </>
                              )
                            }}
                            inputProps={{
                              "aria-label": "controlled",
                            }}
                          />
                          {elem?.module_name}
                        </ListItem>
                      ))}
                  </List>
                ) : (
                  <List className="!grid !grid-cols-2 !gap-2">
                    {formValues &&
                      formValues?.map((elem) => (
                        <ListItem className="!flex !flex-col !items-start !p-0">
                          {elem?.module_name}{" "}
                          <TextField
                            onChange={(event) =>
                              setModule([
                                ...module,
                                {
                                  id: elem?.id,
                                  value: event.target.value,
                                },
                              ])
                            }
                            id={elem?.id}
                            size="small"
                            className="!w-[100%]"
                          />
                        </ListItem>
                      ))}
                  </List>
                )}
                <div className="flex flex-col">
                  <p>Description</p>
                  <ReactQuill placeholder="Enter Module Descriptions" value={description} onChange={setDescription} />
                </div>
              </div>
              <br />
              <span className="flex justify-end gap-5">
                <Button size="small" onClick={handleCloseModal} variant="contained" color="inherit" className="w-32">
                  CANCEL
                </Button>
                <Button size="small" variant="contained" color="success" className="w-32" onClick={submitData}>
                  Add
                </Button>
              </span>
            </Box>
          </Modal>

          <Modal
            open={open2}
            onClose={handleClose2}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box
              sx={{ ...style }}
              className="rounded-2xl !outline-none !w-1/2 !h-5/6 !border-0 !shadow-sm !shadow-blue-900 overflow-auto"
            >
              <p className="!text-black !underline !underline-offset-2  !font-bold pb-3 !text-center !text-xl">
                Update Plan
              </p>

              <div className="flex flex-col gap-2">
                <div className="!w-full">
                  <p>Plan Name </p>
                  <TextField
                    value={planName1}
                    onChange={(event) => setPlanName1(event.target.value)}
                    id="outlined-basic"
                    size="small"
                    className="!w-[100%]"
                    placeholder="Enter Plan Name"
                  />
                </div>
                <div className="flex justify-between w-full gap-4">
                  <div className="!w-full">
                    <p>Monthly Price </p>
                    <TextField
                      value={monthlyPrice1}
                      onChange={(event) => setMonthlyPrice1(event.target.value)}
                      id="outlined-basic"
                      size="small"
                      className="!w-[100%]"
                      placeholder="Enter Monthly Price "
                    />
                  </div>
                  <div className="!w-full">
                    <p>Yearly Price </p>
                    <TextField
                      value={yearlyPrice1}
                      onChange={(event) => setYearlyPrice1(event.target.value)}
                      id="outlined-basic"
                      size="small"
                      className="!w-[100%]"
                      placeholder="Enter Yearly Price "
                    />
                  </div>
                </div>

                <p>Plan Type</p>
                <TextField
                  id="outlined-basic"
                  size="small"
                  className="w-full"
                  value={editModule?.plan_type?.title}
                  disabled
                />
                {editModule?.plan_type?.title === "ERP" ? (
                  <div>
                    <p>Number Of Users</p>
                    <TextField
                      value={userNo1}
                      onChange={(event) => setUserNo1(event.target.value)}
                      id="outlined-basic"
                      size="small"
                      className="!w-[100%]"
                      placeholder="Enter Number Of Users"
                    />
                  </div>
                ) : null}

                {hideValue?.title === "ERP" ? (
                  hideValue?.title === "ERP"
                ) : editModule?.plan_type?.title === "ERP" ? (
                  <>
                    <List className="!grid !grid-cols-2 !border">
                      {formValues &&
                        editModule?.modules?.map((elem) => (
                          <ListItem className="!flex !items-center">
                            <Checkbox
                              checked={selectedUpdateModule?.map((i) => i.module_id).includes(elem?.module_id)}
                              onChange={(event) => {
                                editModule?.plan_type?.title !== "ERP" ? (
                                  setModule1([
                                    ...module1,
                                    {
                                      module_id: elem?.module_id,
                                      value_id: "",
                                      value: "",
                                    },
                                  ])
                                ) : (
                                  <>
                                    {!selectedUpdateModule?.map((i) => i.module_id).includes(elem?.module_id)
                                      ? setSelectedUpdateModule([
                                          ...selectedUpdateModule,
                                          {
                                            module_id: elem?.module_id,
                                          },
                                        ])
                                      : setSelectedUpdateModule(() =>
                                          selectedUpdateModule?.filter((i) => i.module_id !== elem?.module_id)
                                        )}
                                  </>
                                )
                              }}
                              inputProps={{
                                "aria-label": "controlled",
                              }}
                            />

                            {elem?.name}
                          </ListItem>
                        ))}
                    </List>
                  </>
                ) : (
                  <>
                    <List className="!grid !grid-cols-2 !gap-2">
                      {editModule?.modules?.map((elem) => (
                        <ListItem className="!flex !flex-col !items-start !p-0">
                          {elem?.name}
                          <TextField
                            defaultValue={elem?.value}
                            onChange={(event) =>
                              setModule1([
                                ...module1,
                                {
                                  module_id: elem?.module_id,
                                  value_id: elem?.value_id,
                                  value: event.target.value,
                                },
                              ])
                            }
                            id={elem?.id}
                            size="small"
                            className="!w-[100%]"
                          />
                        </ListItem>
                      ))}
                    </List>
                  </>
                )}
              </div>
              <div className="flex flex-col">
                <p>Description</p>
                <ReactQuill placeholder="Enter Module Descriptions" value={description} onChange={setDescription} />
              </div>
              <br />
              <span className="flex justify-end gap-5">
                <Button size="small" onClick={handleClose2} variant="contained" color="inherit" className="w-32">
                  CANCEL
                </Button>
                <Button size="small" variant="contained" color="success" className="w-32" onClick={submitUpdate}>
                  UPDATE
                </Button>
              </span>
            </Box>
          </Modal>
          {/* edit modal end */}

          {/* view visible view  start */}
          <Modal
            open={open1}
            onClose={handleClose1}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...style }} className="rounded-md w-1/2 !p-2 !border-0 !shadow-blue-900">
              <div className="flex items-center justify-between">
                <div className="!text-black !font-bold px-3 !text-xl">
                  {viewData?.plan_name}({viewData?.plan_type})
                </div>
                <IconButton onClick={handleClose1}>
                  <Close />
                </IconButton>
              </div>
              <span className="flex gap-3 py-2">
                <input
                  class="placeholder:italic placeholder:text-slate-400 block bg-white w-80 border border-slate-300 rounded-md py-2 px-2 pr-3 shadow-sm focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
                  placeholder="Search for anything..."
                  type="text"
                  value={searchQuery1}
                  onChange={(event) => setSearchQuery1(event.target.value)}
                />
              </span>
              <TableContainer
                component={Paper}
                sx={{ width: "100%", maxHeight: 440 }}
                className="!text-black !mx-auto !bg-white overflow-auto !shadow-none "
              >
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead className="!bg-slate-700 !px-0 !mx-0">
                    <TableRow className="!bg-slate-700">
                      <TableCell
                        style={{
                          borderRight: "1px solid #D0D0D0",
                        }}
                        className="!text-center !text-white !bg-slate-800 !font-bold"
                      >
                        SR. No.
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: "1px solid #D0D0D0",
                        }}
                        className="!text-center !bg-slate-800 !text-white !font-bold"
                      >
                        MODULE
                      </TableCell>
                      <TableCell style={{}} className="!text-center !bg-slate-800 !text-white !font-bold">
                        VALUE
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="!bg-red">
                    {details &&
                      details?.map((row, index) => (
                        <TableRow key={row.id} className="!text-slate-700 ">
                          <TableCell
                            className="!text-center !text-[12px]"
                            style={{
                              borderRight: "1px solid #D0D0D0",
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            className="!text-center !text-[12px]"
                            style={{
                              borderRight: "1px solid #D0D0D0",
                            }}
                          >
                            {row?.module_name ? row?.module_name : "--"}
                          </TableCell>
                          <TableCell className="!text-center !text-[12px]" style={{}}>
                            {row?.value ? row?.value : "--"}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div
                className="!float-right relative bg-white w-[100%] mx-auto bottom-0 right-0 py-3 border-t-2 border-gray-400 !flex-col !justify-end !items-center"
                style={{ marginRight: "0px" }}
              >
                <button onClick={nextData1} className="!float-right">
                  {currentPage1}...{totalPage1}
                  <ArrowForwardIosIcon />
                </button>
                <button onClick={previousData1} className="float-right">
                  <ArrowBackIosIcon />
                </button>
              </div>
            </Box>
          </Modal>
          {/* view visible view  end*/}
        </div>
        <TableContainer
          component={Paper}
          sx={{ width: "100%", maxHeight: 440 }}
          className="!text-black !mx-auto !bg-white overflow-auto px-2"
        >
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  SR. No.
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  PLAN NAME
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  PLAN TYPE
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  MODULE
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  MONTHLY PRICE
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  YEARLY PRICE
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  USER NUMBER
                </TableCell>
                <TableCell style={{}} className="!text-center !text-gray-800 !font-bold">
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="!bg-red">
              {data &&
                data?.map((row, index) => (
                  <TableRow key={row.id} className="!text-slate-700">
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                      }}
                    >
                      {row?.plan_name ? row?.plan_name : "--"}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                      }}
                    >
                      {row?.plan_type ? row?.plan_type : "--"}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                      }}
                    >
                      <VisibilityIcon onClick={(event) => handleViewDetail(event, row)} className="cursor-pointer" />
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                      }}
                    >
                      {row?.monthly_price ? <span className="text-green-500">₹ {row?.monthly_price}</span> : "--"}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                      }}
                    >
                      {row?.yearly_price ? <span className="text-green-500">₹ {row?.yearly_price}</span> : "--"}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                      }}
                    >
                      {row?.user_num ? row?.user_num : "--"}
                    </TableCell>
                    <TableCell className="!text-center !text-[12px]">
                      <EditIcon
                        className="!text-xl !cursor-pointer !text-green-600"
                        onClick={() => editDataList(row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className="!float-right relative bg-white w-[100%] mx-auto bottom-0 right-0 py-3 border-t-2 border-gray-400 !flex-col !justify-end !items-center"
          style={{ marginRight: "0px" }}
        >
          <button onClick={nextData} className="!float-right">
            {currentPage}...{totalPage}
            <ArrowForwardIosIcon />
          </button>
          <button onClick={previousData} className="float-right">
            <ArrowBackIosIcon />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Plan
