import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Divider, Tab } from "@mui/material"
import classNames from "classnames"
import React, { useEffect, useState } from "react"
import CustomButton from "shared/MkxButton"
import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"
import Brand from "./Brand"
import Category from "./Category"
import RequestedInventory from "./RequestedInventory"
import SubCategory from "./SubCategory"
import TypeOneValue from "./TypeOneValue"
import TypeThree from "./TypeThree"
import TypeThreeValue from "./TypeThreeValue"
import TypeTwo from "./TypeTwo"
import TypeTwoValue from "./TypeTwoValue"

const InventorySettings = () => {
  const [value, setValue] = useState("category")
  const [isRequested, setIsRequested] = useState(false)
  const [data, setData] = useState([])

  const manage_product_variant = localStorage.getItem("manage_product_variant")
  const isProductBase = manage_product_variant === "true"

  const handleChange = (event, value) => {
    setValue(value)
  }

  const getVariantsFn = () => {
    axiosInstance.get(API_URLS.storeRequest, { params: { type_of_request: value } }).then((response) => {
      setData(response.data.data)
    })
  }

  useEffect(() => {
    getVariantsFn()
  }, [value])

  useEffect(() => {
    isRequested ? setValue("type-one-value") : setValue("category")
  }, [isRequested])

  return (
    <>
      {!isProductBase && (
        <span className="flex items-center w-full gap-2 pb-2">
          <CustomButton
            onClick={() => setIsRequested(false)}
            className={classNames("w-full", !isRequested && "bg-gradient-to-r !text-white from-cyan-500 to-blue-500")}
          >
            Available
          </CustomButton>
          <CustomButton
            className={classNames("w-full", isRequested && "bg-gradient-to-r !text-white from-cyan-500 to-blue-500")}
            onClick={() => setIsRequested(true)}
          >
            Requested
          </CustomButton>
        </span>
      )}
      {isRequested && !isProductBase ? (
        <TabContext value={value}>
          <Box className="bg-white bg-opacity-30">
            <TabList onChange={handleChange}>
              <Tab className="!capitalize !font-semibold" label="Type One Value" value="type-one-value" />
              <Tab className="!capitalize !font-semibold" label="Type Two" value="type-two" />
              <Tab className="!capitalize !font-semibold" label="Type Two Value" value="type-two-value" />
              <Tab className="!capitalize !font-semibold" label="Type Three" value="type-three" />
              <Tab className="!capitalize !font-semibold" label="Type Three Value" value="type-three-value" />
            </TabList>
          </Box>
          <Divider />

          <RequestedInventory value={value} refetch={getVariantsFn} data={data} />
        </TabContext>
      ) : (
        <TabContext value={value}>
          <Box className="bg-white bg-opacity-30">
            {isProductBase ? (
              <TabList onChange={handleChange}>
                <Tab className="!capitalize !font-semibold" label="Category" value="category" />
                <Tab className="!capitalize !font-semibold" label="Sub Category" value="subcategory" />
                <Tab className="!capitalize !font-semibold" label="Brand" value="brand" />
              </TabList>
            ) : (
              <TabList onChange={handleChange}>
                <Tab className="!capitalize !font-semibold" label="Category" value="category" />
                <Tab className="!capitalize !font-semibold" label="Sub Category" value="subcategory" />
                <Tab className="!capitalize !font-semibold" label="Brand" value="brand" />
                <Tab className="!capitalize !font-semibold" label="Type One Value" value="type-one-value" />
                <Tab className="!capitalize !font-semibold" label="Type Two" value="type-two" />
                <Tab className="!capitalize !font-semibold" label="Type Two Value" value="type-two-value" />
                <Tab className="!capitalize !font-semibold" label="Type Three" value="type-three" />
                <Tab className="!capitalize !font-semibold" label="Type Three Value" value="type-three-value" />
              </TabList>
            )}
          </Box>
          <Divider />
          <TabPanel className="!p-0" value="category">
            <Category value="category" />
          </TabPanel>
          <TabPanel className="!p-0" value="subcategory">
            <SubCategory value="subcategory" />
          </TabPanel>
          <TabPanel className="!p-0" value="brand">
            <Brand value="brand" />
          </TabPanel>
          <TabPanel className="!p-0" value="type-one-value">
            <TypeOneValue value="type-one-value" />
          </TabPanel>
          <TabPanel className="!p-0" value="type-two">
            <TypeTwo value="type-two" />
          </TabPanel>
          <TabPanel className="!p-0" value="type-two-value">
            <TypeTwoValue value="type-two-value" />
          </TabPanel>
          <TabPanel className="!p-0" value="type-three">
            <TypeThree value="type-three" />
          </TabPanel>
          <TabPanel className="!p-0" value="type-three-value">
            <TypeThreeValue value="type-three-value" />
          </TabPanel>
        </TabContext>
      )}
    </>
  )
}

export default InventorySettings
