import axiosInstance from "config/axios"

export const offerManagmentFn = (reqBody) => {
  try {
    const response = axiosInstance.get(`offer-management-api-of-superadmin/`, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const addOffersFn = (reqBody) => {
  try {
    const response = axiosInstance.post(`offer-management-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateOffersFn = (reqBody) => {
  try {
    const response = axiosInstance.put(`offer-management-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const deleteOffersFn = (reqBody) => {
  try {
    const response = axiosInstance.delete(`offer-management-api-of-superadmin/`, {
      data: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const offerDataFn = (reqBody) => {
  try {
    const response = axiosInstance.get(`category-product-service-api-of-superadmin/`, { params: reqBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const enableDisableOfferFn = (reqBody) => {
  try {
    const response = axiosInstance.patch(`offer-management-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
