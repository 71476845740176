import { API_URLS } from "config/apiUrls"
import axios from "config/axios"

export const NotifictaionFn = (redBody) => {
  try {
    const response = axios.get(`push-notification-api-of-superadmin/`, { params: redBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const addNotifictaionFn = (redBody) => {
  try {
    const response = axios.post(`push-notification-api-of-superadmin/`, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const updateNotifictaionFn = (redBody) => {
  try {
    const response = axios.put(`push-notification-api-of-superadmin/`, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const changeConfigStatusFn = (redBody) => {
  try {
    const response = axios.patch(`push-notification-api-of-superadmin/`, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const notifictaionListFn = (redBody) => {
  try {
    const response = axios.get(API_URLS.notifications, { params: redBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
