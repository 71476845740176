import MenuItem from "@mui/material/MenuItem"
import { useFormik } from "formik"
import React from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { CustomButton } from "../../../shared/CustomButton"
import CustomSelect from "../../../shared/CustomSelect"
import CustomInput from "../../../shared/CustomInput"
import Close from "@mui/icons-material/Close"
// import CustomButton from "../shared/MkxButton";
// import CustomInput from "../../../../Shared/CustomInput";
// import CustomSelect from "../../../../Shared/CustomSelect";
// import GlassDiv from "../../../../Shared/GlassDiv";
// import { createContactSchema } from "../../../../schemas/Contact";
// import { countryStateCityFn } from "../../../../services/Compaigns/CountryStateCity";
// import { createContactFn } from "../../../../services/CrmApi/Contact/CreateContact";
// import { accountListWithoutPaginationFn } from "../../../../services/CrmApi/Contact/AccountListwithoutPagination";
// import { salesPersonListFn } from "../../../../services/CrmApi/SharedApi/SalesPersonList";

const CreateContact = () => {
  const navigate = useNavigate()
  const handleCancel = () => {
    formik.resetForm()
    navigate("/contact")
  }

  const initialValues = {
    account_id: "",
    contact_owner_id: "",
    contact_pic: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    mobile: "",
    assistant: "",
    lead_source: "",
    title: "",
    department: "",
    home_phone: "",
    fax: "",
    asst_phone: "",
    skype_id: "",
    secondary_email: "",
    twitter: "",
    reporting_to: "",
    description: "",
    mailing_street: "",
    mailing_city_id: "",
    mailing_state_id: "",
    mailing_code: "",
    mailing_country_id: "",
    other_street: "",
    other_city_id: "",
    other_state_id: "",
    other_code: "",
    other_country_id: "",
    vendor_name: "",
    rating: "",
    ticker_symbol: "",
    countries: [],
    mailing_state_list: [],
    mailing_city_list: [],
    other_state_list: [],
    other_city_list: [],
  }
  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: createContactSchema,
    enableReinitialize: true,
    onSubmit: () => {
      const formData = new FormData()
      formData.append("account_id", formik.values.account_id)
      formData.append("contact_owner_id", formik.values.contact_owner_id || localStorage.getItem("user_id"))
      formData.append("contact_pic", formik.values.contact_pic)
      formData.append("first_name", formik.values.first_name)
      formData.append("last_name", formik.values.last_name)
      formData.append("email", formik.values.email)
      formData.append("phone", formik.values.phone)
      formData.append("mobile", formik.values.mobile)
      formData.append("assistant", formik.values.assistant)
      formData.append("lead_source", formik.values.lead_source)
      formData.append("title", formik.values.title)
      formData.append("department", formik.values.department)
      formData.append("home_phone", formik.values.home_phone)
      formData.append("asst_phone", formik.values.asst_phone)
      formData.append("secondary_email", formik.values.secondary_email)
      formData.append("twitter", formik.values.twitter)
      formData.append("reporting_to", formik.values.reporting_to)
      formData.append("description", formik.values.description)
      formData.append("mailing_street", formik.values.mailing_street)
      formData.append("mailing_city_id", formik.values.mailing_city_id)
      formData.append("mailing_state_id", formik.values.mailing_state_id)
      formData.append("mailing_code", formik.values.mailing_code)
      formData.append("mailing_country_id", formik.values.mailing_country_id)
      formData.append("other_street", formik.values.other_street)
      formData.append("other_city_id", formik.values.other_city_id)
      formData.append("other_state_id", formik.values.other_state_id)
      formData.append("other_code", formik.values.other_code)
      formData.append("other_country_id", formik.values.other_country_id)
      formData.append("vendor_name", formik.values.vendor_name)
      formData.append("rating", formik.values.rating)

      // createContactForm(formData);
    },
  })
  // const client = useQueryClient();

  // const { data: accountList } = useQuery(
  //   ["accountList"],
  //   () => accountListWithoutPaginationFn(),
  //   {
  //     refetchOnWindowFocus: false,
  //     refetchOnReconnect: false,
  //   }
  // );
  // useQuery(["countries"], () => countryStateCityFn(), {
  //   refetchOnWindowFocus: false,
  //   refetchOnReconnect: false,
  //   onSuccess: (res) => {
  //     formik.setFieldValue("countries", res?.data?.data);
  //   },
  // });

  // useQuery(
  //   ["mailingState", formik.values.mailing_country_id],
  //   () => countryStateCityFn({ country_id: formik.values.mailing_country_id }),
  //   {
  //     refetchOnWindowFocus: false,
  //     refetchOnReconnect: false,
  //     enabled: formik.values.mailing_country_id ? true : false,
  //     onSuccess: (res) => {
  //       formik.setFieldValue("mailing_state_list", res?.data?.data);
  //     },
  //   }
  // );
  // useQuery(
  //   [
  //     "mailingCity",
  //     formik.values.mailing_country_id,
  //     formik.values.mailing_state_id,
  //   ],
  //   () =>
  //     countryStateCityFn({
  //       country_id: formik.values.mailing_country_id,
  //       state_id: formik.values.mailing_state_id,
  //     }),
  //   {
  //     refetchOnWindowFocus: false,
  //     refetchOnReconnect: false,
  //     enabled:
  //       formik.values.mailing_country_id && formik.values.mailing_state_id
  //         ? true
  //         : false,
  //     onSuccess: (res) => {
  //       formik.setFieldValue("mailing_city_list", res?.data?.data);
  //     },
  //   }
  // );
  // useQuery(
  //   ["otherState", formik.values.other_country_id],
  //   () => countryStateCityFn({ country_id: formik.values.other_country_id }),
  //   {
  //     refetchOnWindowFocus: false,
  //     refetchOnReconnect: false,
  //     enabled: formik.values.other_country_id ? true : false,
  //     onSuccess: (res) => {
  //       formik.setFieldValue("other_state_list", res?.data?.data);
  //     },
  //   }
  // );
  // useQuery(
  //   ["otherCity", formik.values.other_country_id, formik.values.other_state_id],
  //   () =>
  //     countryStateCityFn({
  //       country_id: formik.values.other_country_id,
  //       state_id: formik.values.other_state_id,
  //     }),
  //   {
  //     refetchOnWindowFocus: false,
  //     refetchOnReconnect: false,
  //     enabled:
  //       formik.values.other_country_id && formik.values.other_state_id
  //         ? true
  //         : false,
  //     onSuccess: (res) => {
  //       formik.setFieldValue("other_city_list", res?.data?.data);
  //     },
  //   }
  // );

  // const { data: salesPersonList } = useQuery(
  //   ["salesPersonList"],
  //   () => salesPersonListFn(),
  //   {
  //     refetchOnWindowFocus: false,
  //     refetchOnReconnect: false,
  //     enabled: localStorage.getItem("role") === "Store Admin" ? true : false,
  //   }
  // );
  // const { mutate: createContactForm } = useMutation(createContactFn, {
  //   onSuccess: (response) => {
  //     toast.success(
  //       response?.data?.msg ? response?.data?.msg : response?.data?.message
  //     );
  //     if (response?.data?.response_code === 200) {
  //       navigate("/crm/contact");
  //       formik.resetForm();
  //     }
  //     client.refetchQueries(["contactList"]);
  //   },
  //   onError: (error) => {
  //     toast.error(error?.message ? error?.message : error?.msg);
  //   },
  // });

  const ratinglist = ["None", "Acquired", "Active", "Market Failed", "Project Cancelled", "Shut Down"]
  const leadSourceList = [
    "Advertisement",
    "Cold Call",
    "Employee Referral",
    "External Referral",
    "Online Store",
    "Public Relation",
    "Sales Email Alias",
    "Seminar Partner",
    "Internal Seminar",
    "Trade Show",
    "Web Download",
    "Web Search",
    "Chat",
    "Twitter",
    "Facebook",
    "Google",
    "Website",
  ]

  return (
    <div>
      <h4 className="font-bold p-2 text-xl text-gray-800">Contact Information</h4>
      <div className="flex flex-col !my-3 !rounded bg-dark border-secondary shadow-card shadow-zinc-600 m-2">
        <form onSubmit={formik.handleSubmit} className="w-full h-auto p-2 ">
          <div className="flex justify-between gap-2 py-3 ">
            <div className="px-2 pb-2 border rounded-lg shadow-sm shadow-blue-900 hover:shadow-md lg:w-1/2">
              <p className="text-[#432CCD] text-lg py-2">Select Contact Image</p>

              <span className="flex items-center gap-2">
                <CustomButton
                  component="label"
                  className="bg-gradient-to-r from-purple-600 to-pink-300 !p-2 !px-5 !text-white"
                >
                  Choose File
                  <input
                    type="file"
                    hidden
                    id="contact_pic"
                    accept="image/png, image/jpeg"
                    name="contact_pic"
                    onChange={(e) => formik.setFieldValue("contact_pic", e.target.files[0])}
                  />
                </CustomButton>
                <p className="overflow-x-hidden text-ellipsis whitespace-nowrap w-52">
                  {formik.values.contact_pic?.name ? formik.values.contact_pic?.name : "No File Choosen"}
                </p>
              </span>
            </div>
            <span>
              <Close
                className="bg-black rounded-full text-red-500 cursor-pointer"
                onClick={() => navigate("/contact")}
              />
            </span>
          </div>
          <div className="grid grid-cols-1 gap-3">
            <h4 className="font-bold text-blue-800">Personal Information</h4>
            <div className="grid grid-cols-1 gap-3 lg:grid-cols-3 md:grid-cols-2">
              {localStorage.getItem("role") === "Store Admin" && (
                <CustomSelect
                  id="contact_owner_id"
                  formik={formik}
                  label="Contact Owner"
                  placeholder="Select Contact Owner"
                >
                  {/* {salesPersonList?.data?.sales_emp_info_list?.map((person) => (
                  <MenuItem
                    key={person?.id}
                    value={person?.id}
                    className="!capitalize"
                  >
                    {person?.first_name + " | " + person?.user_role}
                  </MenuItem>
                ))} */}
                  llllll
                </CustomSelect>
              )}

              <CustomSelect id="account_id" formik={formik} label="Account*" placeholder="Select Account">
                {/* {accountList?.data?.data?.map((account) => (
                <MenuItem key={account?.id} value={account.id}>
                  {account?.account_name} | {account?.phone}
                </MenuItem>
              ))} */}
                <MenuItem>account</MenuItem>
              </CustomSelect>

              <CustomInput label="First Name*" placeholder="Enter First Name" id="first_name" formik={formik} />

              <CustomInput label="Last Name*" id="last_name" formik={formik} placeholder="Enter Last Name" />

              <CustomInput id="email" type="email" formik={formik} placeholder="Enter Email" label="Email ID*" />
            </div>
            <h4 className="font-bold text-blue-800">More Information</h4>
            <div className="grid grid-cols-1 gap-3 lg:grid-cols-3 md:grid-cols-2">
              <CustomInput id="title" formik={formik} label="Title" placeholder="Enter Title" />

              <CustomInput id="mobile" formik={formik} type="number" label="Mobile*" placeholder="Enter Mobile" />

              <CustomInput id="vendor_name" label="Vendor Name" formik={formik} placeholder="Vendor Name" />
              <CustomInput id="reporting_to" label="Reporting To" formik={formik} placeholder="Reporting To" />

              <CustomSelect id="lead_source" formik={formik} label="Lead Source*" placeholder="Select Lead Source">
                {leadSourceList?.map((type, index) => (
                  <MenuItem key={index} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </CustomSelect>

              <CustomInput id="department" label="Department" placeholder="Enter Department" formik={formik} />
              <CustomInput
                id="home_phone"
                type="number"
                formik={formik}
                label="Home Phone"
                placeholder="Enter Home Phone"
              />
              <CustomInput id="assistant" formik={formik} label="Assistant" placeholder="Enter Assistant Name" />
              <CustomInput
                id="asst_phone"
                type="number"
                formik={formik}
                label="Assistant Phone"
                placeholder="Enter Assistant Phone"
              />

              <CustomSelect id="rating" formik={formik} label="Rating" placeholder="Select Rating">
                {ratinglist?.map((type) => (
                  <MenuItem value={type}>{type}</MenuItem>
                ))}
              </CustomSelect>
            </div>
            <h4 className="font-bold text-blue-800">Billing Information</h4>
            <div className="grid grid-cols-1 gap-3 lg:grid-cols-3 md:grid-cols-2">
              <CustomInput
                id="secondary_email"
                formik={formik}
                label="Secondary Email"
                placeholder="Enter Secondary Email"
              />
              <CustomInput id="twitter" formik={formik} label="Twitter" placeholder="Twitter" />
              <CustomInput
                id="mailing_street"
                formik={formik}
                label="Mailing Street"
                placeholder="Enter Mailing Street"
              />

              <CustomSelect
                id="mailing_country_id"
                formik={formik}
                label="Mailing Country*"
                placeholder="Select Mailing Country"
              >
                {formik?.values?.countries?.map((country) => {
                  return <MenuItem value={country?.id}>{country?.name}</MenuItem>
                })}
              </CustomSelect>

              <CustomSelect
                id="mailing_state_id"
                formik={formik}
                label="Mailing State*"
                placeholder="Select Mailing State"
              >
                {formik?.values?.mailing_state_list?.map((state) => {
                  return <MenuItem value={state?.id}>{state?.state}</MenuItem>
                })}
              </CustomSelect>

              <CustomSelect
                id="mailing_city_id"
                formik={formik}
                label="Mailing City*"
                placeholder="Select Mailing City"
              >
                {formik?.values?.mailing_city_list?.map((city) => {
                  return <MenuItem value={city?.id}>{city?.city}</MenuItem>
                })}
              </CustomSelect>

              <CustomInput
                id="mailing_code"
                type="number"
                formik={formik}
                label="Mailing Code*"
                placeholder="Enter Mailing Code"
              />

              <CustomInput id="other_street" formik={formik} label="Other Street" placeholder="Enter Other Street" />

              <CustomSelect
                id="other_country_id"
                formik={formik}
                label="Other Country"
                placeholder="Select Other Country"
              >
                {formik?.values?.countries?.map((country) => {
                  return <MenuItem value={country?.id}>{country?.name}</MenuItem>
                })}
              </CustomSelect>

              <CustomSelect id="other_state_id" formik={formik} label="Other State" placeholder="Select Other State">
                {formik?.values?.other_state_list?.map((state) => {
                  return <MenuItem value={state?.id}>{state?.state}</MenuItem>
                })}
              </CustomSelect>

              <CustomSelect id="other_city_id" formik={formik} label="Other City" placeholder="Select Other City">
                {formik?.values?.other_city_list?.map((city) => {
                  return <MenuItem value={city?.id}>{city?.city}</MenuItem>
                })}
              </CustomSelect>

              <CustomInput id="other_code" formik={formik} label="Other Code" placeholder="Enter Other Code" />
            </div>

            <CustomInput
              id="description"
              rows="4"
              multiline={true}
              formik={formik}
              placeholder="Enter Description"
              label="Description"
            />

            <div className="flex justify-end gap-4">
              <CustomButton type="submit">Save</CustomButton>

              <CustomButton onClick={handleCancel}>Cancel</CustomButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateContact
