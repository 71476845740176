import axiosInstance from "config/axios"

export const paymentGatewayFn = (reqbody) => {
  try {
    const response = axiosInstance.get("payment-gateway-api-of-superadmin/", { params: reqbody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateGatewayFn = (reqbody) => {
  try {
    const response = axiosInstance.put("payment-gateway-api-of-superadmin/", reqbody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const gatewaysCredentialFn = (reqbody) => {
  try {
    const response = axiosInstance.get(`payment-gateway-credential-api-of-superadmin/`, { params: reqbody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const addGatewayCredentialFn = (reqbody) => {
  try {
    const response = axiosInstance.post(`payment-gateway-credential-api-of-superadmin/`, reqbody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateGatewayCredentialFn = (reqbody) => {
  try {
    const response = axiosInstance.put(`payment-gateway-credential-api-of-superadmin/`, reqbody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
