import { Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { UnitOfMeasuringFn } from "Services/UnitofMeasurement"
import { useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { Active, Inactive } from "shared/ActivaInactive"
import GlassDiv from "shared/CustomDiv"
import CustomPagination from "shared/CustomPagination"
import CustomInput from "shared/MkxInput"
import MkxTableCell from "shared/MkxTable/MkxTableCell"
import { MkxTableLoader } from "shared/MkxTable/MkxTableLoader"
import NoDataFound from "shared/NoDataFound"

const Mesuring = () => {
  const { store_id } = useParams()
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)

  const { data, isLoading } = useQuery(["MeasuringUnit", store_id, page, search], () =>
    UnitOfMeasuringFn({ store_id, page: page, search })
  )

  return (
    <GlassDiv className="flex flex-col !p-0">
      <div className="flex items-center justify-between p-3">
        <CustomInput
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Search Unit of Mesuring "
        />
      </div>

      <TableContainer>
        <Table className="border">
          <TableHead>
            <TableRow>
              <MkxTableCell isHead>No.</MkxTableCell>
              <MkxTableCell isHead>Unit Code</MkxTableCell>
              <MkxTableCell isHead>Status</MkxTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <MkxTableLoader loading={isLoading} row={2} />
            {data?.data?.data?.map((item, ind) => (
              <TableRow key={ind + 1}>
                <MkxTableCell>{page === 1 ? ind + 1 : (page - 1) * 8 + ind + 1}</MkxTableCell>
                <MkxTableCell>{item?.unit_code ? item?.unit_code : "--"}</MkxTableCell>
                <MkxTableCell>
                  <div className="flex items-center justify-center">
                    {item?.active_status ? <Active /> : <Inactive />}
                  </div>
                </MkxTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination data={data} setPage={setPage} />
      <NoDataFound data={data} />
    </GlassDiv>
  )
}

export default Mesuring
