import TextField from "@mui/material/TextField"
import React, { useEffect, useState } from "react"

import FilterAltIcon from "@mui/icons-material/FilterAlt"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import Typography from "@mui/material/Typography"
import { CustomIconButton } from "../../../../shared/CustomIconButton"

import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import axios from "config/axios"
import { toast } from "react-toastify"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

const ProductChoice = () => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState([])
  const [name1, setName1] = useState("")
  const [rowData, setRowData] = useState([])
  const [ids, setIds] = useState(null)
  const [addBtn, setAddBtn] = useState(true)
  const [searchQuery, setSearchQuery] = useState("")

  const previousData = () => {
    setPage(page - 1)
  }
  const nextData = () => {
    setPage(page + 1)
  }

  useEffect(() => {
    axios
      .get(
        `choice-other-variant-data/?page=${page}`,

        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setData(response?.data?.data)
      })
      .catch((error) => {
        alert(error?.message)
      })
  }, [])

  useEffect(() => {
    rowData !== [] && setName1(rowData?.choice)
  }, [rowData])

  const createProduct = () => {
    const subData = {
      id: ids,
      choice: name1,
    }
    !ids
      ? axios
          .post(`choice-other-variant-data/`, subData, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          })
          .then((response) => {
            setName1("")
            toast(response?.data?.message)
          })
          .catch((error) => {
            console.log(error)
          })
      : axios
          .post(`choice-other-variant-data/`, subData, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          })
          .then((response) => {
            setName1("")
            toast(response?.data?.message)
            setAddBtn(true)
          })
          .catch((error) => {
            console.log(error)
          })
  }

  const EditData = (row) => {
    setRowData(row)
    setIds(row.id)
    setAddBtn(false)
  }

  return (
    <div className="bg-slate-100">
      <div className="flex justify-between bg-slate-100">
        <div className="p-2">
          <p className="text-xl font-bold text-black font">Your Choice ( Type Three ) </p>
          <p className="text-black">Manage/Product Variants Storage</p>
        </div>
        <div className="mt-6 mr-3">
          <Button
            onClick={handleOpen}
            className="!text-sm !font-bold !bg-white !px-2 !py-1 mt-2"
            style={{ border: "1px solid black" }}
          >
            + Add New Choice
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="!border-0 !rounded-xl">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {addBtn ? (
                  <div className="!text-center underline">Add Variant Choice</div>
                ) : (
                  <div className="!text-center underline">Update Variant Choice</div>
                )}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="gap-y-3">
                  {/* <TextField id="outlined-basic" label="Outlined"  variant="outlined" /> */}
                  {/* <InputLabel id="demo-simple-select-label">Category Name</InputLabel>
                                    <Select
                                        className="!w-80 !mt-2 !text-black"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={categoryId}
                                        onChange={(e) => setCategoryId(e.target.value)}
                                    >
                                     {category ?
                                            category.map((elem) =>
                                                <MenuItem value={elem.id}>{elem?.title}</MenuItem>
                                            )
                                            : null
                                        }
                                    </Select> */}
                  <TextField
                    value={name1}
                    onChange={(event) => setName1(event.target.value)}
                    id="outlined-basic"
                    label="value"
                    variant="outlined"
                    className="!w-80 !mt-2"
                  />
                </div>
                <div className="items-center justify-between ">
                  <Button variant="outlined" className="float-left top-3" onClick={handleClose}>
                    CANCEL
                  </Button>
                  {addBtn ? (
                    <Button
                      variant="outlined"
                      className="float-right top-3"
                      style={{ marginRight: "15px" }}
                      onClick={createProduct}
                    >
                      CREATE
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      className="float-right top-3"
                      style={{ marginRight: "15px" }}
                      onClick={createProduct}
                    >
                      UPDATE
                    </Button>
                  )}
                </div>
              </Typography>
            </Box>
          </Modal>
        </div>
      </div>

      <div className="flex flex-col !my-3 !rounded  bg-dark bg-slate-100 shadow-card shadow-zinc-600 m-2">
        <div className="flex items-center justify-between p-2 h-14 border-zinc-600">
          <div className="flex">
            <CustomIconButton>
              <FilterAltIcon className="!text-black" />
            </CustomIconButton>
            <input
              type="text"
              className="!border-3 !border-black !w-72 px-2 rounded-md py-1"
              placeholder="search"
              style={{ border: "1px solid black" }}
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
          </div>
          {/* <CustomToggleButton
            id="activate-deactivate"
            name="activate-deactivate"
          /> */}
          {/* <div className="flex">
                        <select
                            className="rounded-md text-black p-1.5 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
                            name="store_id"
                            id="store_id"
                        >
                            <option>hello</option>
                            <option>hello</option>
                            <option>hello</option>
                        </select>
                    </div> */}
        </div>

        <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  {/* <input
                    type="checkbox"
                    className="w-5 h-5 mr-2 border-gray-300 rounded"
                  /> */}
                  ID
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  YOUR CHOICE
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data
                  ?.filter((item) => item.choice && item.choice.toLowerCase().includes(searchQuery.toLowerCase()))

                  .map((row) => (
                    <TableRow key={row?.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {/* <input
                          id={row?.id}
                          name={row?.id}
                          type="checkbox"
                          className="w-5 h-5 mr-5 border-gray-300 rounded"
                        /> */}
                        {row?.id}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.choice}
                      </TableCell>

                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        <DeleteIcon className="!text-xl !text-red-600 !cursor-pointer" />
                        <button onClick={handleOpen}>
                          <EditIcon
                            className="!text-xl !cursor-pointer !text-green-600"
                            onClick={() => EditData(row)}
                          />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className="!float-right bg-white w-[100%] relative mx-auto bottom-0 right-0 py-3 border-t-2 border-gray-300 !flex-col !justify-end !items-center"
          style={{ marginRight: "0px" }}
        >
          <button onClick={nextData} className="float-right">
            <ArrowForwardIosIcon />
          </button>
          <button onClick={previousData} className="float-right">
            <ArrowBackIosIcon />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductChoice
