import { DeleteTwoTone, ReportTwoTone } from "@mui/icons-material"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import EditIcon from "@mui/icons-material/Edit"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { Checkbox, Dialog, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import axios from "config/axios"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Active, Inactive } from "../../shared/ActivaInactive"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

const VariantManagement = () => {
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState("")
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [category, setCategory] = useState([])
  const [varientId, setVarientId] = useState("")
  const [value1, setValue1] = useState("")
  const navigate = useNavigate()
  const [newCategory, setNewCategory] = useState([])
  const [businessCategoryId, setBussinessCategoryId] = useState("")
  const [businessSubCategoryId, setBusinessSubCategoryId] = useState("")
  const [categoryId, setCategoryId] = useState("")
  const [businessData, setBusinessData] = useState([])
  const [subBusinessData, setSubBusinessData] = useState([])
  // end
  const [currentPage, setCurrentPage] = useState(null)
  const [totalPage, setTotalPage] = useState(null)
  // edit start
  const [rowData, setRowData] = useState([])
  const [ids, setIds] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])
  const [isDelete, setIsDelete] = useState(false)
  // edit close

  const previousData = () => {
    setPage(page - 1)
  }
  const nextData = () => {
    setPage(page + 1)
  }
  console.log(page)

  const VariantList = () => {
    searchQuery === ""
      ? axios
          .get(`varient-value-data-list/?page=${page}&category_id=&product_variant_name=`)
          .then((response) => {
            setData(response.data.data)
            setCurrentPage(response?.data?.current_page)
            setTotalPage(response?.data?.total_pages)
          })
          .catch((error) => {
            alert(error.message)
          })
      : axios
          .get(`varient-value-search-api-for-super-admin/?search_value=${searchQuery}`)
          .then((response) => {
            setData(response.data.data)
            setCurrentPage(response?.data?.current_page)
            setTotalPage(response?.data?.total_pages)
          })
          .catch((error) => {
            alert(error.message)
          })
  }

  useEffect(() => {
    VariantList()
  }, [page, searchQuery])

  const createData = () => {
    const subData = {
      id: ids,
      variant_id: varientId || rowData?.variant?.id,
      value: value1,
    }

    !ids
      ? axios
          .post(`add-variant-value-data/`, subData)
          .then((response) => {
            setValue1("")
            setVarientId("")
            toast.success(response?.data?.message)
            VariantList()
            handleClose()
          })
          .catch((error) => {
            console.log("error page", error.response)
            console.log(error)
          })
      : axios
          .post(`varient-value-data-list/`, subData)
          .then((response) => {
            setValue1("")
            setVarientId("")
            toast.success(response?.data?.message)
            VariantList()
            handleClose()
          })
          .catch((error) => {
            console.log("error page", error.response)
            console.log(error)
          })
  }

  const redirect = () => {
    navigate("/variant-mangement-color/variant-list")
  }

  useEffect(() => {
    axios
      .get(`product-category-list-data/?page=2&business_sub_category_id&product_category_name`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setNewCategory(response?.data?.data)
      })
      .catch((error) => {
        console.log("page not found", error)
      })
  }, [])

  const EditData = (row) => {
    setRowData(row)
    setIds(row.id)
    setValue1(row?.value)
  }

  const handleCloseModale = () => {
    setVarientId("")
    setValue1("")
    setIds(null)
    setRowData([])
    handleClose()
  }

  const empty = () => {}

  const CategoryList = () => {
    axios
      .post(`business-category-list-data/`, {})
      .then((response) => {
        setBusinessData(response.data.business_category_list_data)
      })
      .catch((error) => {
        console.log(error)
        // alert(error.message);
      })
  }

  useEffect(() => {
    CategoryList()
  }, [])

  const StoreList = () => {
    const reqbody = new FormData()
    reqbody.append(
      "business_category_ids",
      businessCategoryId !== "" ? businessCategoryId : rowData?.variant?.category?.business_type?.category?.id
    )
    axios
      .post(`business-sub-category-list-data/`, reqbody, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setSubBusinessData(response?.data.business_sub_category_list)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  useEffect(() => {
    rowData.length !== 0 || businessCategoryId !== "" ? StoreList() : empty()
  }, [businessCategoryId, rowData])

  const categoryListData = () => {
    const reqbody = new FormData()
    reqbody.append(
      "business_type_id",
      businessSubCategoryId !== "" ? businessSubCategoryId : rowData?.variant?.category?.business_type?.id
    )

    axios
      .post(`category-list-of-business-type-api-for-admin/`, reqbody, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setNewCategory(response?.data?.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    rowData.length !== 0 || businessSubCategoryId !== "" ? categoryListData() : empty()
  }, [businessSubCategoryId, rowData])

  const variantDataList = () => {
    const fd = new FormData()
    fd.append("category_id", categoryId !== "" ? categoryId : rowData?.variant?.category?.id)
    axios
      .post(`type-one-list-of-category-api-for-admin/`, fd, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setCategory(response.data.data)
      })
      .catch((error) => {
        console.log("page not found", error)
        // alert(error.message);
      })
  }

  useEffect(() => {
    rowData.length !== 0 || categoryId !== "" ? variantDataList() : empty()
  }, [categoryId, rowData])

  const handleAllRowSelect = (event) => {
    setSelectedIds(event.target.checked ? data.map((i) => i.id) : [])
  }

  const handleSelectRow = (id) => {
    setSelectedIds((prevSelectedRow) =>
      prevSelectedRow.includes(id) ? prevSelectedRow.filter((i) => i !== id) : [...prevSelectedRow, id]
    )
  }

  const handleDelete = () => {
    axios
      .post(`delete-type-one-value-api-for-superadmin/`, { variant_value_ids: selectedIds })
      .then((response) => {
        VariantList()
        setIsDelete(false)
        setSelectedIds([])
        toast.success(response.success.message)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const hadleClearAll = () => {
    setIsDelete(false)
    setSelectedIds([])
  }

  return (
    <>
      <div className="bg-slate-100">
        <div className="flex justify-between bg-slate-100">
          <div className="p-2">
            <p className="text-xl font-bold text-black font">Manage Color variant (Type One)</p>
            {/* <p className="text-black">Manage Your Variants</p> */}
          </div>

          <div className="mt-6 mr-3">
            <Button
              onClick={handleOpen}
              className="!text-sm !font-bold !bg-white !px-2 !py-1 mt-2"
              style={{ border: "1px solid black" }}
            >
              + Add Type One Value
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="!border-0 !rounded-xl">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  <div className="!text-center underline">
                    {ids === null ? "Add Type One Value" : "Update Type One Value"}
                  </div>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div className="gap-y-1">
                    <FormControl fullWidth>
                      <div className="py-2 text-sm">Business Type :</div>
                      <Select
                        value={businessCategoryId || rowData?.variant?.category?.business_type?.category?.id || ""}
                        size="small"
                        onChange={(event) => setBussinessCategoryId(event.target.value)}
                      >
                        {businessData?.map((res) => {
                          return <MenuItem value={res.id}>{res.name}</MenuItem>
                        })}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <div className="pt-2 text-sm">Business Sub Type : </div>
                      <Select
                        size="small"
                        value={businessSubCategoryId || rowData?.variant?.category?.business_type?.id || ""}
                        className="!mt-1 !w-full"
                        onChange={(event) => setBusinessSubCategoryId(event.target.value)}
                      >
                        {subBusinessData?.map((res) => {
                          return <MenuItem value={res.id}>{res.name}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <div className="pt-2 text-sm">Category :</div>
                      <Select
                        size="small"
                        className="!mt-1"
                        value={categoryId || rowData?.variant?.category?.id || ""}
                        onChange={(event) => setCategoryId(event.target.value)}
                      >
                        {newCategory?.map((res) => {
                          return <MenuItem value={res.id}>{res.category}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="w-[100%]">
                    <InputLabel
                      id="demo-simple-select-label"
                      className="!mt-2 !text-sm !text-gray-700"
                      style={{ fontFamily: "'Poppins', sans-serif" }}
                    >
                      Type One :
                    </InputLabel>
                    <Select
                      className="!w-full !mt-1 !text-black"
                      labelId="demo-simple-select-label"
                      size="small"
                      id="demo-simple-select"
                      value={varientId || rowData?.variant?.id || ""}
                      onChange={(event) => setVarientId(event.target.value)}
                    >
                      {category
                        ? category.map((item) => (
                            <MenuItem value={item.id}>
                              {item.variant} ({item?.category})
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </div>
                  <InputLabel className="!mt-3 !text-sm !text-gray-700" style={{ fontFamily: "'Poppins', sans-serif" }}>
                    Value :
                  </InputLabel>
                  <TextField
                    value={value1}
                    onChange={(event) => setValue1(event.target.value)}
                    id="outlined-basic"
                    // label="color"
                    variant="outlined"
                    className="!w-[100%] !mt-1"
                    size="small"
                  />
                  <div className="items-center justify-between ">
                    <Button
                      variant="contained"
                      className="float-left top-3"
                      onClick={handleCloseModale}
                      color="warning"
                    >
                      CANCEL
                    </Button>
                    {ids === null ? (
                      <Button variant="contained" className="float-right top-3" onClick={createData} color="success">
                        CREATE
                      </Button>
                    ) : (
                      <Button variant="contained" className="float-right top-3" onClick={createData} color="success">
                        UPDATE
                      </Button>
                    )}
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>

        <div className="flex flex-col !my-3 !rounded  bg-dark bg-slate-100 shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center justify-between p-2 h-14 border-zinc-600">
            <div className="flex">
              <input
                type="text"
                value={searchQuery}
                className="!border-3 !border-black mx-3 !w-72 px-2 rounded-md py-1"
                placeholder="search"
                style={{ border: "1px solid black" }}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
            <div className="flex gap-x-2">
              <div>
                <div>
                  {selectedIds?.length !== 0 && (
                    <>
                      <Button
                        startIcon={<DeleteTwoTone />}
                        className="!text-white bg-gradient-to-r from-red-500 to-rose-500"
                        onClick={() => setIsDelete(true)}
                      >
                        Delete
                      </Button>
                      <Dialog
                        open={isDelete}
                        onClose={() => setIsDelete(false)}
                        PaperProps={{ className: "!max-w-[500px] w-96" }}
                      >
                        <div className="flex flex-col items-center gap-3 p-5">
                          <ReportTwoTone color="error" className="!text-5xl" />
                          <p className="text-xl">Are you sure!</p>
                          <p>You want to delete selected products?</p>
                        </div>
                        <span className="flex justify-end gap-3 pb-3 pr-3">
                          <Button
                            size="small"
                            variant="outlined"
                            color="inherit"
                            onClick={hadleClearAll}
                            className="!capitalize"
                          >
                            Cancel
                          </Button>
                          <Button
                            size="small"
                            disableElevation
                            color="error"
                            className="!capitalize"
                            variant="contained"
                            onClick={() => handleDelete()}
                          >
                            Delete
                          </Button>
                        </span>
                      </Dialog>
                    </>
                  )}
                </div>
              </div>
              <div className="float-left">
                <button
                  onClick={redirect}
                  className="px-3 py-1 text-lg text-black bg-white rounded-lg"
                  style={{ border: "1px solid black" }}
                >
                  <VisibilityIcon /> Type One List
                </button>
              </div>
            </div>
          </div>

          <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    <Checkbox onChange={handleAllRowSelect} />
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    BUSINESS TYPE
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    BUSINESS SUB TYPE
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    CATEGORY
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    TYPE ONE
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    VALUE
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    STATUS
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    ACTION
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data !== "" &&
                  data?.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        <Checkbox
                          value={row?.id}
                          checked={selectedIds.filter((id) => id === row?.id)?.length === 1 ? true : false}
                          onChange={() => handleSelectRow(row.id)}
                        />
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.variant?.category?.business_type?.category?.name}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.variant?.category?.business_type?.name}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.variant?.category?.title}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.variant?.title}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.value}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.business_type?.category?.active_status == true ? <Active /> : <Inactive />}
                      </TableCell>
                      <TableCell className="!text-center !text-slate-700">
                        {/* <DeleteIcon className="!text-xl !text-red-600 !cursor-pointer" /> */}
                        <button onClick={handleOpen}>
                          <EditIcon
                            className="!text-xl !cursor-pointer !text-green-600"
                            onClick={() => EditData(row)}
                          />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            className="!float-right py-3 relative !right-0 bg-white border-t border-slate-400"
            style={{ marginRight: "0px" }}
          >
            <button onClick={nextData} className="float-right">
              {currentPage}...{totalPage}
              <ArrowForwardIosIcon />
            </button>
            <button onClick={previousData} className="float-right">
              <ArrowBackIosIcon />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default VariantManagement
