import FilterAltIcon from "@mui/icons-material/FilterAlt"
import React, { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import axios from "../../../config/axios"
import { Active, Inactive } from "../../../shared/ActivaInactive"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import Popup from "reactjs-popup"
import { CustomIconButton } from "../../../shared/CustomIconButton"
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange"
import VisibilityIcon from "@mui/icons-material/Visibility"
import "../CountryList/country.css"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

const CountryList = () => {
  const [data, setData] = useState()
  const [store, setStore] = useState()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [searchQuery, setSearchQuery] = useState("")

  const box1 = useRef()

  const closeModal = () => {
    box1.current.style.display = "none"
  }
  const [country] = useState([])
  const StoreList = () => {
    axios
      .get(`store-list-api-for-superadmin/`)
      .then((response) => {
        setStore(response?.data.store_list_data)
        // alert(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response)
        console.log(error)
        // alert(error.message);
      })
  }

  const handleChange = (event) => {
    event.target.checked === true ? country.push(event.target.value) : country.pop(event.target.value)
    console.log("Country Selcted", country)
  }

  const handleActivateDeactivate = (event) => {
    event.target.checked === true ? ActivateCountry() : DeactivateCountry()
  }
  const ActivateCountry = () => {
    const reqBodyForActivate = {
      country: country,
    }

    console.log("Activate", reqBodyForActivate)

    axios
      .post(`activate-country-store/`, { ...reqBodyForActivate })
      .then((response) => {
        console.log(response.data)
        countryList()
        reqBodyForActivate.country.length !== 0
          ? toast.success("Country with ID " + reqBodyForActivate.country + " are Activated")
          : toast.warning("Please Select A Country First")
      })
      .catch((error) => {
        console.log("", error.response)
        console.log(error)
        alert(error.message)
      })
  }

  const DeactivateCountry = () => {
    const reqBodyForDeactivate = {
      country: country,
    }

    console.log("Deactivate", reqBodyForDeactivate)

    axios
      .post(`deactivate-country-store/`, { ...reqBodyForDeactivate })
      .then((response) => {
        console.log(response.data)
        countryList()
        reqBodyForDeactivate.country.length !== 0
          ? toast.success("Country with ID " + reqBodyForDeactivate.country + " are Deactivated")
          : toast.warning("Please Select A Country First")
      })
      .catch((error) => {
        console.log("", error.response)
        console.log(error)
        alert(error.message)
      })
  }

  const countryList = () => {
    axios
      .get(`country-list-data/`)
      .then((response) => {
        setData(response?.data?.data)
        // console.log("country data ===>",response?.data?.data[0].data_currency[0]?.currency_name);
        // alert(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response)
        console.log(error)
        // alert(error.message);
      })
  }

  useEffect(
    () => {
      StoreList()
      countryList()
    },
    // eslint-disable-next-line
    []
  )

  // const initialValues = {
  //   store: "",
  //   country: "",
  // };
  // const formik = useFormik({
  //   initialValues: initialValues,

  //   onSubmit: (values, action) => {
  //     const reqBody = {
  //       store: values.store_id,
  //       country: country,
  //     };

  //     console.log("ReqBody", reqBody);
  //     axios
  //       .post(
  //         `map-country-store/`,
  //         { ...reqBody },
  //
  //       )
  //       .then((response) => {
  //         console.log(response.data);
  //         countryList();
  //         // reqBody.country.length !== 0
  //         //   ? toast.success(
  //         //       "Country with ID " +
  //         //         reqBody.country +
  //         //         " are maped to " +
  //         //         reqBody.store
  //         //     )
  //         //   : toast.warning("Please Select A Country First");
  //       })
  //       .catch((error) => {
  //         console.log("", error.response);
  //         console.log(error);
  //         alert(error.message);
  //       });
  //     action.resetForm();
  //   },
  // });

  // console.log(data.data_currency)
  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font-bold text-gray-800 font">Country List</p>
            {/* <p className="text-gray-700">Manage your country list</p> */}
          </div>
          {/* <AddCountry /> */}
        </div>
        <div className="flex flex-col !my-3 !rounded bg-dark border-secondary shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center justify-between p-2 bg-gray-100 h-14 border-zinc-600">
            <div className="flex ">
              <CustomIconButton>
                <FilterAltIcon className="text-black" />
              </CustomIconButton>
              {/* <Search /> */}
              <input
                type="text"
                className="!border-3 !border-black !w-72 px-2 rounded-md py-1"
                placeholder="search"
                style={{ border: "1px solid black" }}
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
            {/* <CustomToggleButton
                id="activate-deactivate"
                name="activate-deactivate"
                onChange={handleActivateDeactivate}
              /> */}
            {/* <div className="flex">
                <select
                  className="rounded-md text-black p-1.5 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
                  name="store_id"
                  id="store_id"
                  // value={formik.values["store_id"]}
                  // onChange={formik.handleChange}
                >
                  <option value="">Please select store from here</option>
                  {store &&
                    store.map((store) => {
                      return (
                        <option key={store.id} value={store.id}>
                          {store.store_name}
                        </option>
                      );
                    })}
                </select>
                <CustomButton type="submit" className="!p-1.5">
                  Assign Country List
                </CustomButton>
              </div> */}
          </div>
          <div
            className="flex items-center justify-between bg-secondary"
            sx={{ maxHeight: 440 }}
            style={{
              borderBottom: "1px solid #D0D0D0",
              fontFamily: "'Poppins', sans-serif",
            }}
          >
            <div
              className="w-full text-center "
              style={{
                borderRight: "1px solid #D0D0D0",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              {/* <input
                type="checkbox"
                className="w-5 h-5 mr-5 border-gray-300 rounded"
              /> */}
              <p className="py-2 font-bold text-black" style={{ fontFamily: "'Poppins', sans-serif" }}>
                ID
              </p>
            </div>
            <div
              className="items-center w-full text-center"
              style={{
                borderRight: "1px solid #D0D0D0",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              <p className="py-2 font-bold text-black" style={{ fontFamily: "'Poppins', sans-serif" }}>
                Country Name
              </p>
            </div>
            <div
              className="flex justify-center w-full"
              style={{
                borderRight: "1px solid #D0D0D0",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              <p className="py-2 font-bold text-black" style={{ fontFamily: "'Poppins', sans-serif" }}>
                Currency
              </p>
            </div>
            <div
              className="flex justify-center w-full"
              style={{
                borderRight: "1px solid #D0D0D0",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              <p className="py-2 font-bold text-black" style={{ fontFamily: "'Poppins', sans-serif" }}>
                Tax
              </p>
            </div>
            <div
              className="flex justify-center w-full"
              style={{
                borderRight: "1px solid #D0D0D0",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              <p className="py-2 font-bold text-black" style={{ fontFamily: "'Poppins', sans-serif" }}>
                Status
              </p>
            </div>
            <div className="flex justify-center w-full">
              <p className="font-bold text-black" style={{ fontFamily: "'Poppins', sans-serif" }}>
                Action
              </p>
            </div>
          </div>
          <div className="!overflow-scroll" style={{ height: "100vh" }}>
            {data &&
              data
                ?.filter((item) => item?.name && item?.name.toLowerCase().includes(searchQuery.toLowerCase()))
                .map((country) => {
                  return (
                    <div key={country.id} className="flex items-center justify-between border-b border-zinc-600">
                      <div
                        className="w-full text-center"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {/* <input
                          id={country.id}
                          name={country.id}
                          value={country.id}
                          onChange={handleChange}
                          type="checkbox"
                          className="w-5 h-5 mr-5 border-gray-300 rounded"
                        /> */}
                        <p className="py-2 text-[12px] text-gray-600">{country.id}</p>
                      </div>
                      <div
                        className="items-center w-full text-center text-gray-600 "
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        <p className="py-2 text-[12px]">{country.name}</p>
                      </div>
                      <div
                        className="flex items-center justify-center w-full py-2 text-center"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        <Popup
                          trigger={
                            <button className="button">
                              {" "}
                              <CurrencyExchangeIcon />{" "}
                            </button>
                          }
                          modal
                        >
                          <div
                            className="p-4 text-black bg-white rounded-xl h-52 w-80"
                            style={{ border: "1px solid gray" }}
                            ref={box1}
                          >
                            <div className="font-bold text-gray-600">CURRENCY DETAILS</div>
                            <div
                              className="absolute px-3 text-white bg-gray-600 rounded right-2 top-2"
                              onClick={closeModal}
                            >
                              X
                            </div>
                            <hr />
                            <div className="py-3">
                              <p>
                                <span className="text-gray-700">Id</span> :{" "}
                                {country?.data_currency[0]?.country?.id ? country?.data_currency[0]?.country?.id : "--"}
                              </p>
                              <p>
                                <span className="text-gray-700">Country Name</span> :{" "}
                                {country?.data_currency[0]?.country?.name
                                  ? country?.data_currency[0]?.country?.name
                                  : "--"}
                              </p>

                              <p>
                                <span className="text-gray-700">Symbol</span> :{" "}
                                {country?.data_currency[0]?.symbol ? country?.data_currency[0]?.symbol : "--"}
                              </p>
                            </div>
                          </div>
                        </Popup>
                      </div>
                      <div
                        className="flex items-center justify-center w-full py-2 text-center"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        <Popup
                          trigger={
                            <button className="button">
                              {" "}
                              <VisibilityIcon />{" "}
                            </button>
                          }
                          modal
                        >
                          <div
                            className="p-4 text-black bg-white rounded-xl h-52 w-80"
                            style={{ border: "1px solid gray" }}
                            ref={box1}
                          >
                            <div className="font-bold text-gray-600">TAX DETAILS</div>
                            <div
                              className="absolute px-3 text-white bg-gray-600 rounded cursor-pointer right-2 top-2"
                              onClick={closeModal}
                            >
                              X
                            </div>
                            <hr />
                            <div className="py-1">
                              <div>
                                <span className="text-gray-700">Id</span> :{" "}
                                {country?.data_currency[0]?.tax_type?.id
                                  ? country?.data_currency[0]?.tax_type?.id
                                  : "--"}
                              </div>
                              <div>
                                <span className="text-gray-700">Tax Name</span> :{" "}
                                {country?.data_currency[0]?.tax_type?.title
                                  ? country?.data_currency[0]?.tax_type?.title
                                  : "--"}
                              </div>

                              <div>
                                <span className="text-gray-700">Rating</span> :
                                {country?.data_currency[0]?.tax_type?.rate[0]
                                  ? country?.data_currency[0]?.tax_type?.rate[0]
                                  : "--"}
                                ,
                                {country?.data_currency[0]?.tax_type?.rate[1]
                                  ? country?.data_currency[0]?.tax_type?.rate[1]
                                  : null}
                                ,
                                {country?.data_currency[0]?.tax_type?.rate[2]
                                  ? country?.data_currency[0]?.tax_type?.rate[2]
                                  : null}
                                ,
                                {country?.data_currency[0]?.tax_type?.rate[3]
                                  ? country?.data_currency[0]?.tax_type?.rate[3]
                                  : null}
                                ,
                                {country?.data_currency[0]?.tax_type?.rate[4]
                                  ? country?.data_currency[0]?.tax_type?.rate[4]
                                  : null}
                              </div>
                            </div>
                          </div>
                        </Popup>
                      </div>
                      <div
                        className="justify-center w-full py-1 text-center "
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {/* {country.map_status === null ? (
                          <Unmaped />
                        ) : country.map_status === 1 ? (
                          
                        ) : (
                         
                        )} */}
                        {country?.status == 1 ? (
                          <Active className="items-center justify-center text-center" />
                        ) : (
                          <Inactive className="text-center" />
                        )}
                      </div>
                      <div className="flex justify-center w-full">
                        {/* <CustomIconButton>
                        <BorderColorIcon />
                      </CustomIconButton>
                      <CustomIconButton>
                        <DeleteIcon />
                      </CustomIconButton> */}
                        <DeleteIcon className="!text-xl !text-red-600 !cursor-pointer" />
                        <button onClick={handleOpen}>
                          <EditIcon className="!text-xl !cursor-pointer !text-green-600" />
                        </button>
                      </div>
                    </div>
                  )
                })}
          </div>
        </div>
      </div>
    </>
  )
}

export default CountryList
