import { DeleteTwoTone, Edit, ReportTwoTone, Visibility } from "@mui/icons-material"
import {
  Button,
  Checkbox,
  Dialog,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { departmentFn } from "Services/Store/StoreDepartment"
import { deleteRolesFn, rolesFn } from "Services/Store/StoreRole"
import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import CustomDiv from "shared/CustomDiv"
import { CustomIconButton } from "shared/CustomIconButton"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import CustomSelect from "shared/MkxSelect"
import MkxPagination from "shared/MkxTable/MkxPagination"
import MkxTableCell from "shared/MkxTable/MkxTableCell"
import { MkxTableLoader } from "shared/MkxTable/MkxTableLoader"
import NoDataFound from "shared/NoDataFound"
import AddRole from "./AddRole"

const Role = () => {
  const { store_id } = useParams()
  const [deptId, setDeptId] = useState("")
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const [selectedIds, setSelectedIds] = useState([])
  const [isDelete, setIsDelete] = useState(false)
  const [selectRole, setSelectRole] = useState({})
  const navigate = useNavigate()

  const handleChange = (event, value) => {
    setPage(value)
  }

  const { data: roles, isLoading } = useQuery(["roles", page, search, deptId], () =>
    rolesFn({ store_id, page, search, department_id: deptId })
  )

  const { data: departmentsData } = useQuery(["departments"], () => departmentFn({ store_id }))
  const department = departmentsData?.data?.data

  const client = useQueryClient()

  const handleSelectRow = (id) => {
    setSelectedIds((prevSelectedRow) =>
      prevSelectedRow.includes(id) ? prevSelectedRow.filter((i) => i !== id) : [...prevSelectedRow, id]
    )
  }

  const handleAllRowSelect = (event) => {
    setSelectedIds(event.target.checked ? roles?.data?.data?.map((i) => i.id) : [])
  }

  const { mutate: deleteRoles } = useMutation(deleteRolesFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        setIsDelete(false)
        setSelectedIds([])
        client.refetchQueries("roles")
      } else {
        toast.error(response.data.message)
      }
    },
  })

  return (
    <CustomDiv className="flex flex-col !p-0">
      <div className="flex items-center justify-between p-2">
        <CustomInput value={search} onChange={(event) => setSearch(event.target.value)} placeholder="Search Role" />
        <div className="flex items-center gap-4">
          {selectedIds?.length !== 0 && (
            <>
              <CustomButton
                startIcon={<DeleteTwoTone />}
                className="!text-red-400 hover:!text-red-500"
                onClick={() => setIsDelete(true)}
              >
                Delete Selected
              </CustomButton>
              <Dialog
                open={isDelete}
                onClose={() => setIsDelete(false)}
                PaperProps={{ className: "!max-w-[500px] w-96" }}
              >
                <div className="flex flex-col items-center gap-3 p-5">
                  <ReportTwoTone color="error" className="!text-5xl" />
                  <p className="text-xl">Are you sure!</p>
                  <p>You want to delete selected items?</p>
                </div>
                <span className="flex justify-end gap-3 pb-3 pr-3">
                  <Button
                    size="small"
                    variant="outlined"
                    color="inherit"
                    onClick={() => setIsDelete(false)}
                    className="!capitalize"
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    disableElevation
                    color="error"
                    className="!capitalize"
                    variant="contained"
                    onClick={() => deleteRoles({ store_id, role_ids: selectedIds })}
                  >
                    Delete
                  </Button>
                </span>
              </Dialog>
            </>
          )}

          <CustomSelect
            id="dept_name"
            name="dept_name"
            placeholder="All Departments"
            value={deptId}
            className="w-80"
            onChange={(event) => setDeptId(event.target.value)}
          >
            <MenuItem value={""}>All Departments</MenuItem>
            {department?.map((item) => (
              <MenuItem value={item?.dept_id}>{item?.dept_name}</MenuItem>
            ))}
          </CustomSelect>
          <AddRole setSelectRole={setSelectRole} selectRole={selectRole} />
        </div>
      </div>

      <TableContainer>
        <Table className="border">
          <TableHead>
            <TableRow>
              <MkxTableCell isHead>
                <Checkbox size="small" onChange={handleAllRowSelect} />
              </MkxTableCell>
              <MkxTableCell isHead>Role Name</MkxTableCell>
              <MkxTableCell isHead>Department</MkxTableCell>
              <MkxTableCell isHead>Action</MkxTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <MkxTableLoader loading={isLoading} row={3} />
            {roles?.data?.data?.map((item) => (
              <TableRow>
                <MkxTableCell>
                  <Checkbox
                    size="small"
                    checked={selectedIds.filter((id) => id === item.id)?.length === 1 ? true : false}
                    className="!p-0"
                    onChange={() => handleSelectRow(item.id)}
                  />
                </MkxTableCell>
                <MkxTableCell>{item.level}</MkxTableCell>
                <MkxTableCell>{item.department}</MkxTableCell>
                <MkxTableCell>
                  <span className="flex items-center justify-center gap-3">
                    <CustomIconButton
                      onClick={() => navigate(`/business-store/${store_id}/view-role-permissions/${item.level}`)}
                    >
                      <Visibility />
                    </CustomIconButton>
                    <CustomIconButton onClick={() => setSelectRole(item)}>
                      <Edit />
                    </CustomIconButton>
                  </span>
                </MkxTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <MkxPagination page={roles?.data?.current_page} count={roles?.data?.total_pages} onChange={handleChange} />
        <NoDataFound data={roles} />
      </TableContainer>
    </CustomDiv>
  )
}

export default Role
