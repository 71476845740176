import { Checkbox } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import axios from "config/axios"
import { flatten } from "lodash"
import * as React from "react"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
// import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button"

// import { FaMinimize } from "react-icons/fa";

const Details = () => {
  const { name } = useParams()
  const [permData, setPermData] = useState([])
  const [selectedUpdateModule, setSelectedUpdateModule] = useState([])
  const [selectedModule1, setSelectedModule1] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)

  const permissionList = () => {
    axios
      .post(`check-group-permission-api-for-superadmin/`, { group_name: name })
      .then((response) => {
        setPermData(response?.data?.data)
        console.log("data", response?.data?.data)
        setSelectedModule1(response.data.data)
        setOpen(true)
      })
      .catch((error) => {
        console.log("", error)
      })
  }
  useEffect(() => {
    permissionList()
  }, [])

  useEffect(() => {
    const filteredIds = []
    for (let i in permData) {
      const filteredPermissions = permData[i]?.permission?.filter((item) => item.active_status === true)
      const filteredIdsForCurrentItem = filteredPermissions.map((item) => item.id)
      filteredIds.push(filteredIdsForCurrentItem)
    }
    setSelectedUpdateModule(flatten(filteredIds))
  }, [permData])

  const redirect = () => {
    navigate("/role-management/")
  }

  const searchData = (even) => {
    setSearchQuery(even.split(" ").join("_"))
  }

  return (
    <>
      <div className="">
        <div className="flex justify-between p-2">
          <div className="text-xl font-bold text-black">
            Admin Groups <span className="text-sm text-green-400">({name})</span>
          </div>
          <div className="float-right">
            <Button variant="contained" className="!mx-0 !px-0 !bg-red-500 text-white" size="small" onClick={redirect}>
              {/* <FaMinimize/> */}x
            </Button>
          </div>
        </div>
        <div className="shadow grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 mx-auto !gap-4 shadow-gray-400 m-2 !h-[80vh] overflow-y-auto rounded p-2">
          {open ? (
            permData.length > 0 ? (
              permData?.map((row) => {
                return (
                  <div className="rounded bg-sky-400 h-72">
                    <div className="flex justify-between p-2 font-bold border-b text-slate-800">
                      <div>{row?.module}</div>
                      <div className="flex gap-x-2">
                        <input
                          type="text"
                          placeholder="search"
                          className="w-40 px-1 rounded outline-none placeholder:font-normal placeholder:text-sm hover:border-red-400"
                          onChange={(event) => searchData(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="h-[247px] overflow-auto">
                      {row?.permission
                        ?.filter((row) => row?.name.toLowerCase().includes(searchQuery))
                        ?.map((elem) => (
                          <div className="flex !border-[0.5px] cursor-pointer">
                            <div>
                              <Checkbox
                                // defaultChecked={item.active_status}
                                // onChange={()=>setChecked}
                                // // onChange={handleChange}
                                // inputProps={{ "aria-label": "controlled" }}
                                disabled
                                size="small"
                                checked={selectedUpdateModule?.map((i) => i).includes(elem?.id)}
                                onChange={(event) => {
                                  {
                                    !selectedUpdateModule?.map((i) => i).includes(elem?.id)
                                      ? setSelectedUpdateModule([...selectedUpdateModule, elem?.id])
                                      : setSelectedUpdateModule(() =>
                                          selectedUpdateModule?.filter((i) => i !== elem?.id)
                                        )
                                  }
                                }}
                                inputProps={{
                                  "aria-label": "controlled",
                                }}
                              />
                            </div>
                            <div className="pt-2 text-start">
                              <span className="text-sm text-white">
                                {elem?.name.split("_").join(" ").toLocaleUpperCase()}
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )
              })
            ) : (
              <div className="text-xl font-bold text-center text-red-400">No Data Found !</div>
            )
          ) : (
            <div className="items-center mx-auto text-center">
              <CircularProgress color="inherit" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Details
