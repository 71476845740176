import {
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"
import axios from "config/axios"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Active, Inactive } from "../../../shared/ActivaInactive"
import { CustomButton } from "../../../shared/CustomButton"
import CreateResellers from "../CreateResellers"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import Modal from "@mui/material/Modal"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import EmployeeAsset from "../ResetPassword"
import { Add, Edit } from "@mui/icons-material"
import CustomInput from "shared/CustomInput"
import MkxButton from "shared/MkxButton"
import { useMutation, useQuery } from "react-query"
import { allResellersFn, updateCommissionFn } from "Services/Resellers/ResellerStoreList"
import SearchAndSelectNew from "shared/SearchAndSelectNew"
import classNames from "classnames"
import moment from "moment"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  rounded: 2,
}

const ResellersSales = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [data, setData] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [planData, setPlanData] = useState([])
  const [name, setName] = useState("")
  const [title, setTitle] = useState("")
  const [changes, setChanges] = useState("")
  const [resellers, setResellers] = useState()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const { data: AllResellers, isLoadingResellers } = useQuery(["resellers", changes], () =>
    allResellersFn({ search: changes })
  )

  const resellersList = AllResellers?.data?.data?.map((i) => ({ label: i.user.name, value: i.user.name }))
  console.log("kdkf", resellersList)
  const handleChange = (event, email) => {
    axios
      .post(
        `reseller_app/activate-or-deactivate/`,
        {
          email: email,
          active_status: event.target.checked ? "true" : "false",
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        // setData(response.data.data);
        toast.success(response.data.message)
        ResellersListData()
      })
      .catch((error) => {
        console.log(error.response)
      })
  }

  const ResellersListData = () => {
    searchQuery === ""
      ? axios
          .get(`reseller_app/reseller-sales-superadmin-api/`, {
            params: { search: resellers },
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          })
          .then((response) => {
            setData(response.data.data)
          })
          .catch((error) => {
            console.log("", error)
          })
      : axios
          .get(`reseller_app/reseller-list/?search_value=${searchQuery}`, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          })
          .then((response) => {
            setData(response.data.data)
          })
          .catch((error) => {
            console.log("", error)
          })
  }
  useEffect(() => {
    ResellersListData()
  }, [searchQuery])

  const handleCloseModal = () => {
    handleClose()
  }

  const submitData = () => {
    const fd = new FormData()
    fd.append("plan_id", name)
    fd.append("commission", title)
    axios
      .post(`add-reseller-commission-api-for-superadmin/`, fd, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setTitle("")
        setName("")
        toast.success(response.data.message)
        ResellersListData()
        handleClose()
      })
      .catch((error) => {
        console.log("", error)
      })
  }

  const planDataList = () => {
    axios
      .get(`plan-list-without-pagination-api-for-superadmin/`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setPlanData(response.data.data)
      })
      .catch((error) => {
        console.log(error.response)
      })
  }
  useEffect(() => {
    planDataList()
  }, [])

  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font-bold text-black">Resellers Sales</p>
            {/* <p className="text-black">View/Search Resellers List</p> */}
          </div>
          <div className="flex gap-x-2">
            <div className="p-2 pt-3">
              {/* " <CustomButton
                variant="outlined"
                size="small"
                className="px-5 border border-gray-600"
                onClick={handleOpen}
              >
                + Reseller Commission
              </CustomButton>" */}
              <SearchAndSelectNew
                name="resellers"
                // formik={formik}
                placeholder={`Select Resellers`}
                setValue={(value) => setResellers(value)}
                className={classNames(`!w-44`)}
                setChanges={setChanges}
                options={resellersList}
                loading={isLoadingResellers}
              />
            </div>
            <div className="p-2 pt-3">{/* <CreateResellers ResellersListData={ResellersListData} /> */}</div>
          </div>
          {/* <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...style, width: 450 }} className="rounded-2xl !border-0 !shadow-sm !shadow-blue-900">
              <div
                id="child-modal-title "
                className="!text-black !underline !underline-offset-2  !font-bold pb-3 !text-center !text-xl"
              >
                Add Commission
              </div>
              <div className="">
                <div>Plan Type</div>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    className="m-1"
                    value={name || ""}
                    onChange={(event) => setName(event.target.value)}
                  >
                    {planData?.map((res) => {
                      return <MenuItem value={res?.id}>{res?.plan_name}</MenuItem>
                    })}
                  </Select>
                </FormControl>
                <div className="mt-2">Commission </div>
                <TextField
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                  id="outlined-basic"
                  size="small"
                  className="!w-[100%] !m-1"
                  placeholder="Enter Commission"
                />
              </div>
              <br />
              <Button onClick={handleCloseModal} variant="contained" color="warning" className="!py-2">
                CANCEL
              </Button>
              <Button
                variant="contained"
                color="success"
                className="!py-2 !px-5 !font-bold !float-right"
                onClick={submitData}
              >
                Add Commission
              </Button>
            </Box>
          </Modal> */}
        </div>
        <div className="flex flex-col !w-[84vw] !rounded overflow-auto bg-secondary border-secondary shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center !bg-gray-100 py-2">
            <input
              class="placeholder:italic placeholder:text-slate-400 block mx-3 bg-white w-80 border border-slate-300 rounded-md py-2 px-2 pr-3 shadow-sm focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
              placeholder="Search for anything..."
              type="text"
              onChange={(event) => setSearchQuery(event.target.value)}
            />
          </div>
          <TableContainer component={Paper} sx={{ width: "100%", maxHeight: 480, overflow: "scroll" }}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  {/* <TableCell className="!text-left !p-2">
                        <Checkbox className="!m-0" />
                      </TableCell> */}
                  <TableCell
                    className="!text-center !font-bold"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    <div className="!w-24">SERIAL NO.</div>
                  </TableCell>
                  <TableCell
                    className="!text-center !font-bold"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    <div className="!w-28">RESELLER ID </div>
                  </TableCell>
                  <TableCell
                    className="!text-center !font-bold"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    <div className="!w-28">Reference ID </div>
                  </TableCell>
                  <TableCell
                    className="!text-center !font-bold"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    <div className="!w-36">Customer Name</div>
                  </TableCell>
                  <TableCell
                    className="!text-center  !font-bold"
                    style={{
                      borderLeft: "1px solid #D0D0D0",
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Customer Email
                  </TableCell>
                  <TableCell
                    className="!text-center  !font-bold"
                    style={{
                      borderLeft: "1px solid #D0D0D0",
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Plan
                  </TableCell>

                  <TableCell
                    className="!text-center !font-bold"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Commission
                  </TableCell>
                  <TableCell
                    className="!text-center !font-bold"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    className="!text-center !font-bold"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      borderLeft: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    <div className="w-36">Amount</div>
                  </TableCell>
                  <TableCell
                    className="!text-center !font-bold"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Payment Status
                  </TableCell>
                  {/* <TableCell
                    className="!text-center !font-bold"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    STATUS
                  </TableCell>
                  <TableCell className="!text-right !font-bold">ACTION</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow key={row.id}>
                    {/* <TableCell
                            className="!text-left !p-2"
                            component="th"
                            scope="row"
                          >
                            <Checkbox className="!m-0" />
                          </TableCell> */}
                    <TableCell className="!text-center" style={{ borderRight: "1px solid #D0D0D0" }}>
                      {index + 1}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.reseller_id ? row?.reseller_id : "--"}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.reference_id ? row?.reference_id : "--"}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.reseller_customer?.first_name + " " + row?.reseller_customer?.last_name}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.reseller_customer?.email}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.reseller_customer?.plan_period ? row?.reseller_customer?.plan_period : "--"}
                    </TableCell>

                    <TableCell
                      className="!text-center  !p-2"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      <div className="flex justify-between px-3 gap-1">
                        <span>{row?.commission}%</span>
                        <CustomButton variant="contained" className="!bg-blue-500 !p-0.5 !rounded-full">
                          {/* <Edit className="!p-1" onClick={() => handleUpdates(row?.commission, row?.id)} /> */}
                        </CustomButton>
                      </div>
                    </TableCell>
                    <TableCell
                      className="!text-center !p-2"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {moment(row?.date).calendar()}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.amount ? row.amount : "--"}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {
                        <Chip
                          label={row?.isPaid === true ? "Paid" : "Unpaid"}
                          color={row?.isPaid === true ? "success" : "error"}
                        />
                      }
                    </TableCell>
                    {/* <TableCell
                      className=""
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      <span className="flex justify-center !text-[12px] items-center">
                        {row.active_status ? <Active size="small" /> : <Inactive />}
                      </span>
                    </TableCell>

                    <TableCell
                      className="!text-right !p-2"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={row.active_status}
                        onChange={(event) => handleChange(event, row?.user?.email)}
                        className="toggle checked:!border-green-500 !border-red-500 toggle-success"
                      />
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {data?.length !== 0 ? (
              <TablePagination
                rowsPerPageOptions={[7, 14, 21, 28]}
                count={data?.length}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default ResellersSales
