import axiosInstance from "config/axios"

export const addSmsFn = (reqBody) => {
  try {
    const response = axiosInstance.post(`sms-configuration-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateSmsFn = (reqBody) => {
  try {
    const response = axiosInstance.put(`sms-configuration-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
