import {
  Add,
  AddCircle,
  Close,
  Dashboard,
  DocumentScanner,
  FormatListBulleted,
  Image,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material"
import {
  Button,
  ClickAwayListener,
  Collapse,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
} from "@mui/material"
import CustomInput from "shared/MkxInput"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import classNames from "classnames"
import { chatTempsFn } from "Services/Chats/WhatsappTemplatesWithoutNumber"
import { variableListFn } from "Services/Settings/ChatSettings/CustomAutoReply"
import { deleteButtonFn, deleteSectionFn, deleteSectionRowFn } from "Services/Settings/ChatSettings/DeletionAPIs"
import { addErpTemplateFn, singleErpTemplateFn, updateErpTemplateFn } from "Services/Settings/ChatSettings/ERPTemplate"

const AddNewERPTemplate = ({ response, setResponse }) => {
  const [open, setOpen] = useState(false)
  const [body, setBody] = useState("")
  const [list, setList] = useState([])
  const [responses, setResponses] = useState({})
  const [isButtonType, setIsButtonType] = useState(false)
  const [isCallToAction, setIsCallToAction] = useState(false)

  const { data: variableList } = useQuery(["variableList"], variableListFn, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  useQuery(
    ["SingleERPTemplate"],
    () =>
      singleErpTemplateFn({
        template_id: response.id,
        template_type: response?.template_type,
      }),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: response.id ? true : false,
      onSuccess: (res) => {
        setResponses(res.data.data?.[0])
      },
    }
  )

  const client = useQueryClient()
  const { data: buttonValuesData } = useQuery(["buttonValuesData"], () => chatTempsFn({ template_type: "Custom" }), {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })
  const buttonValues = buttonValuesData?.data?.data

  const { mutate: addErpTemplate } = useMutation(addErpTemplateFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res?.data?.message)
        setOpen(false)
        setResponse({})
        client.refetchQueries("ERPTemplate")
        formik.resetForm()
      } else {
        toast.error(res.data.message)
      }
    },
  })

  const { mutate: updateErpTemplate } = useMutation(updateErpTemplateFn, {
    onSuccess: (res) => {
      if (res?.data.response_code === 200) {
        toast.success(res.data.message)
        setOpen(false)
        setResponse({})
        client.refetchQueries("ERPTemplate")
      } else {
        toast.error(res?.data?.message)
      }
    },
  })
  const { mutate: deleteButton } = useMutation(deleteButtonFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
        client.refetchQueries("SingleERPTemplate")
      } else {
        toast.error(res.data.message)
      }
    },
  })

  const { mutate: deleteSection } = useMutation(deleteSectionFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
        client.refetchQueries("SingleERPTemplate")
      } else {
        toast.error(res.data.message)
      }
    },
  })

  const { mutate: deleteSectionRow } = useMutation(deleteSectionRowFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
        client.refetchQueries("SingleERPTemplate")
      } else {
        toast.error(res.data.message)
      }
    },
  })
  const initialValues = {
    template_name: responses?.name || "",
    template_type: responses?.template_type || "Custom",
    message_response_type: responses?.message_object_type || "text",
    button_list: responses?.button_list?.map((button) => ({
      button_id: button.id,
      button_type: button.button_type,
      action_type: button.action_type,
      button_text: button.button_text,
      button_value: button.button_value,
      website_url: button.website_url,
      phone_number: button.phone_number,
    })) || [{ button_text: "", button_type: "Quick_Reply", button_value: "" }],
    section_list: responses?.section_list?.map((section) => ({
      section_title: section?.section_title,
      section_list_id: section?.id,
      rows_list: section?.rows?.map((row) => ({
        rows_id: row.rows_id,
        rows_title: row.rows_title,
        rows_list_id: row.id,
      })),
    })) || [{ section_title: "", rows_list: [{ rows_id: "", rows_title: "" }] }],
    action_button_text: responses?.action_button_text || "",
    variable_list: responses?.variable_list || [],
    header_type: responses?.header_type || "none",
    header: responses?.header || "",
    header_file: "",
    header_image: "",
    media_type: responses?.media_type || "image",
    footer: responses?.footer || "",
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      const reqBody = new FormData()
      reqBody.append("template_id", responses?.id)
      reqBody.append("template_name", formik.values.template_name.toLowerCase().replace(" ", "_"))
      reqBody.append("template_type", formik.values.template_type)
      reqBody.append("button_list", JSON.stringify(formik.values.button_list))
      reqBody.append("section_list", JSON.stringify(formik.values.section_list))
      reqBody.append("variable_list", JSON.stringify(formik.values.variable_list))
      reqBody.append("action_button_text", formik.values.action_button_text)
      reqBody.append("header", formik.values.header)
      reqBody.append("header_file", formik.values.header_file)
      reqBody.append("header_image", formik.values.header_image)
      reqBody.append("media_type", formik.values.media_type)
      reqBody.append("header_type", formik.values.header_type)
      reqBody.append("message_response_type", formik.values.message_response_type)
      reqBody.append("body", body)
      reqBody.append("footer", formik.values.footer)
      Object.keys(responses)?.length !== 0 ? updateErpTemplate(reqBody) : addErpTemplate(reqBody)
    },
  })

  useEffect(
    () => {
      if (Object.keys(response)?.length !== 0) {
        setBody(response?.body)
        setOpen(true)
      } else {
        setBody("")
      }
    },
    // eslint-disable-next-line
    [response]
  )

  useEffect(
    () => {
      if (Object.keys(responses)?.length === 0) {
        formik.values.template_type === "Fixed"
          ? formik.setFieldValue("button_list", [])
          : formik.setFieldValue("button_list", [{ button_text: "", button_type: "Quick_Reply", button_value: "" }])
      }
    },
    // eslint-disable-next-line
    [formik?.values?.template_type]
  )

  return (
    <>
      <CustomButton
        className="!rounded-full !px-3"
        startIcon={<AddCircle />}
        onClick={() => {
          setOpen(true)
          setResponses({})
        }}
      >
        Add New ERP Template
      </CustomButton>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
          setResponse({})
          setResponses({})
        }}
        component="form"
        onSubmit={formik.handleSubmit}
        PaperProps={{
          className: "!min-h-[80%] !relative !flex !flex-col !min-w-[50%]",
        }}
      >
        <span className="flex justify-between bg-secondary !rounded-t items-center p-2">
          <span className="flex !text-white !font-semibold items-center gap-2">
            <Dashboard />
            Setup ERP Template
          </span>
          <IconButton
            onClick={() => {
              setOpen(false)
              setResponse({})
              setResponses({})
            }}
          >
            <Close className="!text-white" />
          </IconButton>
        </span>

        <span className="flex flex-col gap-2 h-[80%] overflow-y-auto p-5">
          <span>
            <p className="text-lg font-semibold"> Template Name</p>
            <CustomInput
              id="template_name"
              name="template_name"
              value={formik.values.template_name.toLowerCase().replace(" ", "_")}
              onChange={formik.handleChange}
              placeholder="Enter Template Name"
            />
          </span>

          <span>
            <p className="text-lg font-semibold">Template Type</p>
            <FormControl>
              <RadioGroup
                row
                id="template_type"
                name="template_type"
                value={formik.values.template_type}
                onChange={formik.handleChange}
              >
                <FormControlLabel value="Custom" control={<Radio color="secondary" />} label="Custom" />
                <FormControlLabel value="Fixed" control={<Radio color="secondary" />} label="Fixed" />
              </RadioGroup>
            </FormControl>
          </span>

          <span className="flex items-center gap-4">
            <span className="font-semibold">Header (Optional)</span> <Divider orientation="vertical" className="!h-8" />
            <FormControl>
              <RadioGroup
                row
                id="header_type"
                name="header_type"
                value={formik.values.header_type}
                onChange={formik.handleChange}
              >
                <FormControlLabel value="none" control={<Radio color="secondary" />} label="None" />
                <FormControlLabel value="text" control={<Radio color="secondary" />} label="Text" />
                <FormControlLabel value="media" control={<Radio color="secondary" />} label="Media" />
              </RadioGroup>
            </FormControl>
          </span>

          {formik.values.header_type === "text" && (
            <span>
              <p>Header Text</p>
              <CustomInput id="header" formik={formik} placeholder="Enter Header Text" />
            </span>
          )}
          {formik.values.header_type === "media" && (
            <span className="flex flex-col gap-2">
              <FormControl>
                <RadioGroup
                  row
                  id="media_type"
                  name="media_type"
                  value={formik.values.media_type}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="image"
                    control={<Radio color="secondary" />}
                    label={
                      <span className="flex flex-col items-center justify-center w-20 h-20 border-2 rounded-xl">
                        <Image className="!text-4xl" />
                        <p className="text-xs"> Image</p>
                      </span>
                    }
                  />
                  <FormControlLabel
                    value="document"
                    control={<Radio color="secondary" />}
                    label={
                      <span className="flex flex-col items-center justify-center w-20 h-20 border-2 rounded-xl">
                        <DocumentScanner className="!text-4xl" />
                        <p className="text-xs"> Document</p>
                      </span>
                    }
                  />
                </RadioGroup>
              </FormControl>
              {formik.values.media_type === "image" ? (
                <input
                  type="file"
                  className="p-2 border"
                  accept="image/*"
                  onChange={(event) => formik.setFieldValue("header_image", event.target.files[0])}
                />
              ) : (
                <input
                  type="file"
                  className="p-2 border"
                  accept="application/pdf"
                  onChange={(event) => formik.setFieldValue("header_file", event.target.files[0])}
                />
              )}
            </span>
          )}

          <span>
            <p className="text-lg font-semibold">Select response type</p>
            <FormControl>
              <RadioGroup
                row
                id="message_response_type"
                name="message_response_type"
                value={formik.values.message_response_type}
                onChange={formik.handleChange}
              >
                <FormControlLabel value="text" control={<Radio color="secondary" />} label="Text" />
                <FormControlLabel value="button" control={<Radio color="secondary" />} label="Button" />
                {formik.values.template_type === "Custom" && (
                  <FormControlLabel value="list" control={<Radio color="secondary" />} label="List" />
                )}
              </RadioGroup>
            </FormControl>
          </span>

          <span className="flex flex-col gap-2 pb-20 mkx">
            <textarea
              id="body"
              rows={7}
              multiline
              className="p-2 border border-gray-300 rounded outline-none"
              value={body}
              placeholder={`Hello Customer_Name,
              
    Ready to take your retail business to the next level with Store Name? 
    I am Store_Manager, here to assist you with our services , which offers POS, Billing, Accounting, Inventory 
    management, and much more to help you manage your business more efficiently.
    
Don't Type, Just Dial! 
Speak to an expert now.

Call Us: 9266667999`}
              onChange={(event) => setBody(event.target.value)}
            />

            <CustomButton
              variant="text"
              startIcon={<AddCircle />}
              className="!w-1/4 !flex !justify-start !px-3"
              onClick={() => {
                formik.setFieldValue("variable_list", [
                  ...formik.values.variable_list,
                  {
                    index: formik.values.variable_list?.length + 1,
                    key: "",
                    value: "",
                  },
                ])
                setBody(`${body} *{{${formik.values.variable_list?.length + 1}}}*`)
              }}
            >
              Add Variable
            </CustomButton>
            <Collapse in={formik.values.variable_list?.length !== 0 ? true : false}>
              <span className="!flex !flex-col !gap-2">
                {formik.values.variable_list?.map((variable, index) => {
                  return (
                    <span className="flex gap-2">
                      <CustomInput placeholder="Variable" value={`{{${variable?.index}}}`} disabled />
                      <FormControl className="!w-1/2">
                        <Select
                          size="small"
                          id={`button_value${index + 1}`}
                          value={formik.values.variable_list[index]?.key || ""}
                          onChange={(event) => {
                            const newButtonList = [...formik.values.variable_list]
                            newButtonList[index] = {
                              ...newButtonList[index],
                              key: event.target.value,
                            }
                            formik.setValues({
                              ...formik.values,
                              variable_list: newButtonList,
                            })
                          }}
                        >
                          {variableList?.data?.data?.map((value) => {
                            return <MenuItem value={value?.key}>{value?.key}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                      <CustomInput
                        placeholder="Fallback value"
                        value={variable.value}
                        onChange={(event) => {
                          const newButtonList = [...formik.values.variable_list]
                          newButtonList[index] = {
                            ...newButtonList[index],
                            value: event.target.value,
                          }
                          formik.setValues({
                            ...formik.values,
                            variable_list: newButtonList,
                          })
                        }}
                      />
                    </span>
                  )
                })}
              </span>
            </Collapse>

            {formik.values.message_response_type === "button" && (
              <>
                {formik.values.button_list?.map((button, index) => {
                  return (
                    <span className="flex flex-col gap-2 group">
                      {button?.button_type === "Quick_Reply" ? (
                        <span className="flex gap-2">
                          <CustomInput
                            id={`button_text${index + 1}`}
                            label="Text"
                            placeholder="Enter Button Text"
                            value={button?.button_text || ""}
                            onChange={(event) => {
                              const newButtonList = [...formik.values.button_list]
                              newButtonList[index] = {
                                ...newButtonList[index],
                                button_text: event.target.value,
                              }
                              formik.setValues({
                                ...formik.values,
                                button_list: newButtonList,
                              })
                            }}
                            className="!w-1/2"
                          />
                          <FormControl className="!w-1/2">
                            <Select
                              size="small"
                              id={`button_value${index + 1}`}
                              value={formik.values.button_list[index]?.button_value || ""}
                              onChange={(event) => {
                                const newButtonList = [...formik.values.button_list]
                                newButtonList[index] = {
                                  ...newButtonList[index],
                                  button_value: event.target.value,
                                }
                                formik.setValues({
                                  ...formik.values,
                                  button_list: newButtonList,
                                })
                              }}
                            >
                              {buttonValues?.map((value) => {
                                return <MenuItem value={value?.id}>{value?.name}</MenuItem>
                              })}
                            </Select>
                          </FormControl>
                          {button?.button_id && (
                            <span className="w-12">
                              <IconButton
                                color="error"
                                className={classNames("!hidden", button?.button_id && "group-hover:!flex")}
                                onClick={() =>
                                  button?.button_id &&
                                  deleteButton({
                                    whatsApp_button_id: button?.button_id,
                                  })
                                }
                              >
                                <Close />
                              </IconButton>
                            </span>
                          )}
                        </span>
                      ) : (
                        <span className="flex gap-2">
                          <CustomInput
                            id={`button_text${index + 1}`}
                            label="Text"
                            placeholder="Enter Button Text"
                            value={button?.button_text || ""}
                            onChange={(event) => {
                              const newButtonList = [...formik.values.button_list]
                              newButtonList[index] = {
                                ...newButtonList[index],
                                button_text: event.target.value,
                              }

                              formik.setValues({
                                ...formik.values,
                                button_list: newButtonList,
                              })
                            }}
                            className="!w-1/2"
                          />
                          {button?.action_type === "Visit_Website" && (
                            <CustomInput
                              id={`button_website_url${index + 1}`}
                              label="Website URL"
                              value={button?.website_url || ""}
                              onChange={(event) => {
                                const newButtonList = [...formik.values.button_list]
                                newButtonList[index] = {
                                  ...newButtonList[index],
                                  website_url: event.target.value,
                                }

                                formik.setValues({
                                  ...formik.values,
                                  button_list: newButtonList,
                                })
                              }}
                            />
                          )}
                          {button?.action_type === "Call_Phone_Number" && (
                            <CustomInput
                              type="number"
                              id={`button_phone_number${index + 1}`}
                              label="Call Phone Number"
                              value={button?.phone_number || ""}
                              onChange={(event) => {
                                const newButtonList = [...formik.values.button_list]
                                newButtonList[index] = {
                                  ...newButtonList[index],
                                  phone_number: event.target.value,
                                }

                                formik.setValues({
                                  ...formik.values,
                                  button_list: newButtonList,
                                })
                              }}
                            />
                          )}

                          <span className="w-12">
                            <IconButton
                              color="error"
                              className={classNames("!hidden", button?.button_id && "group-hover:!flex")}
                              onClick={() =>
                                button?.button_id &&
                                deleteButton({
                                  whatsApp_button_id: button?.button_id,
                                })
                              }
                            >
                              <Close />
                            </IconButton>
                          </span>
                        </span>
                      )}
                    </span>
                  )
                })}
                {formik.values.button_list?.length < 3 && (
                  <ClickAwayListener mouseEvent={false} onClickAway={() => setIsButtonType(false)}>
                    <Tooltip
                      PopperProps={{ className: "mkx" }}
                      open={isButtonType}
                      placement="top"
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      onClose={() => setIsButtonType(false)}
                      title={
                        <List className="!py-0">
                          <ListItem className="!text-xs !flex !justify-between !text-secondary !font-semibold !pr-1 !py-1">
                            Button Type
                            <Close
                              onClick={() => setIsButtonType(false)}
                              className="hover:!text-red-500 !cursor-pointer"
                            />
                          </ListItem>
                          <Divider />
                          <ListItemButton
                            onClick={() => {
                              formik.values.button_list?.[formik.values?.button_list?.length - 1]?.button_text !== "" &&
                                formik.setValues({
                                  ...formik.values,
                                  button_list: [
                                    ...formik.values.button_list,
                                    {
                                      button_text: "",
                                      button_type: "Quick_Reply",
                                      button_value: "",
                                    },
                                  ],
                                })
                              setIsButtonType(false)
                            }}
                            className="!w-48"
                          >
                            Quick Reply
                          </ListItemButton>
                          <ListItemButton
                            onClick={() => setIsCallToAction(!isCallToAction)}
                            className="!flex !justify-between !pr-2"
                          >
                            Call To Action{" "}
                            {!isCallToAction ? (
                              <KeyboardArrowDown className="!text-xl" />
                            ) : (
                              <KeyboardArrowUp className="!text-xl" />
                            )}
                          </ListItemButton>
                          <Collapse in={isCallToAction}>
                            <Divider />
                            <ListItem className="!text-xs  !text-secondary !font-semibold !py-1">Action Type</ListItem>
                            <Divider />
                            <ListItemButton
                              onClick={() => {
                                formik.values.button_list?.filter((btn) => btn?.action_type === "Visit_Website")
                                  ?.length < 2
                                  ? formik.values.button_list?.[formik?.values.button_list?.length - 1]?.button_text !==
                                      "" &&
                                    formik.setValues({
                                      ...formik?.values,
                                      button_list: [
                                        ...formik.values.button_list,
                                        {
                                          button_text: "",
                                          button_type: "Call_to_Action",
                                          action_type: "Visit_Website",
                                          website_url: "",
                                        },
                                      ],
                                    })
                                  : toast.warning(`You can add upto two "Visit Website" button in a single template`)
                                setIsButtonType(false)
                                setIsCallToAction(false)
                              }}
                            >
                              Vist Website
                            </ListItemButton>
                            <ListItemButton
                              onClick={() => {
                                formik.values.button_list?.filter((btn) => btn?.action_type === "Call_Phone_Number")
                                  ?.length < 1
                                  ? formik.values.button_list?.[formik.values.button_list?.length - 1]?.button_text !==
                                      "" &&
                                    formik.setValues({
                                      ...formik.values,
                                      button_list: [
                                        ...formik.values.button_list,
                                        {
                                          button_text: "",
                                          button_type: "Call_to_Action",
                                          action_type: "Call_Phone_Number",
                                          phone_number: "",
                                        },
                                      ],
                                    })
                                  : toast.warning(
                                      `You can add upto two "Call Phone Number" button in a single template`
                                    )
                                setIsCallToAction(false)
                                setIsButtonType(false)
                              }}
                            >
                              Call Phone Number
                            </ListItemButton>
                          </Collapse>
                        </List>
                      }
                    >
                      <Button
                        variant="text"
                        color="secondary"
                        startIcon={<Add />}
                        className="!capitalize"
                        onClick={() =>
                          formik?.values.template_type === "Fixed"
                            ? setIsButtonType(true)
                            : formik.values.button_list?.[formik.values.button_list?.length - 1]?.button_text !== "" &&
                              formik?.setValues({
                                ...formik.values,
                                button_list: [
                                  ...formik.values.button_list,
                                  {
                                    button_text: "",
                                    button_type: "Quick_Reply",
                                    button_value: "",
                                  },
                                ],
                              })
                        }
                      >
                        Add Button
                      </Button>
                    </Tooltip>
                  </ClickAwayListener>
                )}
              </>
            )}
            {formik.values.message_response_type === "list" && formik.values.template_type === "Custom" && (
              <>
                <span className="flex gap-1">
                  <CustomInput
                    placeholder="Action Button Text"
                    id="action_button_text"
                    className="!w-1/2"
                    formik={formik}
                  />
                  <Button
                    startIcon={<FormatListBulleted />}
                    onClick={() => setList(formik.values.section_list)}
                    className="!w-1/2 !capitalize"
                  >
                    {formik.values.action_button_text}
                  </Button>
                </span>
              </>
            )}
            <span className="flex flex-col gap-4">
              <span className="font-semibold">Footer (Optional)</span>
              <CustomInput placeholder="Enter Footer" id="footer" formik={formik} />
            </span>
          </span>
        </span>

        <span className="z-50 flex items-center justify-end w-full h-16 p-2 bg-white border-t ">
          <CustomButton type="submit">Save</CustomButton>
        </span>
      </Dialog>
      <Dialog
        open={list?.length === 0 ? false : true}
        onClose={() => setList([])}
        PaperProps={{
          className: "!flex min-w-[40%] !flex-col !justify-between",
        }}
      >
        <p className="py-2 font-semibold text-center">Section</p>
        <Divider />

        <List className="!p-0">
          {formik.values?.section_list?.map((section, sectionIndex) => (
            <>
              <ListItem className="!flex !items-start !flex-col !px-0 !w-full" key={sectionIndex}>
                <span className="flex flex-col p-3 group">
                  <p>Section Title</p>
                  <span className="flex items-center gap-2">
                    <CustomInput
                      placeholder="Enter Section Text"
                      value={section.section_title || ""}
                      onChange={(event) => {
                        const newSectionList = [...formik.values.section_list]
                        newSectionList[sectionIndex] = {
                          ...newSectionList[sectionIndex],
                          section_title: event.target.value,
                        }
                        formik.setValues({
                          ...formik.values,
                          section_list: newSectionList,
                        })
                      }}
                    />
                    <IconButton
                      color="error"
                      className={classNames("!hidden", section?.section_list_id && "group-hover:!flex")}
                      onClick={() =>
                        deleteSection({
                          whatsApp_section_id: section?.section_list_id,
                        })
                      }
                    >
                      <Close />
                    </IconButton>
                  </span>
                </span>
                <Divider className="!w-full !mb-1">Row List</Divider>
                {section.rows_list?.map((row, rowIndex) => (
                  <span className="flex w-full gap-2 px-3 pb-1 border-b group" key={rowIndex}>
                    <CustomInput
                      id={`section${sectionIndex + 1}`}
                      placeholder="Enter Row Text"
                      value={row.rows_title || ""}
                      className="!w-1/2"
                      onChange={(event) => {
                        const newSectionList = [...formik.values.section_list]
                        const newRowList = [...newSectionList[sectionIndex].rows_list]

                        newRowList[rowIndex] = {
                          ...newRowList[rowIndex],
                          rows_title: event.target.value,
                        }

                        newSectionList[sectionIndex] = {
                          ...newSectionList[sectionIndex],
                          rows_list: newRowList,
                        }

                        formik.setValues({
                          ...formik.values,
                          section_list: newSectionList,
                        })
                      }}
                    />

                    <FormControl className="!w-1/2">
                      <Select
                        size="small"
                        id={`button_value${sectionIndex + 1}`}
                        value={row.rows_id || ""}
                        onChange={(event) => {
                          const newSectionList = [...formik.values.section_list]
                          newSectionList[sectionIndex].rows_list[rowIndex] = {
                            ...newSectionList[sectionIndex].rows_list[rowIndex],
                            rows_id: event.target.value,
                          }
                          formik.setValues((prevValues) => ({
                            ...prevValues,
                            section_list: newSectionList,
                          }))
                        }}
                      >
                        {buttonValues?.map((value) => (
                          <MenuItem value={value?.id} key={value?.id}>
                            {value?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {row?.rows_list_id && (
                      <span className="w-12">
                        <IconButton
                          color="error"
                          className={classNames("!hidden", row?.rows_list_id && "group-hover:!flex")}
                          onClick={() =>
                            row?.rows_list_id &&
                            deleteSectionRow({
                              whatsApp_section_rows_id: row?.rows_list_id,
                            })
                          }
                        >
                          <Close />
                        </IconButton>
                      </span>
                    )}
                  </span>
                ))}
              </ListItem>
              <ListItemButton
                className="!flex !gap-2 !text-secondary"
                onClick={() => {
                  const newSectionList = [...formik.values.section_list]
                  newSectionList[sectionIndex] = {
                    ...newSectionList[sectionIndex],
                    rows_list: [...section.rows_list, { rows_id: "", rows_title: "" }],
                  }
                  formik.setValues((prevValues) => ({
                    ...prevValues,
                    section_list: newSectionList,
                  }))
                }}
              >
                <AddCircle fontSize="small" />
                Add New Row
              </ListItemButton>
            </>
          ))}
          <Divider />
          <ListItemButton
            className="!flex !gap-2 !text-secondary"
            onClick={() =>
              formik.setFieldValue("section_list", [
                ...formik.values.section_list,
                {
                  section_title: "",
                  rows_list: [{ rows_id: "", rows_title: "" }],
                },
              ])
            }
          >
            <AddCircle fontSize="small" />
            Add New Section
          </ListItemButton>
        </List>
        <span className="flex justify-end gap-2 p-4">
          <CustomButton onClick={() => setList([])}>Save</CustomButton>
        </span>
      </Dialog>
      {/* <Dialog open={isButtonType}>
        <List>
          <ListItemButton
            onClick={() => {
              formik?.values.formik.values.button_list?.[
                formik?.values.button_list?.length - 1
              ]?.button_text !== "" &&
                formik?.setValues({
                  ...formik?.values,
                  button_list: [
                    ...formik?.values.button_list,
                    { button_text: "", button_value: "" },
                  ],
                });
            }}
          >
            Quick Reply
          </ListItemButton>
          <ListItemButton onClose={() => setIsButtonType(false)}>
            Call_to_Action
          </ListItemButton>
        </List>
      </Dialog> */}
    </>
  )
}

export default AddNewERPTemplate
