import { Chip, IconButton, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import React, { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import GlassDiv from "shared/CustomDiv"
import Loader from "shared/CustomLoader"
import TableCell from "shared/MkxTable/MkxTableCell"
import { categoryItemsFn, deleteCategoryItemsFn, enableInventoryItemFn } from "Services/Settings/Inventory"
import AddCategory from "./AddCategory"
import CustomInput from "shared/MkxInput"
import CustomPagination from "shared/MkxTable/MkxPagination"
import { Delete, Edit, Lock } from "@mui/icons-material"
import AddInventoryByExcel from "../AddInventoryByExcel"
const Category = () => {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [selected, setSelected] = useState(false)

  const client = useQueryClient()

  const {
    data: categoryItems,
    isLoading,
    refetch,
  } = useQuery(["categoryItems", page, search], () => categoryItemsFn({ page, search }), {
    refetchOnWindowFocus: false,
  })

  const data = categoryItems?.data?.data

  const { mutate: enableDisable } = useMutation(enableInventoryItemFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("categoryItems")
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const { mutate: deleteCategoryItems } = useMutation(deleteCategoryItemsFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("categoryItems")
      } else {
        toast.error(response.data.message)
      }
    },
  })
  return (
    <GlassDiv className="!p-0 !rounded">
      <div className="flex items-center justify-between p-2">
        <CustomInput value={search} onChange={(event) => setSearch(event.target.value)} placeholder="Search Category" />
        <div className="flex items-center gap-2">
          <AddInventoryByExcel type="Category" refetch={refetch} />
          <AddCategory setSelected={setSelected} selected={selected} />
        </div>
      </div>

      {!isLoading && (
        <TableContainer component="div" className="!p-0">
          <Table className="bg-white whitespace-nowrap bg-opacity-20 !overflow-x-scroll">
            <TableHead className="!bg-white !bg-opacity-30">
              <TableRow>
                <TableCell isHead>ID</TableCell>
                <TableCell isHead>Name</TableCell>
                <TableCell isHead>Image</TableCell>
                <TableCell isHead>Sync Status</TableCell>
                <TableCell isHead>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.map((item) => (
                <TableRow className="!bg-white !bg-opacity-10" key={item?.id}>
                  <TableCell>{item?.id}</TableCell>
                  <TableCell>{item?.title}</TableCell>
                  <TableCell className="!p-px">
                    <div className="flex items-center justify-center">
                      <img src={item.image} alt="" className="size-10" />
                    </div>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={item?.active_for_sync ? "Active" : "Inactive"}
                      color={item?.active_for_sync ? "success" : "error"}
                      className="!w-20"
                      size="small"
                      onClick={() =>
                        enableDisable({
                          inventory_id: item?.id,
                          inventory_type: "category",
                        })
                      }
                    />
                  </TableCell>
                  <TableCell className="!p-px">
                    {item.action ? (
                      <>
                        <IconButton size="small" onClick={() => setSelected(item)}>
                          <Edit className="hover:text-blue-500" />
                        </IconButton>
                        <IconButton size="small" onClick={() => deleteCategoryItems({ category_ids: [item.id] })}>
                          <Delete className="hover:text-red-500" />
                        </IconButton>
                      </>
                    ) : (
                      <Lock className="text-gray-600" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <CustomPagination setPage={setPage} data={categoryItems} />
        </TableContainer>
      )}

      {isLoading && (
        <div className="flex items-center justify-center h-96">
          <Loader />
        </div>
      )}
    </GlassDiv>
  )
}

export default Category
