import React, { useEffect, useState } from "react"
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import axios from "config/axios"
import BorderColorIcon from "@mui/icons-material/BorderColor"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import { Inactive, Unmaped, Active } from "../../../shared/ActivaInactive"
import { Switch } from "@mui/material"
const superuserId = localStorage.getItem("is_superuser")
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  rounded: 2,
}

const AvailablePayment = () => {
  const [data, setData] = useState([])
  const [open1, setOpen1] = React.useState(false)
  const handleOpen1 = (id) => {
    setOpen1(true)
    // setPaymentId(id);
  }
  const handleClose1 = () => setOpen1(false)
  // const [open2, setOpen2] = React.useState(false);
  // const handleOpen2 = () => setOpen2(true);
  const [payment, setPayment] = useState("")
  // const [paymentId, setPaymentId] = useState(null);
  const storeId = localStorage.getItem("store_id")

  const editFunctionality = (event, payment1, id) => {
    event.preventDefault()
    setPayment(payment1)
    handleOpen1(id)
  }
  const handleModalClose = () => {
    setPayment("")
    handleClose1()
  }

  const Razorpay = () => {
    axios
      .get(`api/admin/multivendor-store-payment-accepted-by-list`, {
        headers: {
          Token: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        setData(response?.data?.store_payment_accepted_by_list)
        console.log("paymentGetwy data List====>", response?.data)
      })
      .catch((error) => {
        console.log("", error)
      })
  }

  useEffect(() => {
    Razorpay()
  }, [])

  const Razorupdate = () => {
    console.log("hello")
  }

  const changeStatus = (event, id) => {
    event.preventDefault()
    const reqbody = new FormData()
    reqbody.append("id", id)
    reqbody.append("store_id", storeId)

    axios
      .post(`api/admin/multivendor-store-payment-accepted-by-selected`, reqbody, {
        headers: {
          Token: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        //
        console.log(response?.data?.store_payment_accepted_by_list)
        Razorpay()
        handleClose1()
      })
      .catch((error) => {
        console.log("", error)
      })
  }

  return (
    <div className=" !bg-slate-100">
      <div className="flex justify-between">
        <div className="p-2">
          <p className="text-xl !text-black font font-bold">Store Payment Setting</p>
        </div>{" "}
        {/* {superuserId === true ? (
          <div className="p-2">
            <p
              className="text-xl !text-black font font-bold"
              onClick={handleOpen2}
            >
              +ADD
            </p>
          </div>
        ) : null} */}
      </div>

      <TableContainer
        component={Paper}
        sx={{ width: "100%", maxHeight: 440 }}
        className="!text-black !mx-auto !bg-white overflow-auto px-2"
      >
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  borderRight: "1px solid #D0D0D0",
                  fontFamily: "'Poppins', sans-serif",
                }}
                className="!text-center !text-gray-800 !font-bold"
              >
                ID
              </TableCell>
              <TableCell
                style={{
                  borderRight: "1px solid #D0D0D0",
                  fontFamily: "'Poppins', sans-serif",
                }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Store
              </TableCell>

              <TableCell
                style={{
                  borderRight: "1px solid #D0D0D0",
                  fontFamily: "'Poppins', sans-serif",
                }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Payment Gateway
              </TableCell>
              <TableCell
                style={{
                  borderRight: "1px solid #D0D0D0",
                  fontFamily: "'Poppins', sans-serif",
                }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Status
              </TableCell>

              <TableCell
                style={{
                  fontFamily: "'Poppins', sans-serif",
                }}
                className="!text-center !text-gray-800 !font-bold"
              >
                Enabled/Disbled
              </TableCell>
              {superuserId === true ? (
                <TableCell
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  Action
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          {data === ""
            ? null
            : data?.map((item, index) => (
                <TableBody className="!bg-red">
                  <TableRow className="!text-slate-700">
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {item?.store?.store_name}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {item?.payment_gateway}
                    </TableCell>

                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      <div className="flex justify-center w-full p-4">
                        {item.status === null ? <Unmaped /> : item.status === true ? <Active /> : <Inactive />}
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      <Switch
                        checked={item.status}
                        // defaultChecked={item.status ? true : false}
                        onChange={(event) => changeStatus(event, item?.id)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      {/* {
                        item.status === true ?
                        <Switch
                        defaultValue={item.status}
                        defaultChecked={item.status}
                      />
                      :
                      null
                      }
                      {
                        item.status === false ?
                        <Switch
                        defaultValue={item.status}
                        defaultChecked={item.status}
                        onClick={(event) => changeStatus(event, item?.id)}
                      />
                      :
                      null
                      } */}
                    </TableCell>
                    {superuserId === true ? (
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        <div>
                          <div>
                            <BorderColorIcon
                              className="text-green-400"
                              onClick={(event) => editFunctionality(event, data?.payment, item.id)}
                            />
                          </div>
                        </div>
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableBody>
              ))}
        </Table>
      </TableContainer>
      <div
        className="!float-right relative bg-white w-[100%] mx-auto bottom-0 right-0 py-3 border-t-2 border-gray-400 !flex-col !justify-end !items-center"
        style={{ marginRight: "0px" }}
      ></div>
      {superuserId === true ? (
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 400 }} className="rounded-2xl !border-0">
            <div
              id="child-modal-title "
              className="!text-black !underline !font-bold !py-3 !text-center !text-xl"
            ></div>
            <div className=" ">
              <p className="text-black"> Update Key</p>

              <div className="">
                <select
                  className="!w-[100%] !my-3"
                  value={payment}
                  onChange={(event) => setPayment(event.target.value)}
                >
                  {data === "" ? null : data?.map((item) => <option>{item.payment_gateway}</option>)}
                </select>
              </div>
            </div>
            <Button onClick={handleModalClose} variant="contained" color="warning" className="!py-2 !mt-3">
              CANCEL
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="success"
              className="!py-2 !px-5 !font-bold !mt-3 !float-right"
              onClick={() => Razorupdate()}
            >
              Update
            </Button>
          </Box>
        </Modal>
      ) : null}
    </div>
  )
}

export default AvailablePayment
