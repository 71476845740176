import axiosInstance from "config/axios"

const fetchEmployeeListFn = async (store_id) => {
  const response = await axiosInstance.get(`employee-candidate-detail-permission-api-of-superadmin/`, {
    params: { store_id },
  })
  return response.data.data
}

const fetchPermisionsFn = async ({ store_id, employee_id }) => {
  const response = await axiosInstance.get(`employee-candidate-detail-permission-api-of-superadmin/`, {
    params: { store_id, employee_id },
  })
  return response.data.data
}
const changePermisionsFn = async (reqBody) => {
  const response = await axiosInstance.post(`employee-candidate-detail-permission-api-of-superadmin/`, reqBody)
  return response
}

export { changePermisionsFn, fetchEmployeeListFn, fetchPermisionsFn }
