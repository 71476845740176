import { Add, Delete, Edit, Redeem } from "@mui/icons-material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Chip, Divider, Tab, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { deleteOffersFn, enableDisableOfferFn, offerManagmentFn } from "Services/Settings/Offers"
import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { CustomIconButton } from "shared/CustomIconButton"
import Loader from "shared/CustomLoader"
import CustomPagination from "shared/CustomPagination"
import MkxTableCell from "shared/MkxTable/MkxTableCell"
import NoDataFound from "shared/NoDataFound"
import AddOffers from "./AddOffers"

const OfferSettings = () => {
  const { store_id } = useParams()
  const [value, setValue] = useState("Offers")
  const [page, setPage] = useState(1)
  const [selected, setSelected] = useState(null)
  const client = useQueryClient()

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  const { data: offers, isLoading } = useQuery(["offersManagement", page], () => offerManagmentFn({ page, store_id }))

  const { mutate: deleteOffer } = useMutation(deleteOffersFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("offersManagement")
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const { mutate: enableDisableOffer } = useMutation(enableDisableOfferFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("offersManagement")
      } else {
        toast.error(response.data.message)
      }
    },
  })

  return (
    <TabContext value={value}>
      <Box>
        <TabList onChange={handleChange}>
          <Tab
            className="!font-semibold"
            label={
              <span className="flex items-center gap-1">
                <Redeem /> Offers
              </span>
            }
            value="Offers"
          />
          <Tab
            className="!font-semibold"
            label={
              <span className="flex items-center gap-1">
                <Add /> Offers
              </span>
            }
            value="Add Offers"
          />
        </TabList>
      </Box>
      <Divider />
      <TabPanel className="w-full h-full !p-0 overflow-auto" value="Offers">
        {!isLoading && offers?.data?.data?.length !== 0 && (
          <TableContainer>
            <Table className="border">
              <TableHead>
                <TableRow>
                  <MkxTableCell isHead>Offer Type</MkxTableCell>
                  <MkxTableCell isHead>Coupon Code</MkxTableCell>
                  <MkxTableCell isHead>Valid From</MkxTableCell>
                  <MkxTableCell isHead>Valid Till</MkxTableCell>
                  <MkxTableCell isHead>Active Status</MkxTableCell>
                  <MkxTableCell isHead>Discount Unit</MkxTableCell>
                  <MkxTableCell isHead>Discount Value</MkxTableCell>
                  <MkxTableCell isHead>Offer Through</MkxTableCell>
                  <MkxTableCell isHead>Action</MkxTableCell>
                </TableRow>
              </TableHead>

              <TableBody className="!bg-white !bg-opacity-10">
                {offers?.data?.data?.map((item) => (
                  <TableRow key={item?.id}>
                    <MkxTableCell>{item?.offer_type}</MkxTableCell>
                    <MkxTableCell>{item?.coupon_code}</MkxTableCell>
                    <MkxTableCell>{item?.valid_from}</MkxTableCell>
                    <MkxTableCell>{item?.valid_until}</MkxTableCell>
                    <MkxTableCell>
                      <Chip
                        size="small"
                        label={item?.active_status ? "Active" : "Inactive"}
                        color={item?.active_status ? "success" : "error"}
                        className="!w-20"
                        onClick={() => enableDisableOffer({ store_id, offer_id: item?.id })}
                      />
                    </MkxTableCell>
                    <MkxTableCell>{item?.discount_unit}</MkxTableCell>
                    <MkxTableCell>{item?.discount_value}</MkxTableCell>
                    <MkxTableCell>
                      {item?.offer_through === "Coupon"
                        ? item?.offer_through
                        : `${item?.category_name || item?.product_name || item?.service_name} (${item?.offer_through})`}
                    </MkxTableCell>
                    <MkxTableCell>
                      <div className="flex justify-center gap-2">
                        <CustomIconButton
                          color="success"
                          onClick={() => {
                            setSelected(item)
                            setValue("Add Offers")
                          }}
                        >
                          <Edit />
                        </CustomIconButton>
                        <CustomIconButton color="error" onClick={() => deleteOffer({ store_id, offer_ids: [item.id] })}>
                          <Delete />
                        </CustomIconButton>
                      </div>
                    </MkxTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <CustomPagination data={offers} setPage={setPage} />
          </TableContainer>
        )}

        <div className="flex items-center justify-center">{isLoading && <Loader />}</div>
        <NoDataFound data={offers} />
      </TabPanel>
      <TabPanel className="w-full h-full !p-0 overflow-auto" value="Add Offers">
        <AddOffers setValue={setValue} selected={selected} setSelected={setSelected} />
      </TabPanel>
    </TabContext>
  )
}

export default OfferSettings
