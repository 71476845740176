import { API_URLS } from "../../../config/apiUrls";
import axiosInstance from "../../../config/axios";

export const deleteButtonFn = (reqBody) => {
  try {
    const response = axiosInstance.delete(API_URLS.deleteButton, {
      params: reqBody,
    });
    return response;
  } catch ({ error }) {
    throw new Error(error.message);
  }
};

export const deleteSectionFn = (reqBody) => {
  try {
    const response = axiosInstance.delete(API_URLS.deleteSection, {
      params: reqBody,
    });
    return response;
  } catch ({ error }) {
    throw new Error(error.message);
  }
};

export const deleteSectionRowFn = (reqBody) => {
  try {
    const response = axiosInstance.delete(API_URLS.deleteSectionRow, {
      params: reqBody,
    });
    return response;
  } catch ({ error }) {
    throw new Error(error.message);
  }
};
