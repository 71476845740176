import { AccessTime, AddCircle, Close, Edit } from "@mui/icons-material"
import { Dialog, IconButton } from "@mui/material"
import {
  deleteStoreWorkingHourFn,
  storeWorkingHourListFn,
  updateStoreWorkingHourfn,
} from "Services/Settings/ChatSettings/StoreWorkingHour/inidex"
import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import WorkingHourFeild from "./WorkingHourFeild"

const SetWorkingHours = ({ isSending }) => {
  const [AllData, setAllData] = useState([])
  const [AvailableDays, setAvailableDays] = useState([
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ])

  const { data: templates } = useQuery(["storeWorkingHourList"], () => storeWorkingHourListFn(), {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      setAllData(response.data.data)
      response.data.data.map((variable) => {
        if (AvailableDays.includes(variable.day)) {
          let index = AvailableDays.indexOf(variable?.day)
          AvailableDays.splice(index, 1)
        }
      })
    },
  })
  const AvailableDaysAfterDelete = () => {
    templates?.data?.data.map((variable) => {
      if (AvailableDays.includes(variable.day)) {
        let index = AvailableDays.indexOf(variable?.day)
        AvailableDays.splice(index, 1)
      }
    })
  }

  const Time = [
    "00:00 AM",
    "00:30 AM",
    "01:00 AM",
    "01:30 AM",
    "02:00 AM",
    "02:30 AM",
    "03:00 AM",
    "03:30 AM",
    "04:00 AM",
    "04:30 AM",
    "05:00 AM",
    "05:30 AM",
    "06:00 AM",
    "06:30 AM",
    "07:00 AM",
    "07:30 AM",
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
    "09:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
  ]
  const [isDate, setIsDate] = useState(false)
  const handleClose = () => {
    setIsDate(false)
  }
  const client = useQueryClient()
  const { mutate: deleteWorkingHour } = useMutation(deleteStoreWorkingHourFn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        toast.success(response?.data?.message)
        handleClose()
        AvailableDaysAfterDelete()
      } else {
        toast.error(response?.data?.message)
      }
      client.refetchQueries("storeWorkingHourList")
    },
    onError: (error) => {
      toast.error(error.message ? error.message : error.msg)
    },
  })
  const { mutate: saveData } = useMutation(updateStoreWorkingHourfn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        toast.success(response?.data?.message)
        client.refetchQueries("storeWorkingHourList")
      } else {
        toast.error(response?.data?.message)
      }
    },
    onError: (error) => {
      toast.error(error.message ? error.message : error.msg)
    },
  })

  const deleteStoreWorkingHour = (reqBody, day) => {
    const updated_store_list = AllData?.filter((workingHour) => {
      return workingHour?.day !== day
    })
    setAllData(updated_store_list)
    if (reqBody?.store_working_hour_id) {
      deleteWorkingHour(reqBody)
    }
    AvailableDaysAfterDelete()
    handleClose()
  }
  const handleOpen = () => {
    client.refetchQueries("storeWorkingHourList")
    setIsDate(true)
  }
  const handleSave = () => {
    saveData({ store_working_hour_list: AllData })
  }

  return (
    <>
      <IconButton size="small" onClick={handleOpen}>
        <Edit className="hover:!text-blue-500" />
      </IconButton>
      <Dialog open={isDate} onClose={() => setIsDate(false)} PaperProps={{ className: "!w-full !h-4/6" }}>
        <div className="relative">
          <span className="flex items-center justify-between p-3 text-white bg-secondary">
            <span className="flex items-center gap-2">
              <AccessTime />
              <p className="font-semibold">Setup your working hours</p>
            </span>
            <IconButton onClick={() => setIsDate(false)}>
              <Close className="!text-white" />
            </IconButton>
          </span>

          <p className="p-5 text-xs font-semibold">
            Auto Reply messages will be triggered based on the below working hours. To understand how business working
            hours affect Auto Replies, read here. (timings will match your system timings)
          </p>
          <form className="w-[95%] pb-20 overflow-y-auto relative p-2 flex gap-2 flex-col border mx-auto">
            {AllData?.map((variable, index) => {
              return (
                <WorkingHourFeild
                  setAllData={setAllData}
                  AllData={AllData}
                  index={index}
                  setAvailableDays={setAvailableDays}
                  key={index}
                  variable={variable}
                  Time={Time}
                  deleteStoreWorkingHour={deleteStoreWorkingHour}
                  AvailableDays={AvailableDays.filter((day) => day !== variable?.day)}
                />
              )
            })}
            {AllData?.length < 7 && (
              <CustomButton
                variant="outlined"
                startIcon={<AddCircle />}
                className="!w-1/3"
                onClick={() => {
                  const newElement = {
                    day: AvailableDays[0],
                    from_time: "10:00 AM",
                    to_time: "07:00 PM",
                  }
                  saveData({
                    store_working_hour_list: [...AllData, newElement],
                  })
                  setAllData((oldArray) => [...oldArray, newElement])
                  AvailableDays.shift()
                }}
              >
                Add Hours
              </CustomButton>
            )}
            <span className="absolute bottom-0 flex justify-end w-[98%] gap-2 py-3">
              <CustomButton color="inherit" onClick={handleClose}>
                Cancel
              </CustomButton>
              <CustomButton disabled={isSending} color="success" className="w-28" onClick={() => handleSave()}>
                {isSending ? "Sending..." : "Save"}
              </CustomButton>
            </span>
          </form>
        </div>
      </Dialog>
    </>
  )
}
export default SetWorkingHours
