import ExpandMore from "@mui/icons-material/ExpandMore"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { Collapse, List } from "@mui/material"
import { menuItems } from "Mock"
import classNames from "classnames"
import React, { useState } from "react"
import { Link, useLocation } from "react-router-dom"

const Sidebar = () => {
  const { pathname } = useLocation()
  const [openState, setOpenState] = useState("")
  const [openSubState, setOpenSubState] = useState("")

  return (
    <div className="flex flex-col p-px h-[90vh] justify-between overflow-y-auto relative hide-scroll">
      <div className="flex flex-col">
        <List className="flex flex-col">
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <Link
                to={item.path}
                className={classNames(
                  "p-2 px-3 flex justify-between text-gray-100 text-sm m-1",
                  pathname === item.path ? "!text-gray-700 bg-white rounded" : ""
                )}
                onClick={() => setOpenState(openState === item.stateKey ? "" : item.stateKey)}
              >
                {item.label}
                {item.stateKey && (openState === item.stateKey ? <ExpandMore /> : <NavigateNextIcon />)}
              </Link>
              {item.stateKey && (
                <Collapse
                  in={openState === item.stateKey}
                  className="flex flex-col mx-1 border rounded-lg"
                  timeout="auto"
                  unmountOnExit
                >
                  <List className="!flex !flex-col !py-0">
                    {item?.subItems?.map((subItem, subIndex) => (
                      <>
                        <Link
                          key={subIndex}
                          to={subItem.path}
                          onClick={() => setOpenSubState(openSubState === subItem.stateKey ? "" : subItem.stateKey)}
                          className={classNames(
                            "p-2 px-3 flex justify-between text-gray-100 text-sm m-1",
                            pathname === subItem.path ? "!text-gray-700 bg-white rounded" : ""
                          )}
                        >
                          {subItem.label}
                          {subItem.stateKey &&
                            (openSubState === subItem.stateKey ? <ExpandMore /> : <NavigateNextIcon />)}
                        </Link>
                        {subItem.stateKey && (
                          <Collapse
                            in={openSubState === subItem.stateKey}
                            className="flex flex-col mx-1 border rounded-lg"
                            timeout="auto"
                            unmountOnExit
                          >
                            <List className="!flex !flex-col !py-0">
                              {subItem?.subItems?.map((subSubItem, subIndex) => (
                                <Link
                                  key={subIndex}
                                  to={subSubItem.path}
                                  className={classNames(
                                    "p-2 px-3 m-1 text-gray-100 text-sm pl-5",
                                    pathname === subSubItem.path ? "!text-gray-700 bg-white rounded" : ""
                                  )}
                                >
                                  {subSubItem.label}
                                </Link>
                              ))}
                            </List>
                          </Collapse>
                        )}
                      </>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </div>
    </div>
  )
}

export default Sidebar
