import { Delete, Edit } from "@mui/icons-material"
import { Skeleton, TableContainer, TableHead, TableRow } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import { deleteNewsAnnoucementsFn, newsAnnoucementsFn } from "Services/NewsManagement"
import moment from "moment"
import { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { toast } from "react-toastify"
import { CustomIconButton } from "shared/CustomIconButton"
import MkxInput from "shared/MkxInput"
import MkxPagination from "shared/MkxTable/MkxPagination"
import MkxTableCell from "shared/MkxTable/MkxTableCell"
import AddNewsAnnouncement from "./AddNewsAnnouncement"

const NewsManagement = () => {
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const [selected, setSelected] = useState(null)

  const handlePageChange = (_event, value) => {
    setPage(value)
  }

  const {
    data: news,
    isLoading,
    refetch,
  } = useQuery(["newsAnnoucements", search, page], () => newsAnnoucementsFn({ page, search: search }))

  const { mutate: deleteNews } = useMutation(deleteNewsAnnoucementsFn, {
    onSuccess: ({ data }) => {
      if (data.response_code === 200) {
        toast.success(data.message)
        refetch()
      } else {
        toast.error(data.message)
      }
    },
  })

  return (
    <>
      <div className="!bg-white">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font font-bold !text-black">News Announcement List</p>
            <p className="!text-black">View/Manage News Announcement list</p>
          </div>
        </div>
        <div className="flex flex-col !mt-3 !rounded bg-dark border-secondary shadow-card shadow-zinc-600 m-2 ">
          <div className="flex items-center justify-between p-2 h-14 border-zinc-600">
            <MkxInput placeholder="Search News Announcement..." onChange={(event) => setSearch(event.target.value)} />
            <AddNewsAnnouncement selected={selected} setSelected={setSelected} />
          </div>

          <TableContainer>
            <Table size="small">
              <TableHead className="!bg-gray-200">
                <TableRow>
                  <MkxTableCell isHead>S/No</MkxTableCell>
                  <MkxTableCell isHead>Title</MkxTableCell>
                  <MkxTableCell isHead>Created Date</MkxTableCell>
                  <MkxTableCell isHead>Pulish Date</MkxTableCell>
                  <MkxTableCell isHead>Action</MkxTableCell>
                </TableRow>
              </TableHead>
              <TableBody className="!overflow-y-auto">
                {isLoading
                  ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => {
                      return (
                        <TableRow>
                          {[1, 2, 3, 4, 5].map(() => {
                            return (
                              <MkxTableCell>
                                <Skeleton />
                              </MkxTableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })
                  : news?.data?.data?.map((news, index) => (
                      <TableRow key={news?.id}>
                        <MkxTableCell>{index + 1}</MkxTableCell>
                        <MkxTableCell>{news?.title ? news?.title : "--"}</MkxTableCell>
                        <MkxTableCell>{news?.date ? moment(news?.date).calendar() : "--"}</MkxTableCell>
                        <MkxTableCell>{news?.publish_date ? moment(news?.publish_date).calendar() : "--"}</MkxTableCell>
                        <MkxTableCell>
                          <div className="flex justify-center gap-1">
                            <CustomIconButton color="primary" onClick={() => setSelected(news)}>
                              <Edit />
                            </CustomIconButton>
                            <CustomIconButton color="error" onClick={() => deleteNews({ news_ids: [news?.id] })}>
                              <Delete />
                            </CustomIconButton>
                          </div>
                        </MkxTableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <MkxPagination count={news?.data?.total_pages} page={news?.data?.current_page} onChange={handlePageChange} />
        </div>
      </div>
    </>
  )
}

export default NewsManagement
