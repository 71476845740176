import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"

export const addWoocommerceSyncFn = (reqBody) => {
  try {
    const response = axiosInstance.post(`woocommerce-setup-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const getWoocommerceSyncFn = (reqBody) => {
  try {
    const response = axiosInstance.get(`woocommerce-setup-api-of-superadmin/`, { params: reqBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const updateWoocommerceSyncFn = (reqBody) => {
  try {
    const response = axiosInstance.put(`woocommerce-setup-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const createProductFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.woocommerce.createProduct, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateProductFn = (reqBody) => {
  try {
    const response = axiosInstance.put(API_URLS.woocommerce.updateProduct, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const syncAllOrdersFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.woocommerce.syncOrders, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const deleteAllProductFn = (reqBody) => {
  try {
    const response = axiosInstance.delete(API_URLS.woocommerce.updateProduct, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const createAllCatgeoryFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.woocommerce.createCatgeory, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const deleteAllCatgeoryFn = (reqBody) => {
  try {
    const response = axiosInstance.delete(API_URLS.woocommerce.createCatgeory, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
