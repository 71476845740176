import { Checkbox, Divider, MenuItem } from "@mui/material"
import { addPaySlipFn, getPaySlipFieldFn, updatePaySlipFn } from "Services/Settings/HRMSetting"
import { changePermisionsFn, fetchEmployeeListFn, fetchPermisionsFn } from "Services/Settings/HiringSettings"
import { useFormik } from "formik"
import { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import MkxSelect from "shared/MkxSelect"
import SearchAndSelectNew from "shared/SearchAndSelectNew"

const HRMSettings = () => {
  const { store_id } = useParams()
  const [employeeId, setEmployeeId] = useState("")
  //   const [changes, setChanges] = useState()

  //   const { data: employees } = useQuery(["employeeList", store_id], () => fetchEmployeeListFn(store_id))
  //   const { data: fetchPermisions, refetch } = useQuery(
  //     ["fetchPermisions", store_id, employeeId],
  //     () => fetchPermisionsFn({ store_id, employee_id: employeeId }),
  //     { enabled: !!employeeId }
  //   )
  const { data: paySlipField, refetch } = useQuery(["getPaySlip", store_id], () => getPaySlipFieldFn({ store_id }))

  const { mutate: addPaySlipField, isLoading: isLoadingAdd } = useMutation(addPaySlipFn, {
    onSuccess: ({ data }) => {
      if (data.response_code === 200) {
        toast.success(data.message)
        refetch()
      } else {
        toast.error(data.message)
      }
    },
  })
  const { mutate: updatePaySlipField, isLoading: isLoadingUpdate } = useMutation(updatePaySlipFn, {
    onSuccess: ({ data }) => {
      if (data.response_code === 200) {
        toast.success(data.message)
        refetch()
      } else {
        toast.error(data.message)
      }
    },
  })

  // Dynamic initial values based on fetched fields
  const initialValue = {
    ...paySlipField?.reduce((acc, field) => {
      acc[field.field] = field.value || false
      return acc
    }, {}),
    //     employee_id: employeeId || false,
  }

  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: (values) => {
      const reqBody = new FormData()
      Object.entries(values).forEach(([key, value]) => {
        reqBody.append(key, value)
      })
      reqBody.get("id") == "false" && reqBody.append("store", store_id)
      reqBody.get("id") !== "false" && reqBody.append("pay_slip_id", reqBody.get("id"))
      // console.log("AAAa", typeof reqBody.get("id"))
      reqBody.get("id") == "false" ? addPaySlipField(reqBody) : updatePaySlipField(reqBody)
    },
  })

  return (
    <>
      <p className="py-2 text-xl font-semibold">HRM Configurations</p>
      <form onSubmit={formik.handleSubmit}>
        {/* <SearchAndSelectNew
          className="w-52"
          setChanges={setChanges}
          value={employeeId}
          options={employees?.data?.map((i) => ({ label: i.name, value: i.id }))}
          //     onChange={(event) => setEmployeeId(event.target.value)}
          setValue={(value) => setEmployeeId(value)}
          placeholder="Select Employee"
          isLoading={isLoading}
        /> */}

        {/* <MkxSelect
          className="w-52 flex justify-end"
          value={employeeId}
          onChange={(event) => setEmployeeId(event.target.value)}
          placeholder="Select Employee"
        >
          {employees?.map((item) => {
            return <MenuItem value={item?.emp_id}>{item?.user}</MenuItem>
          })}
        </MkxSelect> */}
        <div className="grid grid-cols-3 pt-4">
          {paySlipField?.map(
            (value) =>
              value.field !== "id" &&
              value.field !== "store" && (
                <span className="flex items-center">
                  <Checkbox
                    id={`${value.field}`}
                    name={`${value.field}`}
                    className="!p-1 !pl-0"
                    defaultChecked={value.value}
                    checked={formik.values[value.field]}
                    onChange={(e) => formik.setFieldValue(value.field, e.target.checked)}
                  />
                  <p className="my-2 !capitalize">{value.field.replaceAll("_", " ")}</p>
                </span>
              )
          )}
        </div>
        <Divider className="!my-2" />
        <CustomButton
          className="float-right px-5 py-2 font-bold bg-blue-500 rounded-md"
          type={"submit"}
          isLoading={isLoadingUpdate || isLoadingAdd}
        >
          Save
        </CustomButton>
      </form>
    </>
  )
}
export default HRMSettings
