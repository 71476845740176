import { Add, Save } from "@mui/icons-material"
import { addNewsAnnoucementsFn, updateNewsAnnoucementsFn } from "Services/NewsManagement"
import { useFormik } from "formik"
import moment from "moment"
import { useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import MkxButton from "shared/MkxButton"
import MkxInput from "shared/MkxInput"
import MkxModal from "shared/MkxModal"

const AddNewsAnnouncement = ({ selected, setSelected }) => {
  const [open, setOpen] = useState(false)

  const isUpdate = Boolean(selected)
  const client = useQueryClient()

  const { mutate: addNews } = useMutation(addNewsAnnoucementsFn, {
    onSuccess: ({ data }) => {
      if (data.response_code === 200) {
        toast.success(data.message)
        setOpen(false)
        setSelected(null)
        client.refetchQueries("newsAnnoucements")
      } else {
        toast.error(data.message)
      }
    },
  })
  const { mutate: updateNews } = useMutation(updateNewsAnnoucementsFn, {
    onSuccess: ({ data }) => {
      if (data.response_code === 200) {
        toast.success(data.message)
        setOpen(false)
        setSelected(null)
        client.refetchQueries("newsAnnoucements")
      } else {
        toast.error(data.message)
      }
    },
  })

  const initialValues = {
    title: selected?.title ?? "",
    data: selected?.data?.join("\n"),
    publish_date: "",
  }
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const reqbody = {
        title: values.title,
        data: values.data?.split("\n"),
        publish_date: moment(values.publish_date).format("YYYY-MM-DDThh:mm:ss"),
      }
      isUpdate ? updateNews({ news_id: selected.id, ...reqbody }) : addNews(reqbody)
    },
  })

  return (
    <>
      <MkxButton startIcon={<Add />} onClick={() => setOpen(true)}>
        {isUpdate ? "Update" : "Add"} News
      </MkxButton>
      <MkxModal
        title="News Annoucement"
        open={open || isUpdate}
        onClose={() => {
          setOpen(false)
          setSelected(null)
        }}
        className="w-1/2"
      >
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 p-5">
          <MkxInput label="Title" placeholder="Enter Title" id="title" formik={formik} />
          <MkxInput label="Published Date" type="date" id="publish_date" formik={formik} />
          <MkxInput id="data" label="Content" placeholder="Enter Content" rows={10} multiline formik={formik} />
          <MkxButton startIcon={<Save />} type="submit">
            Save
          </MkxButton>
        </form>
      </MkxModal>
    </>
  )
}

export default AddNewsAnnouncement
