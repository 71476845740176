import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import SettingsIcon from "@mui/icons-material/Settings"
import { Button } from "@mui/material"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormLabel from "@mui/material/FormLabel"
import Modal from "@mui/material/Modal"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import Typography from "@mui/material/Typography"
import axios from "config/axios"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

const DeliveryManagement = () => {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [statusDelivery, setStatusDelivery] = useState("")

  const changedeliveryStatus = () => {
    axios
      .put(`delivery_manager/delivery-preference/`, { delivery_option: statusDelivery })
      .then((response) => {
        console.log(response?.data?.message)
        toast.success(response?.data?.success)

        handleClose()
      })
      .catch((error) => {
        console.log("", error)
        toast.success(error?.data?.token)
      })
  }

  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font-bold text-black">Delivery Management</p>
          </div>
        </div>
        <div className="flex m-2">
          <div className="p-2 border border-gray-500 shadow-sm shadow-blue-900">
            <p className="w-64 font-bold text-center text-black">API Configuration</p>
            <div className="flex justify-between p-1 mt-4 text-black rounded shadow-sm cursor-pointer shadow-gray-400">
              <div className="flex justify-between">
                <div>
                  <Button variant="text" color="primary" onClick={handleOpen} size="small">
                    <SettingsIcon />
                    <span className="!px-2">Delivery Prefrence</span>
                  </Button>
                </div>
              </div>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="!border-0 !rounded-xl">
                  <Typography id="modal-modal-title" variant="h6" component="h6">
                    <div
                      className="!text-center underline underline-offset-4"
                      style={{ fontFamily: "'Poppins', sans-serif" }}
                    >
                      <div></div>
                    </div>
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <div className="gap-y-3"></div>
                    <div>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Delivery Prefrence</FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          // defaultValue={data ? data :""}
                          name="radio-buttons-group"
                          onChange={(event) => setStatusDelivery(event.target.value)}
                        >
                          <FormControlLabel value="Ship Rocket" control={<Radio />} label="Stip Rocket" />
                          <FormControlLabel value="Porter" control={<Radio />} label="Porter" />
                          <FormControlLabel value="Bharaat Delivery" control={<Radio />} label="Bhaarat Delivery" />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <div className="items-center justify-between ">
                      <Button className="float-left top-3" variant="contained" color="warning" onClick={handleClose}>
                        CANCEL
                      </Button>

                      <Button
                        variant="contained"
                        color="success"
                        className="float-right mr-3 top-3"
                        onClick={changedeliveryStatus}
                      >
                        SAVE
                      </Button>
                    </div>
                  </Typography>
                </Box>
              </Modal>
            </div>

            <div
              className="flex justify-between p-1 mt-4 text-black rounded shadow-sm cursor-pointer shadow-gray-400 w-44"
              onClick={() => navigate("/delivery-management/ship-rocket")}
            >
              <div className="text-blue-500">Ship Rocket</div>
              <div>
                {" "}
                <ArrowForwardIcon className="!text-blue-500" />
              </div>
            </div>
            <div
              className="flex justify-between p-1 mt-4 text-black rounded shadow-sm cursor-pointer shadow-gray-400 w-44"
              onClick={() => navigate("/delivery-management/porter")}
            >
              <div className="text-blue-500">Porter</div>
              <div>
                {" "}
                <ArrowForwardIcon className="!text-blue-500" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeliveryManagement
