import {
  AccountTree,
  Add,
  AddCircle,
  ArrowBack,
  Check,
  Close,
  ExpandLess,
  ExpandMore,
  FormatListBulleted,
} from "@mui/icons-material"
import {
  Button,
  Collapse,
  Dialog,
  Divider,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Select,
} from "@mui/material"
import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import CustomButton from "shared/MkxButton"

import { useFormik } from "formik"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import CustomInput from "shared/MkxInput"
import { chatTempsFn } from "Services/Chats/WhatsappTemplatesWithoutNumber"
import {
  addflowStepsFn,
  createWorkFlowFn,
  updateWorkFlowFn,
  updateflowStepsFn,
  workflowStepsFn,
} from "Services/Chats/WhatsappWorkflow"
import { variableListFn } from "Services/Settings/ChatSettings/CustomAutoReply"

const CreateWorkflows = () => {
  const { state } = useLocation()
  const [focus, setFocus] = useState(false)
  const [workflowTitle, setWorkflowTitle] = useState("")
  const [body, setBody] = useState("")
  const [response, setResponse] = useState("")
  const [list, setList] = useState([])
  const [isAdd, setIsAdd] = useState(false)
  const navigate = useNavigate()
  const client = useQueryClient()

  const { data: workflowSteps } = useQuery(
    ["workflowSteps", state?.workflowId],
    () => workflowStepsFn({ workflow_id: state?.workflowId }),
    { refetchOnWindowFocus: false, enabled: state?.workflowId ? true : false }
  )
  const workflow = workflowSteps?.data?.data
  const { mutate: createWorkflow } = useMutation(createWorkFlowFn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        navigate("/settings/whatsapp/templates", {
          state: {
            page: "CreateWorkflows",
            workflowId: response?.data?.data?.id,
          },
        })
        setFocus(false)
        client.refetchQueries("workflowSteps")
        client.refetchQueries("whatsappWorkflow")
        toast.success(response?.data?.message)
      } else {
        toast.warning(response?.data?.message)
      }
    },
  })
  const { mutate: updateWorkflow } = useMutation(updateWorkFlowFn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        toast.success(response?.data?.message)
        client.refetchQueries("workflowSteps")
        client.refetchQueries("whatsappWorkflow")
        setFocus(false)
      } else {
        toast.warning(response?.data?.message)
      }
    },
  })
  const { data: buttonValuesData } = useQuery(["buttonValues"], () => chatTempsFn({ template_type: "Custom" }), {
    refetchOnWindowFocus: false,
  })
  const buttonValues = buttonValuesData?.data?.data

  const { mutate: addWorkflowStep } = useMutation(addflowStepsFn, {
    onSuccess: (res) => {
      toast.success(res?.data.message)
      client.refetchQueries("workflowSteps")
      setBody("")
      setList([])
      formik.resetForm()
      setIsAdd(false)
    },
  })
  const { mutate: updateWorkflowStep } = useMutation(updateflowStepsFn, {
    onSuccess: (res) => {
      toast.success(res.data.message)
      client.refetchQueries("workflowSteps")
      setBody("")
      setList([])
      setResponse({})
    },
  })
  const { data: variableList } = useQuery(["variableList"], variableListFn, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const initialValues = {
    message_object_type: response?.message_object_type || "text",
    button_list: response?.button_list?.map((button) => ({
      button_id: button.id,
      button_text: button.button_text,
      button_value: button.button_value,
    })) || [{ button_text: "", button_value: "" }],
    section_list: response?.section_list?.map((section) => ({
      section_title: section.section_title,
      section_list_id: section.id,
      rows_list: section?.rows_list?.map((row) => ({
        rows_id: row.rows_id,
        rows_title: row.rows_title,
        rows_list_id: row.id,
      })),
    })) || [{ section_title: "", rows_list: [{ rows_id: "", rows_title: "" }] }],
    action_button_text: response?.action_button_text || "",
    variable_list: response?.variable_list || [],
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      response
        ? updateWorkflowStep({
            ...formik.values,
            body: body,
            workflow_step_id: response.id,
            workflow_id: state?.workflowId,
          })
        : addWorkflowStep({
            ...formik.values,
            body: body,
            workflow_id: state?.workflowId,
          })
    },
  })

  useEffect(() => {
    workflow && setWorkflowTitle(workflow?.workflow_title)
  }, [workflow])

  useEffect(() => {
    response ? setBody(response?.body) : setBody("")
  }, [response])

  return (
    <span className="flex flex-col h-full">
      <span className="flex items-center justify-between p-2">
        <span className="flex items-center gap-2">
          <IconButton
            onClick={() =>
              navigate("/settings/whatsapp/templates", {
                state: { page: "Workflows" },
              })
            }
          >
            <ArrowBack />
          </IconButton>
          <p className="font-semibold">Back to Workflows</p>
        </span>
      </span>
      <Divider />
      <span className="flex h-[92%] w-full">
        <span className="w-2/3 flex justify-center pt-5 px-[10%]">
          <span className="flex flex-col w-full h-full border border-white shadow-md border-opacity-20">
            <span className="flex items-center gap-3 p-4 shadow">
              <AccountTree color="success" className="!text-4xl rounded p-2" />
              <form
                onSubmit={(event) => {
                  event.preventDefault()
                  state?.workflowId
                    ? updateWorkflow({
                        id: state?.workflowId,
                        workflow_title: workflowTitle,
                      })
                    : createWorkflow({ workflow_title: workflowTitle })
                }}
                className="flex items-center gap-2"
              >
                <input
                  onFocus={() => setFocus(true)}
                  type="text"
                  value={workflowTitle}
                  onChange={(event) => setWorkflowTitle(event.target.value)}
                  placeholder="Enter Workflow Title"
                  className={classNames(
                    "bg-white bg-opacity-20 text-white placeholder:text-gray-200 outline-none !w-full p-1.5",
                    focus && "!bg-white !bg-opacity-20"
                  )}
                />

                {focus && (
                  <span className="flex items-center gap-2">
                    <IconButton type="submit" size="small" className="!rounded-none group !bg-gray-100 !bg-opacity-20">
                      <Check className="group-hover:text-green-500" />
                    </IconButton>
                    <IconButton
                      size="small"
                      className="!rounded-none group !bg-gray-100 !bg-opacity-20"
                      onClick={() => setFocus(false)}
                    >
                      <Close className="group-hover:text-red-500" />
                    </IconButton>
                  </span>
                )}
              </form>
            </span>
            <Divider />
            <span className="flex flex-col h-full gap-4 p-2 overflow-y-auto">
              {workflow?.workflow_step?.map((item, index) => {
                return (
                  <span className="flex flex-col gap-4">
                    <span className="flex justify-end">
                      <span
                        className={classNames(
                          "p-3 flex flex-col shadow-lg border-2 border-blue-500 border-opacity-0 bg-white gap-2 w-[60%]",
                          response.id === item.id && "!border-opacity-100"
                        )}
                      >
                        <p className="text-sm">Response {index + 1}</p>
                        <span className="bg-[#E1EACB] rounded p-3">
                          <p dangerouslySetInnerHTML={{ __html: item?.body }}></p>
                        </span>
                        <span className="flex gap-2">
                          {item?.button_list?.map((button) => {
                            return (
                              <span className="p-2 px-4 text-center text-blue-500 rounded bg-gray-200 !w-full">
                                {button?.button_text}
                              </span>
                            )
                          })}
                        </span>
                      </span>
                    </span>
                    <span className="flex items-center justify-start ">
                      <span className="p-3 flex flex-col shadow-lg bg-white w-[60%]">
                        <span className="p-2 bg-gray-200 rounded">{item?.response_reply?.user_input}</span>
                      </span>
                    </span>
                  </span>
                )
              })}
            </span>
          </span>
        </span>
        <Divider orientation="vertical" />
        <span className="w-1/3 overflow-y-auto shadow-lg">
          <p className="p-5 font-semibold">Edit Response</p>
          <Divider />
          <List className="!p-0">
            {workflow?.workflow_step?.map((item, index) => {
              return (
                <>
                  <ListItemButton
                    onClick={() => {
                      response.id !== item.id ? setResponse(item) : setResponse("")
                      response.id !== item.id && setIsAdd(false)
                    }}
                    className="!flex !justify-between"
                  >
                    Response {index + 1}
                    {response.id === item.id ? <ExpandMore /> : <ExpandLess />}
                  </ListItemButton>
                  <Divider />
                  <Collapse in={response.id === item.id}>
                    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 p-4">
                      <textarea
                        id="body"
                        rows={7}
                        multiline
                        className="p-2 border border-gray-300 rounded outline-none"
                        value={body}
                        placeholder={`Hello Customer_Name,
              
    Ready to take your retail business to the next level with Store Name? 
    I am Store_Manager, here to assist you with our services , which offers POS, Billing, Accounting, Inventory 
    management, and much more to help you manage your business more efficiently.
    
Don't Type, Just Dial! 
Speak to an expert now.

Call Us: 9266667999`}
                        onChange={(event) => setBody(event.target.value)}
                      />
                      {formik.values.message_response_type !== "workflow" && (
                        <>
                          <CustomButton
                            variant="text"
                            startIcon={<AddCircle />}
                            className="!flex !justify-start !px-3"
                            onClick={() => {
                              formik.setFieldValue("variable_list", [
                                ...formik.values.variable_list,
                                {
                                  index: formik.values.variable_list?.length + 1,
                                  key: "",
                                  value: "",
                                },
                              ])
                              setBody(`${body} *{{${formik.values.variable_list?.length + 1}}}*`)
                            }}
                          >
                            Add Variable
                          </CustomButton>
                          <Collapse in={formik.values.variable_list?.length !== 0 ? true : false}>
                            <span className="!flex !flex-col !gap-2">
                              {formik.values.variable_list?.map((variable, index) => {
                                return (
                                  <span className="flex gap-2">
                                    <CustomInput placeholder="Variable" value={`{{${variable?.index}}}`} disabled />
                                    <FormControl className="!w-1/2">
                                      <Select
                                        size="small"
                                        id={`button_value${index + 1}`}
                                        value={formik.values.variable_list[index]?.key || ""}
                                        onChange={(event) => {
                                          const newButtonList = [...formik.values.variable_list]
                                          newButtonList[index] = {
                                            ...newButtonList[index],
                                            key: event.target.value,
                                          }
                                          formik.setValues({
                                            ...formik.values,
                                            variable_list: newButtonList,
                                          })
                                        }}
                                      >
                                        {variableList?.data?.data?.map((value) => {
                                          return <MenuItem value={value?.key}>{value?.key}</MenuItem>
                                        })}
                                      </Select>
                                    </FormControl>
                                    <CustomInput
                                      placeholder="Fallback value"
                                      value={variable.value}
                                      onChange={(event) => {
                                        const newButtonList = [...formik.values.variable_list]
                                        newButtonList[index] = {
                                          ...newButtonList[index],
                                          value: event.target.value,
                                        }
                                        formik.setValues({
                                          ...formik.values,
                                          variable_list: newButtonList,
                                        })
                                      }}
                                    />
                                  </span>
                                )
                              })}
                            </span>
                          </Collapse>
                        </>
                      )}
                      <p>Add text, button or list to your response</p>
                      <FormControl>
                        <Select
                          size="small"
                          id="message_object_type"
                          name="message_object_type"
                          value={formik.values.message_object_type}
                          onChange={formik.handleChange}
                        >
                          <MenuItem value={"text"}>Text</MenuItem>
                          <MenuItem value={"button"}>Button</MenuItem>
                          <MenuItem value={"list"}>List</MenuItem>
                        </Select>
                      </FormControl>
                      {formik.values.message_object_type === "button" && (
                        <>
                          {formik.values.button_list?.map((button, index) => {
                            return (
                              <span className="flex flex-col gap-2">
                                <p>Button {index + 1} </p>
                                <div className="flex gap-2">
                                  <CustomInput
                                    id={`button_text${index + 1}`}
                                    label="Text"
                                    placeholder="Enter Button Text"
                                    value={formik.values.button_list[index]?.button_text || ""}
                                    onChange={(event) => {
                                      const newButtonList = [...formik.values.button_list]
                                      newButtonList[index] = {
                                        ...newButtonList[index],
                                        button_text: event.target.value,
                                      }

                                      formik.setValues({
                                        ...formik.values,
                                        button_list: newButtonList,
                                      })
                                    }}
                                    className="!w-1/2"
                                  />
                                  <FormControl className="!w-1/2">
                                    <Select
                                      size="small"
                                      id={`button_value${index + 1}`}
                                      value={formik.values.button_list[index]?.button_value || ""}
                                      onChange={(event) => {
                                        const newButtonList = [...formik.values.button_list]
                                        newButtonList[index] = {
                                          ...newButtonList[index],
                                          button_value: event.target.value,
                                        }
                                        formik.setValues({
                                          ...formik.values,
                                          button_list: newButtonList,
                                        })
                                      }}
                                    >
                                      {buttonValues?.map((value) => {
                                        return <MenuItem value={value?.id}>{value?.name}</MenuItem>
                                      })}
                                    </Select>
                                  </FormControl>
                                </div>
                              </span>
                            )
                          })}
                          {formik.values.button_list?.length !== 3 && (
                            <CustomButton
                              variant="text"
                              startIcon={<Add />}
                              onClick={() =>
                                formik.values.button_list?.[formik.values.button_list?.length - 1]?.button_text !==
                                  "" &&
                                formik.setValues({
                                  ...formik.values,
                                  button_list: [...formik.values.button_list, { button_text: "", button_value: "" }],
                                })
                              }
                            >
                              Add Button
                            </CustomButton>
                          )}
                        </>
                      )}
                      {formik.values.message_object_type === "list" && (
                        <>
                          <span className="flex gap-1">
                            <CustomInput
                              placeholder="Action Button"
                              id="action_button_text"
                              className="!w-1/2"
                              formik={formik}
                            />
                            <Button
                              startIcon={<FormatListBulleted />}
                              onClick={() => setList(formik.values.section_list)}
                              className="!w-1/2 !capitalize"
                            >
                              {formik.values.action_button_text}
                            </Button>
                          </span>
                        </>
                      )}
                      <span className="flex gap-4">
                        <CustomButton type="submit" className="!w-full">
                          Save
                        </CustomButton>
                        <CustomButton color="inherit" className="!w-full" onClick={() => setResponse("")}>
                          Cancel
                        </CustomButton>
                      </span>
                    </form>
                    <Divider />
                  </Collapse>
                </>
              )
            })}
            <ListItemButton
              className="!text-secondary !flex gap-2"
              onClick={() => {
                setIsAdd(!isAdd)
                setResponse("")
              }}
            >
              <AddCircle fontSize="small" />
              Add Another Response
            </ListItemButton>
            <Collapse in={isAdd}>
              <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 p-4">
                <textarea
                  id="body"
                  rows={7}
                  multiline
                  className="p-2 border border-gray-300 rounded outline-none"
                  value={body}
                  placeholder={`Hello Customer_Name,
              
    Ready to take your retail business to the next level with Store Name? 
    I am Store_Manager, here to assist you with our services , which offers POS, Billing, Accounting, Inventory 
    management, and much more to help you manage your business more efficiently.
    
Don't Type, Just Dial! 
Speak to an expert now.

Call Us: 9266667999`}
                  onChange={(event) => setBody(event.target.value)}
                />
                {formik.values.message_response_type !== "workflow" && (
                  <>
                    <CustomButton
                      variant="text"
                      startIcon={<AddCircle />}
                      className="!flex !justify-start !px-3"
                      onClick={() => {
                        formik.setFieldValue("variable_list", [
                          ...formik.values.variable_list,
                          {
                            index: formik.values.variable_list?.length + 1,
                            key: "",
                            value: "",
                          },
                        ])
                        setBody(`${body} *{{${formik.values.variable_list?.length + 1}}}*`)
                      }}
                    >
                      Add Variable
                    </CustomButton>
                    <Collapse in={formik.values.variable_list?.length !== 0 ? true : false}>
                      <span className="!flex !flex-col !gap-2">
                        {formik.values.variable_list?.map((variable, index) => {
                          return (
                            <span className="flex gap-2">
                              <CustomInput placeholder="Variable" value={`{{${variable?.index}}}`} disabled />
                              <FormControl className="!w-1/2">
                                <Select
                                  size="small"
                                  id={`button_value${index + 1}`}
                                  value={formik.values.variable_list[index]?.key || ""}
                                  onChange={(event) => {
                                    const newButtonList = [...formik.values.variable_list]
                                    newButtonList[index] = {
                                      ...newButtonList[index],
                                      key: event.target.value,
                                    }
                                    formik.setValues({
                                      ...formik.values,
                                      variable_list: newButtonList,
                                    })
                                  }}
                                >
                                  {variableList?.data?.data?.map((value) => {
                                    return <MenuItem value={value?.key}>{value?.key}</MenuItem>
                                  })}
                                </Select>
                              </FormControl>
                              <CustomInput
                                placeholder="Fallback value"
                                value={variable.value}
                                onChange={(event) => {
                                  const newButtonList = [...formik.values.variable_list]
                                  newButtonList[index] = {
                                    ...newButtonList[index],
                                    value: event.target.value,
                                  }
                                  formik.setValues({
                                    ...formik.values,
                                    variable_list: newButtonList,
                                  })
                                }}
                              />
                            </span>
                          )
                        })}
                      </span>
                    </Collapse>
                  </>
                )}
                <p>Add text, button or list to your response</p>
                <FormControl>
                  <Select
                    size="small"
                    id="message_object_type"
                    name="message_object_type"
                    value={formik.values.message_object_type}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={"text"}>Text</MenuItem>
                    <MenuItem value={"button"}>Button</MenuItem>
                    <MenuItem value={"list"}>List</MenuItem>
                  </Select>
                </FormControl>
                {formik.values.message_object_type === "button" && (
                  <>
                    {formik.values.button_list?.map((button, index) => {
                      return (
                        <span className="flex flex-col gap-2">
                          <p>Button {index + 1} </p>
                          <div className="flex gap-2">
                            <CustomInput
                              id={`button_text${index + 1}`}
                              label="Text"
                              placeholder="Enter Button Text"
                              value={formik.values.button_list[index]?.button_text || ""}
                              onChange={(event) => {
                                const newButtonList = [...formik.values.button_list]
                                newButtonList[index] = {
                                  ...newButtonList[index],
                                  button_text: event.target.value,
                                }

                                formik.setValues({
                                  ...formik.values,
                                  button_list: newButtonList,
                                })
                              }}
                              className="!w-1/2"
                            />
                            <FormControl className="!w-1/2">
                              <Select
                                size="small"
                                id={`button_value${index + 1}`}
                                value={formik.values.button_list[index]?.button_value || ""}
                                onChange={(event) => {
                                  const newButtonList = [...formik.values.button_list]
                                  newButtonList[index] = {
                                    ...newButtonList[index],
                                    button_value: event.target.value,
                                  }
                                  formik.setValues({
                                    ...formik.values,
                                    button_list: newButtonList,
                                  })
                                }}
                              >
                                {buttonValues?.map((value) => {
                                  return <MenuItem value={value?.id}>{value?.name}</MenuItem>
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </span>
                      )
                    })}
                    <CustomButton
                      variant="text"
                      startIcon={<Add />}
                      onClick={() =>
                        formik.values.button_list?.[formik.values.button_list?.length - 1]?.button_text !== "" &&
                        formik.setValues({
                          ...formik.values,
                          button_list: [...formik.values.button_list, { button_text: "", button_value: "" }],
                        })
                      }
                    >
                      Add Button
                    </CustomButton>
                  </>
                )}
                {formik.values.message_object_type === "list" && (
                  <>
                    <span className="flex gap-1">
                      <CustomInput
                        placeholder="Action Button"
                        id="action_button_text"
                        className="!w-1/2"
                        formik={formik}
                      />
                      <Button
                        startIcon={<FormatListBulleted />}
                        onClick={() => setList(formik.values.section_list)}
                        className="!w-1/2 !capitalize"
                      >
                        {formik.values.action_button_text}
                      </Button>
                    </span>
                  </>
                )}

                <CustomButton type="submit">Save</CustomButton>
              </form>
              <Divider />
            </Collapse>
          </List>
        </span>
      </span>
      <Dialog
        open={list?.length === 0 ? false : true}
        onClose={() => setList([])}
        PaperProps={{
          className: "!flex min-w-[500px] !flex-col !justify-between",
        }}
      >
        <p className="py-2 font-semibold text-center">Section</p>
        <Divider />

        <List className="!p-0">
          {formik.values?.section_list?.map((section, sectionIndex) => (
            <ListItem className="!flex !items-start !flex-col !px-0 !w-full gap-1" key={sectionIndex}>
              <span className="flex flex-col p-3">
                <p>Section Title</p>
                <CustomInput
                  placeholder="Enter Section Text"
                  value={section.section_title || ""}
                  onChange={(event) => {
                    const newSectionList = [...formik.values.section_list]
                    newSectionList[sectionIndex] = {
                      ...newSectionList[sectionIndex],
                      section_title: event.target.value,
                    }
                    formik.setValues({
                      ...formik.values,
                      section_list: newSectionList,
                    })
                  }}
                />
              </span>

              {section.rows_list?.map((row, rowIndex) => (
                <span className="flex w-full gap-2 px-3 pb-1 border-b" key={rowIndex}>
                  <CustomInput
                    id={`section${sectionIndex + 1}`}
                    placeholder="Enter Section Text"
                    value={row.rows_title || ""}
                    className="!w-1/2"
                    onChange={(event) => {
                      const newSectionList = [...formik.values.section_list]
                      const newRowList = [...newSectionList[sectionIndex].rows_list]

                      newRowList[rowIndex] = {
                        ...newRowList[rowIndex],
                        rows_title: event.target.value,
                      }

                      newSectionList[sectionIndex] = {
                        ...newSectionList[sectionIndex],
                        rows_list: newRowList,
                      }

                      formik.setValues({
                        ...formik.values,
                        section_list: newSectionList,
                      })
                    }}
                  />

                  <FormControl className="!w-1/2">
                    <Select
                      size="small"
                      id={`button_value${sectionIndex + 1}`}
                      value={row.rows_id || ""}
                      onChange={(event) => {
                        const newSectionList = [...formik.values.section_list]
                        newSectionList[sectionIndex].rows_list[rowIndex] = {
                          ...newSectionList[sectionIndex].rows_list[rowIndex],
                          rows_id: event.target.value,
                        }
                        formik.setValues((prevValues) => ({
                          ...prevValues,
                          section_list: newSectionList,
                        }))
                      }}
                    >
                      {buttonValues?.map((value) => (
                        <MenuItem value={value?.id} key={value?.id}>
                          {value?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </span>
              ))}
              <ListItemButton
                className="!flex !gap-2 !text-secondary"
                onClick={() => {
                  const newSectionList = [...formik.values.section_list]
                  newSectionList[sectionIndex] = {
                    ...newSectionList[sectionIndex],
                    rows_list: [...section.rows_list, { rows_id: "", rows_title: "" }],
                  }
                  formik.setValues((prevValues) => ({
                    ...prevValues,
                    section_list: newSectionList,
                  }))
                }}
              >
                <AddCircle fontSize="small" />
                Add New Row
              </ListItemButton>
            </ListItem>
          ))}
          <Divider />
          <ListItemButton
            className="!flex !gap-2 !text-secondary"
            onClick={() =>
              formik.setFieldValue("section_list", [
                ...formik.values.section_list,
                {
                  section_title: "",
                  rows_list: [{ rows_id: "", rows_title: "" }],
                },
              ])
            }
          >
            <AddCircle fontSize="small" />
            Add New Section
          </ListItemButton>
        </List>
        <span className="flex justify-end gap-2 p-4">
          <CustomButton onClick={() => setList([])}>Save</CustomButton>
        </span>
      </Dialog>
    </span>
  )
}

export default CreateWorkflows
