import { Divider } from "@mui/material"
import { WebsiteSyncFn } from "Services/Sync/Websitesync"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import CustomDiv from "shared/CustomDiv"
import Loader from "shared/CustomLoader"
import NoDataFound from "shared/NoDataFound"
import AddSync from "./AddSync"

const OtherSync = () => {
  const { store_id } = useParams()
  const { data, isLoading } = useQuery(["OtherSync"], () => WebsiteSyncFn({ store_id }))
  const Websitesync = data?.data?.data

  return (
    <CustomDiv className="flex flex-col !p-0">
      <div className="flex justify-end p-2">
        <AddSync isUpdate={Websitesync?.length !== 0} />
      </div>
      <Divider />
      {isLoading && <Loader />}
      {Websitesync?.map((item) => {
        return (
          <div className="flex gap-5 p-10">
            <div>
              <p className="mt-5 font-bold"> Email</p>
              <p className="mb-5 text-gray-800"> {item.email}</p>
              <p className="mt-5 font-bold"> Token</p>
              <p className="mb-5 text-gray-800">{item?.token}</p>
              <p className="mt-5 font-bold"> Product Api Url</p>
              <p className="mb-5 text-gray-800 ">{item?.add_new_product_api_url}</p>
              <p className="mt-5 font-bold"> Quantity and Price Api Url </p>
              <p className="mb-5 text-gray-800">{item?.update_quantity_and_price_api_url}</p>
            </div>
            <div>
              <p className="mt-5 font-bold"> Sale Order of Product Api Url </p>
              <p className="mb-5 text-gray-800">{item?.sale_order_product_api_url}</p>
              <p className="mt-5 font-bold"> Sale Invoice Api Url </p>
              <p className="mb-5 text-gray-800">{item?.sale_invoice_api_url}</p>
              <p className="mt-5 font-bold"> Delete Manage Api Url </p>
              <p className="mb-5 text-gray-800 ">{item?.delete_api_url}</p>
              <p className="mt-5 font-bold">Offer Manage Api Url </p>
              <p className="mb-5 text-gray-800 ">{item?.offer_manage_api_url}</p>
            </div>
          </div>
        )
      })}
      <NoDataFound data={data} />
    </CustomDiv>
  )
}

export default OtherSync
