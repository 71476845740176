import { ArrowBack, ArrowForward } from "@mui/icons-material"
import { Button, IconButton, Paper } from "@mui/material"
import axios from "config/axios"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { CustomButton } from "../../../shared/CustomButton"
import CustomInput from "../../../shared/CustomInput"

const AddGroup = () => {
  const [permissionData, setPermissionData] = useState([])
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const PermissionList = () => {
    axios
      .get(`list-of-permission/`)
      .then((response) => {
        setPermissionData(response.data?.permissions)
      })
      .catch((error) => {
        console.log("", error.response)
        console.log(error)
        toast.error(error.response.data.message)
      })
  }

  useEffect(() => {
    PermissionList()
  }, [])

  const initialValues = {
    permissions: "",
    remove_permissions: "",
  }

  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: (values, action) => {
      const reqbody = {
        group: values.group_name,
        permission: selectedPermissions,
      }

      axios
        .post(`add-group-permission/`, reqbody)
        .then((response) => {
          console.log(response.data)
          toast.success(response.data.response)
          response.data.response === "Group is Successfully Created" ? navigate("/groups") : navigate("#")
        })
        .catch((error) => {
          console.log("", error.response)
          console.log(error)
          toast.error(error.message)
        })
      // action.resetForm();
    },
  })

  const handleNext = (event) => {
    if (!selectedPermissions.includes(formik.values.permissions)) {
      setSelectedPermissions((item) => selectedPermissions.concat(formik.values.permissions))
    } else {
      setSelectedPermissions(selectedPermissions.filter((item) => item !== formik.values.permissions))
    }
  }
  const handleBack = (event) => {
    setSelectedPermissions(selectedPermissions.filter((item) => !formik.values["remove_permissions"].includes(item)))
    // setSelectedPermissions(
    //   selectedPermissions.filter(
    //     (item) => item !== formik.values.remove_permissions
    //   )
    // );
  }

  // console.log(
  //   // permissionData,
  //   // selectedPermissions,
  //   "selected",
  //   // permissionData?.filter(
  //   //   (item) => !selectedPermissions.includes(item.id.toString()),
  //   // formik.values["remove_permissions"]
  //   permissionData?.filter((item) =>
  //     selectedPermissions.includes(item.id.toString())
  //   )
  // );
  const handleSelectAll = () => {
    setSelectedPermissions(permissionData.map((item) => item.id.toString()))
  }

  return (
    <>
      <div className="flex w-full p-4">
        <form onSubmit={formik.handleSubmit} className="flex w-full gap-3">
          <Paper className="flex flex-col p-5 !py-4 w-3/4">
            <div className="text-2xl text-center border border-gray-600">Add Group</div>
            <CustomInput
              type="text"
              name="group_name"
              id="group_name"
              value={formik.values["group_name"]}
              onChange={formik.handleChange}
              placeholder="Enter Group Name"
              label="Name* : "
              className2="flex whitespace-nowrap mt-2"
            />
            <p></p>
            <div className="flex items-center justify-center gap-5 mt-5">
              <div className="flex flex-col gap-3">
                <p className="p-2 font-semibold text-center bg-black rounded">Available Permission</p>
                <select
                  name="permissions"
                  id="permissions"
                  multiple
                  className="!text-black !outline-none h-96 w-96 p-1"
                  value={formik.values["permissions"]}
                  onChange={formik.handleChange}
                >
                  {selectedPermissions !== []
                    ? permissionData
                        ?.filter((item) => !selectedPermissions.includes(item.id.toString()))
                        .map((permissionData) => {
                          return (
                            <option key={permissionData.id} value={permissionData.id}>
                              {permissionData.permission_name}
                            </option>
                          )
                        })
                    : selectedPermissions.map((permissionData) => {
                        return (
                          <option key={permissionData.id} value={permissionData.id}>
                            {permissionData.permission_name}
                          </option>
                        )
                      })}
                </select>
                <Button
                  variant="contained"
                  onClick={handleSelectAll}
                  className="p-2 font-semibold text-center rounded bg-dark hover:bg-black"
                >
                  Select All
                </Button>
              </div>

              <div className="flex flex-col">
                <IconButton onClick={handleNext}>
                  <ArrowForward />
                </IconButton>
                <IconButton onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
              </div>
              <div className="flex flex-col gap-3">
                <p className="p-2 font-semibold text-center bg-black rounded">Selected Permission</p>
                <select
                  name="remove_permissions"
                  id="remove_permissions"
                  multiple
                  value={formik.values["remove_permissions"]}
                  onChange={formik.handleChange}
                  className="!text-black !outline-none h-96 w-96 p-1"
                >
                  {permissionData
                    ?.filter((item) => selectedPermissions.includes(item.id.toString()))
                    .map((permissionData, index) => {
                      return (
                        <option key={index} value={permissionData.id}>
                          {permissionData.permission_name}
                        </option>
                      )
                    })}
                </select>
                <Button
                  variant="contained"
                  onClick={() => setSelectedPermissions([])}
                  className="p-2 font-semibold text-center rounded bg-dark hover:bg-black"
                >
                  Remove All
                </Button>
              </div>
            </div>
          </Paper>
          <Paper className="flex items-center justify-center w-1/4">
            <CustomButton type="submit" className="!px-10">
              Create Group
            </CustomButton>
          </Paper>
        </form>
      </div>
    </>
  )
}

export default AddGroup
