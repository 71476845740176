import axiosInstance from "config/axios"

const fetchEmployeeListFn = async (store_id) => {
  const response = await axiosInstance.get(`employee-candidate-detail-permission-api-of-superadmin/`, {
    params: { store_id },
  })
  return response.data.data
}

const getPaySlipFieldFn = async ({ store_id }) => {
  const response = await axiosInstance.get(`store-pay-slip-field-api-of-superadmin/`, {
    params: { store_id },
  })
  return response.data.data
}

const addPaySlipFn = async (reqBody) => {
  const response = await axiosInstance.post(`store-pay-slip-field-api-of-superadmin/`, reqBody)
  return response
}
const updatePaySlipFn = async (reqBody) => {
  const response = await axiosInstance.patch(`store-pay-slip-field-api-of-superadmin/`, reqBody)
  return response
}

export { updatePaySlipFn, addPaySlipFn, getPaySlipFieldFn }
