import { Edit } from "@mui/icons-material"
import { Switch, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { smsFn } from "Services/Settings/SmsSetting/Smslist"
import axiosInstance from "config/axios"
import { useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { Active, Inactive } from "shared/ActivaInactive"
import { CustomIconButton } from "shared/CustomIconButton"
import CustomPagination from "shared/CustomPagination"
import CustomInput from "shared/MkxInput"
import MkxTableCell from "shared/MkxTable/MkxTableCell"
import { MkxTableLoader } from "shared/MkxTable/MkxTableLoader"
import NoDataFound from "shared/NoDataFound"
import AddSetting from "./AddSetting"

const SmsSettings = () => {
  const { store_id } = useParams()
  const [selected, setSelected] = useState(null)
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const client = useQueryClient()

  const { data: sms, isLoading } = useQuery(["sms", page, search], () => smsFn({ store_id, page, search }))
  const handleChange1 = (event, id) => {
    axiosInstance
      .patch(`sms-configuration-api-of-superadmin/`, {
        store_id,
        sms_service_id: id,
        active_status: event.target.checked,
      })
      .then((response) => {
        toast.success(response.data.message)
        client.refetchQueries("sms")
      })
      .catch((error) => {
        console.log(error.response)
      })
  }
  return (
    <div className="">
      <div className="flex items-center justify-between py-2">
        <CustomInput value={search} onChange={(event) => setSearch(event.target.value)} placeholder="Search..." />
        <AddSetting selected={selected} setSelected={setSelected} />
      </div>

      <TableContainer>
        <Table className="border">
          <TableHead>
            <TableRow>
              <MkxTableCell isHead>Sender</MkxTableCell>
              <MkxTableCell isHead>Username</MkxTableCell>
              <MkxTableCell isHead>Password</MkxTableCell>
              <MkxTableCell isHead>Status</MkxTableCell>
              <MkxTableCell isHead>Enabled/Disabled</MkxTableCell>
              <MkxTableCell isHead>Action</MkxTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <MkxTableLoader loading={isLoading} row={5} />
            {sms?.data?.data?.map((item) => (
              <TableRow>
                <MkxTableCell>{item.sender}</MkxTableCell>
                <MkxTableCell>{item.uname}</MkxTableCell>
                <MkxTableCell>{item.password}</MkxTableCell>
                <MkxTableCell>
                  <span className="!flex !justify-center">
                    {item?.active_status === true ? <Active /> : <Inactive />}
                  </span>
                </MkxTableCell>
                <MkxTableCell>
                  <Switch checked={item.active_status} onChange={(event) => handleChange1(event, item?.id)} />
                </MkxTableCell>
                <MkxTableCell>
                  <CustomIconButton color="success" onClick={() => setSelected(item)}>
                    <Edit />
                  </CustomIconButton>
                </MkxTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <CustomPagination data={sms} setPage={setPage} />
        <NoDataFound data={sms} />
      </TableContainer>
    </div>
  )
}

export default SmsSettings
