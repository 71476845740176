import { FilterAlt } from "@mui/icons-material"
import BorderColorIcon from "@mui/icons-material/BorderColor"
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone"
import {
  Button,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import axios from "config/axios"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { CustomIconButton } from "../../shared/CustomIconButton"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

const ShipTocket = () => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(6)
  const [data, setData] = useState({})
  const [email, setEmail] = useState("")
  const [pass, setPass] = useState("")

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const ResellersListData = () => {
    axios
      .get(`delivery_manager/shiprocket/`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setData(response?.data)
        console.log("delivery data====>", response?.data)
      })
      .catch((error) => {
        console.log("", error)
      })
  }
  useEffect(() => {
    ResellersListData()
  }, [])

  const editData = (event, email, pass) => {
    event.preventDefault()
    handleOpen()
    setEmail(email)
    setPass(pass)
  }

  const renameData = () => {
    const fd = new FormData()
    fd.append("email", email)
    fd.append("password", pass)

    axios
      .put(`delivery_manager/shiprocket/`, fd, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        console.log(response)
        // toast.success(response);
        ResellersListData()
        handleClose()
      })
      .catch((error) => {
        console.log("", error)
      })
  }

  const tokenGenerate = (event) => {
    event.preventDefault()
    axios
      .get(`delivery_manager/token-generate/`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        console.log(response?.data?.message)
        toast.success(response?.data?.message)
        // ResellersListData()
        // handleClose()
      })
      .catch((error) => {
        console.log("", error)
        toast.success(error?.data?.token)
      })
  }

  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font-bold text-black">Ship Rocket</p>
            {/* <p className="text-black">View/Search Delivery Management</p> */}
          </div>
          <div className="p-2 pt-3">
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="!border-0 !rounded-xl">
                <Typography id="modal-modal-title" variant="h6" component="h6">
                  <div
                    className="!text-center underline underline-offset-4"
                    style={{ fontFamily: "'Poppins', sans-serif" }}
                  >
                    Add Type Three Value
                  </div>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div className="gap-y-3"></div>
                  <div className="gap-2">
                    <InputLabel className="!mt-1 !text-gray-700" style={{ fontFamily: "'Poppins', sans-serif" }}>
                      <div className="my-1 text-sm">Email :</div>
                    </InputLabel>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      className="!w-[100%] !placeholder:text-sm"
                      size="small"
                      value={email || ""}
                      onChange={(event) => setEmail(event.target.value)}
                    />

                    <InputLabel className=" !text-gray-700" style={{ fontFamily: "'Poppins', sans-serif" }}>
                      <div className="my-1 text-sm">Password :</div>
                    </InputLabel>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      className="!w-[100%] !mt-1 !placeholder:text-sm"
                      size="small"
                      value={pass || ""}
                      onChange={(event) => setPass(event.target.value)}
                    />
                  </div>
                  <div className="items-center justify-between ">
                    <Button className="float-left top-3" variant="contained" color="warning" onClick={handleClose}>
                      CANCEL
                    </Button>

                    <Button variant="contained" color="success" className="float-right mr-3 top-3" onClick={renameData}>
                      CREATE
                    </Button>
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
        <div className="flex flex-col !rounded bg-secondary border-secondary shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center py-2 bg-gray-100 rounded">
            <CustomIconButton>
              <FilterAlt className="text-black" />
            </CustomIconButton>
            <div>
              <input type="text" className="border border-black" />
            </div>
          </div>
          <TableContainer
            component={Paper}
            sx={{ width: "100%", maxHeight: 440 }}
            className="!text-black !mx-auto !bg-white overflow-auto px-2"
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    EMAIL
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    PASSWORD
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    CREATE DATE
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    UPDATE DATE
                  </TableCell>

                  <TableCell
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    ACTION
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className="!cursor-pointer">
                  <TableCell
                    className="!text-center !text-[12px]"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    {data?.email ? data?.email : "--"}
                  </TableCell>
                  <TableCell
                    className="!text-center !text-[12px]"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    {data?.password ? data?.password : "--"}
                  </TableCell>
                  <TableCell
                    className="!text-center !text-[12px]"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    {data?.created_at ? data?.created_at.slice(0, 10) : "--"}
                  </TableCell>
                  <TableCell
                    className="!text-center !text-[12px]"
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    {data?.updated_at ? data?.updated_at.slice(0, 10) : "--"}
                  </TableCell>
                  <TableCell
                    className="!text-center !text-[12px]"
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    <Tooltip title="Generate Token" arrow>
                      {/* <Button>Arrow</Button> */}
                      <FileDownloadDoneIcon className="mr-2" onClick={(event) => tokenGenerate(event)} />
                    </Tooltip>
                    <BorderColorIcon
                      className="!text-green-400"
                      onClick={(event) => editData(event, data?.email, data?.password)}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {data?.length !== 0 ? (
              <TablePagination
                rowsPerPageOptions={[6]}
                count={data?.length}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default ShipTocket
