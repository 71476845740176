import { Payment, Settings } from "@mui/icons-material"
import { useNavigate, useParams } from "react-router-dom"
import CustomButton from "shared/MkxButton"

const PaymentGateway = () => {
  const { store_id } = useParams()
  const navigate = useNavigate()
  return (
    <div className="flex items-center gap-5 flex-wrap !w-full">
      <CustomButton
        startIcon={<Payment className="!h-10 !w-10" />}
        onClick={() => navigate(`/business-store/${store_id}/payment-gateway`)}
        className="!text-2xl !w-80 !h-32"
      >
        Available Payment
      </CustomButton>
      <CustomButton
        startIcon={<Settings className="!h-10 !w-10" />}
        onClick={() => navigate(`/business-store/${store_id}/payment-gateway-setup`)}
        className="!text-2xl !w-80 !h-32"
      >
        Setup Credentials
      </CustomButton>
    </div>
  )
}

export default PaymentGateway
