import {
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import axios from "config/axios"
import { useEffect, useState } from "react"
const Contact = () => {
  const [storeData, setStoreData] = useState([])
  const [storeId, setStoreId] = useState("")
  const [contactData, setContactData] = useState({})
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const handleChange = (event, value) => {
    setPage(value)
  }

  const storeList = () => {
    axios
      .get(`store-list-api-for-superadmin/`)
      .then((response) => {
        setStoreData(response?.data?.data)
        console.log("contactllllll ", response.data?.data)
      })
      .catch((error) => {
        console.log("", error.response)
        console.log(error)
      })
  }

  useEffect(() => {
    storeList()
  }, [])

  const contactListSuper = () => {
    axios
      .get(`app-contact-no-data-api-for-superadmin/?page=${page}&search=${search}&store_id=${storeId}`)
      .then((response) => {
        setContactData(response?.data)
      })
      .catch((error) => {
        console.log("", error.response)
        console.log(error)
      })
  }

  useEffect(() => {
    contactListSuper()
  }, [page, search, storeId])

  return (
    <>
      <div className="py-2">
        <div className="flex justify-between p-2">
          <p className="text-xl font-bold text-gray-800">Contact</p>
        </div>
        <div className="flex flex-col !my-3 !rounded bg-dark border-secondary shadow-card shadow-zinc-600 m-2">
          <div className="flex justify-between p-2 !px-3">
            <div className="flex text-xl">
              <input
                class="placeholder:italic placeholder:text-slate-400 block bg-white w-80 border border-slate-300 rounded-md py-2 px-2 pr-3 shadow-sm focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
                placeholder="Search for anything..."
                type="text"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </div>
            <span className="flex items-center gap-3">
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  Select Store
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Store"
                  size="small"
                  className="w-72"
                  onChange={(event) => setStoreId(event.target.value)}
                >
                  <MenuItem value={" "}>--select Store--</MenuItem>
                  {storeData?.map((item) => (
                    <MenuItem value={item?.id}>{item?.store_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </span>
          </div>
          <TableContainer
            component={Paper}
            // sx={{  }}
            sx={{ width: "100%" }}
            className="!text-black !mx-auto !bg-white overflow-auto px-2"
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead className="!w-full">
                <TableRow className="bg-white bg-opacity-20">
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    Store Name
                  </TableCell>

                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    User
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    App Name
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    Contact Name
                  </TableCell>

                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center !text-gray-800 !font-bold"
                  >
                    Mobile
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody className="overflow-x-scroll">
                {contactData
                  ? contactData?.data?.map((contact, index) => (
                      <TableRow key={contact?.id || index} className="hover:bg-white hover:bg-opacity-20">
                        <TableCell
                          className="!text-center !text-[12px]"
                          style={{
                            borderRight: "1px solid #D0D0D0",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {contact?.store ? contact?.store : "--"}
                        </TableCell>

                        <TableCell
                          className="!text-center !text-[12px]"
                          style={{
                            borderRight: "1px solid #D0D0D0",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {contact?.user ? contact?.user : "--"}
                        </TableCell>
                        <TableCell
                          className="!text-center !text-[12px]"
                          style={{
                            borderRight: "1px solid #D0D0D0",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {contact?.app_name ? contact?.app_name : "--"}
                        </TableCell>

                        <TableCell
                          className="!text-center !text-[12px]"
                          style={{
                            borderRight: "1px solid #D0D0D0",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {contact?.name ? contact?.name : "--"}
                        </TableCell>

                        <TableCell
                          className="!text-center !text-[12px]"
                          style={{
                            borderRight: "1px solid #D0D0D0",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {contact?.mobile ? contact?.mobile : "---"}
                        </TableCell>
                      </TableRow>
                    ))
                  : "No data found"}
              </TableBody>
            </Table>

            {contactData?.data?.length === 0 && (
              <div className="flex flex-col items-center justify-center h-full gap-3 py-16">
                <img src="https://pub-16f3de4d2c4841169d66d16992f9f0d3.r2.dev/assets/Sales/pana.svg" alt="" />
                <p className="text-3xl font-semibold">No Data Found</p>
              </div>
            )}
          </TableContainer>
          {contactData?.total_pages && contactData?.total_pages !== 1 && (
            <span className="flex justify-end p-2">
              <Pagination
                color="primary"
                variant="outlined"
                page={contactData?.current_page || 0}
                count={contactData?.total_pages || 0}
                onChange={handleChange}
              />
            </span>
          )}
        </div>
      </div>
    </>
  )
}

export default Contact
