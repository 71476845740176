import { Checkbox, Divider, MenuItem } from "@mui/material"
import { changePermisionsFn, fetchEmployeeListFn, fetchPermisionsFn } from "Services/Settings/HiringSettings"
import { useFormik } from "formik"
import { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import MkxSelect from "shared/MkxSelect"

const HiringSettings = () => {
  const { store_id } = useParams()
  const [employeeId, setEmployeeId] = useState("")

  const { data: employees } = useQuery(["employeeList", store_id], () => fetchEmployeeListFn(store_id))
  const { data: fetchPermisions, refetch } = useQuery(
    ["fetchPermisions", store_id, employeeId],
    () => fetchPermisionsFn({ store_id, employee_id: employeeId }),
    { enabled: !!employeeId }
  )

  const { mutate: changePermisions, isLoading } = useMutation(changePermisionsFn, {
    onSuccess: ({ data }) => {
      if (data.response_code === 200) {
        toast.success(data.message)
        refetch()
      } else {
        toast.error(data.message)
      }
    },
  })

  const initialValue = {
    candidate_skills: fetchPermisions?.candidate_skills || false,
    interview: fetchPermisions?.interview || false,
    hired_candidate: fetchPermisions?.hired_candidate || false,
    candiate_enquery: fetchPermisions?.candiate_enquery || false,
    id: fetchPermisions?.id || false,
    store: fetchPermisions?.store || false,
    employee: fetchPermisions?.employee || false,
    first_name: fetchPermisions?.first_name || false,
    last_name: fetchPermisions?.last_name || false,
    created_at: fetchPermisions?.created_at || false,
    email: fetchPermisions?.email || false,
    mobile_no: fetchPermisions?.mobile_no || false,
    gender: fetchPermisions?.gender || false,
    dob: fetchPermisions?.dob || false,
    area: fetchPermisions?.area || false,
    address: fetchPermisions?.address || false,
    source: fetchPermisions?.source || false,
    city: fetchPermisions?.city || false,
    state: fetchPermisions?.state || false,
    country: fetchPermisions?.country || false,
    pin_code: fetchPermisions?.pin_code || false,
    image: fetchPermisions?.image || false,
    highest_qualification: fetchPermisions?.highest_qualification || false,
    hiring_profile: fetchPermisions?.hiring_profile || false,
    experience: fetchPermisions?.experience || false,
    cv: fetchPermisions?.cv || false,
    profile_link: fetchPermisions?.profile_link || false,
    interview_schedule: fetchPermisions?.interview_schedule || false,
    candidate_type: fetchPermisions?.candidate_type || false,
    final_round: fetchPermisions?.final_round || false,
    stage_of_candidate: fetchPermisions?.stage_of_candidate || false,
    remarks: fetchPermisions?.remarks || false,
    round_status: fetchPermisions?.round_status || false,
    active_status: fetchPermisions?.active_status || false,
    employee_id: employeeId || false,
  }

  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: (values) => {
      const reqBody = new FormData()
      reqBody.append("candidate_skills", values.candidate_skills)
      reqBody.append("interview", values.interview)
      reqBody.append("hired_candidate", values.hired_candidate)
      reqBody.append("candiate_enquery", values.candiate_enquery)
      reqBody.append("store_id", store_id)
      reqBody.append("id", values.id)
      reqBody.append("store", values.store)
      reqBody.append("employee", values.employee)
      reqBody.append("first_name", values.first_name)
      reqBody.append("last_name", values.last_name)
      reqBody.append("created_at", values.created_at)
      reqBody.append("email", values.email)
      reqBody.append("mobile_no", values.mobile_no)
      reqBody.append("gender", values.gender)
      reqBody.append("dob", values.dob)
      reqBody.append("area", values.area)
      reqBody.append("address", values.address)
      reqBody.append("source", values.source)
      reqBody.append("city", values.city)
      reqBody.append("state", values.state)
      reqBody.append("country", values.country)
      reqBody.append("pin_code", values.pin_code)
      reqBody.append("image", values.image)
      reqBody.append("highest_qualification", values.highest_qualification)
      reqBody.append("hiring_profile", values.hiring_profile)
      reqBody.append("experience", values.experience)
      reqBody.append("cv", values.cv)
      reqBody.append("profile_link", values.profile_link)
      reqBody.append("interview_schedule", values.interview_schedule)
      reqBody.append("candidate_type", values.candidate_type)
      reqBody.append("final_round", values.final_round)
      reqBody.append("stage_of_candidate", values.stage_of_candidate)
      reqBody.append("remarks", values.remarks)
      reqBody.append("round_status", values.round_status)
      reqBody.append("active_status", values.active_status)
      reqBody.append("employee_id", values.employee_id)
      changePermisions(reqBody)
    },
  })

  return (
    <>
      <p className="py-2 text-xl font-semibold">Hiring Configurations</p>
      <form onSubmit={formik.handleSubmit}>
        <MkxSelect
          className="w-52"
          value={employeeId}
          onChange={(event) => setEmployeeId(event.target.value)}
          placeholder="Select Employee"
        >
          {employees?.map((item) => {
            return <MenuItem value={item?.emp_id}>{item?.user}</MenuItem>
          })}
        </MkxSelect>

        <div className="grid grid-cols-3">
          <span className="flex items-center">
            <Checkbox
              id="candidate_skills"
              name="candidate_skills"
              className="!p-1 !pl-0"
              defaultChecked={fetchPermisions?.candidate_skills}
              checked={formik.values.candidate_skills}
              onChange={(e) => formik.setFieldValue("candidate_skills", e.target.checked)}
            />
            <p className="my-2">Candidate Skills</p>
          </span>

          <span className="flex items-center">
            <Checkbox
              id="interview"
              name="interview"
              className="!p-1 !pl-0"
              checked={formik.values.interview}
              onChange={(e) => formik.setFieldValue("interview", e.target.checked)}
            />{" "}
            <p className="my-2">interview</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="hired_candidate"
              name="hired_candidate"
              className="!p-1 !pl-0"
              checked={formik.values.hired_candidate}
              onChange={(e) => formik.setFieldValue("hired_candidate", e.target.checked)}
            />
            <p className="my-2">Hired Candidate</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="candiate_enquery"
              name="candiate_enquery"
              className="!p-1 !pl-0"
              checked={formik.values.candiate_enquery}
              onChange={(e) => formik.setFieldValue("candiate_enquery", e.target.checked)}
            />

            <p className="my-2">Candidate Enquiry</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="id"
              name="id"
              className="!p-1 !pl-0"
              checked={formik.values.id}
              onChange={(e) => formik.setFieldValue("id", e.target.checked)}
            />
            <p className="my-2">Candidate ID</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="store"
              name="store"
              className="!p-1 !pl-0"
              checked={formik.values.store}
              onChange={(e) => formik.setFieldValue("store", e.target.checked)}
            />
            <p className="my-2">Store</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="employee"
              name="employee"
              className="!p-1 !pl-0"
              checked={formik.values.employee}
              onChange={(e) => formik.setFieldValue("employee", e.target.checked)}
            />
            <p className="my-2">Employee</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="first_name"
              name="first_name"
              className="!p-1 !pl-0"
              checked={formik.values.first_name}
              onChange={(e) => formik.setFieldValue("first_name", e.target.checked)}
            />
            <p>First Name</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="last_name"
              name="last_name"
              className="!p-1 !pl-0"
              checked={formik.values.last_name}
              onChange={(e) => formik.setFieldValue("last_name", e.target.checked)}
            />
            <p className="my-2">Last Name</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="created_at"
              name="created_at"
              className="!p-1 !pl-0"
              checked={formik.values.created_at}
              onChange={(e) => formik.setFieldValue("created_at", e.target.checked)}
            />
            <p className="my-2">Created At</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="email"
              name="email"
              className="!p-1 !pl-0"
              defaultChecked={fetchPermisions?.email}
              checked={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.checked)}
            />
            <p className="my-2">Email</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="mobile_no"
              name="mobile_no"
              className="!p-1 !pl-0"
              checked={formik.values.mobile_no}
              onChange={(e) => formik.setFieldValue("mobile_no", e.target.checked)}
            />
            <p>Mobile No</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="gender"
              name="gender"
              className="!p-1 !pl-0"
              checked={formik.values.gender}
              onChange={(e) => formik.setFieldValue("gender", e.target.checked)}
            />
            <p>Gender</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="dob"
              name="dob"
              className="!p-1 !pl-0"
              checked={formik.values.dob}
              onChange={(e) => formik.setFieldValue("dob", e.target.checked)}
            />
            <p>DOB</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="area"
              name="area"
              className="!p-1 !pl-0"
              checked={formik.values.area}
              onChange={(e) => formik.setFieldValue("area", e.target.checked)}
            />
            <p>Area</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="address"
              name="address"
              className="!p-1 !pl-0"
              checked={formik.values.address}
              onChange={(e) => formik.setFieldValue("address", e.target.checked)}
            />
            <p>Address</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="source"
              name="source"
              className="!p-1 !pl-0"
              checked={formik.values.source}
              onChange={(e) => formik.setFieldValue("source", e.target.checked)}
            />
            <p>Source</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="city"
              name="city"
              className="!p-1 !pl-0"
              checked={formik.values.city}
              onChange={(e) => formik.setFieldValue("city", e.target.checked)}
            />
            <p>City</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="state"
              name="state"
              className="!p-1 !pl-0"
              checked={formik.values.state}
              onChange={(e) => formik.setFieldValue("state", e.target.checked)}
            />
            <p>State</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="country"
              name="country"
              className="!p-1 !pl-0"
              checked={formik.values.country}
              onChange={(e) => formik.setFieldValue("country", e.target.checked)}
            />
            <p>Country</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="pin_code"
              name="pin_code"
              className="!p-1 !pl-0"
              checked={formik.values.pin_code}
              onChange={(e) => formik.setFieldValue("pin_code", e.target.checked)}
            />
            <p>PinCode</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="image"
              name="image"
              className="!p-1 !pl-0"
              checked={formik.values.image}
              onChange={(e) => formik.setFieldValue("image", e.target.checked)}
            />
            <p>Image</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="highest_qualification"
              name="highest_qualification"
              className="!p-1 !pl-0"
              checked={formik.values.highest_qualification}
              onChange={(e) => formik.setFieldValue("highest_qualification", e.target.checked)}
            />
            <p>Highest Qualification</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="hiring_profile"
              name="hiring_profile"
              className="!p-1 !pl-0"
              checked={formik.values.hiring_profile}
              onChange={(e) => formik.setFieldValue("hiring_profile", e.target.checked)}
            />
            <p>Hiring Profile</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="experience"
              name="experience"
              className="!p-1 !pl-0"
              checked={formik.values.experience}
              onChange={(e) => formik.setFieldValue("experience", e.target.checked)}
            />
            <p>Experince</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="cv"
              name="cv"
              className="!p-1 !pl-0"
              checked={formik.values.cv}
              onChange={(e) => formik.setFieldValue("cv", e.target.checked)}
            />
            <p>CV</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="profile_link"
              name="profile_link"
              className="!p-1 !pl-0"
              checked={formik.values.profile_link}
              onChange={(e) => formik.setFieldValue("profile_link", e.target.checked)}
            />
            <p>Profile Link</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="interview_schedule"
              name="interview_schedule"
              className="!p-1 !pl-0"
              checked={formik.values.interview_schedule}
              onChange={(e) => formik.setFieldValue("interview_schedule", e.target.checked)}
            />
            <p>Interview Schedule</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="candidate_type"
              name="candidate_type"
              className="!p-1 !pl-0"
              checked={formik.values.candidate_type}
              onChange={(e) => formik.setFieldValue("candidate_type", e.target.checked)}
            />
            <p>Candidate Type</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="final_round"
              name="final_round"
              className="!p-1 !pl-0"
              checked={formik.values.final_round}
              onChange={(e) => formik.setFieldValue("final_round", e.target.checked)}
            />
            <p>Final Round</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="stage_of_candidate"
              name="stage_of_candidate"
              className="!p-1 !pl-0"
              checked={formik.values.stage_of_candidate}
              onChange={(e) => formik.setFieldValue("stage_of_candidate", e.target.checked)}
            />
            <p>Stage Of Candidate</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="remarks"
              name="remarks"
              className="!p-1 !pl-0"
              checked={formik.values.remarks}
              onChange={(e) => formik.setFieldValue("remarks", e.target.checked)}
            />
            <p>Remark</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="round_status"
              name="round_status"
              className="!p-1 !pl-0"
              checked={formik.values.round_status}
              onChange={(e) => formik.setFieldValue("round_status", e.target.checked)}
            />
            <p>Round Status</p>
          </span>
          <span className="flex items-center">
            <Checkbox
              id="active_status"
              name="active_status"
              className="!p-1 !pl-0"
              checked={formik.values.active_status}
              onChange={(e) => formik.setFieldValue("active_status", e.target.checked)}
            />
            <p>Active Status</p>
          </span>
        </div>
        <Divider className="!my-2" />
        <CustomButton
          className="float-right px-5 py-2 font-bold bg-blue-500 rounded-md"
          type={"submit"}
          isLoading={isLoading}
        >
          Save
        </CustomButton>
      </form>
    </>
  )
}
export default HiringSettings
