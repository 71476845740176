import { Settings } from "@mui/icons-material"
import { AddRealSyncFn, RealtimeSyncFn, updateRealFn } from "Services/Sync/Realtimesync"
import { useFormik } from "formik"
import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import MkxModal from "shared/MkxModal"

const AddRealSync = ({ isUpdate }) => {
  const navigate = useNavigate()
  const { store_id } = useParams()
  const [open, setOpen] = useState(false)
  const { state } = useLocation()
  const { data } = useQuery(["realtimeforaupdate"], () => RealtimeSyncFn({ store_id }), {
    enabled: state ? true : false,
  })

  const Realsync = data?.data?.data?.[0]
  const client = useQueryClient()

  const { mutate: addreal } = useMutation(AddRealSyncFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("realtime")
        setOpen(false)
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const { mutate: updatereal } = useMutation(updateRealFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("realtime")
        setOpen(false)
      } else {
        toast.error(response.data.message)
      }
    },
  })

  const initialValues = {
    real_time_sync_id: Realsync?.id || "",
    apiKey: Realsync?.apiKey || "",
    authDomain: Realsync?.authDomain || "",
    databaseURL: Realsync?.databaseURL || "",
    projectId: Realsync?.projectId || "",
    storageBucket: Realsync?.storageBucket || "",
    messagingSenderId: Realsync?.messagingSenderId || "",
    appId: Realsync?.appId || "",
    measurementId: Realsync?.measurementId || "",
    store_id,
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      if (state?.id) {
        updatereal(formik.values)
      } else {
        addreal(formik.values)
      }
    },
  })

  return (
    <>
      <CustomButton startIcon={<Settings />} onClick={() => setOpen(true)}>
        Token and Url
      </CustomButton>
      <MkxModal title="Manage Realtime Sync Config" open={open} onClose={() => setOpen(false)}>
        <form onSubmit={formik.handleSubmit} className="p-4">
          <div className="grid grid-cols-2 gap-5">
            <CustomInput id="apiKey" formik={formik} label="API Key" isRequired />
            <CustomInput id="authDomain" formik={formik} label="AuthDomain" isRequired />
            <CustomInput id="databaseURL" formik={formik} label="DataBase URL" isRequired />
            <CustomInput id="projectId" formik={formik} label="Project Id" isRequired />
            <CustomInput id="storageBucket" formik={formik} label="Storage Bucket" isRequired />
            <CustomInput id="messagingSenderId" formik={formik} label="Messaging Sender Id" isRequired />
            <CustomInput id="appId" formik={formik} label="AppId" isRequired />
            <CustomInput id="measurementId" formik={formik} label="Measurment Id" isRequired />
          </div>

          <div className="flex items-center justify-end gap-5 py-4">
            <CustomButton className="text-sm" type="submit">
              {isUpdate ? "Update" : "Add"}
            </CustomButton>

            <CustomButton className="ml-2 text-sm" onClick={() => navigate("/settings/sync/realtime")}>
              Cancel
            </CustomButton>
          </div>
        </form>
      </MkxModal>
    </>
  )
}

export default AddRealSync
