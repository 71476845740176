import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import classNames from "classnames";
import React from "react";

const Placeholder = ({ children }) => {
  return <div className="text-gray-700">{children}</div>;
};

const MkxSelect = ({
  value,
  id = "",
  onChange,
  children,
  className = "",
  formik,
  isLoading = false,
  disabled,
  placeholder = "Select",
  multiple = false,
  options,
  label = "",
  fullWidth = false,
  size = "small",
  color = "primary",
  isRequired = false,
  ...rest
}) => {
  const selectedValue = formik?.values[id] || value || "";
  const handleChange = formik?.handleChange || onChange;

  return (
    <FormControl size={size} fullWidth={fullWidth}>
      {label && (
        <p className="m-1 font-semibold whitespace-nowrap">
          {label}
          {isRequired && <span className="text-red-800">*</span>}
        </p>
      )}
      <Select
        displayEmpty
        color={color}
        value={selectedValue}
        onChange={handleChange}
        id={id}
        disabled={disabled ? disabled : false}
        multiple={multiple}
        size={size}
        error={formik?.errors[id] && formik?.touched[id]}
        onBlur={formik?.handleBlur}
        name={id}
        renderValue={
          selectedValue?.length !== 0
            ? undefined
            : () => <Placeholder>{placeholder}</Placeholder>
        }
        className={classNames(
          "!outline-none placeholder:!text-gray-100 !capitalize !bg-white !bg-opacity-20",
          className
        )}
        {...rest}
      >
        {isLoading
          ? Array.from({ length: 5 }).map((_, index) => (
              <MenuItem key={index} disabled>
                <Skeleton style={{ width: "100%" }} />
              </MenuItem>
            ))
          : options
          ? options?.map((select) => (
              <MenuItem key={select?.value} value={select?.value}>
                {select?.label}
              </MenuItem>
            ))
          : children}
      </Select>
      {formik?.errors && (
        <FormHelperText sx={{ color: "red" }}>
          {formik?.touched[id] && formik?.errors[id]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default MkxSelect;
