import { Checkbox, Divider } from "@mui/material"
import { settings as generalSettings } from "Mock"
import { storeSettingsFn } from "Services/Business/Store"
import { changeSettingsFn } from "Services/Settings/GeneralSettings"
import React from "react"
import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

const GeneralSetting = () => {
  const { store_id } = useParams()
  const { data: storeSettings, refetch } = useQuery(["storeSettings", store_id], () => storeSettingsFn({ store_id }))
  const settings = storeSettings?.data?.data
  const { mutate: changeSettings } = useMutation(changeSettingsFn, {
    onSuccess: ({ data }) => {
      if (data.response_code === 200) {
        refetch()
      } else {
        toast.error(data.message)
      }
    },
  })

  const handleChangeSetting = (event) => {
    const { name, checked } = event.target
    changeSettings({ store_id, [name]: checked })
  }

  return (
    <div className="flex flex-col">
      {generalSettings.map((category) => (
        <React.Fragment key={category.category}>
          <p className="py-1 text-lg font-semibold">{category.category}</p>
          <div className="grid grid-cols-2 gap-2">
            {category.settings.map((setting) => (
              <div key={setting.name} className="flex items-center">
                <Checkbox
                  onChange={handleChangeSetting}
                  name={setting.name}
                  size="small"
                  disabled={setting.disabled || false}
                  checked={settings?.[setting.name] || false}
                />
                <p>{setting.label}</p>
              </div>
            ))}
          </div>
          <Divider className="!my-2" />
        </React.Fragment>
      ))}
    </div>
  )
}

export default GeneralSetting
