import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Divider, Tab } from "@mui/material"
import { useState } from "react"
import Floors from "./Floors"
import RestaurantTables from "./Tables"

const Restaurant = () => {
  const [value, setValue] = useState("Floors")

  const options = [{ name: "Floors" }, { name: "Tables" }]
  const handleChange = (_, value) => setValue(value)

  return (
    <TabContext value={value}>
      <Box className="bg-white bg-opacity-30">
        <TabList onChange={handleChange}>
          {options.map((button) => {
            return <Tab className="!capitalize !font-semibold" label={button.name} value={button.name} />
          })}
        </TabList>
      </Box>
      <Divider />
      <TabPanel className="!p-0" value="Floors">
        <Floors />
      </TabPanel>
      <TabPanel className="!p-0" value="Tables">
        <RestaurantTables />
      </TabPanel>
    </TabContext>
  )
}

export default Restaurant
