import axiosInstance from "config/axios"

export const departmentFn = (redBody) => {
  try {
    const response = axiosInstance.get(`department-settings-api-of-superadmin/`, {
      params: redBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
