import axiosInstance from "config/axios"

export const addPaymentFn = (reqBody) => {
  try {
    const response = axiosInstance.post(`payment-mode-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
