import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"

export const storeSettingsFn = async () => {
  try {
    const response = await axiosInstance.get(API_URLS.storeSettings)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const changeSettingsFn = (redBody) => {
  try {
    const response = axiosInstance.patch(API_URLS.storeSettings, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
