import { API_URLS } from "config/apiUrls"
import axios from "config/axios"

export const googleMapConfigsFn = (redBody) => {
  try {
    const response = axios.get(API_URLS.googleMapConfigs, {
      params: redBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const addMapConfigsFn = (redBody) => {
  try {
    const response = axios.post(API_URLS.googleMapConfigs, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const updateMapConfigsFn = (redBody) => {
  try {
    const response = axios.put(API_URLS.googleMapConfigs, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const changeMapConfigsFn = (redBody) => {
  try {
    const response = axios.post(API_URLS.changeConfigStatus, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
