import { Add } from "@mui/icons-material"
import { MenuItem } from "@mui/material"
import { addTablesFn, restaurantFloorsFn, updateTablesFn } from "Services/Settings/Restaurant"
import { useFormik } from "formik"
import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import CustomModal from "shared/MkxModal"
import CustomSelect from "shared/MkxSelect"

const AddTables = ({ selected, setSelected }) => {
  const { store_id } = useParams()

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setSelected(null)
  }
  const { data: floors, isLoading } = useQuery(["floorsWithOutPagination"], () => restaurantFloorsFn({ store_id }))
  const isUpdate = Boolean(selected)
  const client = useQueryClient()
  const { mutate: addTables, isLoading: isAdding } = useMutation(addTablesFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("tables")
        handleClose()
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const { mutate: updateTables, isLoading: isUpdating } = useMutation(updateTablesFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("tables")
        handleClose()
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const initialValues = { store_id, floor_id: selected?.floor ?? "", table_no: selected?.table_no ?? "" }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      isUpdate ? updateTables({ table_id: selected?.id, ...values }) : addTables(values)
    },
  })

  return (
    <>
      <CustomButton startIcon={<Add />} onClick={handleOpen}>
        Table
      </CustomButton>
      <CustomModal
        className="!w-[500px]"
        title={isUpdate ? "Update Table" : "Add Table"}
        open={open || isUpdate}
        onClose={handleClose}
        padding={3}
      >
        <form onSubmit={formik.handleSubmit} className="flex flex-col w-full p-5">
          <CustomSelect
            id="floor_id"
            isRequired
            label="Floor"
            formik={formik}
            isLoading={isLoading}
            placeholder="Select Floor"
          >
            {floors?.data?.data?.map((i) => {
              return <MenuItem value={i.id}>{i.floor}</MenuItem>
            })}
          </CustomSelect>
          <CustomInput
            id="table_no"
            isRequired
            formik={formik}
            className="!w-full"
            label="Table Name"
            placeholder="Enter Table Name"
          />

          <span className="flex justify-end gap-3 py-3">
            <CustomButton color="inherit" onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton isLoading={isAdding || isUpdating} type="submit">
              Submit
            </CustomButton>
          </span>
        </form>
      </CustomModal>
    </>
  )
}

export default AddTables
