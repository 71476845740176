import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"

export const addProductByExcelFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.addProductByExcel, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const addInventoryByExcelFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.addInventoryByExcel, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const addProductWithVariantByExcelFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.addProductWithVariantByExcelNew, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateProductWithVariantByExcelFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.addProductWithVariantByExcelNew, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const addCustomerByExcelFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.addCustomerByExcel, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateCustomerByExcelFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.updateCustomerByExcel, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const addVenorByExcelFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.addVendorByExcel, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const requestInventoryByExcelFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.requestInventoryByExcel, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const inventoryProductsFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.stockVerifyProducts, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const newInventoryVerifyFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.newInventoryVerify, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateInventoryVerifyFn = (reqBody) => {
  try {
    const response = axiosInstance.put(API_URLS.newInventoryVerify, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const getInventoryVerifyFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.newInventoryVerify, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const deleteInventoryVerifyFn = (reqBody) => {
  try {
    const response = axiosInstance.delete(API_URLS.newInventoryVerify, {
      data: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const getInventoryVerifyDetailFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.inventoryVerifyDetail, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
