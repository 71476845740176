import { Button, CircularProgress } from "@mui/material"
import classNames from "classnames"
import React from "react"

const MkxButton = ({
  onClick,
  type,
  disabled,
  className = "",
  children,
  component,
  hidden,
  color = "primary",
  variant = "contained",
  startIcon,
  size = "small",
  endIcon,
  isLoading = false,
}) => {
  return (
    <>
      <Button
        disableElevation
        type={type}
        size={size}
        color={color}
        onClick={onClick}
        hidden={hidden}
        disabled={isLoading || disabled}
        component={component}
        variant={variant}
        startIcon={startIcon}
        endIcon={endIcon}
        className={classNames("!px-5", className)}
      >
        {isLoading ? <CircularProgress size={20} thickness={5} /> : children}
      </Button>
    </>
  )
}

export default MkxButton
