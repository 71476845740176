import { Add } from "@mui/icons-material"
import { Pagination, Skeleton, Switch, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { emailsTempFn, patchEmailTemplateFn } from "Services/EmailTemplates"
import { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import MkxButton from "shared/MkxButton"
import MkxInput from "shared/MkxInput"
import MkxTableCell from "shared/MkxTable/MkxTableCell"

const EmailTemplate = () => {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const navigate = useNavigate()

  const handleChange = (event, value) => {
    setPage(value)
  }

  const {
    data: emails,
    isLoading,
    refetch,
  } = useQuery(["emails", page, search], () => emailsTempFn({ page, search_value: search }))

  const { mutate: changeActiveStatusMutation } = useMutation(patchEmailTemplateFn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        toast.success(response?.data?.message)
        refetch()
      } else {
        toast.error(response?.data?.message)
      }
    },
  })

  const handleChangeStatus = (id) => {
    changeActiveStatusMutation({
      email_template_id: id,
    })
  }

  return (
    <>
      <div className="flex flex-col gap-4 p-8">
        <div className="flex items-center justify-between">
          <div className="text-xl font-bold text-red-500">
            <MkxInput
              size="small"
              placeholder="Search Email..."
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
          <MkxButton className="flex items-center mx-2 !px-5 gap-2" onClick={() => navigate("/create-email-template")}>
            <Add />
            Add Template
          </MkxButton>
        </div>

        <TableContainer component="div" className="!border !border-gray-300 !border-opacity-30 !rounded">
          <Table className="!overflow-x-auto bg-white !bg-opacity-20">
            <TableHead>
              <TableRow className="!bg-opacity-40 cursor-pointer !bg-gray-200">
                <MkxTableCell isHead className="!py-3">
                  Sr. No.
                </MkxTableCell>
                <MkxTableCell isHead className="!py-3">
                  Template Type
                </MkxTableCell>
                <MkxTableCell isHead className="!py-3">
                  Active Status
                </MkxTableCell>
                <MkxTableCell isHead className="!py-3">
                  Template Content
                </MkxTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? [1, 2, 3, 4, 5].map((i) => {
                    return (
                      <TableRow key={i}>
                        <MkxTableCell>
                          <Skeleton />
                        </MkxTableCell>
                        <MkxTableCell>
                          <Skeleton />
                        </MkxTableCell>
                        <MkxTableCell>
                          <Skeleton />
                        </MkxTableCell>
                        <MkxTableCell>
                          <Skeleton />
                        </MkxTableCell>
                      </TableRow>
                    )
                  })
                : emails?.data?.data?.map((item, index) => {
                    return (
                      <TableRow className="hover:!bg-white hover:!bg-opacity-40 cursor-pointer" key={item.id}>
                        <MkxTableCell>{index + 1}</MkxTableCell>
                        <MkxTableCell>{item.template_type_title}</MkxTableCell>
                        <MkxTableCell>
                          <Switch
                            checked={item.active_status}
                            onChange={() => handleChangeStatus(item.id)}
                            color="secondary"
                          />
                        </MkxTableCell>
                        <MkxTableCell>
                          <MkxButton
                            variant="contained"
                            onClick={() => {
                              navigate(`/create-email-template/${item.id}`)
                            }}
                          >
                            View
                          </MkxButton>
                        </MkxTableCell>
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>
          {emails?.data?.total_pages !== 1 && (
            <span className="flex justify-end p-3">
              <Pagination
                count={emails?.data?.total_pages || 0}
                variant="outlined"
                shape="circular"
                color="primary"
                page={emails?.data?.current_page || 0}
                onChange={handleChange}
              />
            </span>
          )}
        </TableContainer>
      </div>
    </>
  )
}

export default EmailTemplate
