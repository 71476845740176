import { LinearProgress, List, ListItemButton } from "@mui/material"
import { allSettings } from "Mock"
import { storeSettingsFn } from "Services/Business/Store"
import classNames from "classnames"
import { useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

const StoreSetting = () => {
  const { store_id } = useParams()
  const [setting, setSetting] = useState("General")
  const { data: storeSettings, isLoading } = useQuery(["store", store_id], () => storeSettingsFn({ store_id }))
  const store = storeSettings?.data?.data
  const component = allSettings?.find((i) => i.name.toLowerCase() === setting.toLowerCase()).component

  return isLoading ? (
    <LinearProgress />
  ) : (
    <div className="flex flex-col h-full gap-1 p-1 text-black bg-zinc-100 text-opacity-90">
      <div className="flex items-center h-[10%] gap-5 p-2 bg-white rounded shadow">
        {store?.logo && <img src={store?.logo} alt="" className="w-16 h-16 rounded" />}
        <div className="flex flex-col">
          <p className="text-xl font-semibold">{store?.store_name}</p>
          <p className="text-sm">{store?.email}</p>
        </div>
      </div>

      <div className="flex w-full h-[90%] gap-1">
        <List className="h-full w-60 bg-white !p-1 rounded shadow !overflow-y-auto">
          {allSettings.map((item) => {
            return (
              <ListItemButton
                onClick={() => setSetting(item.name)}
                className={classNames(
                  "!px-3 !transition-all !duration-500 !py-1",
                  item.name === setting ? "!bg-[#020073] !rounded !text-white" : ""
                )}
              >
                {item.name}
              </ListItemButton>
            )
          })}
        </List>
        <div className="flex flex-col w-full h-full p-2 overflow-y-auto bg-white rounded shadow">{component}</div>
      </div>
    </div>
  )
}

export default StoreSetting
