import { useState } from "react";
import {
  Alert,
  Box,
  IconButton,
  Popper,
  Fade,
  FormGroup,
  FormControlLabel,
  Switch,
  AlertTitle,
  Button,
  Paper,
} from "@mui/material";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import notification from "../../assets/Header/Notification.svg";
import MarkChatUnreadRoundedIcon from "@mui/icons-material/MarkChatUnreadRounded";
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";
import { toast } from "react-toastify";

const types = ["success", "info", "warning", "error"];
export const Notifications = () => {
  const { notifications, clear, markAllAsRead, markAsRead, unreadCount } =
    useNotificationCenter();
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const addNotification = () => {
    // use a random type of notification
    toast("Good Day", {
      type: types[Math.floor(Math.random() * types.length)],
    });
  };

  const toggleNotificationCenter = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };

  const toggleFilter = () => {
    setShowUnreadOnly(!showUnreadOnly);
  };

  return (
    <Box>
      <IconButton
        className="!bg-white !bg-opacity-20"
        onClick={toggleNotificationCenter}
      >
        <img src={notification} alt="" />
      </IconButton>

      <Popper
        open={isOpen}
        anchorEl={anchorEl}
        transition
        className="!-left-10 !top-1 !w-96"
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={100}>
            <Box component={Paper}>
              <div className="!p-0 bg-dark rounded-md">
                <div className="flex items-center !p-2 justify-between">
                  <p className="text-xl font-semibold tracking-wide">
                    Notifications
                  </p>
                  <FormGroup sx={{ color: "#fff" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="secondary"
                          onChange={toggleFilter}
                          checked={showUnreadOnly}
                        />
                      }
                      label="Unread"
                    />
                  </FormGroup>
                </div>
                <div className="h-96 overflow-y-auto !p-1">
                  {(!notifications.length ||
                    (unreadCount === 0 && showUnreadOnly)) && (
                    <p className="m-4 font-semibold text-center">
                      No Notification
                    </p>
                  )}
                  {(showUnreadOnly
                    ? notifications.filter((v) => !v.read)
                    : notifications
                  ).map((notification) => {
                    return (
                      <Alert
                        className="!rounded-lg !capitalize !m-1 !px-2 flex items-center"
                        severity={notification.type || "info"}
                        onClick={() => markAsRead(notification.id)}
                        action={
                          notification.read ? (
                            <MarkChatReadIcon className="!text-white mr-2" />
                          ) : (
                            <MarkChatUnreadRoundedIcon className="!text-white mr-2" />
                          )
                        }
                      >
                        <AlertTitle className="!capitalize !font-semibold">
                          {notification.type}
                        </AlertTitle>
                        {notification.content}
                      </Alert>
                    );
                  })}
                </div>
                <div className="flex !rounded-none !p-2 justify-between gap-2">
                  <Button
                    variant="contained"
                    className="!text-xs !capitalize"
                    onClick={clear}
                  >
                    Clear All
                  </Button>
                  {/* <Button variant="contained"   className="!text-xs !capitalize" onClick={addNotification}>
                    Add Notification
                  </Button> */}
                  <Button
                    variant="contained"
                    className="!text-xs !capitalize"
                    onClick={markAllAsRead}
                  >
                    Mark all as read
                  </Button>
                </div>
              </div>
            </Box>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};
