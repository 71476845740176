import React, { useState } from "react"
import CustomButton from "shared/MkxButton"
import { Add } from "@mui/icons-material"
import CustomModal from "shared/MkxModal"
import { useFormik } from "formik"
import CustomInput from "shared/MkxInput"
import { useMutation, useQueryClient } from "react-query"
import { addBrandItemsFn, updateBrandItemsFn } from "Services/Settings/Inventory"
import { toast } from "react-toastify"

const AddBrand = ({ selected, setSelected }) => {
  const [open, setOpen] = useState(false)
  const client = useQueryClient()

  const handleClose = () => {
    setOpen(false)
    setSelected(false)
  }
  const { mutate: addBrandItems } = useMutation(addBrandItemsFn, {
    onSuccess: ({ data }) => {
      if (data.response_code === 200) {
        toast.success(data.message)
        client.refetchQueries("brandItems")
        handleClose()
      } else {
        toast.error(data.message)
      }
    },
  })
  const { mutate: updateBrandItems } = useMutation(updateBrandItemsFn, {
    onSuccess: ({ data }) => {
      if (data.response_code === 200) {
        toast.success(data.message)
        client.refetchQueries("brandItems")
        handleClose()
      } else {
        toast.error(data.message)
      }
    },
  })

  const initialValues = {
    title: selected?.title ?? "",
    image: selected?.image ?? "",
    meta_title: selected?.meta_title ?? "",
    meta_keyword: selected?.meta_keyword ?? "",
    description: selected?.description ?? "",
    meta_description: selected?.meta_description ?? "",
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      const form = document.getElementById("brand")
      const reqBody = new FormData(form)
      reqBody.append("brand_id", selected.id)
      selected ? updateBrandItems(reqBody) : addBrandItems(reqBody)
    },
  })

  return (
    <>
      <CustomButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Add New
      </CustomButton>
      <CustomModal
        title={selected ? "Update Brand" : "Add Brand"}
        open={open || Boolean(selected)}
        onClose={handleClose}
        padding={4}
      >
        <form id="brand" onSubmit={formik.handleSubmit} className="flex flex-col">
          <div className="grid grid-cols-2 gap-x-4 gap-y-2">
            <CustomInput
              type="file"
              id="image"
              label="Image"
              onChange={(event) => formik.setFieldValue("image", event.target.files[0])}
            />
            <CustomInput id="title" label="Title" placeholder="Enter Title" formik={formik} />
            <CustomInput id="meta_title" label="Meta Title" placeholder="Enter Meta Title" formik={formik} />
            <CustomInput id="meta_keyword" label="Meta Keyword" placeholder="Enter Meta Keyword" formik={formik} />
            <CustomInput
              rows={3}
              multiline
              paddingX={0}
              id="description"
              label="Description"
              placeholder="Enter Description"
              formik={formik}
            />
            <CustomInput
              rows={3}
              multiline
              paddingX={0}
              id="meta_description"
              label="Meta Description"
              placeholder="Enter Meta Description"
              formik={formik}
            />
          </div>
          <div className="flex items-center justify-end gap-4 py-3">
            <CustomButton onClick={handleClose}>Cancel</CustomButton>
            <CustomButton type="submit">Submit</CustomButton>
          </div>
        </form>
      </CustomModal>
    </>
  )
}

export default AddBrand
