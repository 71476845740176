import { Add } from "@mui/icons-material"
import { MenuItem } from "@mui/material"
import { addPaymentFn } from "Services/Payment/AddPayment"
import axiosInstance from "config/axios"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import CustomModal from "shared/MkxModal"
import CustomSelect from "shared/MkxSelect"

const AddPayment = () => {
  const { store_id } = useParams()
  const [mode, setMode] = useState()
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const client = useQueryClient()

  const initialValues = {
    payment_type: "",
    store_id,
  }
  const { mutate: addPayment, isLoading } = useMutation(addPaymentFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("paymentlist")
        handleClose()
        payment()
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      addPayment(formik.values)
    },
  })
  const payment = () =>
    axiosInstance.get(`payment-mode-api-of-superadmin/`, { params: { store_id } }).then((res) => {
      setMode(res.data.data)
    })

  useEffect(() => {
    payment()
  }, [])

  return (
    <>
      <CustomButton startIcon={<Add />} className="!px-5" onClick={() => setOpen(true)}>
        Payment
      </CustomButton>
      <CustomModal title="Add Payment" open={open} onClose={handleClose} className="!w-1/3 !min-h-fit">
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4 p-4">
          <CustomSelect type="text" id="payment_type" formik={formik} label="Payment Mode">
            {mode?.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </CustomSelect>

          <span className="flex justify-end gap-3 px-6 pb-4">
            <CustomButton color="inherit" onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton isLoading={isLoading} type="submit">
              Add
            </CustomButton>
          </span>
        </form>
      </CustomModal>
    </>
  )
}

export default AddPayment
