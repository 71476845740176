import { Add } from "@mui/icons-material"
import React, { useState } from "react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import CustomModal from "shared/MkxModal"
import axiosInstance from "config/axios"
import { addInventoryByExcelFn } from "Services/Inventory"

const AddInventoryByExcel = ({ type = "Category", refetch }) => {
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState(null)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  const { mutate: uploadExcel } = useMutation(addInventoryByExcelFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        refetch()
        handleClose()
      }
    },
  })
  const handleDownload = async () => {
    try {
      const response = await axiosInstance.post(
        "product/sample-format-of-inventory-data-for-excel/",
        { inventory_data_type: type?.toLowerCase().replace(" ", "") },
        { responseType: "blob" }
      )

      const url = URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"] }))

      const a = document.createElement("a")
      a.href = url
      a.download = `sample_${type?.toLowerCase()}_data.xlsx`
      a.click()

      URL.revokeObjectURL(url)
    } catch (error) {
      toast.error("Failed to download sample product data")
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const reqBody = new FormData()
    reqBody.append("inventory_data_type", type?.toLowerCase().replace(" ", ""))
    reqBody.append("excel_file", file)
    uploadExcel(reqBody)
  }

  return (
    <>
      <CustomButton startIcon={<Add />} onClick={handleOpen}>
        Add {type} By Excel
      </CustomButton>
      <CustomModal padding={4} open={open} onClose={handleClose} className="w-1/3" title={`Add ${type} By Excel`}>
        <form onSubmit={handleSubmit} className="flex flex-col items-center justify-center gap-5">
          <span className="flex items-center justify-between w-full p-2">
            <p>Download Sample </p>
            <CustomButton onClick={handleDownload}>Download</CustomButton>
          </span>
          <div className="flex flex-col w-full">
            <CustomInput
              type="file"
              label="Upload Excel"
              className="basis-full"
              onChange={(event) => setFile(event.target.files[0])}
            />
          </div>

          <CustomButton type="submit">Add {type}</CustomButton>
        </form>
      </CustomModal>
    </>
  )
}

export default AddInventoryByExcel
