// import { resetPasswordFn } from "Services/HRM/Employee"
import { resetPasswordFn } from "Services/Resellers/ResellerStoreList"
import { useFormik } from "formik"
import { useState } from "react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { CustomButton } from "shared/CustomButton"
import CustomInput from "shared/CustomInput"
import CustomModal from "shared/CustomModal"

const ResetPassword = ({ reseller_id }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
    formik.resetForm()
  }

  const handleClose = () => {
    setOpen(false)
    formik.resetForm()
  }

  const { mutate: resetPassword, isLoading } = useMutation(resetPasswordFn, {
    onSuccess: (response) => {
      toast.success(response.data.message)
      handleClose()
      formik.resetForm()
    },
  })

  const initialValues = { new_password: "", confirm_password: "" }

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      const reqBody = {
        reseller_id: reseller_id,
        new_password: values.new_password,
        confirm_password: values.confirm_password,
      }
      resetPassword(reqBody)
    },
  })

  return (
    <>
      <CustomButton className="bg-primary text-white" size="small" onClick={handleOpen}>
        Reset
      </CustomButton>

      <CustomModal open={open} onClose={handleClose} setOpen={setOpen} className="!w-[530px]" title="Reset Password">
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
          <CustomInput
            name="new_password"
            onChange={formik.handleChange}
            type="password"
            isRequired
            label="New Password"
            placeholder="••••••••••••"
          />
          <CustomInput
            onChange={formik.handleChange}
            name="confirm_password"
            type="password"
            isRequired
            label="Confirm Password"
            placeholder="••••••••••••"
          />
          <CustomButton
            isLoading={isLoading}
            type="submit"
            className="!mt-5 !w-full !bg-primary !text-white !flex !justify-center !mx-auto"
          >
            Reset
          </CustomButton>
        </form>
      </CustomModal>
    </>
  )
}

export default ResetPassword
