import { Close, Link } from "@mui/icons-material"
import { Dialog, Divider, IconButton } from "@mui/material"
import { useFormik } from "formik"
import React, { useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { useMutation } from "react-query"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import { generateAccessTokenFn } from "Services/Settings/ChatSettings/GenerateAccessToken"

const APIIntgeration = ({ isDisbaled, template }) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
    generateAccessToken()
  }
  const { mutate: generateAccessToken, data: token } = useMutation(generateAccessTokenFn, {
    onSuccess: (response) => {
      console.log(response?.data)
    },
  })
  const initialValues = {
    api_url: `${window.location.origin}/whatsapp?template=${template?.name}&name=BhaaratStore&mobile=9266667999&email=info@bhaarat.store.com&callback_url=https://bhaarat.store&access_token=${token?.data?.data?.key}`,
    accesss_token: token?.data?.data?.key,
  }
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {},
  })

  return (
    <>
      <IconButton disabled={isDisbaled} onClick={handleOpen}>
        <Link />
      </IconButton>
      <Dialog open={open} onClose={handleClose} PaperProps={{ className: "min-h-[30%] min-w-[50%]" }}>
        <span className="flex items-center bg-zinc-100 justify-between p-1 !pl-3">
          <p>Setup API Integration</p>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </span>
        <Divider />
        <div className="flex flex-col gap-2 p-2">
          <span className="">
            <p>Access Token</p>
            <CustomInput
              id="accesss_token"
              placeholder="Access Token"
              value={formik.values.accesss_token}
              className="!w-full"
            />
          </span>
          <span className="">
            <p>API Url</p>
            <CustomInput
              id="api_url"
              placeholder="API Url"
              value={formik.values.api_url}
              className="!w-full"
              InputProps={{
                endAdornment: (
                  <CopyToClipboard text={formik.values.api_url}>
                    <CustomButton variant="text" className="!whitespace-nowrap">
                      Copy URL
                    </CustomButton>
                  </CopyToClipboard>
                ),
              }}
            />
          </span>
          <span className="flex justify-end gap-2 p-2">
            <CustomButton onClick={handleClose}>OK</CustomButton>
          </span>
        </div>
      </Dialog>
    </>
  )
}

export default APIIntgeration
