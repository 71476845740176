import { Settings } from "@mui/icons-material"
import { AddwebsiteSyncFn, WebsiteSyncFn, updateWebsiteFn } from "Services/Sync/Websitesync"
import { useFormik } from "formik"
import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import MkxModal from "shared/MkxModal"

const AddSync = ({ isUpdate }) => {
  const [open, setOpen] = useState(false)
  const { store_id } = useParams()
  const { data } = useQuery(["OtherSyncForUpdate"], () => WebsiteSyncFn({ store_id }), { enabled: isUpdate })

  const Websitesync = data?.data?.data?.[0]

  const client = useQueryClient()

  const { mutate: addwebsite } = useMutation(AddwebsiteSyncFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("OtherSync")
        setOpen(false)
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const { mutate: updatewebsite } = useMutation(updateWebsiteFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("OtherSync")
        setOpen(false)
      } else {
        toast.error(response.data.message)
      }
    },
  })

  const initialValues = {
    website_sync_id: isUpdate ? Websitesync?.id : "",
    token: Websitesync?.token || "",
    add_new_product_api_url: Websitesync?.add_new_product_api_url || "",
    offer_manage_api_url: Websitesync?.offer_manage_api_url || "",
    delete_api_url: Websitesync?.delete_api_url || "",
    sale_order_product_api_url: Websitesync?.sale_order_product_api_url || "",
    sale_invoice_api_url: Websitesync?.sale_invoice_api_url || "",
    update_quantity_and_price_api_url: Websitesync?.update_quantity_and_price_api_url || "",
    store_id,
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      if (isUpdate) {
        updatewebsite(formik.values)
      } else {
        addwebsite(formik.values)
      }
    },
  })

  return (
    <>
      <CustomButton startIcon={<Settings />} onClick={() => setOpen(true)}>
        Token and Url
      </CustomButton>
      <MkxModal title="Manage Website Sync Config" open={open} onClose={() => setOpen(false)}>
        <form onSubmit={formik.handleSubmit} className="p-4">
          <div className="grid grid-cols-2 gap-5">
            <CustomInput id="token" formik={formik} label="Other Site Token" isRequired />
            <CustomInput id="add_new_product_api_url" formik={formik} label="Add New Product API URL" isRequired />
            <CustomInput id="offer_manage_api_url" formik={formik} label="Offer Manage API URL" isRequired />
            <CustomInput id="delete_api_url" formik={formik} label="Delete Manage API URL" isRequired />
            <CustomInput
              id="sale_order_product_api_url"
              formik={formik}
              label="Sale Order of Product API URL"
              isRequired
            />
            <CustomInput id="sale_invoice_api_url" formik={formik} label="Sale Invoice API URL" isRequired />
            <CustomInput
              id="update_quantity_and_price_api_url"
              formik={formik}
              label="Update Quantity and Price API URL"
              isRequired
            />
          </div>
          <div className="flex items-center justify-end gap-5">
            <CustomButton color="inherit" onClick={() => setOpen(false)}>
              Cancel
            </CustomButton>
            <CustomButton type="submit">{isUpdate ? "Update" : "Add"}</CustomButton>
          </div>
        </form>
      </MkxModal>
    </>
  )
}

export default AddSync
