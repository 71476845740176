import ApprovalRequest from "components/ApprovalRequest"
import BackupRestore from "components/BackupRestore"
import BusinessCategory from "components/BusinessCategory"
import BusinessSubCategory from "components/BusinessCategory/BusinessSubCategory"
import CondidateData from "components/CandidateData"
import Contact from "components/Contact"
import CreateContact from "components/Contact/CreateContact"
import CustomerData from "components/CustomerData"
import Dashboard from "components/Dashboard"
import DefaultTemplates from "components/DefaultiTemplates"
import DeliveryManagement from "components/DeliveryManagement"
import EmailTemplate from "components/EmailTemplate"
import AddTemplate from "components/EmailTemplate/AddTemplate/AddTemplate"
import Groups from "components/Groups"
import AddGroup from "components/Groups/AddGroups"
import Details from "components/Groups/Details"
import GroupDetail from "components/Groups/GroupDetails"
import Module from "components/Groups/Module"
import Permissions from "components/Groups/Permissions"
import RoleDetails from "components/Groups/RoleDetails"
import HelpAndSupport from "components/HelpAndSupport"
import LearningZone from "components/LearningZone"
import CityList from "components/ManageDemoPlaces/CityList"
import CountryList from "components/ManageDemoPlaces/CountryList"
import StateList from "components/ManageDemoPlaces/StateList"
import NewsManagement from "components/NewsManagement"
import Notification from "components/Notification"
import OfferManagement from "components/OfferManagement"
import OrderManagement from "components/OrderManagement"
import AvailablePayment from "components/PaymentGetway/AvailablePayment"
import CradiantialSetup from "components/PaymentGetway/CradiantialSetup"
import ModuleManagement from "components/PlanManagement/ModuleManagement"
import Plan from "components/PlanManagement/Plan"
import PlanType from "components/PlanManagement/PlanType"
import Porter from "components/Porter"
import ProductCategory from "components/ProductCategory"
import ProductBrand from "components/ProductCategory/ProductBrand"
import ProductSubCategory from "components/ProductCategory/ProductSubCategory"
import ProductData from "components/ProductData"
import ProductVariantData from "components/ProductVariantData"
import Resellers from "components/Resellers/ListResellers"
import ResellersSales from "components/Resellers/SaleList"
import Restore from "components/Restore"
import ShipTocket from "components/ShipTocket"
import Store from "components/Store"
import StoreLicense from "components/Store/StoreLicense"
import StorePermission from "components/Store/StorePermissions"
import StoreSetting from "components/Store/StoreSetting"
import BhaaratDelivery from "components/Store/StoreSetting/DeliveryManagement/BhaaratDelivery"
import PorterConfig from "components/Store/StoreSetting/DeliveryManagement/Porter"
import ShipRoket from "components/Store/StoreSetting/DeliveryManagement/ShipRoket"
import ViewRole from "components/Store/StoreSetting/DepartmentRole/Role/ViewRole"
import AvailablePaymentGateway from "components/Store/StoreSetting/PaymentGateway/AvailablePayment"
import SetupCredential from "components/Store/StoreSetting/PaymentGateway/SetupCredential"
import AddPermissions from "components/Store/StoreSetting/UserApproval/AddPermission"
import StoreAdmins from "components/StoreAdmins"
import Testing from "components/Testing"
import VariablesSettings from "components/VariablesSettings"
import VariantCombinations from "components/VariantCombinations"
import VariantManagemnet from "components/VariantMangement"
import ProductVariantList from "components/VariantMangement/ProductVariantList"
import VariantOther from "components/VariantOther"
import ProductOther from "components/VariantOther/ProductOther"
import ProductChoice from "components/VariantOther/ProductOther/ProductChoice"
import VariantStorage from "components/VariantStorage"
import ProductStorage from "components/VariantStorage/ProductStorage"
import WalletManagement from "components/WalletManagement"
import WhatsappSetting from "components/WhatsappSetting"
import NotFound from "shared/NotFound"

export const routes = [
  { id: 0, path: "*", component: <NotFound /> },
  { id: 1, path: "/dashboard", component: <Dashboard /> },
  { id: 2, path: "/store", component: <Store /> },
  { id: 3, path: "/store/:id", component: <StorePermission /> },
  { id: 4, path: "/business-data", component: <BusinessCategory /> },
  { id: 4, path: "/business-category", component: <BusinessCategory /> },
  { id: 5, path: "/business-category/business-sub-category", component: <BusinessSubCategory /> },
  { id: 6, path: "/test", component: <Testing /> },
  { id: 7, path: "/manage-store", component: <Store /> },
  { id: 7, path: "/store-license", component: <StoreLicense /> },
  { id: 7, path: "/manage-demography", component: <CountryList /> },
  { id: 7, path: "/manage-demography/country", component: <CountryList /> },
  { id: 7, path: "/manage-demography/state", component: <StateList /> },
  { id: 7, path: "/manage-demography/city", component: <CityList /> },
  { id: 8, path: "/variant-mangement", component: <VariantManagemnet /> },
  { id: 8, path: "/variant-mangement-color", component: <VariantManagemnet /> },
  { id: 8, path: "/variant-mangement-storage", component: <VariantStorage /> },
  { id: 8, path: "/variant-management-other", component: <VariantOther /> },
  { id: 9, path: "/variant-combinations", component: <VariantCombinations /> },
  { id: 10, path: "/offer-management", component: <OfferManagement /> },
  { id: 11, path: "/customer-data", component: <CustomerData /> },
  { id: 12, path: "/candidate-data", component: <CondidateData /> },
  { id: 13, path: "/product-data", component: <ProductData /> },
  { id: 14, path: "/product-variant-data", component: <ProductVariantData /> },
  { id: 15, path: "/resellers", component: <Resellers /> },
  { id: 15, path: "/resellers/resellers-management", component: <Resellers /> },
  { id: 15, path: "/resellers/plans", component: <PlanType /> },
  { id: 15, path: "/resellers/plans/plan-type", component: <PlanType /> },
  { id: 15, path: "/resellers/plans/module", component: <ModuleManagement /> },
  { id: 15, path: "/resellers/plans/plan", component: <Plan /> },
  { id: 16, path: "/role-management", component: <Groups /> },
  { id: 16, path: "/role-management/permission/:name", component: <Permissions /> },
  { id: 16, path: "/role-management/details/:name", component: <Details /> },
  { id: 16, path: "/role-management/details", component: <RoleDetails /> },
  { id: 17, path: "/add-group", component: <AddGroup /> },
  { id: 18, path: "/group-detail/:id", component: <GroupDetail /> },
  { id: 19, path: "/order-management", component: <OrderManagement /> },
  { id: 21, path: "/manage-product", component: <ProductCategory /> },
  { id: 22, path: "/product-brand", component: <ProductBrand /> },
  { id: 23, path: "/product-sub-category", component: <ProductSubCategory /> },
  { id: 24, path: "/store-wallet-name", component: <WalletManagement /> },
  { id: 24, path: "/variant-mangement-color/variant-list", component: <ProductVariantList /> },
  { id: 24, path: "/variant-mangement-storage/variant-storage-list", component: <ProductStorage /> },
  { id: 24, path: "/variant-management-other/variant-other-list", component: <ProductOther /> },
  { id: 25, path: "/variant-management-other/variant-other-list/choice", component: <ProductChoice /> },
  { id: 26, path: "/help-suport", component: <HelpAndSupport /> },
  { id: 26, path: "/notification", component: <Notification /> },
  { id: 26, path: "/delivery-management", component: <DeliveryManagement /> },
  { id: 26, path: "/delivery-management/ship-rocket", component: <ShipTocket /> },
  { id: 26, path: "/delivery-management/porter", component: <Porter /> },
  { id: 27, path: "/backup-restore", component: <BackupRestore /> },
  { id: 27, path: "/backup-restore/backup", component: <BackupRestore /> },
  { id: 27, path: "/backup-restore/restore", component: <Restore /> },
  { id: 28, path: "/whatsapp", component: <WhatsappSetting /> },
  { id: 29, path: "/whatsapp-settings", component: <DefaultTemplates /> },
  { id: 30, path: "/settings/whatsapp/variables", component: <VariablesSettings /> },
  { id: 31, path: "/invertory/request", component: <ApprovalRequest /> },
  { id: 32, path: "/role/module", component: <Module /> },
  { id: 33, path: "/payment/available", component: <AvailablePayment /> },
  { id: 34, path: "/payment/setup", component: <CradiantialSetup /> },
  { id: 35, path: "/contact", component: <Contact /> },
  { id: 36, path: "/contact/create", component: <CreateContact /> },
  { id: 37, path: "/news-management", component: <NewsManagement /> },
  { id: 38, path: "/business-store/:store_id", component: <StoreSetting /> },
  { id: 38, path: "/business-store/:store_id/permissions/:emp_id", component: <AddPermissions /> },
  { id: 38, path: "/business-store/:store_id/shiprocket-config", component: <ShipRoket /> },
  { id: 38, path: "/business-store/:store_id/porter-config", component: <PorterConfig /> },
  { id: 38, path: "/business-store/:store_id/bhaarat-delivery-config", component: <BhaaratDelivery /> },
  { id: 38, path: "/business-store/:store_id/view-role-permissions/:name", component: <ViewRole /> },
  { id: 38, path: "/business-store/:store_id/payment-gateway", component: <AvailablePaymentGateway /> },
  { id: 38, path: "/business-store/:store_id/payment-gateway-setup", component: <SetupCredential /> },
  { id: 38, path: "/learning-zone", component: <LearningZone /> },
  { id: 39, path: "/email-templates", component: <EmailTemplate /> },
  { id: 40, path: "/create-email-template", component: <AddTemplate /> },
  { id: 41, path: "/create-email-template/:templateId", component: <AddTemplate /> },
  { id: 42, path: "/resellers/resellers-sales", component: <ResellersSales /> },
  { id: 43, path: "/store-admins", component: <StoreAdmins /> },
]
