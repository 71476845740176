import { Add } from "@mui/icons-material"
import { Box, IconButton, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { getPermissionFn } from "Services/Permission"
import { useState } from "react"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import CustomInput from "shared/MkxInput"
import MkxPagination from "shared/MkxTable/MkxPagination"
import MkxTableCell from "shared/MkxTable/MkxTableCell"

import { MkxTableLoader } from "shared/MkxTable/MkxTableLoader"

const UserApproval = () => {
  const { store_id } = useParams()
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)

  const navigate = useNavigate()
  const handleChange = (event, value) => {
    setPage(value)
  }

  const { data: permissions, isLoading } = useQuery(["permissions", page, search], () =>
    getPermissionFn({ store_id, page, search })
  )

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between pb-3">
        <CustomInput
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Search Employee..."
        />
      </div>

      <TableContainer className="border-t border-l border-collapse rounded whitespace-nowrap">
        <Table>
          <TableHead>
            <TableRow className="!bg-gray-200">
              <MkxTableCell>SNo</MkxTableCell>
              <MkxTableCell>Department</MkxTableCell>
              <MkxTableCell>Role Name</MkxTableCell>
              <MkxTableCell>Employee Name</MkxTableCell>
              <MkxTableCell>Employee Email</MkxTableCell>
              <MkxTableCell>Permissions</MkxTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <MkxTableLoader loading={isLoading} row={5} />
            {permissions?.data?.data?.map((item, index) => {
              return (
                <TableRow>
                  <MkxTableCell>{index + 1}</MkxTableCell>
                  <MkxTableCell>{item.department_name}</MkxTableCell>
                  <MkxTableCell>{item.role}</MkxTableCell>
                  <MkxTableCell>{item.user}</MkxTableCell>
                  <MkxTableCell>{item.email}</MkxTableCell>
                  <MkxTableCell className="!py-2 !border-r">
                    <div className="flex items-center justify-center gap-2">
                      <IconButton
                        size="small"
                        onClick={() => navigate(`/business-store/${store_id}/permissions/${item.emp_id}`)}
                      >
                        <Add className="text-green-800" />
                      </IconButton>
                    </div>
                  </MkxTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <span className="flex justify-end p-3">
        <MkxPagination
          page={permissions?.data?.current_page}
          count={permissions?.data?.total_pages}
          variant="outlined"
          onChange={handleChange}
        />
      </span>

      {permissions?.data?.data?.length === 0 && (
        <Box className="flex flex-col items-center justify-center h-full gap-3 py-20">
          <img src="https://pub-16f3de4d2c4841169d66d16992f9f0d3.r2.dev/assets/Sales/pana.svg" alt="" />
          <p className="text-3xl font-semibold">No Data Found</p>
        </Box>
      )}
    </div>
  )
}

export default UserApproval
