import { Edit } from "@mui/icons-material"
import { IconButton, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { gatewaysCredentialFn } from "Services/Settings/PaymentGateway"
import { useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { Active, Inactive } from "shared/ActivaInactive"
import GlassDiv from "shared/CustomDiv"
import CustomPagination from "shared/CustomPagination"
import TableCell from "shared/MkxTable/MkxTableCell"
import { MkxTableLoader } from "shared/MkxTable/MkxTableLoader"
import NoDataFound from "shared/NoDataFound"
import AddCredential from "./AddCredential"

const SetupCredential = () => {
  const { store_id } = useParams()
  const [page, setPage] = useState(1)
  const [selected, setSelected] = useState(null)

  const { data: gatewaysCredentials, isLoading } = useQuery(["gatewaysCredentials", page], () =>
    gatewaysCredentialFn({ page, store_id })
  )

  return (
    <div className="flex flex-col h-full p-1 bg-gray-200">
      <GlassDiv className="flex h-full flex-col gap-2 !p-0">
        <div className="flex items-center justify-between p-2">
          <p className="text-lg font-semibold"> Setup Credential</p>
          <AddCredential selected={selected} setSelected={setSelected} />
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell isHead>SNo</TableCell>
                <TableCell isHead>Key</TableCell>
                <TableCell isHead>Secret</TableCell>
                <TableCell isHead>Payment Gateway</TableCell>
                <TableCell isHead>Status</TableCell>
                <TableCell isHead>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <MkxTableLoader loading={isLoading} row={5} />
              {gatewaysCredentials?.data?.data?.map((row, index) => (
                <TableRow>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row?.key}</TableCell>
                  <TableCell>{row?.secret}</TableCell>
                  <TableCell>{row?.payment_gatewaay}</TableCell>
                  <TableCell>
                    <span className="flex items-center justify-center">
                      {row?.status === true ? <Active /> : <Inactive />}
                    </span>
                  </TableCell>
                  <TableCell>
                    <IconButton size="small" onClick={() => setSelected(row)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <CustomPagination data={gatewaysCredentials} setPage={setPage} />
          <NoDataFound data={gatewaysCredentials} />
        </TableContainer>
      </GlassDiv>
    </div>
  )
}
export default SetupCredential
