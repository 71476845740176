import { Edit } from "@mui/icons-material"
import { Button, Switch, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { confirmEmailFn } from "Services/Settings/EmailSetting/ConfirmEmailSettings"
import { emailsFn } from "Services/Settings/EmailSetting/Emails"
import { updateEmailsFn } from "Services/Settings/EmailSetting/UpdateEmail"
import { useFormik } from "formik"
import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { CustomIconButton } from "shared/CustomIconButton"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import CustomModal from "shared/MkxModal"
import MkxPagination from "shared/MkxTable/MkxPagination"
import MkxTableCell from "shared/MkxTable/MkxTableCell"
import { MkxTableLoader } from "shared/MkxTable/MkxTableLoader"
import AddEmail from "./AddEmail"

const EmailSetting = () => {
  const { store_id } = useParams()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const client = useQueryClient()
  const [open, setOpen] = useState(false)

  const handleChange = (event, value) => {
    setPage(value)
  }

  const { data: emails, isLoading } = useQuery(["emails", store_id, page, search], () =>
    emailsFn({ store_id, page, search })
  )
  const data = emails?.data?.data

  const { mutate: defaultSet } = useMutation(confirmEmailFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("emails")
      } else {
        toast.error(response.data.message)
      }
    },
  })

  const handleClose = () => setOpen(false)
  const selected = data?.find((i) => i.id === open) || {}
  const initialValues = {
    email_setup_id: open,
    host: selected.host || "",
    username: selected.username || "",
    password: selected.password || "",
  }

  const { mutate: updateEmails, isLoading: isLoading1 } = useMutation(updateEmailsFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("emails")
        handleClose()
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => updateEmails({ ...formik.values, store_id }),
  })

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <div className="text-xl font-bold text-red-500">
            <CustomInput
              size="small"
              placeholder="Search Email..."
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
          <AddEmail />
        </div>

        <TableContainer>
          <Table className="border">
            <TableHead>
              <TableRow>
                <MkxTableCell isHead>Sr. No.</MkxTableCell>
                <MkxTableCell isHead>Email Host</MkxTableCell>
                <MkxTableCell isHead>Email Host User</MkxTableCell>
                <MkxTableCell isHead>Campaign</MkxTableCell>
                <MkxTableCell isHead>Default Email</MkxTableCell>
                <MkxTableCell isHead>Action</MkxTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <MkxTableLoader loading={isLoading} row={5} />
              {data?.map((item, index) => {
                return (
                  <TableRow key={item.id}>
                    <MkxTableCell>{index + 1}</MkxTableCell>
                    <MkxTableCell>{item.host}</MkxTableCell>
                    <MkxTableCell>{item.username}</MkxTableCell>
                    <MkxTableCell>
                      <Switch
                        checked={item.confirm_setting}
                        size="small"
                        onChange={() => defaultSet({ store_id, email_setup_id: item.id, email_setup_type: "campaign" })}
                      />
                    </MkxTableCell>
                    <MkxTableCell>
                      <Switch
                        checked={item.is_default}
                        size="small"
                        onChange={(event) => {
                          if (event.target.checked) {
                            defaultSet({ store_id, email_setup_id: item.id, email_setup_type: "default" })
                          } else {
                            toast.warning("Already Default")
                          }
                        }}
                      />
                    </MkxTableCell>
                    <MkxTableCell>
                      <CustomIconButton color="success">
                        <Edit onClick={() => setOpen(item?.id)} />
                      </CustomIconButton>
                    </MkxTableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <MkxPagination count={emails?.data?.total_pages} page={emails?.data?.current_page} onChange={handleChange} />
        </TableContainer>
      </div>
      <CustomModal open={open} onClose={handleClose} title="Update Email" className="!w-1/3">
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3 px-4">
          <CustomInput id="host" formik={formik} className="!w-full" label="Host (Optional)" />
          <span className="grid grid-cols-2 gap-5">
            <CustomInput id="username" formik={formik} label="Email Host*" />
            <CustomInput type="password" id="password" formik={formik} label="Email Host Password*" />
          </span>
          <span className="flex justify-end gap-3 py-3">
            <Button color="inherit" onClick={handleClose} className="!py-2 !px-10">
              Cancel
            </Button>
            <CustomButton isLoading={isLoading1} type="submit" className="!w-32">
              Update
            </CustomButton>
          </span>
        </form>
      </CustomModal>
    </>
  )
}

export default EmailSetting
