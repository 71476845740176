import { Add } from "@mui/icons-material"
import { FormControl, FormControlLabel, MenuItem, Radio, RadioGroup } from "@mui/material"
import { addGatewayCredentialFn, paymentGatewayFn, updateGatewayCredentialFn } from "Services/Settings/PaymentGateway"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import CustomModal from "shared/MkxModal"
import CustomSelect from "shared/MkxSelect"

const AddCredential = ({ selected, setSelected }) => {
  const { store_id } = useParams()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setSelected(null)
  }
  const client = useQueryClient()

  const { data: paymentGateways } = useQuery(["paymentGatewayList"], () => paymentGatewayFn({ store_id }))

  const { mutate: addGatewayCredential, isLoading } = useMutation(addGatewayCredentialFn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        toast.success(response?.data?.message)
        client.refetchQueries("gatewaysCredentials")
        formik.resetForm()
        handleClose()
      } else {
        toast.error(response?.data?.message)
      }
    },
  })
  const { mutate: updateGatewayCredential, isLoading: isLoadingUpdate } = useMutation(updateGatewayCredentialFn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        toast.success(response?.data?.message)
        client.refetchQueries("gatewaysCredentials")
        formik.resetForm()
        handleClose()
      } else {
        toast.error(response?.data?.message)
      }
    },
  })

  const initialValues = {
    key: selected?.key || "",
    secret: selected?.secret || "",
    status: selected?.status ? "True" : "False" || "True",
    payment_gateway_id: selected?.store_payment_Accepted_by || "",
    store_id,
  }
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      selected
        ? updateGatewayCredential({
            payment_gateway_credential_id: selected?.id,
            ...values,
          })
        : addGatewayCredential(values)
    },
  })

  useEffect(() => {
    Boolean(selected) && handleOpen()
  }, [selected])

  return (
    <>
      <CustomButton size="medium" startIcon={<Add />} onClick={handleOpen}>
        Add
      </CustomButton>

      <CustomModal
        className="!w-1/4"
        open={open}
        onClose={handleClose}
        title={`${selected ? "Update" : "Add"} Credentials`}
      >
        <form onSubmit={formik.handleSubmit} className="grid gap-5 p-5">
          <CustomSelect
            label="Payment Gateway"
            id="payment_gateway_id"
            className="!w-full"
            formik={formik}
            placeholder="Select Payment Gateway"
          >
            {paymentGateways?.data?.data?.map((gateway) => (
              <MenuItem value={gateway?.id}>{gateway?.payment_gateway}</MenuItem>
            ))}
          </CustomSelect>

          <CustomInput id="key" formik={formik} label="Key (MID)" placeholder="Enter Key" />

          <CustomInput id="secret" label="Secret (Salt Key)" formik={formik} placeholder="Enter Secret" />
          <FormControl>
            <p className="font-semibold">Status</p>
            <RadioGroup row id="status" name="status" value={formik.values.status} onChange={formik.handleChange}>
              <FormControlLabel value="True" control={<Radio />} label="True" />
              <FormControlLabel value="False" control={<Radio />} label="False" />
            </RadioGroup>
          </FormControl>
          <div className="flex justify-end gap-5">
            <CustomButton onClick={handleClose}>CANCEL</CustomButton>
            <CustomButton isLoading={isLoading || isLoadingUpdate} type="submit">
              {selected ? "Update" : "Add"}
            </CustomButton>
          </div>
        </form>
      </CustomModal>
    </>
  )
}
export default AddCredential
