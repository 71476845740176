import { Outlet } from "react-router-dom"
import Header from "shared/Header"
import Sidebar from "shared/Sidebar"

const Layout = () => {
  return (
    <>
      <div className="sticky top-0 w-full h-screen bg-gradient bg-secondary">
        <Header />
        <div className="flex bg-gradient bg-secondary">
          <div className="z-30 text-black shadow-sm shadow-gray-400" style={{ width: "300px" }}>
            <Sidebar />
          </div>
          <div className="w-full h-[90vh] overflow-y-auto bg-secondary" style={{ width: "100%" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout
