import { FilterAlt } from "@mui/icons-material"
import axios from "config/axios"
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import React, { useEffect, useState, useRef } from "react"
import { CustomIconButton } from "../../shared/CustomIconButton"
import Search from "../../shared/Search"
import { DownloadTableExcel } from "react-export-table-to-excel"
import excel from "../../assets/excel.svg"
import { toast } from "react-toastify"

const ProductVariantData = () => {
  const [data, setData] = useState()

  const tableRef = useRef(null)
  const ProductVariantDataFileList = () => {
    axios
      .get(`http://45.64.156.214:9897/product-variant-export-data-in-excel/`)
      .then((response) => {
        setData(response?.data.data)
        // toast(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response)
        console.log(error)
        toast(error.message)
      })
  }

  useEffect(() => {
    ProductVariantDataFileList()
  }, [])

  console.log("Data", data)
  return (
    <div className="m-4">
      <div className="flex flex-col !my-3 !rounded bg-secondary border-secondary shadow-card shadow-zinc-600">
        <div className="flex justify-between items-center p-2 h-14 border-zinc-600">
          <div className="flex items-center">
            <CustomIconButton>
              <FilterAlt />
            </CustomIconButton>
            <Search />
          </div>
          <DownloadTableExcel filename="users table" sheet="users" currentTableRef={tableRef.current}>
            <div className="!bg-white !rounded">
              <img src={excel} alt="" className="h-10" />
            </div>
          </DownloadTableExcel>
        </div>
      </div>
      <TableContainer component={Paper} sx={{ maxWidth: 1420 }}>
        <Table className="overflow-auto !whitespace-nowrap" ref={tableRef}>
          <TableHead>
            <TableRow>
              <TableCell align="center">Product ID</TableCell>
              <TableCell align="center">Quantity</TableCell>
              <TableCell align="center">Purchase Price</TableCell>
              <TableCell align="center">Selling Price</TableCell>
              <TableCell align="center">MRP</TableCell>
              <TableCell align="center">Variant Values ID</TableCell>
              <TableCell align="center">Barcode</TableCell>
              <TableCell align="center">Barcode Image</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((item) => (
                <TableRow>
                  <TableCell align="center">{item.product_id}</TableCell>
                  <TableCell align="center">{item.quantity}</TableCell>
                  <TableCell align="center">{item.purchase_price}</TableCell>
                  <TableCell align="center">{item.selling_price}</TableCell>
                  <TableCell align="center">{item.mrp}</TableCell>
                  <TableCell align="center">{item.variant_values_id}</TableCell>
                  <TableCell align="center">{item.Barcode}</TableCell>
                  <TableCell align="center">{item.Barcode_image}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ProductVariantData
