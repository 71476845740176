import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import RemoveIcon from "@mui/icons-material/Remove"
import { Checkbox } from "@mui/material"
import axios from "config/axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"

const ViewModule = ({ row, handleClose, permissionList1 }) => {
  const [permData, setPermData] = useState([])
  const [newDataAdd, setNewDataAdd] = useState([])
  const [selectedUpdateModule, setSelectedUpdateModule] = useState([])
  const [newSelectPermission, setNewSelectPermission] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [searchQuery1, setSearchQuery1] = useState("")
  const [page, setPage] = useState(1)
  const [permissionData, setPermissionData] = useState([])

  const permissionList = () => {
    axios
      .get(`module-permission-api-for-superadmin/?&search=${searchQuery1}`)
      .then((response) => {
        setPermissionData(response?.data.data)
      })
      .catch((error) => {
        console.log("", error)
      })
  }
  useEffect(() => {
    permissionList()
  }, [searchQuery1])

  const moduleList = () => {
    axios
      .get(`module-permission-api-for-superadmin/?module_id=${row?.id}&page=${page}&search=${searchQuery}`)
      .then((response) => {
        setPermData(response?.data)
        setNewDataAdd([...newDataAdd, ...response.data.data])
      })
      .catch((error) => {
        console.log("", error)
      })
  }
  useEffect(
    () => {
      row?.id && moduleList()
    },
    // eslint-disable-next-line
    [row?.id, page, searchQuery]
  )

  console.log(newDataAdd, "newDataAdd")

  const updateData = () => {
    const uniqueArray = []
    for (const value of selectedUpdateModule) {
      if (!uniqueArray.includes(value)) {
        uniqueArray.push(value)
      }
    }

    const permissionData = {
      module_id: Number(row?.id),
      action_type: "remove",
      permission_ids: uniqueArray,
    }

    axios
      .post(`module-permission-api-for-superadmin/`, permissionData)
      .then((response) => {
        if (response.data.response_code === 200) {
          moduleList()
          toast.success(response?.data?.message)
          setSelectedUpdateModule([])
          permissionList()
        } else {
          toast.success(response?.data?.message)
        }
      })
      .catch((error) => {
        console.log("", error)
      })
  }

  const searchData1 = (even) => {
    setSearchQuery1(even.split(" ").join("_"))
  }

  const chat = document.getElementById("Chat")

  chat?.addEventListener("scroll", () => {
    if (chat?.scrollTop) {
      if (page !== permData?.total_pages) {
        setPage(page + 1)
      } else {
        setPage(page)
      }
    }
  })

  function removeDuplicateObjects(arr) {
    const uniqueIds = new Set()
    return arr.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id)
        return true
      }
      return false
    })
  }

  const deduplicatedData = removeDuplicateObjects(newDataAdd)
  const updateData1 = () => {
    const uniqueArray = []
    for (const value of newSelectPermission) {
      if (!uniqueArray.includes(value)) {
        uniqueArray.push(value)
      }
    }

    const permissionData = {
      module_id: Number(row?.id),
      action_type: "add",
      permission_ids: uniqueArray,
    }
    axios
      .post(`module-permission-api-for-superadmin/`, permissionData)
      .then((response) => {
        if (response.data.response_code === 200) {
          moduleList()
          toast.success(response?.data?.message)
          setNewSelectPermission([])
          permissionList()
        } else {
          toast.success(response?.data?.message)
        }
      })
      .catch((error) => {
        console.log("", error)
      })
  }

  const data = permissionData?.filter((item) => item?.id === row?.id)

  console.log("object", data?.[0]?.permission)
  const handleCloseView = () => {
    handleClose()
    permissionList1()
  }

  const searchFun = (event) => {
    setSearchQuery(event.target.value)
    setNewDataAdd([])
    setPage(1)
  }

  return (
    <>
      <div className="">
        <div className="flex justify-between shadow mx-auto !gap-1  shadow-gray-400 m-2 !h-[80vh] rounded p-2">
          <div className="rounded w-[49%] overflow-y-auto bg-sky-400">
            <div className="flex items-center justify-between p-2 font-bold border-b text-slate-800">
              <div>{row?.module_name}</div>
              <div className="flex items-center gap-x-2">
                <input
                  type="text"
                  placeholder="search "
                  className="w-40 px-1 rounded outline-none placeholder:font-normal placeholder:text-sm hover:border-red-400"
                  onChange={(event) => searchData1(event.target.value)}
                />
                <RemoveIcon className="bg-white rounded-full cursor-pointer" onClick={handleCloseView} />
              </div>
            </div>
            <div className="">
              {data && data?.[0]?.permission?.length !== 0 ? (
                data?.[0]?.permission
                  // ?.filter((row) => row?.name.toLowerCase().includes(searchQuery1))
                  ?.map((elem) => (
                    <div className="flex !border-[0.5px] hover:bg-sky-300 cursor-pointer">
                      <div>
                        <Checkbox
                          size="small"
                          checked={selectedUpdateModule?.map((i) => i).includes(elem?.id)}
                          onChange={(event) => {
                            {
                              !selectedUpdateModule?.map((i) => i).includes(elem?.id)
                                ? setSelectedUpdateModule([...selectedUpdateModule, elem?.id])
                                : setSelectedUpdateModule(() => selectedUpdateModule?.filter((i) => i !== elem?.id))
                            }
                          }}
                          inputProps={{
                            "aria-label": "controlled",
                          }}
                        />
                      </div>
                      <div className="pt-2 text-start">
                        <span className="text-sm text-black">{elem?.name}</span>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="mx-auto font-bold text-center text-white">No Data Found</div>
              )}
            </div>
          </div>
          <div className="text-center items-center flex flex-col justify-center !gap-y-3">
            {selectedUpdateModule?.length !== 0 ? (
              <>
                <ExpandLessIcon
                  className="bg-blue-600 !text-[40px] rotate-90 rounded-full text-white"
                  onClick={updateData}
                />
              </>
            ) : (
              <>
                <ExpandLessIcon
                  className="bg-gray-200 !text-[40px] rotate-90 rounded-full text-white"
                  //   onClick={updateData}
                />
              </>
            )}

            {newSelectPermission?.length !== 0 ? (
              <ExpandLessIcon
                className="bg-blue-600 !text-[40px] -rotate-90 items-center rounded-full text-white"
                onClick={updateData1}
              />
            ) : (
              <ExpandLessIcon
                className="bg-gray-200 !text-[40px] -rotate-90 items-center rounded-full text-white"
                //   onClick={updateData1}
              />
            )}
          </div>
          <div className="shadow rounded bg-sky-400 w-[49%] overflow-y-auto" id="Chat">
            <div className="flex items-center justify-between p-2 font-bold border-b text-slate-800">
              <div className="flex items-center gap-x-2">
                <input
                  type="text"
                  placeholder="search 2"
                  className="w-64 px-1 rounded outline-none placeholder:font-normal placeholder:text-sm hover:border-red-400"
                  onChange={(event) => searchFun(event)}
                />
              </div>
            </div>
            <div className="">
              {deduplicatedData &&
                deduplicatedData?.map((elem) => (
                  <div className="flex !border-[0.5px] hover:bg-sky-300 cursor-pointer">
                    <div>
                      <Checkbox
                        size="small"
                        checked={newSelectPermission?.map((i) => i).includes(elem?.id)}
                        onChange={(event) => {
                          {
                            !newSelectPermission?.map((i) => i).includes(elem?.id)
                              ? setNewSelectPermission([...newSelectPermission, elem?.id])
                              : setNewSelectPermission(() => newSelectPermission?.filter((i) => i !== elem?.id))
                          }
                        }}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    </div>
                    <div className="pt-2 text-start">
                      <span className="text-sm text-black">{elem?.name}</span>
                    </div>
                  </div>
                ))}
              <div className=""></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewModule
