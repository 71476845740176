import axios from "config/axios"

export const updateDepartmentFn = (redBody) => {
  try {
    const response = axios.put("department-settings-api-of-superadmin/", redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
