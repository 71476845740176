import { Button, createTheme, TextField, ThemeProvider } from "@mui/material"
import axios from "config/axios"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import login from "../../../assets/authentication/bg.jpg"
import logo from "../../../assets/authentication/logo.png"

const ResetPassword = () => {
  const navigate = useNavigate()
  const lightTheme = createTheme({ palette: { mode: "light", primary: { main: "#1345B7" } } })

  const initialValues = { email: "", password: "" }
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      const reqBody = { store_email: values.email }
      axios
        .post(`password-forget-email/`, { ...reqBody })
        .then((response) => {
          toast.success(response.data.message)
          navigate("/")
        })
        .catch((error) => {
          console.log(error)
        })
    },
  })

  return (
    <>
      <div className="flex">
        <img src={login} alt="" className="fixed object-cover w-screen h-screen" />
        <div className="flex z-[999] flex-col h-screen justify-center items-center p-16 text-black w-[30%] border-r-2 border-opacity-20 bg-white bg-opacity-10 backdrop-blur-md">
          <img src={logo} alt="" className="h-28" />
          <div>
            <p className="mt-10 text-2xl text-left">Reset Password</p>
            <p className="my-2 text-xs">
              Enter your email address and we’ll send you an email with instructions to reset your password
            </p>
          </div>

          <form onSubmit={formik.handleSubmit} className="flex flex-col w-full my-2">
            <ThemeProvider theme={lightTheme}>
              <label className="py-1">Email</label>
              <TextField
                id="email"
                name="email"
                size="small"
                placeholder="Email"
                value={formik.values["email"]}
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                type="email"
                className="rounded-md text-black px-2 p-2 focus:!outline-none !border-2 bg-white bg-opacity-20 backdrop-blur-sm"
              />

              <div className="flex mt-5">
                <Button
                  disableElevation
                  variant="contained"
                  type={"submit"}
                  className="px-10 !rounded-md !p-1.5 !capitalize !mx-0"
                >
                  Sent
                </Button>
              </div>
            </ThemeProvider>
          </form>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
