import { Divider, List, ListItemButton } from "@mui/material"
import React from "react"
import classNames from "classnames"
import { useLocation, useNavigate } from "react-router-dom"
import ChatSettings from "./ChatSettings"
import Workflows from "./Workflows"
import Inbox from "./Inbox"
import CreateWorkflows from "./CreateWorkflows"
import { AccountTree, MoveToInbox, SmartToy, Dashboard } from "@mui/icons-material"
import ERPTemplateSettings from "./ERPTemplateSettings"
import GlassDiv from "shared/CustomDiv"

const Automation = () => {
  const { state } = useLocation()
  const navigate = useNavigate()

  return (
    <GlassDiv className="!p-0 flex flex-col h-full">
      {state?.page === "CreateWorkflows" ? (
        <CreateWorkflows />
      ) : (
        <div className="flex h-full">
          <div className="flex flex-col w-1/6 h-full !p-0">
            <p className="p-6 text-xl font-semibold">Automation</p>
            <Divider />
            <List className="!pr-1 !py-0">
              <ListItemButton
                className={classNames(
                  "!flex gap-2",
                  state?.page === "Inbox" &&
                    "!bg-gradient-to-r !w-full from-purple-500 to-purple-400 !text-white !rounded-r-full"
                )}
                onClick={() =>
                  navigate("/settings/whatsapp/templates", {
                    state: { page: "Inbox" },
                  })
                }
              >
                <MoveToInbox /> Inbox Settings
              </ListItemButton>
              <ListItemButton
                className={classNames(
                  "!flex gap-2",
                  state?.page === "AutoButton" &&
                    "!bg-gradient-to-r !w-full from-purple-500 to-purple-400 !text-white !rounded-r-full"
                )}
                onClick={() =>
                  navigate("/settings/whatsapp/templates", {
                    state: { page: "AutoButton" },
                  })
                }
              >
                <SmartToy /> Custom Auto Reply
              </ListItemButton>
              <ListItemButton
                className={classNames(
                  "!flex gap-2",
                  state?.page === "Workflows" &&
                    "!bg-gradient-to-r !w-full from-purple-500 to-purple-400 !text-white !rounded-r-full",
                  state?.page === "CreateWorkflows" &&
                    "!bg-gradient-to-r !w-full from-purple-500 to-purple-400 !text-white !rounded-r-full"
                )}
                onClick={() =>
                  navigate("/settings/whatsapp/templates", {
                    state: { page: "Workflows" },
                  })
                }
              >
                <AccountTree /> Workflows
              </ListItemButton>
              <ListItemButton
                className={classNames(
                  "!flex gap-2",
                  state?.page === "ERPTemplate" &&
                    "!bg-gradient-to-r !w-full from-purple-500 to-purple-400 !text-white !rounded-r-full"
                )}
                onClick={() =>
                  navigate("/settings/whatsapp/templates", {
                    state: { page: "ERPTemplate" },
                  })
                }
              >
                <Dashboard /> ERP Template
              </ListItemButton>
            </List>
          </div>
          <Divider orientation="vertical" />
          <div className="flex flex-col w-5/6 h-full overflow-auto">
            {!state || (state?.page === "Inbox" && <Inbox />)}
            {state?.page === "AutoButton" && <ChatSettings />}
            {state?.page === "Workflows" && <Workflows />}
            {state?.page === "ERPTemplate" && <ERPTemplateSettings />}
          </div>
        </div>
      )}
    </GlassDiv>
  )
}

export default Automation
