import { API_URLS } from "config/apiUrls"
import axiosInstance from "config/axios"

export const storeAdminListFn = (redBody) => {
  try {
    const response = axiosInstance.get(API_URLS.storeAdminList, {
      params: redBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const changeTwoFactorStatusFn = (redBody) => {
  try {
    const response = axiosInstance.post(API_URLS.storeAdminList, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const paymentStatusChangeFn = (redBody) => {
  try {
    const response = axiosInstance.post(API_URLS.paymentStatusChange, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
