import { IconButton } from "@mui/material"
import classNames from "classnames"

/**
 * A custom icon button component.
 * @param {import("@mui/material").IconButtonProps} props - The props for the CustomIconButton component.
 * @returns {JSX.Element} A custom icon button.
 */
export const CustomIconButton = ({ onClick, size = "small", className, children, ...rest }) => {
  return (
    <IconButton size={size} onClick={onClick} className={classNames("!bg-gray-200 !rounded-md", className)} {...rest}>
      {children}
    </IconButton>
  )
}
