import { resetPasswordStoreFn } from "Services/Business/Store"
import { useFormik } from "formik"
import { useState } from "react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import MkxButton from "shared/MkxButton"
import MkxInput from "shared/MkxInput"
import MkxModal from "shared/MkxModal"

const ResetPassword = ({ store_id }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  const { mutate: resetPassword, isLoading } = useMutation(resetPasswordStoreFn, {
    onSuccess: (response) => {
      if (response?.data.response_code === 200) {
        handleClose()
        toast.success(response.data.message)
        formik.resetForm("")
      } else {
        toast.error(response.data.message)
      }
    },
  })

  const initialValues = {
    new_password: "",
    confirm_password: "",
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      resetPassword({ store_id, ...values })
    },
  })

  return (
    <>
      <MkxButton size="small" onClick={handleOpen}>
        Reset
      </MkxButton>

      <MkxModal open={open} onClose={handleClose} setOpen={setOpen} title="Reset Password">
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 p-4">
          <MkxInput
            isRequired
            formik={formik}
            id="new_password"
            type="password"
            placeholder="Enter Password"
            label="New Password"
          />
          <MkxInput
            isRequired
            formik={formik}
            id="confirm_password"
            type="password"
            placeholder="Enter Password Again"
            label="Confirm Password"
          />
          <MkxButton isLoading={isLoading} type="submit" className="!mt-5 !w-full !flex !justify-center !mx-auto">
            Reset
          </MkxButton>
        </form>
      </MkxModal>
    </>
  )
}

export default ResetPassword
