import classNames from "classnames";
import React from "react";

const CustomSelect = ({
  children,
  id,
  label,
  value,
  name,
  onChange,
  className,
  multiple,
  hidden,
}) => {
  return (
    <div hidden={hidden} className="flex flex-col justify-center mb-1.5">
      <p hidden={hidden} className="m-1 font-semibold">
        {label}
      </p>
      <select
        id={id}
        multiple={multiple}
        name={name}
        value={value}
        hidden={hidden}
        onChange={onChange}
        className={classNames(
          "rounded-md p-1 outline-none bg-white w-full text-zinc-600 border-opacity-30 border border-zinc-600",
          className
        )}
      >
        {children}
      </select>
    </div>
  );
};

export default CustomSelect;
