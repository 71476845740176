import { DeleteTwoTone, ReportTwoTone } from "@mui/icons-material"
import AddIcon from "@mui/icons-material/Add"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import EditIcon from "@mui/icons-material/Edit"
import {
  Checkbox,
  Dialog,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import Modal from "@mui/material/Modal"
import TextField from "@mui/material/TextField"
import axios from "config/axios"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { addProductCategoryFn, updateProductCategoryFn } from "../../Services/Business"
import axiosInstance from "config/axios"
import MkxButton from "shared/MkxButton"
import MkxInput from "../../shared/MkxInput"
import MkxSelect from "../../shared/MkxSelect"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
}

const ProductCategory = () => {
  const [productData, setProductData] = useState([])
  const [open1, setOpen1] = React.useState(false)
  const [page, setPage] = React.useState(1)
  const [rowData, setRowData] = useState(null)
  const [searchQuery, setSearchQuery] = useState("")
  const [subCategory, setSubCategory] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(null)
  const [category, setCategory] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [isDelete, setIsDelete] = useState(false)

  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => {
    setOpen1(false)
    formik.resetForm()
    setRowData(null)
  }
  const previousData = () => {
    setPage(page - 1)
  }
  const nextData = () => {
    setPage(page + 1)
  }

  const CategoryList = () => {
    axiosInstance
      .post(`business-category-list-data/`, {})
      .then((response) => {
        setCategory(response.data.business_category_list_data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const { mutate: updateProductCategory } = useMutation(updateProductCategoryFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
        CategoryList()
        handleClose1()
      } else {
        toast.error(res.data.message)
      }
    },
  })
  const { mutate: addProductCategory } = useMutation(addProductCategoryFn, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
        CategoryList()
        handleClose1()
      } else {
        toast.error(res.data.message)
      }
    },
  })

  const formik = useFormik({
    initialValues: {
      category_id: rowData?.id || "",
      business_category_id: rowData?.business_type?.category?.id || "",
      business_sub_category_id: rowData?.business_type?.id || "",
      product_category_name: rowData?.title || "",
      description: rowData?.description || "",
      image: null,
      meta_title: rowData?.meta_title || "",
      meta_description: rowData?.meta_description || "",
      meta_keyword: rowData?.meta_keyword || "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const reqbody = new FormData()
      reqbody.append("category_id", values.category_id)
      reqbody.append("business_sub_category_id", values.business_sub_category_id)
      reqbody.append("product_category_name", values.product_category_name)
      reqbody.append("description", values.description)
      reqbody.append("image", values.image || "")
      reqbody.append("meta_title", values.meta_title)
      reqbody.append("meta_description", values.meta_description)
      reqbody.append("meta_keyword", values.meta_keyword)
      rowData ? updateProductCategory(reqbody) : addProductCategory(reqbody)
    },
  })

  const businessSubTypes = () => {
    const reqbody = new FormData()
    reqbody.append("business_category_ids", formik.values.business_category_id)
    axiosInstance
      .post(`business-sub-category-list-data/`, reqbody)
      .then((response) => {
        setSubCategory(response?.data.business_sub_category_list)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    CategoryList()
  }, [])

  useEffect(() => {
    formik.values.business_category_id && businessSubTypes()
  }, [formik.values.business_category_id])

  const productCategories = () => {
    searchQuery === ""
      ? axiosInstance
          .get(`product-category-list-data/?page=${page}&business_sub_category_id&product_category_name=`)
          .then((response) => {
            setCurrentPage(response?.data?.current_page)
            setTotalPage(response?.data?.total_pages)
            setProductData(response.data.data)
          })
          .catch((error) => {})
      : axiosInstance
          .get(`category-search-api-for-admin/?search_value=${searchQuery}`)
          .then((response) => {
            setProductData(response.data.data)
            setCurrentPage(response?.data?.current_page)
            setTotalPage(response?.data?.total_pages)
          })
          .catch((error) => {})
  }

  useEffect(() => {
    productCategories()
  }, [page, searchQuery])

  const handleAllRowSelect = (event) => {
    setSelectedIds(event.target.checked ? productData.map((i) => i.id) : [])
  }

  const handleSelectRow = (id) => {
    setSelectedIds((prevSelectedRow) =>
      prevSelectedRow.includes(id) ? prevSelectedRow.filter((i) => i !== id) : [...prevSelectedRow, id]
    )
  }

  const handleDelete = () => {
    axios
      .post(
        `delete-category-api-for-superadmin/`,
        { category_ids: selectedIds },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        productCategories()
        setIsDelete(false)
        setSelectedIds([])
        toast.success(response.success.message)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const hadleClearAll = () => {
    setIsDelete(false)
    setSelectedIds([])
  }

  return (
    <>
      <div className="flex flex-col p-3">
        <p className="text-xl !text-black font font-bold">Product Categories</p>
        <p>Manage Product Categories List</p>
      </div>
      <div className="flex flex-col !rounded !text-black shadow-card shadow-zinc-600 m-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center p-2">
            <TextField
              placeholder="Search Business Type..."
              size="small"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
          </div>

          <div className="flex mx-3 text-red-500 gap-x-3">
            {selectedIds?.length !== 0 && (
              <>
                <Button
                  startIcon={<DeleteTwoTone />}
                  className="!text-white bg-gradient-to-r from-red-500 to-rose-500"
                  onClick={() => setIsDelete(true)}
                >
                  Delete
                </Button>
                <Dialog
                  open={isDelete}
                  onClose={() => setIsDelete(false)}
                  PaperProps={{ className: "!max-w-[500px] w-96" }}
                >
                  <div className="flex flex-col items-center gap-3 p-5">
                    <ReportTwoTone color="error" className="!text-5xl" />
                    <p className="text-xl">Are you sure!</p>
                    <p>You want to delete selected Categories?</p>
                  </div>
                  <span className="flex justify-end gap-3 pb-3 pr-3">
                    <MkxButton variant="outlined" color="inherit" onClick={hadleClearAll}>
                      Cancel
                    </MkxButton>
                    <MkxButton color="error" variant="contained" onClick={() => handleDelete()}>
                      Delete
                    </MkxButton>
                  </span>
                </Dialog>
              </>
            )}

            <Button variant="outlined" color="primary" onClick={handleOpen1}>
              <AddIcon /> Add Category
            </Button>
            <Modal open={open1} onClose={handleClose1}>
              <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{ ...style, width: 700 }}
                className="rounded !outline-none"
              >
                <p className="!text-black !underline !underline-offset-2 !font-bold pb-6 !text-center !text-xl">
                  {!rowData ? "Add Category" : "Update Category"}
                </p>

                <div className="grid grid-cols-2 gap-4">
                  <MkxSelect
                    id="business_category_id"
                    formik={formik}
                    label="Business Type"
                    isRequired
                    placeholder="Select Business Type"
                  >
                    {category?.map((res) => {
                      return <MenuItem value={res.id}>{res.name}</MenuItem>
                    })}
                  </MkxSelect>
                  <MkxSelect
                    id="business_sub_category_id"
                    formik={formik}
                    label="Business Sub Type"
                    isRequired
                    placeholder="Select Business Sub Type"
                  >
                    {subCategory?.map((res) => {
                      return <MenuItem value={res.id}>{res.name}</MenuItem>
                    })}
                  </MkxSelect>

                  <MkxInput
                    id="product_category_name"
                    formik={formik}
                    isRequired
                    label="Product Category Name"
                    placeholder="Enter Product Category Name"
                  />
                  <MkxInput
                    id="image"
                    type="file"
                    label="Product Category Image"
                    onChange={(event) => formik.setFieldValue("image", event.target.files[0])}
                  />
                  <MkxInput id="description" formik={formik} label="Description" placeholder="Enter Description" />
                  <MkxInput id="meta_title" formik={formik} label="Meta Title" placeholder="Enter Meta Title" />
                  <MkxInput
                    id="meta_description"
                    formik={formik}
                    label="Meta Description"
                    placeholder="Enter Meta Description"
                  />
                  <MkxInput id="meta_keyword" formik={formik} label="Meta Keyword" placeholder="Enter Meta Keyword" />
                </div>

                <div className="flex justify-end gap-4 py-4">
                  <MkxButton onClick={handleClose1} color="inherit">
                    CANCEL
                  </MkxButton>

                  <MkxButton type="submit" color="success">
                    {!rowData ? "ADD" : "UPDATE"}
                  </MkxButton>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
        <Divider />
        <TableContainer component={Paper} className="max-h-[60vh]">
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead className="!bg-gray-200">
              <TableRow>
                <TableCell className="!text-center !text-gray-800 !font-bold">
                  <Checkbox onChange={handleAllRowSelect} />
                </TableCell>
                <TableCell className="!text-center !text-gray-800 !font-bold">CATEGORY NAME</TableCell>
                <TableCell className="!text-center !text-gray-800 !font-bold">IMAGE</TableCell>
                <TableCell className="!text-center !text-gray-800 !font-bold">BUSINESS TYPE</TableCell>
                <TableCell className="!text-center !text-gray-800 !font-bold">SUB BUSINESS TYPE</TableCell>
                <TableCell className="!text-center !text-gray-800 !font-bold">ACTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productData &&
                productData?.map((row, index) => (
                  <TableRow key={row.id} className="!text-slate-700">
                    <TableCell className="!text-center !text-[12px]">
                      <Checkbox
                        value={row?.id}
                        checked={selectedIds.filter((id) => id === row?.id)?.length === 1}
                        onChange={() => handleSelectRow(row.id)}
                      />
                    </TableCell>
                    <TableCell className="!text-center !text-[12px]">{row.title ? row.title : "--"}</TableCell>
                    <TableCell className="!text-center !text-[12px]">
                      {row?.image ? <img src={row?.image} className="mx-auto h-7 w-7" /> : "--"}
                    </TableCell>
                    <TableCell className="!text-center !text-[12px]">{row?.business_type?.category?.name}</TableCell>
                    <TableCell className="!text-center !text-[12px]">{row?.business_type?.name}</TableCell>
                    <TableCell className="!text-center !text-[12px]">
                      <IconButton
                        onClick={() => {
                          setRowData(row)
                          handleOpen1()
                        }}
                      >
                        <EditIcon color="success" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
        <div
          className="!float-right relative bg-white w-[100%] mx-auto bottom-0 right-0 py-3 !flex-col !justify-end !items-center"
          style={{ marginRight: "0px" }}
        >
          <button onClick={nextData} className="!float-right">
            {currentPage} ... {totalPage}
            <ArrowForwardIosIcon />
          </button>
          <button onClick={previousData} className="float-right">
            <ArrowBackIosIcon />
          </button>
        </div>
      </div>
    </>
  )
}

export default ProductCategory
