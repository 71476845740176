import { FormControl, FormControlLabel, MenuItem, Radio, RadioGroup } from "@mui/material"
import { DataSelect } from "Helper/Settings"
import { addOffersFn, offerDataFn, updateOffersFn } from "Services/Settings/Offers"
import { useFormik } from "formik"
import moment from "moment"
import { useEffect } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import CustomSelect from "shared/MkxSelect"

const AddOffers = ({ setValue, selected, setSelected }) => {
  const { store_id } = useParams()
  const client = useQueryClient()
  const { mutate: addOffers, isLoading: isLoadingAdding } = useMutation(addOffersFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        setValue("Offers")
        client.refetchQueries("offersManagement")
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const { mutate: updateOffers, isLoading: isLoadingUpdating } = useMutation(updateOffersFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        setValue("Offers")
        setSelected(null)
        client.refetchQueries("offersManagement")
      } else {
        toast.error(response.data.message)
      }
    },
  })

  const initialValues = {
    valid_from: selected?.valid_from
      ? moment(selected?.valid_from, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss")
      : moment(new Date()).format("YYYY-MM-DDThh:mm:ss"),
    valid_until: selected?.valid_until
      ? moment(selected?.valid_until, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss")
      : moment(new Date()).format("YYYY-MM-DDThh:mm:ss"),
    offer_type: selected?.offer_type || "",
    discount_unit: selected?.discount_unit || "",
    discount_value: selected?.discount_value || "",
    offer_tag: selected?.offer_tag || "",
    offer_product_id: selected?.offer_product_id || "",
    coupon_code: selected?.coupon_code || "",
    category_id: selected?.category_id || "",
    title: selected?.category_name || selected?.product_name || selected?.service_name || "",
    product_id: selected?.product_id || "",
    service_id: selected?.service_id || "",
    min_order_amount: selected?.min_order_amount || "",
    term_condition: selected?.term_condition || "",
    offer_on: selected?.offer_through?.toLowerCase() || "category",
    store_id,
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      selected ? updateOffers({ offer_id: selected.id, ...formik.values }) : addOffers({ ...formik.values })
    },
  })

  const { data: productList, isLoading: isLoadingProducts } = useQuery(["offerDataProduct"], () =>
    offerDataFn({ store_id, data_type: "product" })
  )

  useEffect(() => {
    formik.setFieldValue("category_id", "")
    formik.setFieldValue("product_id", "")
    formik.setFieldValue("service_id", "")
  }, [formik.values.offer_on])

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-3 gap-5 p-5">
        <FormControl>
          <p className="m-1 font-semibold whitespace-nowrap">
            Offer On<span className="text-red-800">*</span>
          </p>
          <RadioGroup
            row
            name="offer_on"
            value={formik.values.offer_on === "coupon" ? "category" : formik.values.offer_on}
            onChange={formik.handleChange}
            className="!bg-white focus-within:!border-[#0000ff] border-gray-950 !border !border-opacity-20 !bg-opacity-20 !rounded !px-3"
          >
            <FormControlLabel value="category" control={<Radio size="small" />} label="Category" />
            <FormControlLabel value="product" control={<Radio size="small" />} label="Product" />
            <FormControlLabel value="service" control={<Radio size="small" />} label="Service" />
          </RadioGroup>
        </FormControl>

        {formik.values.offer_type !== "coupon" && formik.values.offer_type !== "buyOneGetOne" && (
          <DataSelect formik={formik} />
        )}

        <CustomSelect id="offer_type" isRequired label="Offer Type" placeholder="Select Offer Type" formik={formik}>
          <MenuItem value={"discount"}>Discount</MenuItem>
          <MenuItem value={"buyOneGetOne"}>Buy One Get One</MenuItem>
          <MenuItem value={"coupon"}>Coupon</MenuItem>
          <MenuItem value={"cashback"}>Cashback</MenuItem>
        </CustomSelect>
        <CustomInput id="offer_tag" label="Offer Tag" isRequired placeholder="Enter Offer Tag" formik={formik} />
        {formik.values.offer_type === "coupon" && (
          <>
            <CustomInput
              id="min_order_amount"
              label="Min Order Amount"
              isRequired
              placeholder="Enter Min Order Amount"
              formik={formik}
            />
            <CustomInput
              id="coupon_code"
              label="Coupon Code"
              isRequired
              placeholder="Enter Coupon Code"
              formik={formik}
            />
          </>
        )}
        {formik.values.offer_type === "buyOneGetOne" && (
          <CustomSelect
            id="offer_product_id"
            isRequired
            label="Offer Product"
            placeholder="Select Offer Product"
            formik={formik}
            isLoading={isLoadingProducts}
          >
            {productList?.data?.data?.map((i) => {
              return <MenuItem value={i.id}>{i.title}</MenuItem>
            })}
          </CustomSelect>
        )}
        <CustomSelect
          id="discount_unit"
          label="Discount Type"
          isRequired
          placeholder="Select Discount Type"
          formik={formik}
        >
          <MenuItem value="Percentage">Percentage</MenuItem>
          <MenuItem value="Amount">Amount</MenuItem>
        </CustomSelect>
        <CustomInput
          id="discount_value"
          isRequired
          label="Discount Value"
          placeholder="Enter Discount Value"
          formik={formik}
        />

        <CustomInput
          type="datetime-local"
          id="valid_from"
          label="Valid From"
          isRequired
          placeholder="Enter Valid From"
          formik={formik}
        />
        <CustomInput
          type="datetime-local"
          id="valid_until"
          isRequired
          label="Valid Until"
          placeholder="Enter Valid Until"
          formik={formik}
        />
      </div>
      <div className="grid grid-cols-2 gap-5 p-3">
        <CustomInput
          id="term_condition"
          label="Terms And Conditions"
          rows={4}
          isRequired
          multiline
          placeholder="Enter Terms And Conditions"
          formik={formik}
        />
      </div>
      <div className="flex items-center justify-end px-5 py-2">
        <CustomButton isLoading={isLoadingAdding || isLoadingUpdating} type="submit" className="!w-40">
          Submit
        </CustomButton>
      </div>
    </form>
  )
}

export default AddOffers
