import { DeleteTwoTone, Edit, ReportTwoTone } from "@mui/icons-material"
import {
  Button,
  Checkbox,
  Dialog,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { departmentFn } from "Services/Store/StoreDepartment"
import axiosInstance from "config/axios"
import { useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import GlassDiv from "shared/CustomDiv"
import CustomButton from "shared/MkxButton"
import CustomInput from "shared/MkxInput"
import MkxPagination from "shared/MkxTable/MkxPagination"
import MkxTableCell from "shared/MkxTable/MkxTableCell"
import NoDataFound from "shared/NoDataFound"
import AddDepartment from "./AddDepartment"
import { CustomIconButton } from "shared/CustomIconButton"

const Department = () => {
  const { store_id } = useParams()
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const [selectedIds, setSelectedIds] = useState([])
  const [isDelete, setIsDelete] = useState(false)
  const [selectDept, setSelectDept] = useState({})
  const handleChange = (event, value) => {
    setPage(value)
  }

  const { data: departmentsData, isLoading: isLoading } = useQuery(["departmentsMain", search, page], () =>
    departmentFn({ store_id, page, search })
  )
  const departments = departmentsData?.data?.data

  const client = useQueryClient()

  const handleSelectRow = (id) => {
    setSelectedIds((prevSelectedRow) =>
      prevSelectedRow.includes(id) ? prevSelectedRow.filter((i) => i !== id) : [...prevSelectedRow, id]
    )
  }
  const handleAllRowSelect = (event) => {
    setSelectedIds(event.target.checked ? departments?.map((i) => i.dept_id) : [])
  }

  const handleDelete = () => {
    axiosInstance
      .delete(`department-settings-api-of-superadmin/`, { data: { store_id, department_ids: selectedIds } })
      .then((response) => {
        if (response.data.response_code === 200) {
          toast.success(response.data.message)
          setIsDelete(false)
          setSelectedIds([])
          client.refetchQueries("departmentsMain")
        }
      })
  }
  return (
    <GlassDiv className="flex flex-col !p-0">
      <div className="flex items-center justify-between p-2">
        <CustomInput
          value={search}
          type="search"
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Search Department"
        />
        <div className="flex items-center gap-4">
          {selectedIds?.length !== 0 && (
            <>
              <CustomButton
                startIcon={<DeleteTwoTone />}
                className="!text-red-400 hover:!text-red-500"
                onClick={() => setIsDelete(true)}
              >
                Delete Selected
              </CustomButton>
              <Dialog
                open={isDelete}
                onClose={() => setIsDelete(false)}
                PaperProps={{ className: "!max-w-[500px] w-96" }}
              >
                <div className="flex flex-col items-center gap-3 p-5">
                  <ReportTwoTone color="error" className="!text-5xl" />
                  <p className="text-xl">Are you sure!</p>
                  <p>You want to delete selected items?</p>
                </div>
                <span className="flex justify-end gap-3 pb-3 pr-3">
                  <Button
                    size="small"
                    variant="outlined"
                    color="inherit"
                    onClick={() => setIsDelete(false)}
                    className="!capitalize"
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    disableElevation
                    color="error"
                    className="!capitalize"
                    variant="contained"
                    onClick={() => handleDelete()}
                  >
                    Delete
                  </Button>
                </span>
              </Dialog>
            </>
          )}

          <AddDepartment selectDept={selectDept} setSelectDept={setSelectDept} />
        </div>
      </div>

      <TableContainer>
        <Table className="border">
          <TableHead>
            <TableRow>
              <MkxTableCell isHead>
                <Checkbox size="small" onChange={handleAllRowSelect} />
              </MkxTableCell>
              <MkxTableCell isHead>Department name</MkxTableCell>
              <MkxTableCell isHead>Action</MkxTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading
              ? [1, 2, 3, 4, 5]?.map(() => {
                  return (
                    <TableRow>
                      <MkxTableCell>
                        <Skeleton />
                      </MkxTableCell>
                      <MkxTableCell>
                        <Skeleton />
                      </MkxTableCell>
                      <MkxTableCell>
                        <Skeleton />
                      </MkxTableCell>
                    </TableRow>
                  )
                })
              : departments?.map((item) => (
                  <TableRow>
                    <MkxTableCell>
                      <Checkbox
                        size="small"
                        checked={selectedIds.filter((id) => id === item.dept_id)?.length === 1 ? true : false}
                        className="!p-0"
                        onChange={() => handleSelectRow(item.dept_id)}
                      />
                    </MkxTableCell>
                    <MkxTableCell>{item.dept_name}</MkxTableCell>
                    <MkxTableCell>
                      <CustomIconButton color="success" onClick={() => setSelectDept(item)}>
                        <Edit />
                      </CustomIconButton>
                    </MkxTableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>

      <MkxPagination
        page={departmentsData?.data?.current_page}
        count={departmentsData?.data?.total_pages}
        onChange={handleChange}
      />
      <NoDataFound data={departmentsData} />
    </GlassDiv>
  )
}

export default Department
