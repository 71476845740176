import { Checkbox, Divider } from "@mui/material"
import axiosInstance from "config/axios"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import CustomDiv from "shared/CustomDiv"

const ViewRole = () => {
  const { name } = useParams()
  const [permData, setPermData] = useState([])
  const [searchQuery] = useState("")

  const viewList = () =>
    axiosInstance.get(`/store-role-permissions-settings-api-of-superadmin/?role_name=${name}`).then((res) => {
      setPermData(res?.data?.data)
    })

  useEffect(() => {
    viewList()
  }, [name])

  return (
    <>
      <div className="flex flex-col w-full h-full gap-1 p-1 bg-gray-200">
        <CustomDiv className="flex items-center justify-between">
          <div className="flex items-center gap-2 font-bold">
            <span> Role : </span>
            <span className="text-blue-500">{name}</span>
          </div>
        </CustomDiv>

        <div className="grid grid-cols-2 gap-5">
          {permData?.map((row) => {
            return (
              <CustomDiv className="!p-0">
                <div className="rounded">
                  <div className="p-2">{row?.module_name}</div>
                  <Divider />
                  <div className="grid grid-cols-2 gap-1 p-2 overflow-auto h-60">
                    {row?.permission
                      ?.filter((perm) => perm?.name.toLowerCase().includes(searchQuery))
                      ?.map((perm) => (
                        <div key={perm?.id} className="flex gap-2">
                          <Checkbox size="small" checked className="!p-0" />
                          <span className="text-sm capitalize">{perm?.name.replaceAll("_", " ")}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </CustomDiv>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ViewRole
