import { IconButton, Skeleton, TableContainer, TableHead, TableRow } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import moment from "moment"
import React, { useState } from "react"
import { useQuery } from "react-query"
import { storeLicenseFn } from "Services/Business/Store"
import MkxInput from "../../../shared/MkxInput"
import MkxPagination from "../../../shared/MkxTable/MkxPagination"
import MkxTableCell from "../../../shared/MkxTable/MkxTableCell"
import UpgradePlan from "./UpgradePlan"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import CopyToClipboard from "react-copy-to-clipboard"
import { toast } from "react-toastify"

const StoreLicense = () => {
  const [searchQuery, setSearchQuery] = useState("")
  const [page, setPage] = useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  }

  const { data: stores, isLoading } = useQuery(
    ["storeLicense", searchQuery, page],
    () => storeLicenseFn({ page, search: searchQuery }),
    { refetchOnWindowFocus: false }
  )

  return (
    <>
      <div className="!bg-white">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font font-bold !text-black">Business License</p>
            <p className="!text-black">View Business License</p>
          </div>
        </div>
        <div className="flex flex-col !mt-3 !rounded bg-dark border-secondary shadow-card shadow-zinc-600 m-2 ">
          <div className="flex items-center p-2 h-14 border-zinc-600">
            <MkxInput placeholder="Search Stores..." onChange={(event) => setSearchQuery(event.target.value)} />
          </div>

          <TableContainer>
            <Table size="small">
              <TableHead className="!bg-gray-200">
                <TableRow>
                  <MkxTableCell isHead>Store Name</MkxTableCell>
                  <MkxTableCell isHead>License</MkxTableCell>
                  <MkxTableCell isHead>License Date</MkxTableCell>
                  <MkxTableCell isHead>License Expiry</MkxTableCell>
                  <MkxTableCell isHead>Employee</MkxTableCell>
                  <MkxTableCell isHead>Plan</MkxTableCell>
                  <MkxTableCell isHead>Remaining Days</MkxTableCell>
                  <MkxTableCell isHead>Token</MkxTableCell>
                  <MkxTableCell isHead>Upgrade Plan</MkxTableCell>
                </TableRow>
              </TableHead>
              <TableBody className="!overflow-y-auto">
                {isLoading
                  ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(() => {
                      return (
                        <TableRow>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => {
                            return (
                              <MkxTableCell>
                                <Skeleton />
                              </MkxTableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })
                  : stores?.data?.data?.map((store) => (
                      <TableRow key={store?.id}>
                        <MkxTableCell>{store?.store_name || "-"}</MkxTableCell>
                        <MkxTableCell>{store?.license || "-"}</MkxTableCell>
                        <MkxTableCell>{moment(store?.license_date).format("l")}</MkxTableCell>
                        <MkxTableCell>{moment(store?.license_expiry_date).format("l")}</MkxTableCell>
                        <MkxTableCell>{store?.employees || "-"}</MkxTableCell>
                        <MkxTableCell>{store?.plan || "-"}</MkxTableCell>
                        <MkxTableCell>{store?.remaining_days || "-"}</MkxTableCell>
                        <MkxTableCell>
                          <CopyToClipboard
                            text={store?.authorization_token}
                            onCopy={() => toast.success("Token copied to clipboard")}
                          >
                            <IconButton size="small" color="primary">
                              <ContentCopyIcon fontSize="small" />
                            </IconButton>
                          </CopyToClipboard>
                        </MkxTableCell>
                        <MkxTableCell>
                          <UpgradePlan store={store} />
                        </MkxTableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <MkxPagination count={stores?.data?.total_pages} page={stores?.data?.current_page} onChange={handleChange} />
        </div>
      </div>
    </>
  )
}

export default StoreLicense
