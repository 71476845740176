import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";

const MkxInput = ({
  type = "",
  value,
  onChange,
  placeholder = "",
  label = "",
  className = "",
  id = "",
  disabled,
  hidden,
  formik,
  multiline = false,
  variant = "outlined",
  rows = 1,
  size = "small",
  InputProps,
  accept,
  readOnly = false,
  onFocus,
  multiple = false,
  onBlur,
  isRequired = false,
  ...rest
}) => {
  const [isVisible, setIsVisible] = useState({ [id]: false });
  const selectedValue = formik?.values[id] || value;
  const handleChange = formik?.handleChange || onChange;

  return (
    <div className="flex flex-col justify-center">
      {label && (
        <p className="m-1 font-semibold whitespace-nowrap">
          {label}
          {isRequired && <span className="text-red-800">*</span>}
        </p>
      )}
      <TextField
        id={id}
        name={id}
        type={type !== "password" ? type : isVisible[id] ? "text" : "password"}
        disabled={disabled}
        hidden={hidden}
        size={size}
        inputProps={{
          className: type === "file" && "!pt-[6px] !pl-2 !pb-[12px]",
        }}
        multiple={multiple}
        InputProps={
          type !== "password"
            ? InputProps
            : {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => setIsVisible({ [id]: !isVisible[id] })}
                    >
                      {isVisible[id] ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }
        }
        variant={variant}
        onFocus={onFocus}
        multiline={multiline}
        rows={rows}
        error={formik?.errors?.[id] && formik?.touched?.[id] ? true : false}
        onBlur={onBlur || formik?.handleBlur}
        value={selectedValue}
        accept={accept}
        onChange={handleChange}
        placeholder={placeholder}
        readOnly={readOnly}
        className={classNames(
          "!outline-none placeholder:!text-gray-100 !capitalize !bg-white !bg-opacity-20 !border-opacity-30 !border !border-white",
          className
        )}
        {...rest}
      />
      {formik?.touched?.[id] && formik?.errors?.[id] && (
        <FormHelperText className="!text-red-500 !mx-1">
          {formik?.touched?.[id] && formik?.errors?.[id]}
        </FormHelperText>
      )}
    </div>
  );
};

export default MkxInput;
