import axios from "axios"

/**
 * Create an instance of axios with a base URL
 * @type {import("axios").AxiosInstance}
 */
// const baseURL = "https://erp.aaragroups.com/"
// const baseURL = "http://192.168.1.71:9093/"
const baseURL = "https://erpstaging.aaragroups.com/"


const axiosInstance = axios.create({ baseURL })

/**
 * Add authorization token to the request headers
 * @param {import("axios").AxiosRequestConfig} config - Axios request config
 * @returns {import("axios").AxiosRequestConfig} Modified Axios request config
 */

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token")
    config.headers = { Authorization: token, ...config.headers }
    return config
  },
  (err) => Promise.reject(err)
)

export default axiosInstance
