import React, { useEffect, useState } from "react"
import axios from "config/axios"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { useNavigate } from "react-router-dom"

const Dashboard = () => {
  const [orderData, setOrderData] = useState([])
  const [timePeriod, setTimePeriod] = useState("last_7_days")
  const navigation = useNavigate()

  useEffect(() => {
    axios
      .post(`dashboard-section-date-filter-data/`, { time_period: timePeriod })
      .then((response) => {
        setOrderData(response?.data?.data)
        console.log("dashboard count==>", response)
      })
      .catch((error) => {
        console.log("error page", error.response)
        console.log(error, "dashboard counter Errror.....!")
      })
  }, [timePeriod])

  return (
    <>
      <div className="flex justify-between item-center w-[100%]">
        <div className="text-xl font-bold !text-gray-600 p-3">Recent Activity</div>
        <div></div>
      </div>

      <div
        className=" !border-gray-300 drop-shadow-lg bg-white  mx-auto rounded  w-[97%]"
        style={{ border: "1px solid gray" }}
      >
        <div className="flex items-center justify-between ">
          <div className="px-4 font-serif font-bold text-gray-600">ALL ORDER STATUS</div>
          <div className="mt-2 mr-2">
            <FormControl size="small">
              <Select
                // labelId="demo-select-small-label"
                // id="demo-select-small"
                // label="Age"
                className="!h-8 !w-44"
                // defaultValue="last_7_days"
                value={timePeriod}
                // size="small"
                onChange={(event) => setTimePeriod(event.target.value)}
              >
                <MenuItem size="small" className="text-sm" value="last_7_days">
                  LAST 7 DAYS
                </MenuItem>
                <MenuItem className="text-sm" value="today">
                  TODAY
                </MenuItem>
                <MenuItem className="text-sm" value="yesterday">
                  YESTERDAY
                </MenuItem>

                <MenuItem className="text-sm" value="last_15_days">
                  LAST 15 DAYS
                </MenuItem>
                <MenuItem className="text-sm" value="last_30_days">
                  LAST 30 DAYS
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="flex gap-4 p-3">
          <div
            className="flex gap-x-10 justify-center items-center !h-36 w-44  rounded p-3 shadow-sm shadow-blue-500"
            onClick={() => navigation("/order-management", { state: { status: "Pending" } })}
          >
            <div className="gap-y-6">
              <p className="text-xl font-bold text-gray-700 "> Pending</p>
              <p className="py-5 text-center text-gray-900 ">
                {orderData[0]?.total_shipped_sales_order ? orderData[0]?.total_shipped_sales_order : "0"}
              </p>
            </div>
          </div>
          <div
            className="flex gap-x-10 justify-center items-center !h-36 w-44  rounded p-3 shadow-sm shadow-sky-500"
            onClick={() => navigation("/order-management", { state: { status: "Shipped" } })}
          >
            <div className="gap-y-6">
              <p className="text-xl font-bold text-gray-700 "> Shipped</p>
              <p className="py-5 text-center text-gray-900 ">
                {orderData[0]?.total_pending_sales_order ? orderData[0]?.total_pending_sales_order : "0"}
              </p>
            </div>
          </div>
          <div
            className="flex gap-x-10 justify-center items-center !h-36 w-44  rounded p-3 shadow-sm shadow-green-500"
            onClick={() =>
              navigation("/order-management", {
                state: { status: "Delivered" },
              })
            }
          >
            <div className="gap-y-6">
              <p className="text-xl font-bold text-gray-700 ">Delivered</p>
              <p className="py-5 text-center text-gray-900 ">
                {orderData[0]?.total_delivered_sales_order ? orderData[0]?.total_delivered_sales_order : "0"}
              </p>
            </div>
          </div>
          <div
            className="flex gap-x-10 justify-center items-center !h-36 w-44  rounded p-3 shadow-sm shadow-gray-500"
            onClick={() => navigation("/order-management", { state: { status: "Accepted" } })}
          >
            <div className="gap-y-6">
              <p className="text-xl font-bold text-gray-700 "> Accepted</p>
              <p className="py-5 text-center text-gray-900 ">
                {orderData[0]?.total_accepted_sales_order ? orderData[0]?.total_accepted_sales_order : "0"}
              </p>
            </div>
          </div>
          <div
            className="flex gap-x-10 justify-center items-center !h-36 w-44  rounded p-3 shadow-sm shadow-purple-500"
            onClick={() => navigation("/order-management", { state: { status: "Assigned" } })}
          >
            <div className="gap-y-6">
              <p className="text-xl font-bold text-gray-700 "> Assigned</p>
              <p className="py-5 text-center text-gray-900 ">
                {orderData[0]?.total_assigned_sales_order ? orderData[0]?.total_assigned_sales_order : "0"}
              </p>
            </div>
          </div>
        </div>
        <div className="flex gap-4 p-3">
          <div
            className="flex gap-x-10 justify-center items-center !h-36 w-44  rounded p-3 shadow-sm shadow-lime-500"
            onClick={() =>
              navigation("/order-management", {
                state: { status: "Cancelled" },
              })
            }
          >
            <div className="gap-y-6">
              <p className="text-xl font-bold text-gray-700 "> Cancelled</p>
              <p className="py-5 text-center text-gray-900 ">
                {orderData[0]?.total_canceled_sales_order ? orderData[0]?.total_canceled_sales_order : "0"}
              </p>
            </div>
          </div>
          <div
            className="flex gap-x-10 justify-center items-center !h-36 w-44  rounded p-3 shadow-sm shadow-pink-500"
            onClick={() => navigation("/order-management", { state: { status: "Returned" } })}
          >
            <div className="gap-y-6">
              <p className="text-xl font-bold text-gray-700 "> Returned</p>
              <p className="py-5 text-center text-gray-900 ">
                {orderData[0]?.total_returned_sales_order ? orderData[0]?.total_returned_sales_order : "0"}
              </p>
            </div>
          </div>
          <div
            className="flex gap-x-10 justify-center items-center !h-36 w-44  rounded p-3 shadow-sm shadow-blue-500"
            onClick={() => navigation("/order-management", { state: { status: "Refunded" } })}
          >
            <div className="gap-y-6">
              <p className="text-xl font-bold text-gray-700 "> Refund</p>
              <p className="py-5 text-center text-gray-900 ">
                {orderData[0]?.total_refunded_sales_order ? orderData[0]?.total_refunded_sales_order : "0"}
              </p>
            </div>
          </div>
          <div
            className="flex gap-x-10 justify-center items-center !h-36 w-44  rounded p-3 shadow-sm shadow-yellow-500"
            onClick={() => navigation("/order-management", { state: { status: "Drafts" } })}
          >
            <div className="gap-y-6">
              <p className="text-xl font-bold text-gray-700 "> Drafts</p>
              <p className="py-5 text-center text-gray-900 ">
                {orderData[0]?.total_drafts_sales_order ? orderData[0]?.total_drafts_sales_order : "0"}
              </p>
            </div>
          </div>
          <div
            className="flex gap-x-10 justify-center items-center !h-36 w-44  rounded p-3 shadow-sm shadow-pink-500"
            onClick={() => navigation("/order-management", { state: { status: "Rejected" } })}
          >
            <div className="gap-y-6">
              <p className="text-xl font-bold text-gray-700 "> Rejected</p>
              <p className="py-5 text-center text-gray-900 ">
                {orderData[0]?.total_rejected_sales_order ? orderData[0]?.total_rejected_sales_order : "0"}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="flex mt-5 ml-3 gap-x-4">
        <div className="flex gap-x-10 justify-center items-center !h-36 w-44  rounded p-3 shadow-sm shadow-blue-500">
          <div className="gap-y-6">
            <p className="text-xl font-bold text-gray-700 ">Total Visitors</p>
            <p className="py-5 text-center text-gray-300 ">6543</p>
          </div>
        </div>
        <div className="flex gap-x-10 justify-center items-center !h-36 w-44  rounded p-3 shadow-sm shadow-red-500">
          <div className="gap-y-6">
            <p className="pt-3 text-xl font-bold text-gray-700">Total Store</p>
            <p className="py-5 ">
              <div className="text-black">
                <span className="text-gray-700">Active</span> :{" "}
                {activeObjectCount}
              </div>
              <div className="text-black">
                <span className="text-gray-700">Deactive</span> :{" "}
                {deActiveObjectCount}
              </div>
            </p>
          </div>
        </div>
        <div className="flex gap-x-10 justify-center items-center !h-36 w-44  rounded p-3 shadow-sm shadow-pink-500">
            <div className="gap-y-6">
              <p className="text-xl font-bold text-gray-700 ">
                Total Reseller
              </p>
              <p className="py-5 text-center text-gray-900 ">
                {orderData[0]?.total_reseller_count}
              </p>
            </div>
          </div>
          <div className="flex gap-x-10 justify-center items-center !h-36 w-44  rounded p-3 shadow-sm shadow-gray-500">
            <div className="gap-y-6">
              <p className="text-xl font-bold text-gray-700 ">Total Wallet</p>
              <p className="py-5 text-center text-gray-900 ">
                {orderData[0]?.total_wallet_count}
              </p>
            </div>
          </div>
      </div> */}
    </>
  )
}

export default Dashboard
