import { CircularProgress, Dialog } from "@mui/material"
import React from "react"
import "./index.css"

const Loader = ({ color = "#020073" }) => {
  return (
    <div className="loading-wave">
      <div style={{ backgroundColor: color }} className="loading-bar"></div>
      <div style={{ backgroundColor: color }} className="loading-bar"></div>
      <div style={{ backgroundColor: color }} className="loading-bar"></div>
      <div style={{ backgroundColor: color }} className="loading-bar"></div>
    </div>
  )
}

export default Loader

export const CustomLoader = ({ isLoading = false, title = "LOADING" }) => {
  return (
    <Dialog open={isLoading} PaperProps={{ elevation: 1 }}>
      <div className="flex items-center justify-center h-20 gap-2 p-2 min-w-72">
        <p className="text-xl">{title}</p>
        <CircularProgress size={25} thickness={5} />
      </div>
    </Dialog>
  )
}
