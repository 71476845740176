import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Divider, Tab } from "@mui/material"
import { useState } from "react"
import OtherSync from "./OtherSync"
import RealSync from "./RealSync"
import Woocommerce from "./Woocommerce"

const Sync = () => {
  const [value, setValue] = useState("Website Sync")
  const handleChange = (_, value) => setValue(value)
  const options = [{ name: "Website Sync" }, { name: "Realtime Sync" }, { name: "Woocommerce" }]
  return (
    <TabContext value={value}>
      <Box className="bg-white bg-opacity-30">
        <TabList onChange={handleChange}>
          {options.map((button) => {
            return <Tab className="!capitalize !font-semibold" label={button.name} value={button.name} />
          })}
        </TabList>
      </Box>
      <Divider />
      <TabPanel className="!p-0" value="Website Sync">
        <OtherSync />
      </TabPanel>
      <TabPanel className="!p-0" value="Realtime Sync">
        <RealSync />
      </TabPanel>
      <TabPanel className="!p-0" value="Woocommerce">
        <Woocommerce />
      </TabPanel>
    </TabContext>
  )
}

export default Sync
