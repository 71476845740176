import axiosInstance from "config/axios"

export const loyalityPointSettingFn = (reqBody) => {
  try {
    const response = axiosInstance.get(`loyalty-point-setup-api-of-superadmin/`, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const postloyalityPointSettingFn = (reqBody) => {
  try {
    const response = axiosInstance.post(`loyalty-point-setup-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateloyalityPointSettingFn = (reqBody) => {
  try {
    const response = axiosInstance.put(`loyalty-point-setup-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const deletePointSettingFn = (reqBody) => {
  try {
    const response = axiosInstance.delete(`loyalty-point-setup-api-of-superadmin/`, {
      data: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const changeLoyalityPointStatusFn = (reqBody) => {
  try {
    const response = axiosInstance.patch(`loyalty-point-setup-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
