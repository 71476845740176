import axiosInstance from "config/axios"

export const deliverySettingsFn = (reqBody) => {
  try {
    const response = axiosInstance.get(`delivery-settings-api-of-superadmin/`, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const postDeliverySettingsFn = (reqBody) => {
  try {
    const response = axiosInstance.post(`delivery-settings-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const addShipRocketConfigFn = (reqBody) => {
  try {
    const response = axiosInstance.post(`shiprocket-settings-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateShipRocketConfigFn = (reqBody) => {
  try {
    const response = axiosInstance.put(`shiprocket-settings-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const getShipRocketConfigFn = (reqBody) => {
  try {
    const response = axiosInstance.get(`shiprocket-settings-api-of-superadmin/`, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const changeShipRocketConfigFn = (reqBody) => {
  try {
    const response = axiosInstance.patch(`shiprocket-settings-api-of-superadmin/`, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
