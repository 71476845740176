import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Divider, Tab } from "@mui/material"
import { useState } from "react"
import Department from "./Department"
import Role from "./Role"

const DepartmentRole = () => {
  const [value, setValue] = useState("Department")
  const buttons = [{ name: "Department" }, { name: "Role" }]

  const handleChange = (_, value) => setValue(value)

  return (
    <TabContext value={value}>
      <Box className="bg-white bg-opacity-30">
        <TabList onChange={handleChange}>
          {buttons.map((button) => {
            return <Tab className="!capitalize !font-semibold" label={button.name} value={button.name} />
          })}
        </TabList>
      </Box>
      <Divider />
      <TabPanel className="!p-0" value="Department">
        <Department />
      </TabPanel>
      <TabPanel className="!p-0" value="Role">
        <Role />
      </TabPanel>
    </TabContext>
  )
}

export default DepartmentRole
