import React from "react"
import CustomModal from "shared/MkxModal"
import { NotifictaionFn } from "Services/PushNotification"
import { useQuery } from "react-query"
import { CircularProgress } from "@mui/material"

const ViewConfig = ({ config, setConfig }) => {
  const { data: configsData, isLoading } = useQuery(
    ["notifictaionsId", config],
    () => NotifictaionFn({ firebase_id: config }),
    { refetchOnWindowFocus: false }
  )

  const configs = configsData?.data?.data

  const handleClose = () => {
    setConfig(null)
  }

  return (
    <>
      <CustomModal
        open={Boolean(config)}
        onClose={handleClose}
        padding={0}
        title="Config Detail"
        className="overflow-y-auto !w-auto max-h-[80%]"
      >
        {isLoading ? (
          <div className="flex items-center justify-center h-80">
            <CircularProgress />
          </div>
        ) : (
          <table className="w-full">
            <tbody>
              {configs?.firebase_service_account &&
                Object.entries(configs.firebase_service_account).map(([key, value]) => {
                  if (key !== "private_key")
                    return (
                      <tr className="flex w-full p-2 border-b border-black border-opacity-10" key={key}>
                        <td className="flex flex-col w-1/3 gap-1">
                          <p className="font-semibold capitalize">{key.replaceAll("_", " ")} :</p>
                        </td>
                        <td className="flex flex-col w-2/3 gap-1">
                          <p>{value}</p>
                        </td>
                      </tr>
                    )
                })}
            </tbody>
          </table>
        )}
      </CustomModal>
    </>
  )
}

export default ViewConfig
