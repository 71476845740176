import { Circle } from "@mui/icons-material"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import React from "react"
import CustomButton from "shared/MkxButton"
import GlassDiv from "shared/CustomDiv"
import RequestInventory from "../RequestInventory"

const RequestedInventory = ({ value, data, refetch }) => {
  return (
    <>
      <GlassDiv className="!p-0 !rounded">
        <div className="flex items-center justify-end p-2">
          <RequestInventory value={value} refetch={refetch} />
        </div>
        <TableContainer component="div">
          <Table className="bg-white whitespace-nowrap bg-opacity-20 !overflow-x-scroll">
            <TableHead className="!bg-white !bg-opacity-30">
              <TableRow>
                <TableCell className="border-r !font-bold !text-center !border-white !border-opacity-50">
                  Sr. No.
                </TableCell>
                <TableCell className="border-r !font-bold !text-center !border-white !border-opacity-50">
                  Title
                </TableCell>
                <TableCell className="border-r !font-bold !text-center !border-white !border-opacity-50">
                  Category
                </TableCell>
                <TableCell className="border-r !font-bold !text-center !border-white !border-opacity-50">
                  Request Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, ind) => (
                <TableRow
                  classNAme="!bg-white  !bg-opacity-20"
                  key={item?.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    className="!border-r !py-2 !text-center !border-white !border-opacity-50"
                    component="th"
                    scope="row"
                  >
                    {ind + 1}
                  </TableCell>
                  <TableCell
                    className="!border-r !py-2 !text-center !border-white !border-opacity-50"
                    component="th"
                    scope="row"
                  >
                    {item?.request_title || "--"}
                  </TableCell>

                  <TableCell className="!border-r !py-2 !text-center !border-white !border-opacity-50">
                    {item?.category || "--"}
                  </TableCell>
                  <TableCell className="!border-r !py-2 !text-center !border-white !border-opacity-50">
                    <CustomButton
                      startIcon={
                        <Circle
                          fontSize="inherit"
                          color={
                            item?.status === "Pending" ? "warning" : item?.status === "Accepted" ? "success" : "error"
                          }
                        />
                      }
                    >
                      {item?.status}
                    </CustomButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {data.length === 0 && <span className="flex justify-center p-3">No Data Found</span>}
      </GlassDiv>
    </>
  )
}
export default RequestedInventory
