import FilterAltIcon from "@mui/icons-material/FilterAlt"
import { CustomIconButton } from "../../shared/CustomIconButton"
import Search from "../../shared/Search"
import CreateNewCombo from "./CreateNewCombo"

const VariantCombinations = () => {
  return (
    <>
      <div className="!bg-gray-100">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font-bold text-black font">Variant Combinations</p>
            <p className="text-black">Manage Variant Groups</p>
          </div>
          <CreateNewCombo />
        </div>

        <div className="flex flex-col !my-3 !rounded  bg-dark border-secondary shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center p-2 bg-gray-100 h-14 border-zinc-600">
            <CustomIconButton>
              <FilterAltIcon className="text-black" />
            </CustomIconButton>
            <Search />
          </div>
          <div className="flex items-center justify-between bg-white border-b-2 border-zinc-600">
            <div className="flex w-full p-4">
              <p>Group Name</p>
            </div>
            <div className="flex justify-center w-full p-4">
              <p>Variant Value</p>
            </div>
            <div className="flex justify-center w-full p-4">
              <p>Status</p>
            </div>

            <div className="flex justify-center w-full">
              <p>Action</p>
            </div>
          </div>

          {/* <SimpleSnackbar /> */}
          {/* {data &&
            data.map((varient) => {
              return (
                <div
                  key={varient.id}
                  className="flex items-center justify-between border-b border-zinc-600"
                >
                  <div className="flex w-full p-4">
                    <p>{varient.variant_name}</p>
                  </div>
                  <div className="flex justify-center w-full p-4">
                    <p>{varient.value}</p>
                  </div>
                  <div className="flex justify-center w-full p-4">
                    <p>{varient.status === 1 ? <Active /> : <Inactive />}</p>
                  </div>
                  <div className="flex justify-center w-full">
                    <Switch
                      color="warning"
                      // id={checkbox.id}
                      // name={checkbox.id}
                      // onChange={formik.handleChange}
                      // checked={formik.values[checkbox.id]}
                    />
                    <AssignVariant variant_id={varient.id} />
                    <CustomIconButton
                    // onClick={() => {
                    //   navigate(`/store/${store.id}`);
                    // }}
                    >
                      <BorderColorIcon />
                    </CustomIconButton>
                    <CustomIconButton
                    // onClick={() => {
                    //   navigate(`/store/${store.id}`);
                    // }}
                    >
                      <DeleteIcon />
                    </CustomIconButton>
                  </div>
                </div>
              );
            })} */}
        </div>
      </div>
    </>
  )
}

export default VariantCombinations
