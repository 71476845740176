import FilterAltIcon from "@mui/icons-material/FilterAlt"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import axios from "config/axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { CustomIconButton } from "../../shared/CustomIconButton"

const OfferManagement = () => {
  const [data, setData] = useState([])
  const [store, setStore] = useState([])
  const [id, setId] = useState(null)
  const [filData, setFilData] = useState([])
  const [searchQuery, setSearchQuery] = useState("")

  const offerData = () => {
    searchQuery === ""
      ? id == null
        ? axios
            .get(`offer-list-for-admin/?page=1`, {})
            .then((response) => {
              setData(response?.data?.data)
            })
            .catch((error) => {
              console.log("", error.response)
            })
        : axios
            .get(`offer-filler-by-store/?store_id=${id}&page=1`, {})
            .then((response) => {
              setData(response?.data?.data)
            })
            .catch((error) => {
              console.log("", error.response)
            })
      : axios
          .get(`offer-list-for-admin/?search_value=${searchQuery}`)
          .then((response) => {
            setData(response?.data?.data)
          })
          .catch((error) => {
            console.log("", error.response)
          })
  }

  useEffect(() => {
    offerData()
  }, [id, searchQuery])

  useEffect(() => {
    axios
      .get(
        `offer-filler-by-store/?store_id=${id}&page=1`,
        {},
        {
          headers: {
            Authorization: "5bec3ae37bd14b0db36c574abdaccd60",
          },
        }
      )
      .then((response) => {
        setFilData(response?.data?.data)
        // console.log("filter data==>",response?.data?.data)
      })
      .catch((error) => {
        console.log("", error.response)
      })
  }, [id])

  const storeData = () => {
    axios
      .get(
        `store-list-api-for-superadmin/`,
        {},
        {
          headers: {
            Authorization: "5bec3ae37bd14b0db36c574abdaccd60",
          },
        }
      )
      .then((response) => {
        setStore(response?.data?.data)
        // console.log("store data==>",response)
      })
      .catch((error) => {
        console.log("", error.response)
      })
  }

  useEffect(() => {
    storeData()
  }, [])

  const handleChange = (offerID) => {
    axios
      .post(
        `offer-enable-disable-for-admin/`,
        { offer_id: offerID },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        console.log("succes")
        toast.success(response.data.message)
        offerData()
      })
      .catch((error) => {
        console.log("", error.response)
      })
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="flex justify-between bg-gray-100">
          <div className="p-2">
            <p className="text-xl font-bold text-black">Manage offers</p>
          </div>
        </div>

        <div className="flex flex-col !my-3 !rounded border-dark shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center justify-between p-2 bg-gray-100 h-14 border-zinc-600">
            <div className="flex">
              <CustomIconButton>
                <FilterAltIcon className="text-black" />
              </CustomIconButton>
              <input
                class="placeholder:italic placeholder:text-slate-400 block bg-white w-80 border border-slate-300 rounded-md py-2 px-2 pr-3 shadow-sm focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
                placeholder="Search for anything..."
                type="text"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
            <div className="flex">
              <select
                className="rounded-md text-black p-1.5 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
                name="store_id"
                id="store_id"
                onChange={(event) => setId(event.target.value)}
              >
                <option value="">Please select store from here</option>
                {store?.map((elem) => (
                  <option value={elem?.id}>{elem?.store_name}</option>
                ))}
              </select>
            </div>
          </div>
          <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    {/* <input
                    type="checkbox"
                    className="w-5 h-5 mr-2 border-gray-300 rounded"
                  /> */}
                    <div className="w-16">SR NO.</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-32">CREATE DATE</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-32">VALID FROM</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-32">VALID UNTIL</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-32">ACTIVE STATUS</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-24">TYPE</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-32">DISCOUNT UNIT</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-32">DISCOUNT VALUE</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-28">OFFER ON</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data ? (
                  data?.map((row, ind) => (
                    <TableRow key={row?.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {ind + 1}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.create_date.slice(0, 10)}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.valid_from.slice(0, 10)}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.valid_until.slice(0, 10)}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {/* <Switch
                              checked={row?.active_status || false}
                              onChange={() => handleChange(row?.id)}
                              label="Jason Killian"
                              size="small"
                        /> */}
                        <input
                          type="checkbox"
                          checked={row.active_status}
                          onChange={() => handleChange(row?.id)}
                          className="toggle checked:!border-green-500 !border-red-500 toggle-success"
                        />
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.offer_type ? row?.offer_type : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.discount_unit ? row?.discount_unit : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {/* discount_value */}
                        {row?.discount_value ? row?.discount_value : "--"}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {/* offer_on */}
                        {row?.offer_on ? row?.offer_on : "--"}
                      </TableCell>
                      {/* <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        
                      </TableCell> */}
                    </TableRow>
                  ))
                ) : (
                  <div className="text-[20px] text-red-500"> No Store Found</div>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default OfferManagement
