import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import CustomModal from "shared/MkxModal"
import CustomInput from "shared/MkxInput"
import { addOperationsFn } from "Services/Operations/AddOperations"
import { updateOperationsFn } from "Services/Operations/UpdateDepartment"
import CustomButton from "shared/MkxButton"

const AddOperations = ({ setSelectDept, selectDept }) => {
  const [open, setOpen] = useState(false)
  const client = useQueryClient()
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectDept({})
  }

  const { mutate: addOperations, isLoading: isLoading } = useMutation(addOperationsFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("operations")
        handleClose()
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const { mutate: updateOperations, isLoading: isLoading1 } = useMutation(updateOperationsFn, {
    onSuccess: (response) => {
      if (response.data.response_code === 200) {
        toast.success(response.data.message)
        client.refetchQueries("operations")
        handleClose()
      } else {
        toast.error(response.data.message)
      }
    },
  })
  const initialValues = {
    operations: selectDept?.operations || "",
    description: selectDept?.description || "",
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      const formData = new FormData()
      if (selectDept?.id) {
        formData.append("operations_id", selectDept?.id)
        formData.append("operations", formik.values.operations)
        formData.append("description", formik.values.description)
        updateOperations(formData)
      } else {
        formData.append("operations", formik.values.operations)
        formData.append("description", formik.values.description)
        addOperations(formData)
      }
    },
  })
  useEffect(() => {
    selectDept && Object.keys(selectDept)?.length !== 0 && handleOpen()
  }, [selectDept])

  return (
    <>
      <CustomButton className="mx-2 !px-5">
        <span className="flex items-center " onClick={handleOpen}>
          <img
            src="https://pub-16f3de4d2c4841169d66d16992f9f0d3.r2.dev/assets/Inventory/addbutton.svg"
            alt=""
            className="h-6 mt-1 mr-2"
          />
          Operations
        </span>
      </CustomButton>

      <CustomModal
        title={selectDept?.id ? "Update Operations" : "Add Operations"}
        open={open}
        onClose={handleClose}
        className="!w-1/4"
      >
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
          <CustomInput id="operations" placeholder="Operations Name" label="Operations Name" formik={formik} />
          <CustomInput
            id="description"
            placeholder="Description Name"
            label="Description Name"
            formik={formik}
            multiline
            rows={5}
          />

          <div className="flex justify-end gap-3 my-2">
            <CustomButton type="button" onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton isLoading={selectDept?.id ? isLoading1 : isLoading} type="submit">
              {selectDept?.id ? "Update" : "Add"}
            </CustomButton>
          </div>
        </form>
      </CustomModal>
    </>
  )
}

export default AddOperations
