import { useState } from "react"
import MkxButton from "shared/MkxButton"
import MkxModal from "shared/MkxModal"

const FileViewer = ({ url }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <MkxButton onClick={() => setOpen(true)}>View</MkxButton>
      <MkxModal open={open} onClose={() => setOpen(false)} title="User Manual">
        <iframe src={`${url}#toolbar=0`} className="w-[60vw] h-[80vh]" />
      </MkxModal>
    </>
  )
}

export default FileViewer
