import axiosInstance from "config/axios"

export const rolesFn = (reqbody) => {
  try {
    const response = axiosInstance.get(`store-role-settings-api-of-superadmin/`, {
      params: reqbody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateRolesFn = (reqbody) => {
  try {
    const response = axiosInstance.put(`store-role-settings-api-of-superadmin/`, reqbody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const getRolesFn = (reqbody) => {
  try {
    const response = axiosInstance.get(`add-store-role-settings-api-of-superadmin/`, {
      params: reqbody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const addRolesFn = (reqbody) => {
  try {
    const response = axiosInstance.post(`add-store-role-settings-api-of-superadmin/`, reqbody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const deleteRolesFn = (reqbody) => {
  try {
    const response = axiosInstance.delete(`store-role-settings-api-of-superadmin/`, {
      data: reqbody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
