import { Edit, Visibility, VisibilityOff } from "@mui/icons-material"
import { IconButton, Switch, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { changeShipRocketConfigFn, getShipRocketConfigFn } from "Services/Settings/DeliverySetting"
import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import GlassDiv from "shared/CustomDiv"
import CustomPagination from "shared/MkxTable/MkxPagination"
import MkxTableCell from "shared/MkxTable/MkxTableCell"
import AddConfig from "./AddConfig"
const ShipRoket = () => {
  const { store_id } = useParams()
  const [page, setPage] = useState(1)
  const [isViewPassword, setIsViewPassword] = useState("")
  const [selected, setSelected] = useState(false)
  const { data: getShipRocketConfig, isLoading } = useQuery(
    ["getShipRocketConfig"],
    () => getShipRocketConfigFn({ page, store_id }),
    { refetchOnWindowFocus: false }
  )
  const client = useQueryClient()

  const { mutate: changeStatus } = useMutation(changeShipRocketConfigFn, {
    onSuccess: ({ data }) => {
      if (data.response_code === 200) {
        toast.success(data.message)
        client.refetchQueries("getShipRocketConfig")
      } else {
        toast.error(data.message)
      }
    },
  })
  const configs = getShipRocketConfig?.data?.data
  return (
    <div className="flex flex-col h-full p-1 bg-gray-200">
      <GlassDiv className="overflow-y-scroll w-full h-full !p-0 !overflow-auto">
        <span className="flex items-center justify-between p-3">
          <p className="text-lg font-semibold">Ship Rocket</p>
          <AddConfig setSelected={setSelected} selected={selected} />
        </span>
        <TableContainer component="div" className="!p-0">
          <Table className="bg-white whitespace-nowrap bg-opacity-20 !overflow-x-scroll">
            <TableHead className="!bg-white !bg-opacity-30">
              <TableRow>
                <MkxTableCell isHead>EMAIL</MkxTableCell>
                <MkxTableCell isHead>PASSWORD</MkxTableCell>
                <MkxTableCell isHead>CREATE DATE</MkxTableCell>
                <MkxTableCell isHead>UPDATE DATE</MkxTableCell>
                <MkxTableCell isHead>STATUS</MkxTableCell>
                <MkxTableCell isHead>ACTION</MkxTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {configs?.map((config) => {
                return (
                  <TableRow className="!cursor-pointer">
                    <MkxTableCell>{config?.username}</MkxTableCell>
                    <MkxTableCell className="w-40">
                      <div className="flex items-center justify-between">
                        {isViewPassword === config.id ? config?.password : "••••••••••••••••"}
                        <IconButton
                          size="small"
                          onClick={() => setIsViewPassword(isViewPassword === config.id ? "" : config.id)}
                        >
                          {isViewPassword === config.id ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </div>
                    </MkxTableCell>
                    <MkxTableCell>{config?.created_at}</MkxTableCell>
                    <MkxTableCell>{config?.updated_at}</MkxTableCell>
                    <MkxTableCell>
                      <Switch
                        checked={config.active_status}
                        onChange={() => changeStatus({ store_id, shiprocket_id: config.id })}
                      />
                    </MkxTableCell>
                    <MkxTableCell>
                      <IconButton size="small" onClick={() => setSelected(config)}>
                        <Edit className="hover:!text-blue-500" />
                      </IconButton>
                    </MkxTableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination setPage={setPage} data={getShipRocketConfig} />
      </GlassDiv>
    </div>
  )
}

export default ShipRoket
