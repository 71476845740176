import React, { useEffect, useState } from "react"
import axios from "config/axios"
import { useFormik } from "formik"
import { Box, Modal } from "@mui/material"
import { CustomButton } from "../../../shared/CustomButton"

const AddState = () => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [country, setCountry] = useState()

  const CountryList = () => {
    axios
      .post(`country-list-data/`, {})
      .then((response) => {
        setCountry(response?.data.country_data_list)
        // alert(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response)
        console.log(error)
        // alert(error.message);
      })
  }
  useEffect(() => {
    CountryList()
  }, [])

  const initialValues = {
    country_id: "",
    state_name: "",
  }

  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: (values, action) => {
      const reqBody = {
        country_id: values.country_id,
        state_name: values.state_name,
      }

      axios
        .post(`add-state-data/`, { ...reqBody })
        .then((response) => {
          console.log(response.data)
          alert(response.data.message)
          handleClose()
        })
        .catch((error) => {
          console.log("", error.response)
          console.log(error)
          alert(error.message)
        })
      action.resetForm()
    },
  })

  return (
    <>
      <CustomButton onClick={handleOpen}>+ Add State</CustomButton>
      <Modal open={open} onClose={handleClose}>
        <Box className="absolute w-1/3 p-4 -translate-x-1/2 -translate-y-1/2 rounded shadow-md outline-none top-1/2 left-1/2 text-zinc-300 bg-secondary">
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 mx-auto">
            <p className="my-5 text-2xl font-semibold text-center text-white underline">Add State</p>
            <p className="text-white">Country</p>
            <select
              className="p-2 text-black bg-white border-2 rounded-md sm:w-auto focus:outline-none border-zinc-700"
              name="country_id"
              id="country_id"
              value={formik.values["country_id"]}
              onChange={formik.handleChange}
            >
              <option value="">Select Country</option>
              {country &&
                country.length > 0 &&
                country.map((country) => {
                  return <option value={country.id}>{country.name}</option>
                })}
            </select>

            <p className="text-left text-white">State Name</p>
            <input
              autoComplete="off"
              className="p-2 text-black bg-white border-2 rounded-md sm:w-auto focus:outline-none border-zinc-700"
              name="state_name"
              id="state_name"
              value={formik.values["state_name"]}
              onChange={formik.handleChange}
              placeholder="Enter State Name"
            />
            <CustomButton className="!mx-auto px-4 !my-7 !p-2" type="submit">
              Add State
            </CustomButton>
          </form>
        </Box>
      </Modal>
    </>
  )
}

export default AddState
