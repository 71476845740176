import { Box, Button, FormControl, Modal, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../../config/axios";
import { CustomButton } from "../../../shared/CustomButton";

const AddBussinessCategory = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const initialValues = {
    name: "",
  };

  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: (values, action) => {
      const reqBody = {
        category_name: values.category_name,
      };

      axiosInstance
        .post(`business-category-add/`, reqBody)
        .then((response) => {
          if (response?.data?.response_code === 200) {
            toast.success(response.data.message);
            handleClose();
          } else {
            toast.warning(response.data.message);
          }
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
        });
      action.resetForm();
    },
  });
  return (
    <>
      <CustomButton onClick={handleOpen} className="border border-black">
        + Add Bussiness Type
      </CustomButton>
      <Modal open={open} onClose={handleClose}>
        <Box className="absolute w-1/3 px-8 py-6 -translate-x-1/2 -translate-y-1/2 bg-white rounded shadow-sm outline-none top-1/2 left-1/2 shadow-blue-900">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col gap-3 py-3 text-black"
          >
            <p className="py-4 text-2xl text-center underline underline-offset-2">
              Add New Category
            </p>
            <FormControl size="small">
              <p>Category Name*</p>
              <TextField
                name="category_name"
                value={formik.values["category_name"]}
                onChange={formik.handleChange}
                size="small"
                placeholder="Enter Business Category Name"
              />
            </FormControl>
            <div className="flex justify-end py-2">
              <Button variant="contained" type="submit" className="!capitalize">
                Add Category
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default AddBussinessCategory;
